// HELPER
// import { loadScript } from './BookingHelper'

// AXIOS
import axios from 'axios'

export const displayRazorpay = async (
  amount_payable: number,
  balance_amount: number,
  bookingId: number | null,
  paymentOption: string,
  name: string | null,
  email: string | null,
  mobile: number | null,
  setLoading: (loading: boolean) => void,
  toggleBookingModalFromRZP: () => void,
  couponCode: string | null,
  setPaymentClose?: () => void
): Promise<any> => {
  // const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
  if (!couponCode) {
    couponCode = ''
  }

  if (window && !window.Razorpay) {
    alert('Razorpay SDK failed to load. Are you online?')
    return
  }

  let RAZORPAY_URL = `/payment/razorpay/${bookingId}`
  RAZORPAY_URL =
    paymentOption !== ''
      ? `${RAZORPAY_URL + paymentOption}&coupon_code=${couponCode}`
      : RAZORPAY_URL

  const bookingData = await axios.get(RAZORPAY_URL).then((res) => res.data)

  const { id, amount, currency, notes } = bookingData.order

  const options = {
    key: process.env.NEXT_PUBLIC_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
    amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency,
    name: 'COOX',
    // description: "Test Transaction",
    // image: { logo },
    order_id: id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function () {
      // toggleBookingModalFromRZP()

      if (amount_payable === balance_amount) {
        // paid full amount
        window.location.href = `/my-bookings?bookingId=${bookingId}&event=success&payment=paid_advance`
      } else {
        // paid balance amount
        window.location.href = `/my-bookings?bookingId=${bookingId}&event=success&payment=paid_balance`
      }
      // window.location.href = `/my-bookings/${bookingId}/success?payment=paid_advance`
      // alert(response.razorpay_payment_id);
      // alert(response.razorpay_order_id);
      // alert(response.razorpay_signature);
    },
    prefill: {
      name,
      email,
      contact: mobile,
    },
    notes,
    theme: {
      color: '#ffc107',
    },
    modal: {
      ondismiss: function () {
        setLoading(false)
        setPaymentClose && setPaymentClose()
      },
    },
  }
  const paymentObject = new window.Razorpay(options)

  paymentObject.open()
  // setLoading(false)
}
