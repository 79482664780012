import { useEffect, useState } from 'react'

// BOOTSTRAP
import { Button, Modal } from 'react-bootstrap'

// Dayjs
import dayjs from 'dayjs'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import authSlice from '@/lib/slices/authSlice'

// AXIOS
import axios from 'axios'

// HELPERS
import { displayRazorpay } from './displayRazorpay'
import { displayPaytm } from './displayPaytm'
import { getCuisineIdsByComboId } from '@/components/BookingModals/BookChefModal/helpers'
// GTM
import { gtmEvent } from '@/lib/gtm'

// TS INTERFACES
import { SWtype } from '../commonInterfaces'
import { RootState } from '@/lib/rootState'
import { IaddressFromAPI } from 'types/bookingModalTypes'
import { useRouter } from 'next/router'
import { FaArrowRight } from 'react-icons/fa'

interface ICTAHelper {
  loading: boolean
  setLoading: any
  activeStep: number
  SW: SWtype | null
}

export const checkingAvailabilityCondition = (
  city: string,
  state: string,
  bookingDate: string,
  meal: string,
  cities: any
): boolean => {
  let validDate = false
  const today = dayjs().format('YYYY-MM-DD')
  // const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')
  if (today === bookingDate) {
    validDate = true
  }
  // if (tomorrow === bookingDate && meal !== 'dinner') {
  //   if (dayjs().isAfter(`${today} 18:00:00`)) {
  //     validDate = true
  //   }
  // }
  if (cities !== undefined && cities.length !== 0) {
    for (const tempCity of cities) {
      if (
        (tempCity.name === city &&
          tempCity.state === state &&
          tempCity.bookable === 0) ||
        validDate
      ) {
        return true
      } else if (
        tempCity.name === city &&
        tempCity.state === state &&
        tempCity.bookable === 1
      ) {
        return false
      }
    }
    // return true
  } else {
    return true
  }
  // if (
  //   [
  //     'Navi Mumbai',
  //     'Hyderabad',
  //     'Thane',
  //     'Palwal',
  //     'Jhajjar',
  //     'Sonipat',
  //     'Panchgaon',
  //     'Ballabgarh',
  //     'Bahadurgarh',
  //     'Dadri',
  //     'Bulandshahr',
  //     'Panipat',
  //     'Sona',
  //     'Meerut',
  //     'Manesar',
  //     'Aligarh',
  //     'Rohtak',
  //     'Karnal',
  //     'Vashi',
  //     'Hapur',
  //     'Muzaffarnagar',
  //     'Muzzafarnagar',
  //     'Jaipur',
  //     'Ahmedabad',
  //     'Kolkata',
  //     'Chandigarh',
  //     'Surat',
  //     'Coimbatore',
  //     'Chembur',
  //     'Pune',
  //     'Mohali',
  //     'Panchkula',
  //   ].includes(city) ||
  //   validDate
  // )
  //   return true
  // else return false
}

export const CTAHelper = ({
  activeStep,
  SW,
  loading,
  setLoading,
}: ICTAHelper): JSX.Element => {
  const router = useRouter()
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )
  const {
    userAddressData: { addresses },
    citiesList,
    upgradeBooking,
    addAssistant,
  } = REDUX__bookingModalData

  const REDUX__authData = useSelector((state: RootState) => state.auth)
  const combos = useSelector((state: RootState) => state.apiData.combos)
  const unPublishedCombos = useSelector(
    (state: RootState) => state.apiData.unPublishedCombos
  )
  const { userData } = REDUX__authData

  const dispatch = useDispatch()

  // const [setModalOpen, setShowOpenModal] = useState(false)

  const [address, setAddress] = useState([])
  const [city, setCity] = useState([])
  const [cuisineIds, setCuisineIds] = useState([])

  useEffect(() => {
    if (
      userData?.id !== null &&
      userData?.name !== null &&
      userData?.email !== null
    ) {
      axios.get(`/user/address`).then((res) => {
        if (addresses !== res.data.addresses) {
          dispatch(
            bookingModalSlice.actions.setUserAddressData({
              addresses: res.data.addresses,
            })
          )
          for (let i = 0; i < res.data.addresses.length; i++) {
            if (res.data.addresses[i]?.is_default === 1) {
              dispatch(
                bookingModalSlice.actions.setSelectedAddressId(
                  res.data.addresses[i]?.address_id
                )
              )
            }
          }
        }
        if (res.data.addresses !== address) setAddress(res.data.addresses)
      })
    }
  }, [])

  useEffect(() => {
    axios.get(`/ocl/cities`).then((res) => {
      if (citiesList !== res.data.cities) {
        dispatch(bookingModalSlice.actions.setCitiesList(res.data.cities))
      }

      if (res.data.cities !== city) setCity(res.data.cities)
    })
  }, [])
  // handler for step 5 & 6 & 7
  const validateBookingDesignXYZMenu = (
    callback: ((stepName?: string) => void) | undefined,
    stepType: 'lunchStep' | 'dinnerStep' | 'breakfastStep',
    typeDishLength: number,
    gasRequiredDishesLength: number
  ) => {
    /**
     * validates and submits booking data to booking summary API
     * @param {callback} - it is a function that increases the step count
     */

    //  Destructuring
    const {
      bookingDetails: {
        occasionId,
        numberOfPeople,
        bookingDate,
        bookingTime,
        meal,
        bookingId,
        comboIds,
        bookingLevel,
        numberOfBurners,
      },
      designMenu: {
        cuisineIds,
        dishDetails,
        lunchDishes,
        dinnerDishes,
        breakfastDishes,
      },
      userAddressData: { selectedAddressId },
      dishesAllowed,
    } = REDUX__bookingModalData
    const { userData } = REDUX__authData

    if (typeDishLength < 2 || typeDishLength === undefined || !typeDishLength) {
      alert('Please select at least 2 dishes')
      return false
    }

    if (typeDishLength > 16) {
      alert('Please select max 16 dishes')
      return false
    }

    const numberOfburner = parseInt(numberOfBurners, 10)
    if (dishesAllowed && gasRequiredDishesLength > dishesAllowed) {
      alert(
        `With ${numberOfburner} ${
          numberOfburner > 1 ? 'gas burners' : 'gas burner'
        }, you can choose max ${dishesAllowed} dishes that are prepared on gas`
      )
      return false
    }

    // if (meal === 'lunch') {
    if (
      localStorage.getItem('expiresIn') == null &&
      ((meal === 'breakfast_lunch_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast_lunch' && stepType === 'lunchStep') ||
        (meal === 'lunch_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast' && stepType === 'breakfastStep') ||
        (meal === 'lunch' && stepType === 'lunchStep') ||
        (meal === 'dinner' && stepType === 'dinnerStep'))
    ) {
      // If expiresIn is null in localStorage then ask the user to login

      dispatch(bookingModalSlice.actions.setTruecallerOpen(true))
      dispatch(authSlice.actions.setSource('booking_modal'))
      setTimeout(() => {
        dispatch(authSlice.actions.toggleLoginModal())
      }, 0)
    } else if (
      userData.id &&
      (!userData?.name || !userData?.email) &&
      ((meal === 'breakfast_lunch_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast_lunch' && stepType === 'lunchStep') ||
        (meal === 'lunch_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast' && stepType === 'breakfastStep') ||
        (meal === 'lunch' && stepType === 'lunchStep') ||
        (meal === 'dinner' && stepType === 'dinnerStep'))
    ) {
      // If user-name or user-email is empty in Redux then show userDetails Modal
      const mobile = JSON.parse(localStorage.getItem('authUser')).mobile
      // console.log('m', mobile)
      dispatch(authSlice.actions.toggleUserDetailsModal())
      // if (!bookingId) {
      const dishObjFunc = (dish: any) => ({
        id: dish.id,
        meal_type: dish.meal_type,
      })
      const lunchDishes2 = lunchDishes?.map(dishObjFunc)
      const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
      const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
      const dishDetails2 = dishDetails?.map(dishObjFunc)
      // Create booking lead
      axios({
        method: 'POST',
        url: `/booking`,
        data: {
          cuisines: cuisineIds,
          occasion_id: occasionId,
          number_of_people: numberOfPeople,
          booking_date: bookingDate,
          booking_time: bookingTime,
          dish_details:
            meal === 'breakfast_lunch_dinner'
              ? [...lunchDishes2, ...dinnerDishes2, ...breakfastDishes2]
              : meal === 'breakfast_lunch'
              ? [...lunchDishes2, ...breakfastDishes2]
              : meal === 'breakfast_dinner'
              ? [...dinnerDishes2, ...breakfastDishes2]
              : meal === 'lunch_dinner'
              ? [...dinnerDishes2, ...lunchDishes2]
              : dishDetails2,
          cuisine_combos: comboIds,
          booking_type: meal,
          cook_level: bookingLevel,
          mobile: String(mobile),
        },
      }).then((res) => {
        setLoading(false)

        dispatch(
          bookingModalSlice.actions.setBookingId(res.data.data.booking.id)
        )
        dispatch(
          bookingModalSlice.actions.setSpclAppliances(
            res.data.data.booking.special_appliances
          )
        )
      })
      // }
    } else {
      setLoading(true)
      if (
        (meal === 'lunch' && stepType === 'lunchStep') ||
        (meal === 'dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast' && stepType === 'breakfastStep') ||
        (meal === 'breakfast_lunch_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast_lunch' && stepType === 'lunchStep') ||
        (meal === 'lunch_dinner' && stepType === 'dinnerStep') ||
        (meal === 'breakfast_dinner' && stepType === 'dinnerStep')
      ) {
        const mobile = JSON.parse(localStorage.getItem('authUser')).mobile
        // console.log('mob', mobile)
        const dishObjFunc = (dish: any) => ({
          id: dish.id,
          meal_type: dish.meal_type,
        })
        const lunchDishes2 = lunchDishes?.map(dishObjFunc)
        const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
        const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
        const dishDetails2 = dishDetails?.map(dishObjFunc)
        // Create booking lead
        axios({
          method: 'POST',
          url: `/booking`,
          data: {
            cuisines: cuisineIds,
            occasion_id: occasionId,
            number_of_people: numberOfPeople,
            booking_date: bookingDate,
            booking_time: bookingTime,
            dish_details:
              meal === 'breakfast_lunch_dinner'
                ? [...lunchDishes2, ...dinnerDishes2, ...breakfastDishes2]
                : meal === 'breakfast_lunch'
                ? [...lunchDishes2, ...breakfastDishes2]
                : meal === 'breakfast_dinner'
                ? [...dinnerDishes2, ...breakfastDishes2]
                : meal === 'lunch_dinner'
                ? [...dinnerDishes2, ...lunchDishes2]
                : dishDetails2,
            cuisine_combos: comboIds,
            booking_type: meal,
            cook_level: bookingLevel,
            mobile: String(mobile),
          },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        })
          .then((res) => {
            setLoading(false)

            dispatch(
              bookingModalSlice.actions.setBookingId(res.data.data.booking.id)
            )
            dispatch(
              bookingModalSlice.actions.setSpclAppliances(
                res.data.data.booking.special_appliances
              )
            )
            if (res.data.data.booking.special_appliances.length === 0) {
              if (
                address.length === 0 &&
                addresses.length === 0 &&
                ((meal === 'breakfast_lunch_dinner' &&
                  stepType === 'dinnerStep') ||
                  (meal === 'breakfast_lunch' && stepType === 'lunchStep') ||
                  (meal === 'lunch_dinner' && stepType === 'dinnerStep') ||
                  (meal === 'breakfast_dinner' && stepType === 'dinnerStep') ||
                  (meal === 'breakfast' && stepType === 'breakfastStep') ||
                  (meal === 'lunch' && stepType === 'lunchStep') ||
                  (meal === 'dinner' && stepType === 'dinnerStep'))
              ) {
                if (selectedAddressId) {
                  const dishObjFunc = (dish: any) => ({
                    id: dish.id,
                    meal_type: dish.meal_type,
                  })
                  const lunchDishes2 = lunchDishes?.map(dishObjFunc)
                  const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
                  const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
                  const dishDetails2 = dishDetails?.map(dishObjFunc)
                  const numberOfburner = parseInt(numberOfBurners, 10)
                  const appID = []
                  if (numberOfburner === 1) {
                    appID[0] = 41
                  } else if (numberOfburner == 2) {
                    appID[0] = 35
                  } else if (numberOfburner == 3) {
                    appID[0] = 36
                  } else if (numberOfburner == 4) {
                    appID[0] = 37
                  } else if (numberOfburner == 5) {
                    appID[0] = 38
                  } else if (numberOfburner == 6) {
                    appID[0] = 42
                  }
                  setLoading(true)
                  axios({
                    method: 'POST',
                    url: `/booking/summary`,
                    data: {
                      cuisines: cuisineIds,
                      occasion_id: occasionId,
                      cuisine_combos: comboIds,
                      number_of_people: numberOfPeople,
                      booking_date: bookingDate,
                      booking_time: bookingTime,
                      dish_details:
                        meal === 'lunch' ||
                        meal === 'dinner' ||
                        meal === 'breakfast'
                          ? dishDetails2
                          : breakfastDishes2
                              ?.concat(lunchDishes2)
                              ?.concat(dinnerDishes2),
                      payment_option: 2,
                      appliance_ids: appID, //add comma separated appliance ids in this array
                      address_id: selectedAddressId,
                      booking_id: res.data.data.booking.id,
                      booking_type: meal,
                      upgrade_booking: upgradeBooking,
                      add_assistant: addAssistant,
                    },
                    headers: {
                      Authorization:
                        'Bearer ' + localStorage.getItem('accessToken'),
                    },
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(
                          bookingModalSlice.actions.setBookingSummary(
                            response.data.data
                          )
                        )
                        return callback && callback('summary')
                      }
                    })
                    .catch((error) => {
                      if (error.response.status === 406) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error.response.status === 500) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {
                      setLoading(false)
                    })

                  // return SW?.goToStep(9)
                } else {
                  dispatch(
                    bookingModalSlice.actions.toggleAddAddressModal({
                      stepNo: 4,
                    })
                  )
                  return false
                }
              } else {
                if (selectedAddressId) {
                  const dishObjFunc = (dish: any) => ({
                    id: dish.id,
                    meal_type: dish.meal_type,
                  })
                  const lunchDishes2 = lunchDishes?.map(dishObjFunc)
                  const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
                  const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
                  const dishDetails2 = dishDetails?.map(dishObjFunc)
                  const numberOfburner = parseInt(numberOfBurners, 10)
                  const appID = []
                  if (numberOfburner === 1) {
                    appID[0] = 41
                  } else if (numberOfburner == 2) {
                    appID[0] = 35
                  } else if (numberOfburner == 3) {
                    appID[0] = 36
                  } else if (numberOfburner == 4) {
                    appID[0] = 37
                  } else if (numberOfburner == 5) {
                    appID[0] = 38
                  } else if (numberOfburner == 6) {
                    appID[0] = 42
                  }
                  setLoading(true)
                  // console.log('setting setload to true')
                  axios({
                    method: 'POST',
                    url: `/booking/summary`,
                    data: {
                      cuisines: cuisineIds,
                      occasion_id: occasionId,
                      cuisine_combos: comboIds,
                      number_of_people: numberOfPeople,
                      booking_date: bookingDate,
                      booking_time: bookingTime,
                      dish_details:
                        meal === 'lunch' ||
                        meal === 'dinner' ||
                        meal === 'breakfast'
                          ? dishDetails2
                          : breakfastDishes2
                              ?.concat(lunchDishes2)
                              ?.concat(dinnerDishes2),
                      payment_option: 2,
                      appliance_ids: appID, //add comma separated appliance ids in this array
                      address_id: selectedAddressId,
                      booking_id: res.data.data.booking.id,
                      booking_type: meal,
                      upgrade_booking: upgradeBooking,
                      add_assistant: addAssistant,
                    },
                    headers: {
                      Authorization:
                        'Bearer ' + localStorage.getItem('accessToken'),
                    },
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(
                          bookingModalSlice.actions.setBookingSummary(
                            response.data.data
                          )
                        )
                        return callback && callback('summary')
                      }
                    })
                    .catch((error) => {
                      if (error.response.status === 406) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error.response.status === 500) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {
                      setLoading(false)
                    })

                  // return SW?.goToStep(9)
                } else {
                  SW?.goToNamedStep('address')
                }
              }
            } else {
              SW?.goToNamedStep('appliances')
            }
          })
          .catch(() => {
            setLoading(false)

            // alert('Some error occured!')
          })
          .finally(() => {
            // setLoading(false)
          })
        // axios
        //   .get(`/user/address`, {
        //     headers: {
        //       Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        //     },
        //   })
        //   .then((response) => {
        //     const defaultAddressId: number = (
        //       response.data.addresses.find(
        //         (address: IaddressFromAPI) => address.is_default === 1
        //       ) || { address_id: null }
        //     ).address_id

        //     dispatch(
        //       bookingModalSlice.actions.setUserAddressData({
        //         addresses: response.data.addresses,
        //         selectedAddressId: defaultAddressId,
        //       })
        //     )
        //   })
      } else if (meal === 'breakfast_lunch' && stepType === 'breakfastStep') {
        callback && callback('lunch')
        setLoading(false)
      } else if (
        meal === 'breakfast_lunch_dinner' &&
        stepType === 'breakfastStep'
      ) {
        callback && callback('lunch')
        setLoading(false)
      } else if (
        meal === 'breakfast_lunch_dinner' &&
        stepType === 'lunchStep'
      ) {
        callback && callback('dinner')
        setLoading(false)
      } else if (meal === 'breakfast_dinner' && stepType === 'breakfastStep') {
        callback && callback('dinner')
        setLoading(false)
      } else if (meal === 'lunch_dinner' && stepType === 'lunchStep') {
        callback && callback('dinner')
        setLoading(false)
      } else {
        callback && callback()
        setLoading(false)
      }
    }
  }

  switch (activeStep) {
    // ---------------------- STEP 1 CTA ----------------------
    case 1: {
      const getGasDetails = (callback: (() => void) | undefined) => {
        if (callback) {
          callback()
        }
      }

      return (
        <Button
          onClick={() => getGasDetails(SW?.nextStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------- STEP 2 CTA ----------------------
    case 2: {
      const {
        bookingDetails: {
          occasionId,
          numberOfPeople,
          bookingDate,
          bookingTime,
          meal,
          comboIds,
          numberOfBurners,
        },
      } = REDUX__bookingModalData
      // const combos = useSelector((state: RootState) => state.apiData.combos)
      const validateBookingDetails = (
        callback: ((stepName?: string) => void) | undefined
      ) => {
        // Condtions to check mandatory fields
        if (occasionId == null) {
          alert('Please Select Occasion')
          ;(document as any)
            .getElementById('bh_select_occasion')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (bookingDate === '') {
          alert('Please Select Date')
          ;(document as any)
            .getElementById('goToDate')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (numberOfPeople === '') {
          alert('Please Select Number of People')
          ;(document as any)
            .getElementById('goToPeople')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (meal === '') {
          alert('Please Select Meal')
          ;(document as any)
            .getElementById('goToMeal')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }

        if (!bookingTime || bookingTime === null) {
          alert('Please Select Time')
          ;(document as any)
            .getElementById('goToTime')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }

        if (comboIds.length === 0) {
          alert('Please select Cuisine')
          ;(document as any)
            .getElementById('goToCuisine')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }

        if (!numberOfBurners) {
          alert('Please select Gas Burner')
          ;(document as any)
            .getElementById('goToGasBurner')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }

        // to check next day booking timing
        // const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')
        // if (tomorrow === bookingDate && meal !== 'dinner') {
        //   const today = dayjs().format('YYYY-MM-DD')
        //   if (dayjs().isAfter(`${today} 18:00:00`)) {
        //     setShowOpenModal(true)
        //     return false
        //   }
        // }
        let optedCuisines = []
        let specialCombo = unPublishedCombos.filter((combo) =>
          comboIds.includes(combo.id)
        )
        let optedCombos = combos.filter((combo) => comboIds.includes(combo.id))
        optedCombos = [...optedCombos, ...specialCombo]
        optedCombos.forEach((c) => {
          optedCuisines = [...optedCuisines, ...c.cuisines]
        })
        let optedCuisine = []
        optedCuisines.forEach((c) => {
          console.log(c)
          optedCuisine = [...optedCuisine, c.cuisine_id]
        })
        // setCuisineIds(getCuisineIdsByComboId(comboIds, combos))
        setLoading(true)
        const numberOfburner = parseInt(numberOfBurners, 10)
        let meal_types = []
        if (meal === 'lunch' || meal === 'dinner' || meal === 'breakfast')
          meal_types = [meal]
        else if (meal == 'lunch_dinner') {
          meal_types = ['lunch', 'dinner']
        } else if (meal == 'breakfast_lunch') {
          meal_types = ['lunch', 'breakfast']
        } else if (meal == 'breakfast_dinner') {
          meal_types = ['breakfast', 'dinner']
        } else if (meal == 'breakfast_lunch_dinner') {
          meal_types = ['breakfast', 'lunch', 'dinner']
        }
        // console.log(REDUX__bookingModalData)
        // console.log(meal_types)
        // console.log(cuisineIds)
        axios({
          method: 'POST',
          url: `/booking/menu-dishes`,
          data: {
            cuisine_ids: optedCuisine,
            // occasion_id: occasionId,
            // number_of_people: numberOfPeople,
            // booking_date: bookingDate,
            // booking_time: bookingTime,
            // dish_details:
            //   meal === 'breakfast_lunch_dinner'
            //     ? [...lunchDishes2, ...dinnerDishes2, ...breakfastDishes2]
            //     : meal === 'breakfast_lunch'
            //     ? [...lunchDishes2, ...breakfastDishes2]
            //     : meal === 'breakfast_dinner'
            //     ? [...dinnerDishes2, ...breakfastDishes2]
            //     : meal === 'lunch_dinner'
            //     ? [...dinnerDishes2, ...lunchDishes2]
            //     : dishDetails2,
            combo_ids: comboIds,
            number_of_burners: numberOfburner,
            meal_types: meal_types,
            // cook_level: bookingLevel,
            // mobile: String(mobile),
          },
        })
          .then((res) => {
            if (res.status === 200) {
              dispatch(
                bookingModalSlice.actions.setDishesAllowed(
                  res.data.dishes_allowed
                )
              )
              dispatch(bookingModalSlice.actions.setMenuData(res.data.dishes))
              if (meal === 'dinner') {
                callback && callback('dinner')
              } else if (meal === 'lunch' || meal === 'lunch_dinner') {
                callback && callback('lunch')
              } else {
                callback && callback()
              }
              console.log(res)
            }
            // callback && callback()
          })
          .finally(() => {
            setLoading(false)
          })
        // axios({
        //   method: 'POST',
        //   url: `/appliances/dishes_allowed`,
        //   data: {
        //     number_of_burners: numberOfburner,
        //   },
        // })
        //   .then((response) => {
        //     // console.log('bakkakaka', callback)

        //     if (response.status === 200) {
        //       dispatch(
        //         bookingModalSlice.actions.setDishesAllowed(
        //           response.data.dishes_allowed
        //         )
        //       )
        //       if (meal === 'dinner') {
        //         callback && callback('dinner')
        //       } else if (meal === 'lunch' || meal === 'lunch_dinner') {
        //         callback && callback('lunch')
        //       } else {
        //         callback && callback()
        //       }
        //     }
        //   })
        //   .finally(() => {
        //     setLoading(false)
        //   })
        //dfdsfds
        // if (callback) {
        //   callback()
        // }
        // setLoading(false)
      }
      return (
        <>
          <Button
            onClick={() => {
              if (
                meal === 'breakfast' ||
                meal === 'breakfast_lunch' ||
                meal === 'breakfast_dinner' ||
                meal === 'breakfast_lunch_dinner'
              ) {
                validateBookingDetails(SW?.nextStep)
              } else {
                validateBookingDetails(SW?.goToNamedStep)
              }
            }}
            block
            variant="warning"
            disabled={loading}
          >
            Proceed{' '}
            {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
            {loading && '...'}
          </Button>
          {/* <Modal
            show={setModalOpen}
            onHide={() => setShowOpenModal(false)}
            centered
            scrollable
            backdrop="static"
            style={{ backgroundColor: 'rgba(128,128,128,0.3)' }}
          >
            <Modal.Body>
              <p>
                Tomorrow morning slots are filling fast. Contact us to check
                availability
              </p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={() => setShowOpenModal(false)}>
                <Link href="https://api.whatsapp.com/send/?phone=919004044234&text=Hey%2C+I+want+to+book+a+cook+%EF%BF%BD+&app_absent=0">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'white' }}
                  >
                    Chat on WhatsApp
                  </a>
                </Link>
              </Button>
              <Button
                style={{ backgroundColor: 'white' }}
                onClick={() => setShowOpenModal(false)}
              >
                <p className="mb-0" style={{ color: 'blue' }}>
                  Cancel
                </p>
              </Button>
            </Modal.Footer>
          </Modal> */}
        </>
      )
    }

    // ---------------------- STEP 3 CTA ----------------------
    // case 31: {
    //   const {
    //     bookingDetails: { meal },
    //     bookingLevel: { bookingLevel, comboId, is_veg, numberOfBurners },
    //   } = REDUX__bookingModalData

    //   const validateBookingLevel = (
    //     callback: ((stepName?: string) => void) | undefined
    //   ) => {
    //     if (!bookingLevel) {
    //       alert('Please select Level')
    //       return true
    //     }
    //     if (comboId === 0) {
    //       alert('Please select Cuisine')
    //       return false
    //     }

    //     if (is_veg !== 'veg' && is_veg !== 'nonveg') {
    //       alert('Please select Preference')
    //       return false
    //     }
    //     if (!numberOfBurners) {
    //       alert('Please select Gas Burner')
    //       return false
    //     }

    //     setLoading(true)
    //     const numberOfburner = parseInt(numberOfBurners, 10)
    //     axios({
    //       method: 'POST',
    //       url: `/appliances/dishes_allowed`,
    //       data: {
    //         number_of_burners: numberOfburner,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.status === 200) {
    //           dispatch(
    //             bookingModalSlice.actions.setDishesAllowed(
    //               response.data.dishes_allowed
    //             )
    //           )
    //           if (meal === 'dinner') {
    //             callback && callback(6)
    //           } else if (meal === 'lunch') {
    //             callback && callback('dinner')
    //           } else if (meal === 'lunch_dinner') {
    //             callback && callback('dinner')
    //           } else {
    //             callback && callback()
    //           }
    //         }
    //       })
    //       .finally(() => {
    //         setLoading(false)
    //       })
    //   }
    //   return (
    //     <Button
    //       onClick={() => {
    //         if (
    //           meal === 'breakfast' ||
    //           meal === 'breakfast_lunch' ||
    //           meal === 'breakfast_dinner' ||
    //           meal === 'breakfast_lunch_dinner'
    //         ) {
    //           validateBookingLevel(SW?.nextStep)
    //         } else {
    //           validateBookingLevel(SW?. goToNamedStep)
    //         }
    //       }}
    //       block
    //       variant="warning"
    //       disabled={loading}
    //     >
    //       Proceed{' '}
    //       {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
    //       {loading && '...'}
    //     </Button>
    //   )
    // }

    // ---------------------STEP 4 CTA -----------------------

    //entry points - breakfast, breakfast_lunch, breakfast_lunch_dinner, breakfast_dinner
    case 3: {
      const {
        bookingDetails: { meal },
        designMenu: { dishDetails, breakfastDishes },
      } = REDUX__bookingModalData
      let proceedTo = 'View Total Bill'
      const getEachMeal = meal.split('_')
      if (getEachMeal[1] === 'lunch') {
        proceedTo = 'Lunch'
      } else if (getEachMeal[1] === 'dinner') {
        proceedTo = 'Dinner'
      }
      let breakfastDishLength = 0,
        breakfastGasRequiredDishesLength = 0,
        totalBreakfastCost = 0
      const arrayOfDishes = meal === 'breakfast' ? dishDetails : breakfastDishes
      if (Array.isArray(arrayOfDishes)) {
        if (arrayOfDishes?.length !== 0) {
          breakfastDishLength = arrayOfDishes?.filter((dish) => {
            if (dish.meal_type === 'breakfast') {
              totalBreakfastCost += dish.price
              return true
            }
            return false
          }).length
          breakfastGasRequiredDishesLength = arrayOfDishes?.filter(
            (dish) =>
              dish.meal_type === 'breakfast' && dish.requires_gas === true
          ).length
        }
      }

      return (
        <Button
          onClick={() => {
            if (meal === 'breakfast') {
              validateBookingDesignXYZMenu(
                SW?.goToNamedStep,
                'breakfastStep',
                breakfastDishLength,
                breakfastGasRequiredDishesLength
              )
            } else {
              validateBookingDesignXYZMenu(
                SW?.goToNamedStep,
                'breakfastStep',
                breakfastDishLength,
                breakfastGasRequiredDishesLength
              )
            }
          }}
          block
          variant="warning"
          disabled={loading}
        >
          {proceedTo === 'View Total Bill' ? (
            <span>
              {breakfastDishLength
                ? breakfastDishLength === 1
                  ? `(1 dish ₹${totalBreakfastCost})  `
                  : `(${breakfastDishLength} dishes ₹${totalBreakfastCost})  `
                : null}
              {proceedTo}{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </span>
          ) : (
            <span>
              {breakfastDishLength
                ? breakfastDishLength === 1
                  ? `(1 dish ₹${totalBreakfastCost})  `
                  : `(${breakfastDishLength} dishes ₹${totalBreakfastCost})  `
                : null}
              Proceed to {proceedTo}{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </span>
          )}
        </Button>
      )
    }

    // ---------------------- STEP 5 CTA ----------------------

    //entry points - lunch, lunch_dinner, breakfast_lunch_dinner, breakfast_lunch
    case 4: {
      const {
        bookingDetails: { meal },
        designMenu: { dishDetails, lunchDishes },
      } = REDUX__bookingModalData

      let proceedTo = 'View Total Bill'
      const getEachMeal = meal.split('_')
      if (getEachMeal[2] === 'dinner' || getEachMeal[1] === 'dinner') {
        proceedTo = 'Dinner'
      }

      let lunchDishLength = 0,
        lunchGasRequiredDishesLength = 0,
        totalLunchCost = 0
      const arrayOfDishes = meal === 'lunch' ? dishDetails : lunchDishes
      if (Array.isArray(arrayOfDishes)) {
        if (arrayOfDishes?.length !== 0) {
          lunchDishLength = arrayOfDishes?.filter((dish) => {
            if (dish.meal_type === 'lunch') {
              totalLunchCost += dish.price
              return true
            } else false
          }).length
          lunchGasRequiredDishesLength = arrayOfDishes?.filter(
            (dish) => dish.meal_type === 'lunch' && dish.requires_gas === true
          ).length
        }
      }

      return (
        <Button
          onClick={() => {
            if (meal === 'lunch') {
              validateBookingDesignXYZMenu(
                SW?.goToNamedStep,
                'lunchStep',
                lunchDishLength,
                lunchGasRequiredDishesLength
              )
            } else {
              validateBookingDesignXYZMenu(
                SW?.goToNamedStep,
                'lunchStep',
                lunchDishLength,
                lunchGasRequiredDishesLength
              )
            }
          }}
          block
          variant="warning"
          disabled={loading}
        >
          {proceedTo === 'View Total Bill' ? (
            <span>
              {lunchDishLength
                ? lunchDishLength === 1
                  ? `(1 dish ₹${totalLunchCost})  `
                  : `(${lunchDishLength} dishes ₹${totalLunchCost})  `
                : null}
              {proceedTo}{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </span>
          ) : (
            <span>
              {lunchDishLength
                ? lunchDishLength === 1
                  ? `(1 dish ₹${totalLunchCost})  `
                  : `(${lunchDishLength} dishes ₹${totalLunchCost})  `
                : null}
              Proceed to {proceedTo}{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </span>
          )}
        </Button>
      )
    }

    // ---------------------- STEP 6 CTA ----------------------

    case 5: {
      const {
        bookingDetails: { meal },
        designMenu: { dishDetails, dinnerDishes },
      } = REDUX__bookingModalData

      let dinnerDishLength = 0,
        dinnerGasRequiredDishesLength = 0,
        totalDinnerCost = 0
      const arrayOfDishes = meal === 'dinner' ? dishDetails : dinnerDishes
      if (Array.isArray(arrayOfDishes)) {
        if (arrayOfDishes?.length !== 0) {
          dinnerDishLength = arrayOfDishes?.filter((dish) => {
            if (dish.meal_type === 'dinner') {
              totalDinnerCost += dish.price
              return true
            }
            return false
          }).length
          dinnerGasRequiredDishesLength = arrayOfDishes?.filter(
            (dish) => dish.meal_type === 'dinner' && dish.requires_gas === true
          ).length
        }
      }

      return (
        <Button
          onClick={() =>
            validateBookingDesignXYZMenu(
              SW?.goToNamedStep,
              'dinnerStep',
              dinnerDishLength,
              dinnerGasRequiredDishesLength
            )
          }
          block
          variant="warning"
          disabled={loading}
        >
          {dinnerDishLength
            ? dinnerDishLength === 1
              ? `(1 dish ₹${totalDinnerCost})  `
              : `(${dinnerDishLength} dishes ₹${totalDinnerCost})  `
            : null}
          View Total Bill{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }
    // ---------------------- STEP 7 CTA ----------------------
    case 6: {
      const {
        bookingDetails: {
          bookingId,
          occasionId,
          numberOfPeople,
          bookingDate,
          bookingTime,
          meal,
          comboIds,
          numberOfBurners,
          appliances,
        },
        spclAppliances: { spclAppliances },
        userAddressData: { selectedAddressId },
        designMenu: {
          cuisineIds,
          dishDetails,
          lunchDishes,
          dinnerDishes,
          breakfastDishes,
        },
      } = REDUX__bookingModalData

      const validateBookingAppliances = (
        callback: ((stepName?: string) => void) | undefined
      ) => {
        if (spclAppliances.length !== 0) {
          let selectedAppliances = []
          if (appliances) {
            selectedAppliances = [...appliances]
          }
          for (let item = 0; item < spclAppliances?.length; item++) {
            const mainAppliance = spclAppliances[item]
            if (
              !selectedAppliances.includes(mainAppliance.id) &&
              !selectedAppliances.includes(
                mainAppliance.alternative_appliance?.appliance_id
              ) &&
              !selectedAppliances.includes(
                mainAppliance.alternative_appliance_2?.appliance_id
              )
            ) {
              alert('You need to arrange these appliances. Please select.')
              
              ;(document as any)
                .getElementById(`appliance_${mainAppliance.id}`)
                .scrollIntoView({ behavior: 'smooth' })
              return false
            }
          }
        }
        if (selectedAddressId) {
          const dishObjFunc = (dish: any) => ({
            id: dish.id,
            meal_type: dish.meal_type,
          })
          const lunchDishes2 = lunchDishes?.map(dishObjFunc)
          const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
          const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
          const dishDetails2 = dishDetails?.map(dishObjFunc)
          const numberOfburner = parseInt(numberOfBurners, 10)
          let appID = []
          if (appliances) {
            appID = [...appliances]
          }
          if (numberOfburner === 1) {
            appID.push(41)
          } else if (numberOfburner == 2) {
            appID.push(35)
          } else if (numberOfburner == 3) {
            appID.push(36)
          } else if (numberOfburner == 4) {
            appID.push(37)
          } else if (numberOfburner == 5) {
            appID.push(38)
          } else if (numberOfburner == 6) {
            appID.push(42)
          }

          setLoading(true)
          axios({
            method: 'POST',
            url: `/booking/summary`,
            data: {
              cuisines: cuisineIds,
              occasion_id: occasionId,
              cuisine_combos: comboIds,
              number_of_people: numberOfPeople,
              booking_date: bookingDate,
              booking_time: bookingTime,
              dish_details:
                meal === 'lunch' || meal === 'dinner' || meal === 'breakfast'
                  ? dishDetails2
                  : breakfastDishes2
                      ?.concat(lunchDishes2)
                      ?.concat(dinnerDishes2),

              payment_option: 2,
              appliance_ids: appID, //add comma separated appliance ids in this array
              address_id: selectedAddressId,
              booking_id: bookingId,
              booking_type: meal,
              upgrade_booking: upgradeBooking,
              add_assistant: addAssistant,
            },
          })
            .then((response) => {
              if (response.status === 200) {
                dispatch(
                  bookingModalSlice.actions.setBookingSummary(
                    response.data.data
                  )
                )
                callback && callback('summary')
              }
            })
            .catch((error) => {
              if (error.response.status === 406) {
                dispatch(bookingModalSlice.actions.toggleAPIAddressErrorModal())
              } else if (error.response.status === 500) {
                dispatch(bookingModalSlice.actions.toggleAPIAddressErrorModal())
              }
            })
            .finally(() => {
              setLoading(false)
            })
        } else {
          setLoading(true)

          axios
            .get(`/user/address`)
            .then((response) => {
              const defaultAddressId: number = (
                response.data.addresses.find(
                  (address: IaddressFromAPI) => address.is_default === 1
                ) || { address_id: null }
              ).address_id

              dispatch(
                bookingModalSlice.actions.setUserAddressData({
                  addresses: response.data.addresses,
                  selectedAddressId: defaultAddressId,
                })
              )
              if (
                response.data.addresses.length === 0 &&
                addresses.length === 0
              ) {
                dispatch(
                  bookingModalSlice.actions.toggleAddAddressModal({
                    stepNo: 4,
                  })
                )
                return false
                // callback && callback('summary')
              } else {
                if (defaultAddressId) {
                  const dishObjFunc = (dish: any) => ({
                    id: dish.id,
                    meal_type: dish.meal_type,
                  })
                  const lunchDishes2 = lunchDishes?.map(dishObjFunc)
                  const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
                  const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
                  const dishDetails2 = dishDetails?.map(dishObjFunc)
                  const numberOfburner = parseInt(numberOfBurners, 10)
                  let appID = []
                  if (appliances) {
                    appID = [...appliances]
                  }
                  if (numberOfburner === 1) {
                    appID.push(41)
                  } else if (numberOfburner == 2) {
                    appID.push(35)
                  } else if (numberOfburner == 3) {
                    appID.push(36)
                  } else if (numberOfburner == 4) {
                    appID.push(37)
                  } else if (numberOfburner == 5) {
                    appID.push(38)
                  } else if (numberOfburner == 6) {
                    appID.push(42)
                  }

                  // setLoading(true)
                  axios({
                    method: 'POST',
                    url: `/booking/summary`,
                    data: {
                      cuisines: cuisineIds,
                      occasion_id: occasionId,
                      cuisine_combos: comboIds,
                      number_of_people: numberOfPeople,
                      booking_date: bookingDate,
                      booking_time: bookingTime,
                      dish_details:
                        meal === 'lunch' ||
                        meal === 'dinner' ||
                        meal === 'breakfast'
                          ? dishDetails2
                          : breakfastDishes2
                              ?.concat(lunchDishes2)
                              ?.concat(dinnerDishes2),

                      payment_option: 2,
                      appliance_ids: appID, //add comma separated appliance ids in this array
                      address_id: defaultAddressId,
                      booking_id: bookingId,
                      booking_type: meal,
                      upgrade_booking: upgradeBooking,
                      add_assistant: addAssistant,
                    },
                    headers: {
                      Authorization:
                        'Bearer ' + localStorage.getItem('accessToken'),
                    },
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(
                          bookingModalSlice.actions.setBookingSummary(
                            response.data.data
                          )
                        )
                        callback && callback('summary')
                      }
                    })
                    .catch((error) => {
                      if (error.response.status === 406) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error.response.status === 500) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {
                      // setLoading(false)
                    })
                  // callback && callback('summary')
                } else callback && callback('address')
              }
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }

      return (
        <Button
          onClick={() => validateBookingAppliances(SW?.goToNamedStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Yes, I can arrange{' '}
          {loading ? (
            '...'
          ) : (
            <>
              {' '}
              <FaArrowRight style={{ verticalAlign: 'text-top' }} />
            </>
          )}
        </Button>
      )
    }

    // ---------------------- STEP 8 CTA ----------------------
    case 7: {
      const {
        bookingDetails: {
          bookingId,
          occasionId,
          numberOfPeople,
          bookingDate,
          bookingTime,
          meal,
          comboIds,
          numberOfBurners,
          appliances,
        },
        userAddressData: { selectedAddressId },
        designMenu: {
          cuisineIds,
          dishDetails,
          lunchDishes,
          dinnerDishes,
          breakfastDishes,
        },
      } = REDUX__bookingModalData

      const dishObjFunc = (dish: any) => ({
        id: dish.id,
        meal_type: dish.meal_type,
      })
      const lunchDishes2 = lunchDishes?.map(dishObjFunc)
      const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
      const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
      const dishDetails2 = dishDetails?.map(dishObjFunc)

      const validateBookingAddress = (callback: (() => void) | undefined) => {
        if (!selectedAddressId) {
          alert('Please select city')
          return false
        }
        const selectedCity = addresses?.filter(
          (address) => +address.address_id === +selectedAddressId
        )[0]?.city

        const numberOfburner = parseInt(numberOfBurners, 10)
        let appID = []
        if (appliances) {
          appID = [...appliances]
        }
        if (numberOfburner === 1) {
          appID.push(41)
        } else if (numberOfburner == 2) {
          appID.push(35)
        } else if (numberOfburner == 3) {
          appID.push(36)
        } else if (numberOfburner == 4) {
          appID.push(37)
        } else if (numberOfburner == 5) {
          appID.push(38)
        } else if (numberOfburner == 6) {
          appID.push(42)
        }

        setLoading(true)
        axios({
          method: 'POST',
          url: `/booking/summary`,
          data: {
            cuisines: cuisineIds,
            occasion_id: occasionId,
            cuisine_combos: comboIds,
            number_of_people: numberOfPeople,
            booking_date: bookingDate,
            booking_time: bookingTime,
            dish_details:
              meal === 'lunch' || meal === 'dinner' || meal === 'breakfast'
                ? dishDetails2
                : breakfastDishes2?.concat(lunchDishes2)?.concat(dinnerDishes2),

            payment_option: 2,
            appliance_ids: appID, //add comma separated appliance ids in this array
            address_id: selectedAddressId,
            booking_id: bookingId,
            booking_type: meal,
            upgrade_booking: upgradeBooking,
            add_assistant: addAssistant,
          },
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        })
          .then((response) => {
            if (response.status === 200) {
              dispatch(
                bookingModalSlice.actions.setBookingSummary(response.data.data)
              )
              callback && callback()
            }
          })
          .catch((error) => {
            if (error.response.status === 406) {
              dispatch(bookingModalSlice.actions.toggleAPIAddressErrorModal())
            } else if (error.response.status === 500) {
              dispatch(bookingModalSlice.actions.toggleAPIAddressErrorModal())
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
      return (
        <Button
          onClick={() => validateBookingAddress(SW?.nextStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------- STEP 10 payment CTA ----------------------
    case 9:
      return (
        <Button onClick={SW?.nextStep} block variant="warning">
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )

    // ---------------------- STEP 9  booking summary CTA ----------------------
    case 8: {
      const {
        upgradeBooking,
        addAssistant,
        bookingDetails: { bookingId, bookingDate, meal },
        payment: { paymentGateway },
        summary: {
          invoice: {
            invoice_footer: { payable_now, discount, amount_payable },
          },
        },
        userAddressData: { selectedAddressId, addresses },
      } = REDUX__bookingModalData

      const cityName = addresses.filter(
        (address) => +address.address_id === +selectedAddressId
      )[0]?.city

      const stateName = addresses.filter(
        (address) => +address.address_id === +selectedAddressId
      )[0]?.state

      let couponCode: string
      if (discount === undefined || discount.code === undefined) {
        couponCode = ''
      } else {
        couponCode = discount.code
      }

      const {
        userData: { name, email, mobile },
      } = REDUX__authData

      const validateBookingPayment = () => {
        if (!paymentGateway) {
          alert('Please select a Payment method')
          ;(document as any)
            .getElementById('paymentMethod')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        gtmEvent('book-chef-flow', {
          step: 'payment-attempted',
          type: paymentGateway,
        })
        setLoading(true)

        axios({
          method: 'PATCH',
          url: `/booking`,
          data: {
            booking_id: bookingId,
            upgrade_booking: upgradeBooking,
            add_assistant: addAssistant,
          },
        }).then(() => {
          if (paymentGateway === 'paytm') {
            const paymentOption = '?payment_option=2'
            const toggleBookingModalFromPaytm = () =>
              dispatch(bookingModalSlice.actions.toggleBookingModal())

            dispatch(bookingModalSlice.actions.setPaymentOpen(true))

            displayPaytm(
              amount_payable,
              amount_payable,
              bookingId,
              paymentOption,
              setLoading,
              toggleBookingModalFromPaytm,
              couponCode,
              router,
              () => dispatch(bookingModalSlice.actions.setPaymentOpen(false))
            )
          }
          if (paymentGateway === 'razorpay') {
            const paymentOption = '?payment_option=2'
            const toggleBookingModalFromRZP = () =>
              dispatch(bookingModalSlice.actions.toggleBookingModal())

            dispatch(bookingModalSlice.actions.setPaymentOpen(true))

            displayRazorpay(
              amount_payable,
              amount_payable,
              bookingId,
              paymentOption,
              name,
              email,
              mobile,
              setLoading,
              toggleBookingModalFromRZP,
              couponCode,
              () => dispatch(bookingModalSlice.actions.setPaymentOpen(false))
            )
          }
        })
      }

      const requestAvailablity = async () => {
        setLoading(true)

        // so that leave site dialog box do not open
        dispatch(bookingModalSlice.actions.setPaymentOpen(true))

        const res = await axios.post(
          `/booking/${bookingId}/check-chef-availability`
        )
        // setLoading(false)
        window.location.href = `/my-bookings?bookingId=${bookingId}&event=checkAddress&show=cart`
      }
      return (
        <>
          {' '}
          {checkingAvailabilityCondition(
            cityName,
            stateName,
            bookingDate,
            meal,
            city
          ) ? (
            <Button
              onClick={requestAvailablity}
              block
              variant="warning"
              disabled={loading}
            >
              Request Availability{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </Button>
          ) : (
            <Button
              onClick={() => validateBookingPayment()}
              block
              variant="warning"
              disabled={loading}
            >
              Pay 40% Advance to book Chef (₹{payable_now}) {loading && '...'}
            </Button>
          )}
        </>
      )
    }

    // ---------------------- DEFAULT CTA ----------------------
    default:
      return (
        <button onClick={() => alert('OOPS, something went wrong')}>
          Default button
        </button>
      )
  }
}
