const wrapS3URLForIcons = (imgName: string) =>
  `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/${imgName}`

export const bookingHowItWorksData = [
  // {
  //   imgSrc: wrapS3URLForIcons('customMade.svg'),
  //   point: 'Choose your Menu',
  // },
  {
    imgSrc: wrapS3URLForIcons('ingredients.png'),
    point: 'Ingredients List is shared',
  },
  {
    imgSrc: wrapS3URLForIcons('male_chef.svg'),
    point: 'Chef and Assistant Cook arrive on Time',
  },
  {
    imgSrc: wrapS3URLForIcons('hot_and_fresh.png'),
    point: 'Food is cooked Hot and Fresh',
  },
  {
    imgSrc: wrapS3URLForIcons('clean_kitchen.png'),
    point: 'Chef cleans the Kitchen and Leaves',
  },
]
