import { useEffect, useState } from 'react'

// BOOTSTRAP
import { Button } from 'react-bootstrap'

// Dayjs
import dayjs from 'dayjs'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'
import authSlice from '@/lib/slices/authSlice'

// AXIOS
import axios from 'axios'

// HELPERS
import { displayRazorpay } from './displayRazorpay'
import { displayPaytm } from './displayPaytm'

// GTM
import { gtmEvent } from '@/lib/gtm'

// TS INTERFACES
import { SWtype } from '../commonInterfaces'
import { RootState } from '@/lib/rootState'
import { useRouter } from 'next/router'
import { FaArrowRight } from 'react-icons/fa'

interface ICTAHelper {
  loading: boolean
  setLoading: any
  activeStep: number
  SW: SWtype | null
}

export const checkingAvailabilityCondition = (
  city: string,
  state: string,
  bookingDate: string,
  meal: string,
  cities: any
): boolean => {
  let validDate = false
  const today = dayjs().format('YYYY-MM-DD')
  // const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')
  if (today === bookingDate) {
    validDate = true
  }
  // if (tomorrow === bookingDate && meal !== 'dinner') {
  //   if (dayjs().isAfter(`${today} 18:00:00`)) {
  //     validDate = true
  //   }
  // }
  if (cities !== undefined && cities.length !== 0) {
    for (const tempCity of cities) {
      if (
        (tempCity.name === city &&
          tempCity.state === state &&
          tempCity.bookable === 0) ||
        validDate
      ) {
        return true
      } else if (
        tempCity.name === city &&
        tempCity.state === state &&
        tempCity.bookable === 1
      ) {
        return false
      }
    }
  } else return true
  // if (
  //   [
  //     'Navi Mumbai',
  //     'Mumbai',
  //     'Hyderabad',
  //     'Bengaluru',
  //     'Thane',
  //     'Palwal',
  //     'Jhajjar',
  //     'Sonipat',
  //     'Panchgaon',
  //     'Ballabgarh',
  //     'Bahadurgarh',
  //     'Dadri',
  //     'Bulandshahr',
  //     'Panipat',
  //     'Sona',
  //     'Meerut',
  //     'Manesar',
  //     'Aligarh',
  //     'Rohtak',
  //     'Karnal',
  //     'Hapur',
  //     'Muzzafarnagar',
  //     'Jaipur',
  //     'Ahmedabad',
  //     'Kolkata',
  //     'Chandigarh',
  //     'Surat',
  //     'Coimbatore',
  //     'Chembur',
  //     'Pune',
  //     'Mohali',
  //     'Panchkula',
  //   ].includes(city) ||
  //   validDate
  // )
  //   return true
  // else return false
}

export const CTAHelper = ({
  activeStep,
  SW,
  loading,
  setLoading,
}: ICTAHelper): JSX.Element => {
  const router = useRouter()
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookDailyCookModal
  )
  const {
    userAddressData: { addresses },
    citiesList,
  } = REDUX__bookingModalData

  const REDUX__authData = useSelector((state: RootState) => state.auth)
  const { userData } = REDUX__authData

  const dispatch = useDispatch()

  // const [setModalOpen, setShowOpenModal] = useState(false)
  const [address, setAddress] = useState([])
  const [city, setCity] = useState([])

  useEffect(() => {
    if (
      userData?.id !== null &&
      userData?.name !== null &&
      userData?.email !== null
    ) {
      axios.get(`/user/address`).then((res) => {
        if (addresses !== res.data.addresses) {
          dispatch(
            bookDailyCookModalSlice.actions.setUserAddressData({
              addresses: res.data.addresses,
            })
          )
          for (let i = 0; i < res.data.addresses.length; i++) {
            if (res.data.addresses[i]?.is_default === 1) {
              dispatch(
                bookDailyCookModalSlice.actions.setSelectedAddressId(
                  res.data.addresses[i]?.address_id
                )
              )
            }
          }
        }

        if (res.data.addresses !== address) setAddress(res.data.addresses)
      })
    }
  }, [])

  useEffect(() => {
    axios.get(`/ocl/cities`).then((res) => {
      if (citiesList !== res.data.cities) {
        dispatch(bookDailyCookModalSlice.actions.setCitiesList(res.data.cities))
      }

      if (res.data.cities !== city) setCity(res.data.cities)
    })
  }, [])

  // handler for step 5 & 6 & 7
  const validateBookingDesignXYZMenu = (SW) => {
    /**
     * validates and submits booking data to booking summary API
     * @param {callback} - it is a function that increases the step count
     */
    console.log('!')
    //  Destructuring
    const {
      bookingDetails: {
        occasionId,
        numberOfPeople,
        bookingDate,
        bookingTime,
        booking_type
      },
      // designMenu: { cuisineIds, dishDetails },
      userAddressData: { selectedAddressId },
    } = REDUX__bookingModalData

    const { userData } = REDUX__authData

    if (localStorage.getItem('expiresIn') == null) {
      // If expiresIn is null in localStorage then ask the user to login

      dispatch(bookDailyCookModalSlice.actions.setTruecallerOpen(true))
      dispatch(authSlice.actions.setSource('book_daily_cook_modal'))
      setTimeout(() => {
        dispatch(authSlice.actions.toggleLoginModal())
      }, 0)
    } else if (userData.id && (!userData?.name || !userData?.email)) {
      // If user-name or user-email is empty in Redux then show userDetails Modal
      const mobile = JSON.parse(localStorage.getItem('authUser')).mobile
      dispatch(authSlice.actions.toggleUserDetailsModal())
      // if (!bookingId) {
      // const dishObjFunc = (dish: any) => dish.id
      // const dishDetails2 = dishDetails?.map(dishObjFunc)
      // Create booking lead
      // /waiter-cleaner
      // /waiter-cleaner/summary
      axios({
        method: 'POST',
        url: `/booking/daily-cook`,
        data: {
          booking_type: booking_type,
          number_of_people: numberOfPeople,
          booking_date: bookingDate,
          cook_arrival_time: bookingTime,
          mobile: String(mobile),
        },
      }).then((res) => {
        setLoading(false)

        dispatch(
          bookDailyCookModalSlice.actions.setBookingId(res.data.data.booking.id)
        )
        console.log(res.data.data)
      })
      // }
    } else {
      setLoading(true)
      const mobile = JSON.parse(localStorage.getItem('authUser')).mobile
      // Create booking lead
      axios({
        method: 'POST',
        url: `/booking/daily-cook`,
        data: {
          booking_type: booking_type,
          number_of_people: numberOfPeople,
          booking_date: bookingDate,
          cook_arrival_time: bookingTime,
          mobile: String(mobile),
        },
      })
        .then((res) => {
          console.log(res);
          
          setLoading(false)
          console.log('success')
          dispatch(
            bookDailyCookModalSlice.actions.setBookingId(res.data.data.booking.id)
          )
          if (address.length === 0 && addresses.length === 0) {
            if (selectedAddressId) {
              setLoading(true)
              axios({
                method: 'POST',
                url: `booking/daily-cook/summary`,
                data: {
                  address_id: selectedAddressId,
                  booking_id: res.data.data.booking.id,
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    dispatch(
                      bookDailyCookModalSlice.actions.setBookingSummary(
                        response.data.data
                      )
                    )
                    return callback && callback('summary')
                  }
                })
                .catch((error) => {
                  if (error.response.status === 406) {
                    dispatch(
                      bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
                    )
                  } else if (error.response.status === 500) {
                    dispatch(
                      bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
                    )
                  }
                })
                .finally(() => {
                  setLoading(false)
                })
            } else {
              dispatch(
                bookDailyCookModalSlice.actions.toggleAddAddressModal({
                  stepNo: 4,
                })
              )
              return false
            }
          } else {
            if (selectedAddressId) {
              setLoading(true)
              axios({
                method: 'POST',
                url: `booking/daily-cook/summary`,
                data: {
                  address_id: selectedAddressId,
                  booking_id: res.data.data.booking.id,
                },
              })
                .then((response) => {
                  if (response?.status === 200) {
                    dispatch(
                      bookDailyCookModalSlice.actions.setBookingSummary(
                        response.data.data
                      )
                    )
                    SW?.goToNamedStep('summary')
                  }
                })
                .catch((error) => {
                  if (error?.response?.status === 406) {
                    dispatch(
                      bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
                    )
                  } else if (error?.response?.status === 500) {
                    dispatch(
                      bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
                    )
                  }
                })
                .finally(() => {
                  setLoading(false)
                })
            } else SW?.goToNamedStep('address')
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log('error', err)
          // alert('Some error occured!')
        })
        .finally(() => {
          // setLoading(false)
        })
    }
  }
  console.log('activeStep', activeStep)
  switch (activeStep) {
    // ---------------------- STEP 1 CTA ----------------------
    case 100: {
      const getGasDetails = (callback: (() => void) | undefined) => {
        if (callback) {
          callback()
        }
      }

      return (
        <Button
          onClick={() => getGasDetails(SW?.nextStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------- STEP 2 CTA ----------------------
    case 1: {
      const {
        bookingDetails: {
          occasionId,
          booking_type,
          numberOfPeople,
          bookingDate,
          bookingTime,
        },
      } = REDUX__bookingModalData

      const validateBookingDetails = (
        callback: ((stepName?: string) => void) | undefined
      ) => {
        // Condtions to check mandatory fields
        // if (occasionId == null) {
        //   alert('Please Select Occasion')
        //   ;(document as any)
        //     .getElementById('bh_select_occasion')
        //     .scrollIntoView({ behavior: 'smooth' })
        //   return false
        // }
        if (numberOfPeople === '') {
          alert('Please Select Number of People')
          ;(document as any)
            .getElementById('goToPeople')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (bookingDate === '') {
          alert('Please Select Date')
          ;(document as any)
            .getElementById('goToDate')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (booking_type === '') {
          alert('Please Select Meals')
          ;(document as any)
            .getElementById('goToMeal')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        validateBookingDesignXYZMenu(SW)
      }
      return (
        <Button
          onClick={() => {
            validateBookingDetails(SW?.nextStep)
          }}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------- STEP 8 CTA ----------------------
    case 2: {
      console.log('ye hua select')
      const {
        bookingDetails: {
          bookingId,
          occasionId,
          numberOfPeople,
          bookingDate,
          bookingTime,
        },
        userAddressData: { selectedAddressId },
      } = REDUX__bookingModalData

      const validateBookingAddress = (callback: (() => void) | undefined) => {
        console.log('selectedAddressId', selectedAddressId)
        if (!selectedAddressId) {
          alert('Please select city')
          return false
        }

        setLoading(true)
        axios({
          method: 'POST',
          url: `booking/daily-cook/summary`,
          data: {
            address_id: selectedAddressId,
            booking_id: bookingId,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              dispatch(
                bookDailyCookModalSlice.actions.setBookingSummary(response.data.data)
              )
              callback && callback('summary')
            }
          })
          .catch((error) => {
            if (error.response.status === 406) {
              dispatch(
                bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
              )
            } else if (error.response.status === 500) {
              dispatch(
                bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
              )
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
      return (
        <Button
          onClick={() => validateBookingAddress(SW?.nextStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------- STEP 10 payment CTA ----------------------
    case 5:
      return (
        <Button onClick={SW?.nextStep} block variant="warning">
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )

    // ---------------------- STEP 9  booking summary CTA ----------------------
    case 3: {
      const {
        upgradeBooking,
        bookingDetails: { bookingId, bookingDate, meal },
        payment: { paymentGateway },
        summary: {
          invoice: {
            invoice_footer: { payable_now, discount, amount_payable },
          },
        },
        userAddressData: { selectedAddressId, addresses },
      } = REDUX__bookingModalData

      const cityName = addresses.filter(
        (address) => +address.address_id === +selectedAddressId
      )[0]?.city

      const stateName = addresses.filter(
        (address) => +address.address_id === +selectedAddressId
      )[0]?.state

      let couponCode: string
      if (discount === undefined || discount.code === undefined) {
        couponCode = ''
      } else {
        couponCode = discount.code
      }

      const {
        userData: { name, email, mobile },
      } = REDUX__authData

      const validateBookingPayment = () => {
        if (!paymentGateway) {
          alert('Please select a Payment method')
          ;(document as any)
            .getElementById('paymentMethod')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        gtmEvent('book-waiter-flow', {
          step: 'payment-attempted',
          type: paymentGateway,
        })
        setLoading(true)

        axios({
          method: 'PATCH',
          url: `/booking`,
          data: {
            booking_id: bookingId,
            upgrade_booking: upgradeBooking,
          },
        }).then(() => {
          if (paymentGateway === 'paytm') {
            const paymentOption = '?payment_option=1'
            const toggleBookingModalFromPaytm = () =>
              dispatch(
                bookDailyCookModalSlice.actions.toggleBookingWaiterModal()
              )

            dispatch(bookDailyCookModalSlice.actions.setPaymentOpen(true))

            displayPaytm(
              amount_payable,
              amount_payable,
              bookingId,
              paymentOption,
              setLoading,
              toggleBookingModalFromPaytm,
              couponCode,
              router,
              () =>
                dispatch(bookDailyCookModalSlice.actions.setPaymentOpen(false))
            )
          }
          if (paymentGateway === 'razorpay') {
            const paymentOption = '?payment_option=1'
            const toggleBookingModalFromRZP = () =>
              dispatch(
                bookDailyCookModalSlice.actions.toggleBookingWaiterModal()
              )

            dispatch(bookDailyCookModalSlice.actions.setPaymentOpen(true))

            displayRazorpay(
              amount_payable,
              amount_payable,
              bookingId,
              paymentOption,
              name,
              email,
              mobile,
              setLoading,
              toggleBookingModalFromRZP,
              couponCode,
              () =>
                dispatch(bookDailyCookModalSlice.actions.setPaymentOpen(false))
            )
          }
        })
      }

      const requestAvailablity = async () => {
        setLoading(true)

        // so that leave site dialog box do not open
        dispatch(bookDailyCookModalSlice.actions.setPaymentOpen(true))

        const res = await axios.post(
          `/booking/${bookingId}/check-chef-availability`
        )

        setTimeout(() => {
          window.location.replace(
            `/my-bookings?bookingId=${bookingId}&event=checkAddress&show=cart`
          )
        }, 0)
        // setLoading(false)
      }
      return (
        <>
          <Button
            onClick={requestAvailablity}
            block
            variant="warning"
          // disabled={loading}
          >
            Request Availability{' '}
            {!loading && (
              <FaArrowRight style={{ verticalAlign: 'text-top' }} />
            )}
            {loading && '...'}
          </Button>
        </>
      )
    }

    // ---------------------- DEFAULT CTA ----------------------
    default:
      return (
        <button onClick={() => alert('OOPS, something went wrong')}>
          Default button
        </button>
      )
  }
}
