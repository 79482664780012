const wrapS3URLForIcons = (imgName: string) =>
  `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/${imgName}`

export const bookingHowItWorksData = [
  {
    imgSrc: wrapS3URLForIcons('cleaner4.png'),
    point: 'Cleaner arrives on time',
  },
  {
    imgSrc: wrapS3URLForIcons('cleaner6.png'),
    point: 'Washes dishes, utensils and appliances',
  },
  {
    imgSrc: wrapS3URLForIcons('clean_kitchen.png'),
    point: 'Wraps up the kitchen and leaves',
  },
]
