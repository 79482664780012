const wrapS3URL = (glimpseName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/${glimpseName}.jpg`
}

interface Iphotos {
  src: string
  alt: string
  width: number
  height: number
}

export const photos: Array<Iphotos> = [
  {
    src: wrapS3URL('glimpse18'),
    alt: 'COOX Bartender making drinks for our clients. Book Bartenders online',
    width: 2,
    height: 3,
  },
  // {
  //   src: wrapS3URL('glimpse19'), //table
  //   alt: 'COOX Bartenders pictures with our customers. Hire professional bartender near me',
  //   width: 2,
  //   height: 3,
  // },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/bartender10.jpg', //family
    alt: 'COOX Bartenders family pictures with our customers. Hire professional bartender near me',
    width: 3,
    height: 3,
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/bartender8.jpg',
    alt: 'COOX bartender services near you. Hire personal bartender for parties events',
    width: 3,
    height: 3,
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/bartender9.jpg',
    alt: 'COOX bartender serving drinks. Hire top rated bartenders in your city',
    width: 3,
    height: 3,
  },
  {
    src: 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/bartender7.jpg',
    alt: 'COOX bartender serving drinks. Hire top rated bartenders in your city',
    width: 3,
    height: 3,
  },
]

///
