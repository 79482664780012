import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

// STYLES
import styles from '../../BookingModals/BookChefModal/index.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import auth from '@/lib/slices/authSlice'

// INTERFACES
// import { RootState } from '@/lib/rootState'
interface Props {
  mobileError: string
  setLoading: Dispatch<SetStateAction<boolean>>
}

const EnterMobileView = ({ mobileError, submitMobile }: Props): JSX.Element => {
  const dispatch = useDispatch()

  const [mobile, setMobile] = useState<string>('')
  const mobileInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    mobileInputRef.current?.focus()
  }, [])
  useEffect(() => {
    dispatch(auth.actions.setUserMobileWhileLogin(mobile))
    if (mobile.length === 10) submitMobile(mobile)
  }, [mobile])

  const loginModalSource = useSelector((state: IAuth) => state.auth.source)

  if (
    loginModalSource === 'booking_modal' ||
    loginModalSource === 'booking_bartender_modal' ||
    loginModalSource === 'booking_waiter_modal' ||
    loginModalSource === 'booking_cleaner_modal'
  ) {
    return (
      <>
        <p className="text-left text-muted">
          Please add your details to view total bill
        </p>
        <div>
          <form
            className="form-group"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <h5 className={styles.dark__grey__color}>Mobile</h5>
            <input
              ref={mobileInputRef}
              autoComplete="tel-local"
              autoFocus
              inputMode="numeric"
              pattern="[0-9]*"
              type="text"
              name="mobile"
              placeholder="Enter Mobile Number"
              className="form-control"
              value={mobile}
              onChange={({ target: { value } }) => {
                const filteredNum = value.replace(/[^0-9]/g, '')
                filteredNum.length > 10 ? null : setMobile(filteredNum)
              }}
            />
            <div className={`${styles.error__message} mt-2 text-left`}>
              {mobileError}
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* <p className="mt-2 text-center">View Payment Summary ahead {'>'}</p> */}
          </form>
        </div>
      </>
    )
  } else {
    return (
      <>
        <Row>
          <Col>
            <h4 className="DARK__GOLDEN__COLOR text-center">COOX</h4>
          </Col>
        </Row>
        <br />
        <br />

        <form
          className="form-group"
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <h5 className={styles.dark__grey__color}>Mobile</h5>
          <input
            ref={mobileInputRef}
            autoComplete="tel-local"
            autoFocus
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            name="mobile"
            placeholder="Enter Mobile Number"
            className="form-control"
            value={mobile}
            onChange={({ target: { value } }) => {
              const filteredNum = value.replace(/[^0-9]/g, '')
              filteredNum.length > 10 ? null : setMobile(filteredNum)
            }}
          />
          <div className={`${styles.error__message} mt-2 text-left`}>
            {mobileError}
          </div>
        </form>
      </>
    )
  }
}

export default EnterMobileView
///
