import { createSlice } from '@reduxjs/toolkit'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

const initialState: RootState['apiData'] = {
  occasions: [],
  combos: [],
  unPublishedCombos: [],
  cuisines: [],
  askServiceModal: false,
}

const apiDataSlice = createSlice({
  name: 'apiData',
  initialState,
  reducers: {
    setOccasions: (state, occasions) => {
      state.occasions = occasions.payload
    },
    setCombos: (state, combos) => {
      state.combos = combos.payload
    },
    setUnPublishedCombos: (state, combos) => {
      state.unPublishedCombos = combos.payload
    },
    setCuisines: (state, cuisines) => {
      state.cuisines = cuisines.payload
    },
    setAskServiceModal: (state, askServicesModal) => {
      state.askServiceModal = askServicesModal.payload
    },
  },
})

export default apiDataSlice
