interface City {
  city: string
  state: string
  country: string
}

interface Cities {
  [key: string]: City
}

export const cities: Cities = {
  'new delhi': { city: 'New Delhi', state: 'Delhi', country: 'India' },
  delhi: { city: 'New Delhi', state: 'Delhi', country: 'India' },
  noida: { city: 'Noida', state: 'Uttar Pradesh', country: 'India' },
  'greater noida': {
    city: 'Greater Noida',
    state: 'Uttar Pradesh',
    country: 'India',
  },
  ghaziabad: { city: 'Ghaziabad', state: 'Uttar Pradesh', country: 'India' },
  gurgaon: { city: 'Gurugram', state: 'Haryana', country: 'India' },
  gurugram: { city: 'Gurugram', state: 'Haryana', country: 'India' },
  faridabad: { city: 'Faridabad', state: 'Haryana', country: 'India' },
  mumbai: { city: 'Mumbai', state: 'Maharashtra', country: 'India' },
  pune: { city: 'Pune', state: 'Maharashtra', country: 'India' },
  hyderabad: { city: 'Hyderabad', state: 'Telangana', country: 'India' },
  bengaluru: { city: 'Bengaluru', state: 'Karnataka', country: 'India' },
  kolkata: { city: 'Kolkata', state: 'West Bengal', country: 'India' },
}
