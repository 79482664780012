interface Props {
  step: number
}

const BookingModalHeading = (props: Props): JSX.Element => {
  const { step } = props
  switch (step) {
    case 1:
      return <span>Book a Cleaner</span>
    case 2:
      return <span>Occasion Details</span>
    case 3:
      return <span>Select City</span>
    case 4:
      return <span>Summary</span>
    default:
      return <span></span>
  }
}

export default BookingModalHeading
