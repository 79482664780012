// REACT ICONS
import { RootState } from '@/lib/rootState'
import { Dispatch, SetStateAction, useState } from 'react'
// import { FaCheckCircle } from 'react-icons/fa'

// BOOTSTRAP
import { Button } from 'react-bootstrap'

// STYLES
import styles from './index.module.css'
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import { Promo } from '..'

interface Props {
  availableCoupons: RootState['bookingModal']['summary']['available_coupons']
  addPromo: (a: Promo | { code: string }) => void
  setShowPromoModal: (a: boolean) => void
  setCouponCode: Dispatch<SetStateAction<string | null>> | undefined
  bookingId: number
  loading: boolean
  promoLoading: boolean
  applyHandler: any
}

const ApplyPromoCode = (props: Props): JSX.Element => {
  const {
    availableCoupons,
    couponError,
    setCouponError,
    promoLoading,
    applyHandler,
    loading,
  } = props

  // const [couponError, setCouponError] = useState('')
  const [couponInput, setCouponInput] = useState('')

  // const promoChangedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   //
  //   addPromo(e.target.value)
  // }
  const validCoupons = availableCoupons.map((coupon) => {
    return (
      <div key={coupon.code}>
        <div className="row mt-4">
          <div className={`${styles.CouponName} col-9`}>
            <Button
              variant="link"
              disabled={
                ((loading || promoLoading) && couponInput === coupon.code) ||
                (props?.bookingTotal > 0 &&
                  coupon?.min_payable_amount - props?.bookingTotal > 0)
              }
              onClick={() => {
                setCouponInput(coupon.code)
                applyHandler(coupon.code)
              }}
              style={{
                padding: '8px',
                border: 'dotted 2px',
                cursor: 'pointer',
              }}
            >
              {coupon.code}
            </Button>
          </div>
          <div className="col-3 text-right">
            <Button
              variant="warning"
              onClick={() => {
                setCouponInput(coupon.code)
                applyHandler(coupon.code)
              }}
              disabled={
                ((loading || promoLoading) && couponInput === coupon.code) ||
                (props?.bookingTotal > 0 &&
                  coupon?.min_payable_amount - props?.bookingTotal > 0)
              }
            >
              Select
              {(loading || promoLoading) && couponInput === coupon.code
                ? '...'
                : null}
            </Button>
          </div>
        </div>
        <p className="mb-0">
          {coupon.is_fixed
            ? `Get Flat ${coupon.discount_amount} Discount`
            : `Get Flat ${coupon.discount_amount}% Discount up to Rs ${coupon.max_discount}`}
        </p>
        <small>{coupon.description}</small>
        <br />
        {props?.bookingTotal > 0 &&
        coupon?.min_payable_amount - props?.bookingTotal > 0 ? (
          <small className="text-danger">{`Coupon is applicable on minimum bill of ₹${
            coupon?.min_payable_amount
          }. You need to add ₹${
            coupon?.min_payable_amount - props?.bookingTotal
          } more to apply this offer`}</small>
        ) : null}

        {/* <div className="row">
          <div className="col-9">
            <span className={styles.CouponCode}>{coupon.code}</span>
          </div>
          <div className="col-3">
            <Button
              variant="outline-warning"
              size="sm"
              onClick={() => setCouponInput(coupon.code)}
            >
              Select
            </Button>
          </div>
        </div> */}
        <hr />
      </div>
    )
  })
  return (
    <>
      <h5>Apply Coupon Code</h5>
      <div className="input-group">
        <input
          type="text"
          placeholder="Enter Coupon Code"
          className="form-control"
          value={couponInput}
          onChange={(e) => {
            setCouponError('')
            setCouponInput(e.target.value.toUpperCase())
          }}
        />
        <div className="input-group-append">
          <button
            className="btn btn-warning"
            type="button"
            onClick={() => applyHandler(couponInput)}
            disabled={loading || promoLoading}
          >
            Apply
            {loading || promoLoading ? '...' : null}
          </button>
        </div>
      </div>
      <small className={styles.CouponError}>{couponError}</small>
      <br />
      <br />
      {validCoupons.length > 0 ? (
        <>
          <h5>Available Offers</h5>

          {/* {promo !== '' ? (
            <>
              <span className={`text-success ${styles.font__size__12}`}>
                Promo Code applied
              </span>{' '}
              <FaCheckCircle
                className={`text-success ${styles.font__size__12}`}
              />
            </>
          ) : null} */}
          {validCoupons}
        </>
      ) : (
        <small>No coupons available</small>
      )}
    </>
  )
}

export default ApplyPromoCode
