// STYLES
import styles from './index.module.css'

import { Link as LinkScroll } from 'react-scroll'

// Icons
import { FaArrowAltCircleUp } from 'react-icons/fa'

const FloatingtoTopBtn = (): JSX.Element => {
  const iconStyles = {
    color: 'white',
    fontSize: '1.7em',
    border: '0px solid black',
    borderRadius: '100%',
    backgroundColor: 'grey',
  }

  return (
    <LinkScroll
      activeClass="active"
      to="toppp"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
    >
      <div className={`m-2 px-2 ${styles.to_top_button}`}>
        <FaArrowAltCircleUp style={iconStyles} />
      </div>
    </LinkScroll>
  )
}

export default FloatingtoTopBtn
