// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col, Container } from 'react-bootstrap'

// STATIC DATA
import { bookingHowItWorksData } from '@/staticData/how-it-works-cleaner.data'

import Gallery from 'react-photo-gallery'

// DATA
import { photos } from '@/staticData/booking-cleaner-modal-glimpses.data'

// TS INTERFACES
import { SWtype } from '@/components/BookingModals/BookChefModal/commonInterfaces'
import { BsCalendar2Check } from 'react-icons/bs'

const BookingHowItWorks = ({ SW }: { SW: SWtype | null }): JSX.Element => {
  return (
    <>
      <Row>
        <Col className="pl-0">
          <p
            style={{ paddingLeft: '1%', fontSize: '17.5px' }}
            className="text-muted"
          >
            How It Works:
          </p>
        </Col>
      </Row>
      <Row
        className="mb-3"
        onClick={() => {
          SW.nextStep()
        }}
      >
        <Col className="d-inline-flex pl-0">
          <BsCalendar2Check size={27}  style={{ marginRight: '3.3%', marginLeft: '1%' }}/>
          <p className="text-muted">Select Event Date</p>
        </Col>
      </Row>
      {bookingHowItWorksData.map(({ imgSrc, point }) => (
        <Row key={point} className="mb-3">
          {/* <Col md={1} xs={2}></Col> */}
          <Col className="d-inline-flex pl-0">
            <img
              src={imgSrc}
              alt={point}
              width="30px"
              height="30px"
              style={{ marginRight: '3.3%', marginLeft: '1%' }}
            />
            <p className="text-muted">{point}</p>
          </Col>
        </Row>
      ))}
      <div className="mt-3">
        <Gallery photos={photos} />
      </div>
    </>
  )
}

export default BookingHowItWorks
