import { ChangeEvent } from 'react'

// STYLES
import styles from './index.module.css'

// TS INTERFACES
interface Props {
  name?: string
  id?: string
  value: string | number
  onChange: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined
  checked: boolean
}

const FakeCheckbox = (props: Props): JSX.Element => {
  const { name, id, value, onChange, checked } = props

  return (
    <>
      <input
        className={styles.fake__checkbox__input}
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />{' '}
      <span
        className={`${styles.checkbox__img} ${
          checked ? styles.checkbox__img__filled : ''
        }`}
      />
    </>
  )
}

export default FakeCheckbox
