import { useEffect, useState } from 'react'

// BOOTSTRAP
import { Button, Modal } from 'react-bootstrap'

// Dayjs
import dayjs from 'dayjs'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import authSlice from '@/lib/slices/authSlice'

// AXIOS
import axios from 'axios'

// HELPERS
import { displayRazorpay } from './displayRazorpay'
import { displayPaytm } from './displayPaytm'

// GTM
import { gtmEvent } from '@/lib/gtm'

// TS INTERFACES
import { SWtype } from '../commonInterfaces'
import { RootState } from '@/lib/rootState'
import { IaddressFromAPI } from 'types/bookingModalTypes'
import { useRouter } from 'next/router'
import { FaArrowRight } from 'react-icons/fa'

interface ICTAHelper {
  loading: boolean
  setLoading: any
  activeStep: number
  SW: SWtype | null
}

export const checkingAvailabilityCondition = (
  city: string,
  state: string,
  bookingDate: string,
  meal: string,
  cities: any
): boolean => {
  let validDate = false
  const today = dayjs().format('YYYY-MM-DD')
  // const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')
  if (today === bookingDate) {
    validDate = true
  }
  // if (tomorrow === bookingDate && meal !== 'dinner') {
  //   if (dayjs().isAfter(`${today} 18:00:00`)) {
  //     validDate = true
  //   }
  // }
  if (cities !== undefined && cities.length !== 0) {
    for (const tempCity of cities) {
      if (
        (tempCity.name === city &&
          tempCity.state === state &&
          tempCity.bookable === 0) ||
        validDate
      ) {
        return true
      } else if (
        tempCity.name === city &&
        tempCity.state === state &&
        tempCity.bookable === 1
      ) {
        return false
      }
    }
  } else {
    return true
  }
  // if (
  //   [
  //     'Navi Mumbai',
  //     'Mumbai',
  //     'Hyderabad',
  //     'Bengaluru',
  //     'Thane',
  //     'Palwal',
  //     'Jhajjar',
  //     'Sonipat',
  //     'Panchgaon',
  //     'Ballabgarh',
  //     'Bahadurgarh',
  //     'Dadri',
  //     'Bulandshahr',
  //     'Panipat',
  //     'Sona',
  //     'Meerut',
  //     'Manesar',
  //     'Aligarh',
  //     'Rohtak',
  //     'Karnal',
  //     'Hapur',
  //     'Muzzafarnagar',
  //     'Jaipur',
  //     'Ahmedabad',
  //     'Kolkata',
  //     'Chandigarh',
  //     'Surat',
  //     'Coimbatore',
  //     'Chembur',
  //     'Pune',
  //     'Mohali',
  //     'Panchkula',
  //   ].includes(city) ||
  //   validDate
  // )
  //   return true
  // else return false
}

export const CTAHelper = ({
  activeStep,
  SW,
  loading,
  setLoading,
}: ICTAHelper): JSX.Element => {
  const router = useRouter()
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingBartenderModal
  )
  const {
    userAddressData: { addresses },
    citiesList,
  } = REDUX__bookingModalData

  const REDUX__authData = useSelector((state: RootState) => state.auth)
  const { userData } = REDUX__authData

  const dispatch = useDispatch()

  // const [setModalOpen, setShowOpenModal] = useState(false)
  const [address, setAddress] = useState([])
  const [city, setCity] = useState([])
  useEffect(() => {
    if (
      userData?.id !== null &&
      userData?.name !== null &&
      userData?.email !== null
    ) {
      axios.get(`/user/address`).then((res) => {
        if (addresses !== res.data.addresses) {
          dispatch(
            bookingBartenderModalSlice.actions.setUserAddressData({
              addresses: res.data.addresses,
            })
          )
          for (let i = 0; i < res.data.addresses.length; i++) {
            if (res.data.addresses[i]?.is_default === 1) {
              dispatch(
                bookingBartenderModalSlice.actions.setSelectedAddressId(
                  res.data.addresses[i]?.address_id
                )
              )
            }
          }
        }
        if (res.data.addresses !== address) setAddress(res.data.addresses)
      })
    }
  }, [])

  useEffect(() => {
    axios.get(`/ocl/cities`).then((res) => {
      if (citiesList !== res.data.cities) {
        dispatch(
          bookingBartenderModalSlice.actions.setCitiesList(res.data.cities)
        )
      }

      if (res.data.cities !== city) setCity(res.data.cities)
    })
  }, [])

  // handler for step 5 & 6 & 7
  const validateBookingDesignXYZMenu = (SW, drinksCount: number) => {
    /**
     * validates and submits booking data to booking summary API
     * @param {callback} - it is a function that increases the step count
     */

    //  Destructuring
    const {
      bookingDetails: {
        occasionId,
        numberOfPeople,
        bookingDate,
        bookingTime,
        meal,
      },
      designMenu: { cuisineIds, dishDetails },
      userAddressData: { selectedAddressId },
    } = REDUX__bookingModalData

    const { userData } = REDUX__authData

    if (drinksCount < 2 || drinksCount === undefined || !drinksCount) {
      alert('Please select at least 2 drinks')
      return false
    }

    if (drinksCount > 16) {
      alert('Please select max 16 drinks')
      return false
    }

    // if (meal === 'lunch') {
    if (localStorage.getItem('expiresIn') == null) {
      // If expiresIn is null in localStorage then ask the user to login

      dispatch(bookingBartenderModalSlice.actions.setTruecallerOpen(true))
      dispatch(authSlice.actions.setSource('booking_bartender_modal'))
      setTimeout(() => {
        dispatch(authSlice.actions.toggleLoginModal())
      }, 0)
    } else if (userData.id && (!userData?.name || !userData?.email)) {
      // If user-name or user-email is empty in Redux then show userDetails Modal
      const mobile = JSON.parse(localStorage.getItem('authUser')).mobile
      dispatch(authSlice.actions.toggleUserDetailsModal())
      // if (!bookingId) {
      const dishObjFunc = (dish: any) => dish.id
      const dishDetails2 = dishDetails?.map(dishObjFunc)
      // Create booking lead
      axios({
        method: 'POST',
        url: `/booking/bartender`,
        data: {
          cuisines: [52],
          occasion_id: occasionId,
          number_of_people: numberOfPeople,
          booking_date: bookingDate,
          booking_time: bookingTime,
          dishes: dishDetails2,
          mobile: String(mobile),
        },
      }).then((res) => {
        setLoading(false)

        dispatch(
          bookingBartenderModalSlice.actions.setBookingId(res.data.booking.id)
        )
        dispatch(
          bookingBartenderModalSlice.actions.setSpclAppliances(
            res.data.special_appliances
          )
        )
      })
      // }
    } else {
      setLoading(true)

      const dishObjFunc = (dish: any) => dish.id

      const dishDetails2 = dishDetails?.map(dishObjFunc)
      const mobile = JSON.parse(localStorage.getItem('authUser')).mobile
      // Create booking lead
      axios({
        method: 'POST',
        url: `/booking/bartender`,
        data: {
          cuisines: [52],
          occasion_id: occasionId,
          number_of_people: numberOfPeople,
          booking_date: bookingDate,
          booking_time: bookingTime,
          dishes: dishDetails2,
          mobile: String(mobile),
        },
      })
        .then((res) => {
          setLoading(false)
          console.log('success')
          dispatch(
            bookingBartenderModalSlice.actions.setBookingId(res.data.booking.id)
          )
          dispatch(
            bookingBartenderModalSlice.actions.setSpclAppliances(
              res.data.special_appliances
            )
          )
          if (res.data.special_appliances.length === 0) {
            if (address.length === 0 && addresses.length === 0) {
              if (selectedAddressId) {
                setLoading(true)
                axios({
                  method: 'POST',
                  url: `booking/bartender/summary`,
                  data: {
                    payment_option: 2,
                    address_id: selectedAddressId,
                    booking_id: res.data.booking.id,
                  },
                })
                  .then((response) => {
                    if (response.status === 200) {
                      dispatch(
                        bookingBartenderModalSlice.actions.setBookingSummary(
                          response.data
                        )
                      )
                      return callback && callback('summary')
                    }
                  })
                  .catch((error) => {
                    if (error.response.status === 406) {
                      dispatch(
                        bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    } else if (error.response.status === 500) {
                      dispatch(
                        bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    }
                  })
                  .finally(() => {
                    setLoading(false)
                  })
                // return SW?.goToStep(9)
              } else {
                dispatch(
                  bookingBartenderModalSlice.actions.toggleAddAddressModal({
                    stepNo: 4,
                  })
                )
                return false
              }
            } else {
              if (selectedAddressId) {
                let appID = []
                setLoading(true)
                axios({
                  method: 'POST',
                  url: `booking/bartender/summary`,
                  data: {
                    payment_option: 2,
                    address_id: selectedAddressId,
                    appliance_ids: appID,
                    booking_id: res.data.booking.id,
                  },
                })
                  .then((response) => {
                    if (response.status === 200) {
                      dispatch(
                        bookingBartenderModalSlice.actions.setBookingSummary(
                          response.data
                        )
                      )
                      return SW?.goToNamedStep('summary')
                    }
                  })
                  .catch((error) => {
                    if (error?.response?.status === 406) {
                      dispatch(
                        bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    } else if (error?.response?.status === 500) {
                      dispatch(
                        bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    }
                  })
                  .finally(() => {
                    setLoading(false)
                  })
              } else SW?.goToNamedStep('address')
            }
          } else {
            console.log('else ka baap wala')
            SW?.goToNamedStep('appliances')
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log('error', err)
          // alert('Some error occured!')
        })
        .finally(() => {
          // setLoading(false)
        })
      // axios
      //   .get(`/user/address`, {
      //     headers: {
      //       Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      //     },
      //   })
      //   .then((response) => {
      //     const defaultAddressId: number = (
      //       response.data.addresses.find(
      //         (address: IaddressFromAPI) => address.is_default === 1
      //       ) || { address_id: null }
      //     ).address_id

      //     dispatch(
      //       bookingBartenderModalSlice.actions.setUserAddressData({
      //         addresses: response.data.addresses,
      //         selectedAddressId: defaultAddressId,
      //       })
      //     )
      //   })
    }
  }
  console.log('activeStep', activeStep)
  switch (activeStep) {
    // ---------------------- STEP 1 CTA ----------------------
    case 1: {
      const getGasDetails = (callback: (() => void) | undefined) => {
        if (callback) {
          callback()
        }
      }

      return (
        <Button
          onClick={() => getGasDetails(SW?.nextStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------- STEP 2 CTA ----------------------
    case 2: {
      const {
        bookingDetails: {
          occasionId,
          numberOfPeople,
          bookingDate,
          bookingTime,
        },
      } = REDUX__bookingModalData

      const validateBookingDetails = (
        callback: ((stepName?: string) => void) | undefined
      ) => {
        // Condtions to check mandatory fields
        if (occasionId == null) {
          alert('Please Select Occasion')
          ;(document as any)
            .getElementById('bh_select_occasion')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (bookingDate === '') {
          alert('Please Select Date')
          ;(document as any)
            .getElementById('goToDate')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (!bookingTime || bookingTime === null) {
          alert('Please Select Time')
          ;(document as any)
            .getElementById('goToTime')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        if (numberOfPeople === '') {
          alert('Please Select Number of People')
          ;(document as any)
            .getElementById('goToPeople')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        callback()
      }
      return (
        <Button
          onClick={() => {
            validateBookingDetails(SW?.nextStep)
          }}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------STEP 4 CTA -----------------------

    //entry points - breakfast, breakfast_lunch, breakfast_lunch_dinner, breakfast_dinner
    case 3: {
      const {
        designMenu: { dishDetails },
      } = REDUX__bookingModalData
      const proceedTo = 'View Total Bill'

      let drinksLength = 0,
        totalDrinksCost = 0
      const arrayOfDishes = dishDetails
      if (Array.isArray(arrayOfDishes)) {
        if (arrayOfDishes?.length !== 0) {
          drinksLength = arrayOfDishes?.filter((dish) => {
            totalDrinksCost += dish.price
            return true
          }).length
        }
      }

      return (
        <Button
          onClick={() => {
            validateBookingDesignXYZMenu(SW, drinksLength)
          }}
          block
          variant="warning"
          disabled={loading}
        >
          {proceedTo === 'View Total Bill' ? (
            <span>
              {drinksLength
                ? drinksLength === 1
                  ? `(1 drink ₹${totalDrinksCost})  `
                  : `(${drinksLength} drinks ₹${totalDrinksCost})  `
                : null}
              {proceedTo}{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </span>
          ) : (
            <span>
              {drinksLength
                ? drinksLength === 1
                  ? `(1 item ₹${totalDrinksCost})  `
                  : `(${drinksLength} items ₹${totalDrinksCost})  `
                : null}
              Proceed to {proceedTo}{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </span>
          )}
        </Button>
      )
    }

    // ---------------------- STEP 7 CTA ----------------------
    case 4: {
      console.log('ye hua select 6')
      const {
        bookingDetails: {
          bookingId,
          occasionId,
          numberOfPeople,
          bookingDate,
          bookingTime,
          meal,
          comboIds,
          numberOfBurners,
          appliances,
        },
        spclAppliances: { spclAppliances },
        userAddressData: { selectedAddressId },
        designMenu: {
          cuisineIds,
          dishDetails,
          lunchDishes,
          dinnerDishes,
          breakfastDishes,
        },
      } = REDUX__bookingModalData

      const validateBookingAppliances = (
        callback: ((stepName?: string) => void) | undefined
      ) => {
        if (spclAppliances.length !== 0) {
          let selectedAppliances = []
          if (appliances) {
            selectedAppliances = [...appliances]
          }
          for (let item = 0; item < spclAppliances?.length; item++) {
            const mainAppliance = spclAppliances[item]
            if (
              !selectedAppliances.includes(mainAppliance.id) &&
              !selectedAppliances.includes(
                mainAppliance.alternative_appliance?.appliance_id
              ) &&
              !selectedAppliances.includes(
                mainAppliance.alternative_appliance_2?.appliance_id
              )
            ) {
              alert('Please select all the required appliances')
              return false
            }
          }
        }
        if (selectedAddressId) {
          let appID = []
          if (appliances) {
            appID = [...appliances]
          }
          setLoading(true)
          axios({
            method: 'POST',
            url: `booking/bartender/summary`,
            data: {
              payment_option: 2,
              address_id: selectedAddressId,
              appliance_ids: appID,
              booking_id: bookingId,
            },
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
          })
            .then((response) => {
              if (response.status === 200) {
                dispatch(
                  bookingBartenderModalSlice.actions.setBookingSummary(
                    response.data
                  )
                )
                callback && callback('summary')
              }
            })
            .catch((error) => {
              if (error.response.status === 406) {
                dispatch(
                  bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                )
              } else if (error.response.status === 500) {
                dispatch(
                  bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                )
              }
            })
            .finally(() => {
              setLoading(false)
            })
        } else {
          setLoading(true)

          axios
            .get(`/user/address`)
            .then((response) => {
              const defaultAddressId: number = (
                response.data.addresses.find(
                  (address: IaddressFromAPI) => address.is_default === 1
                ) || { address_id: null }
              ).address_id

              dispatch(
                bookingBartenderModalSlice.actions.setUserAddressData({
                  addresses: response.data.addresses,
                  selectedAddressId: defaultAddressId,
                })
              )
              if (
                response.data.addresses.length === 0 &&
                addresses.length === 0
              ) {
                dispatch(
                  bookingBartenderModalSlice.actions.toggleAddAddressModal({
                    stepNo: 4,
                  })
                )
                return false
                // callback && callback('summary')
              } else {
                let appID = []
                if (appliances) {
                  appID = [...appliances]
                }
                if (defaultAddressId) {
                  axios({
                    method: 'POST',
                    url: `booking/bartender/summary`,
                    data: {
                      payment_option: 2,
                      address_id: defaultAddressId,
                      appliance_ids: appID,
                      booking_id: bookingId,
                    },
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(
                          bookingBartenderModalSlice.actions.setBookingSummary(
                            response.data
                          )
                        )
                        return SW?.goToNamedStep('summary')
                      }
                    })
                    .catch((error) => {
                      if (error?.response?.status === 406) {
                        dispatch(
                          bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error?.response?.status === 500) {
                        dispatch(
                          bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {})
                } else SW?.goToNamedStep('address')
                // callback && callback('address')
              }
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }

      return (
        <Button
          onClick={() => validateBookingAppliances(SW?.goToNamedStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Yes, I can arrange{' '}
          {loading ? (
            '...'
          ) : (
            <>
              {' '}
              <FaArrowRight style={{ verticalAlign: 'text-top' }} />
            </>
          )}
        </Button>
      )
    }

    // ---------------------- STEP 8 CTA ----------------------
    case 5: {
      console.log('ye hua select')
      const {
        bookingDetails: {
          bookingId,
          occasionId,
          numberOfPeople,
          bookingDate,
          bookingTime,
          meal,
          comboIds,
          numberOfBurners,
          appliances,
        },
        userAddressData: { selectedAddressId },
        designMenu: {
          cuisineIds,
          dishDetails,
          lunchDishes,
          dinnerDishes,
          breakfastDishes,
        },
      } = REDUX__bookingModalData

      const dishObjFunc = (dish: any) => ({
        id: dish.id,
        meal_type: dish.meal_type,
      })
      const lunchDishes2 = lunchDishes?.map(dishObjFunc)
      const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
      const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
      const dishDetails2 = dishDetails?.map(dishObjFunc)

      const validateBookingAddress = (callback: (() => void) | undefined) => {
        console.log('selectedAddressId', selectedAddressId)
        if (!selectedAddressId) {
          alert('Please select city')
          return false
        }
        let appID = []
        if (appliances) {
          appID = [...appliances]
        }
        setLoading(true)
        axios({
          method: 'POST',
          url: `booking/bartender/summary`,
          data: {
            payment_option: 2,
            address_id: selectedAddressId,
            appliance_ids: appID,
            booking_id: bookingId,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              dispatch(
                bookingBartenderModalSlice.actions.setBookingSummary(
                  response.data
                )
              )
              callback && callback()
            }
          })
          .catch((error) => {
            if (error.response.status === 406) {
              dispatch(
                bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
              )
            } else if (error.response.status === 500) {
              dispatch(
                bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
              )
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }
      return (
        <Button
          onClick={() => validateBookingAddress(SW?.nextStep)}
          block
          variant="warning"
          disabled={loading}
        >
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )
    }

    // ---------------------- STEP 10 payment CTA ----------------------
    case 7:
      return (
        <Button onClick={SW?.nextStep} block variant="warning">
          Proceed{' '}
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      )

    // ---------------------- STEP 9  booking summary CTA ----------------------
    case 6: {
      const {
        upgradeBooking,
        bookingDetails: { bookingId, bookingDate, meal },
        payment: { paymentGateway },
        summary: {
          invoice: {
            invoice_footer: { payable_now, discount, amount_payable },
          },
        },
        userAddressData: { selectedAddressId, addresses },
      } = REDUX__bookingModalData

      const cityName = addresses.filter(
        (address) => +address.address_id === +selectedAddressId
      )[0]?.city

      const stateName = addresses.filter(
        (address) => +address.address_id === +selectedAddressId
      )[0]?.state

      let couponCode: string
      if (discount === undefined || discount.code === undefined) {
        couponCode = ''
      } else {
        couponCode = discount.code
      }

      const {
        userData: { name, email, mobile },
      } = REDUX__authData

      const validateBookingPayment = () => {
        if (!paymentGateway) {
          alert('Please select a Payment method')
          ;(document as any)
            .getElementById('paymentMethod')
            .scrollIntoView({ behavior: 'smooth' })
          return false
        }
        gtmEvent('book-bartender-flow', {
          step: 'payment-attempted',
          type: paymentGateway,
        })
        setLoading(true)

        axios({
          method: 'PATCH',
          url: `/booking`,
          data: {
            booking_id: bookingId,
            upgrade_booking: upgradeBooking,
          },
        }).then(() => {
          if (paymentGateway === 'paytm') {
            const paymentOption = '?payment_option=2'
            const toggleBookingModalFromPaytm = () =>
              dispatch(
                bookingBartenderModalSlice.actions.toggleBookingBartenderModal()
              )

            dispatch(bookingBartenderModalSlice.actions.setPaymentOpen(true))

            displayPaytm(
              amount_payable,
              amount_payable,
              bookingId,
              paymentOption,
              setLoading,
              toggleBookingModalFromPaytm,
              couponCode,
              router,
              () =>
                dispatch(
                  bookingBartenderModalSlice.actions.setPaymentOpen(false)
                )
            )
          }
          if (paymentGateway === 'razorpay') {
            const paymentOption = '?payment_option=2'
            const toggleBookingModalFromRZP = () =>
              dispatch(
                bookingBartenderModalSlice.actions.toggleBookingBartenderModal()
              )

            dispatch(bookingBartenderModalSlice.actions.setPaymentOpen(true))

            displayRazorpay(
              amount_payable,
              amount_payable,
              bookingId,
              paymentOption,
              name,
              email,
              mobile,
              setLoading,
              toggleBookingModalFromRZP,
              couponCode,
              () =>
                dispatch(
                  bookingBartenderModalSlice.actions.setPaymentOpen(false)
                )
            )
          }
        })
      }

      const requestAvailablity = async () => {
        setLoading(true)

        // so that leave site dialog box do not open
        dispatch(bookingBartenderModalSlice.actions.setPaymentOpen(true))

        const res = await axios.post(
          `/booking/${bookingId}/check-chef-availability`
        )

        setTimeout(() => {
          window.location.href = `/my-bookings?bookingId=${bookingId}&event=checkAddress&show=cart`
        }, 0)
        // setLoading(false)
      }
      return (
        <>
          {' '}
          {checkingAvailabilityCondition(
            cityName,
            stateName,
            bookingDate,
            meal,
            city
          ) ? (
            <Button
              onClick={requestAvailablity}
              block
              variant="warning"
              disabled={loading}
            >
              Request Availability{' '}
              {!loading && (
                <FaArrowRight style={{ verticalAlign: 'text-top' }} />
              )}
              {loading && '...'}
            </Button>
          ) : (
            <Button
              onClick={() => validateBookingPayment()}
              block
              variant="warning"
              disabled={loading}
            >
              Pay 40% Advance to book Bartender (₹{payable_now}) {loading && '...'}
            </Button>
          )}
        </>
      )
    }

    // ---------------------- DEFAULT CTA ----------------------
    default:
      return (
        <button onClick={() => alert('OOPS, something went wrong')}>
          Default button
        </button>
      )
  }
}
