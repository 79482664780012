export const getCuisineIdsByComboId = (comboIds, combosState) => {
  /**
   * getCuisineIdsByComboId
   * @param {comboIds} - Ids of combos selected by user
   * @param {combosState} - Array of Combos (Fetched from API)
   * @return {array} - returns an array of cuisine Ids that are in the selected combo
   */

  // const cuisineIds = combosState
  //   .filter((combo) => comboIds.includes(combo.id))[0]
  //   ?.cuisines?.map((cuisine) => cuisine.cuisine_id)

  let optedCuisines = []
  const optedCombos = combosState.filter((combo) => comboIds.includes(combo.id))
  optedCombos.forEach((c) => {
    optedCuisines = [...optedCuisines, ...c.cuisines]
  })

  return optedCuisines.map((c) => c.cuisine_id)
}

// This function is not used anymore
export const convertFilteredDishesToMultiSelectInputType = (filteredDishes) => {
  /**
   * convertFilteredDishesToMultiSelectInputType
   * @param {filteredDishes} - dishes filtered based on the combo(cuisineIds) selected by the user
   * @return {array} - returns an array with the required shape for the Multiselect dropdown
   */

  const reshapedFilteredDishes = filteredDishes
    .reduce((acc, val) => {
      return [
        acc,
        {
          cuisine_name: val.cuisine_name,
          value: val.id,
          name: val.name,
          label: val.name,
          is_oven_required: val.is_oven_required,
          cost: val.cost,
          type: val.type,
          is_veg: val.is_veg,
          image: val.image,
          dish_type_id: val.dish_type_id,
          served_with: val.served_with,
        },
      ]
    }, [])
    .flat(Infinity)

  return reshapedFilteredDishes
}

// This function is not used anymore
export const findDishesBasedOnCuisineIds = (dishes, combosState, comboId) => {
  /**
   * findDishesBasedOnCuisineIds
   * @param {dishes} - array of all dishes
   * @param {combosState} - Array of Combos (Fetched from API)
   * @param {comboId} - Id of combo selected by user
   * @return {array} - returns an array of dishes containing only dishes based on the cuisineIds, in the end the filteredDishes are converted to the desired format for the multi-select dropdown
   */

  const selectedCombo = combosState.find((combo) => combo.id === comboId)

  if (!selectedCombo) return []
  const filteredDishes = dishes.filter((dish) => {
    let matched = false

    selectedCombo.cuisines.forEach((cuisine) => {
      if (
        cuisine.cuisine_id === dish.cuisine_id &&
        cuisine.dish_types.includes(String(dish.dish_type_id))
      ) {
        matched = true
      }
    })

    return matched
  })

  const finalDishes =
    convertFilteredDishesToMultiSelectInputType(filteredDishes)

  return finalDishes
}

export const shapeSelectedDishesForSummaryAPI = (selectedDishes, mealType) => {
  /**
   * shapeSelectedDishesForSummaryAPI
   * @param {selectedDishes} - array of objects having selected dishes by the user
   */

  const reshapedDishes = selectedDishes
    .reduce((acc, val) => {
      // console.log('acc', acc, 'val', val)
      return [
        acc,
        {
          id: val.value,
          meal_type: mealType,
          requires_gas: val.requires_gas,
          price: val.price,
        },
      ]
    }, [])
    .flat(Infinity)
  return reshapedDishes
}

export const sumTwoNumbers = (a: number, b: number): number => {
  if (b) {
    return a + b
  } else return a
}

export const loadScript = (src: string): Promise<any> => {
  /**
   * @param {src} - JS script source
   * Loads a script to the DOM
   */
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src

    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
