import Link from 'next/link'
import { useRouter } from 'next/router'

import { useState, useEffect } from 'react'

// AXIOS
import axios from 'axios'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import authSlice from '@/lib/slices/authSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

// GTM
import { gtmEvent } from '@/lib/gtm'

interface Props {
  sideDrawerOpen: boolean
  toggleSideDrawer: () => void
}

const SideDrawer = (props: Props): JSX.Element => {
  const { sideDrawerOpen, toggleSideDrawer } = props
  const dispatch = useDispatch()
  const router = useRouter()
  const { pathname } = useRouter()

  const REDUX__authData = useSelector((state: RootState) => state.auth)
  const {
    isLoggedIn,
    userData: { mobile, name },
  } = REDUX__authData

  const [notification, setNotification] = useState(0)

  useEffect(() => {
    if (isLoggedIn) {
      //   axios
      //     .get(`/notifications/booking?type=current`, {
      //       headers: {
      //         Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      //       },
      //     })
      //     .then((response) => {
      //       setNotification(response?.data.total_booking)
      //     })
      // }
      window.scrollTo(0, 0)
    }
  }, [isLoggedIn])

  const logout = () => {
    toggleSideDrawer()
    dispatch(authSlice.actions.logoutUser())
    localStorage.clear()
    router.replace('/')
  }

  const showLoginModal = () => {
    dispatch(authSlice.actions.toggleLoginModal())
    dispatch(authSlice.actions.setSource('navbar'))
    toggleSideDrawer()
  }

  return (
    <nav className={sideDrawerOpen ? 'side-drawer open' : 'side-drawer'}>
      <ul>
        {isLoggedIn ? (
          <>
            <li>
              <span
                className="text-muted d-inline"
                style={{ fontSize: '17.6px' }}
              >
                <span>
                  <img
                    src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/default/user.jpg"
                    alt="user default"
                    width="30"
                    height="30"
                    className="rounded-circle"
                  />
                </span>{' '}
                {isLoggedIn && name ? name : mobile}
              </span>
            </li>
            <li>
              <Link href="/my-profile">
                <a
                  onClick={() => {
                    toggleSideDrawer()
                  }}
                >
                  My Profile
                </a>
              </Link>
            </li>
            <li>
              <Link href="/my-bookings">
                <a
                  className={notification ? 'notification--badge' : ''}
                  data-badge={notification}
                  onClick={() => {
                    toggleSideDrawer()
                  }}
                >
                  My Bookings
                </a>
              </Link>
            </li>
            <li>
              <Link href="/my-credits">
                <a
                  onClick={() => {
                    toggleSideDrawer()
                  }}
                >
                  My Wallet
                </a>
              </Link>
            </li>

            {/* removed from mobile sidebar */}
            {/* <li> 
              <Link href="/about"> 
              <a onClick={() => { toggleSideDrawer() }} > About Us </a> 
              </Link> 
            </li> 
            
             <li> 
               <Link href="/register-as-cook"> 
               <a onClick={() => { toggleSideDrawer() }} > Register as Chef </a> 
               </Link> 
              </li> */}
          </>
        ) : (
          <li
            onClick={showLoginModal}
            style={{
              color: 'rgba(0, 0, 0, 0.5)',
              fontSize: '1.2rem',
            }}
          >
            Sign Up / Login
          </li>
        )}

        <li>
          <Link href="/">
            <a
              // onClick={() => {
              //   toggleSideDrawer()
              //   gtmEvent('book-chef-flow', {
              //     step: 'how-it-works',
              //     type: 'side-bar',
              //   })
              //   dispatch(bookingModalSlice.actions.toggleBookingModal())
              // }}
              onClick={() => {
                if (pathname.includes('bartender')) {
                  gtmEvent('book-bartender-flow', {
                    step: 'how-it-works',
                    type: 'side-bar',
                  })
                  dispatch(
                    bookingBartenderModalSlice.actions.toggleBookingBartenderModal()
                  )
                } else if (pathname.includes('waiter')) {
                  gtmEvent('book-waiter-flow', {
                    step: 'how-it-works',
                    type: 'side-bar',
                  })
                  dispatch(
                    bookingWaiterModalSlice.actions.toggleBookingWaiterModal()
                  )
                } else if (pathname.includes('cleaner')) {
                  gtmEvent('book-cleaner-flow', {
                    step: 'how-it-works',
                    type: 'side-bar',
                  })
                  dispatch(
                    bookingCleanerModalSlice.actions.toggleBookingCleanerModal()
                  )
                } else if (
                  pathname.includes('cook') &&
                  pathname !== '/register-as-cook' &&
                  pathname !== '/register-as-cook-hindi'
                ) {
                  gtmEvent('book-chef-flow', {
                    step: 'how-it-works',
                    type: 'side-bar',
                  })
                  dispatch(bookingModalSlice.actions.toggleBookingModal())
                } else {
                  dispatch(apiDataSlice.actions.setAskServiceModal(true))
                }
              }}
            >
              Book Now
            </a>
          </Link>
        </li>
        <li>
          <Link href="/gallery">
            <a
              onClick={() => {
                toggleSideDrawer()
              }}
            >
              Gallery
            </a>
          </Link>
        </li>
        <li>
          <Link href="/reviews">
            <a
              onClick={() => {
                toggleSideDrawer()
              }}
            >
              Reviews
            </a>
          </Link>
        </li>
        <li>
          <Link href="/menu">
            <a
              onClick={() => {
                toggleSideDrawer()
              }}
            >
              Menu
            </a>
          </Link>
        </li>
        <li>
          <Link href="/cook/top-rated-cooks-chefs">
            <a
              onClick={() => {
                toggleSideDrawer()
              }}
            >
              Top Rated Chefs
            </a>
          </Link>
        </li>
        {isLoggedIn ? (
          <li
            onClick={() => logout()}
            style={{
              color: 'rgba(0, 0, 0, 0.5)',
              fontSize: '1.2rem',
            }}
          >
            Logout
          </li>
        ) : null}
      </ul>
    </nav>
  )
}

export default SideDrawer
