import React, { useState, useEffect } from 'react'
import Select, { ActionMeta } from 'react-select'

// STYLES
import styles from '../../index.module.css'

// DAYJS
import dayjs from 'dayjs'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'

// SHIMMER LOADER
import ShimmerLoader from './ShimmerLoader/index'

// INTERFACE
import { RootState } from '@/lib/rootState'

// AXIOS
import axios from 'axios'

// HELPERS=
import { Col, Row } from 'react-bootstrap'
import PeopleConfirmationModal from '@/components/BookBartenderModal/PeopleConfirmationModal'

const BookingDetails = (): JSX.Element => {
  const dispatch = useDispatch()

  const REDUX__bookingDetails = useSelector(
    (state: RootState) => state.bookingBartenderModal.bookingDetails
  )
  // const REDUX__menuPreferences = useSelector(
  //   (state: RootState) => state.bookingModal.menuPreferences
  // )

  // const REDUX__burners = useSelector(
  //   (state: RootState) => state.bookingBartenderModal.burners
  // )

  // const checkVal = (type: BurnerType) => {
  //   if (type === 'One Burners') {
  //     return '1'
  //   } else if (type === 'Two Burners') {
  //     return '2'
  //   } else if (type === 'Three Burners') {
  //     return '3'
  //   } else if (type === 'Four Burners') {
  //     return '4'
  //   } else if (type === 'Five Burners') {
  //     return '5'
  //   } else if (type === 'Six Burners') {
  //     return '6'
  //   }
  // }

  const tickMark =
    'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

  const initialBookingDataState = {
    occasionId: REDUX__bookingDetails.occasionId || null,
    numberOfPeople: '',
    bookingDate: '',
    bookingTime: '',
    meal: '',
    is_veg: 'nonveg',
    numberOfBurners: '',
    bookingLevel: 'Bartender',
    // comboId: 0,
    comboIds: [],
    is_fried: 'yes',
  }
  // const [comboId, setComboId] = useState<number | ''>('')
  // const [is_veg, setIs_veg] = useState<boolean | ''>('')
  // const [preferenceType, setPreferenceType] = useState<PreferenceType>(
  //   'appetizers-and-main-course'
  // )
  // const [numberOfBurners, setNumberOfBurners] = useState<Iburner | ''>('')

  const [setLunchMenu, setShowLunch] = useState(false)
  const [setBreakfastMenu, setShowBreakfast] = useState(false)
  const [setDinnerMenu, setShowDinner] = useState(false)
  const [data, setData] = useState(initialBookingDataState)
  const [showPeopleConfirmation, setShowPeopleConfirmation] = useState(false)
  const [numberOfPeople, setNumberOfPeople] = useState(null)

  useEffect(() => {
    dispatch(bookingBartenderModalSlice.actions.setBookingDetails(data))
  }, [data])

  // useEffect(() => {
  //   /**
  //    * set initial state values based on the redux state
  //    */
  //   // setIs_veg(REDUX__menuPreferences.is_veg ?? '')
  //   setNumberOfBurners(REDUX__menuPreferences.numberOfBurners ?? '')
  // }, [REDUX__menuPreferences])

  // TODO: move the fetching of occasionsState to a better place immediately
  // const [occasionsState, setOccasionsState] = useState(
  //   useSelector((state: RootState) => state.apiData.occasions)
  // )
  const [specialDates, setSpecialDates] = useState([])
  // const [combos, setCombos] = useState([])
  const occasionsState = useSelector(
    (state: RootState) => state.apiData.occasions
  )

  useEffect(() => {
    axios.get('/admin/booking/special-date').then((res) => {
      const specialDates = res.data.special_dates.map((dateObj: any) => ({
        ...dateObj,
        date: dayjs(dateObj.date).format('YYYY-MM-DD'),
      }))
      setSpecialDates(specialDates)
    })

    axios({
      url: `/occasions?without_meta=true`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(apiDataSlice.actions.setOccasions(response.data))
      })
      .catch(() => {
        // console.log(err)
      })

    axios({
      url: `/cuisines/combos`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(
          apiDataSlice.actions.setCombos(
            response.data.combos.filter(
              ({ published }: { published: boolean }) => published
            )
          )
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const onDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // {console.log('cuisine clicked', e)}

    console.log('name', e.target.name)

    console.log('value', e.target.value)

    console.log('type', e.target.type)
    if (name === 'comboIds') {
      let combos = [...data.comboIds]
      const comboId = parseInt(value)
      const index = combos.indexOf(comboId)
      if (index > -1) {
        combos.splice(index, 1)
      } else {
        if (combos.length > 2) return
        combos = [...combos, comboId]
      }

      setData({ ...data, [name]: combos })
    } else if (name === 'occasionId' || name === 'comboId') {
      setData({ ...data, [name]: parseInt(value) })
    } else if (name === 'bookingDate' || name === 'bookingTime') {
      setData({ ...data, [name]: value })
    } else if (name === 'numberOfBurners') {
      setData({ ...data, numberOfBurners: value })
    }
  }

  const onSelectDataChange = (
    option:
      | { value: string; label: string }
      | { value: number; label: string }
      | null,
    action:
      | ActionMeta<{ value: string; label: string }>
      | ActionMeta<{ value: number; label: string }>
  ) => {
    const name = action.name
    const value = option.value

    if (name === 'numberOfPeople') {
      if (value === 51) {
        setShowPeopleConfirmation(true)
        setNumberOfPeople(null)
        setData({ ...data, [name]: '' })
        return
      }

      setNumberOfPeople(option)
      setData({ ...data, [name]: parseInt(value) })
    } else if (name === 'bookingDate') {
      setData({ ...data, [name]: value, bookingTime: '' })
    } else if (name === 'occasionId') {
      setData({ ...data, [name]: parseInt(value) })
    }
  }

  useEffect(() => {
    if (
      setLunchMenu === false &&
      setDinnerMenu === false &&
      setBreakfastMenu === false
    ) {
      // console.log('nothing')
      setData({ ...data, meal: '' })
    }
    if (
      setLunchMenu === false &&
      setDinnerMenu === true &&
      setBreakfastMenu === false
    ) {
      // console.log('dinner')
      setData({
        ...data,
        meal: 'dinner',
        lunchDishes: [],
        breakfastDishes: [],
        dinnerDishes: [],
        bookingTime: '',
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === false &&
      setBreakfastMenu === false
    ) {
      // console.log('lunch')
      setData({
        ...data,
        meal: 'lunch',
        lunchDishes: [],
        breakfastDishes: [],
        dinnerDishes: [],
        bookingTime: '',
      })
    }

    if (
      setLunchMenu === false &&
      setDinnerMenu === false &&
      setBreakfastMenu === true
    ) {
      // console.log('Breakfast')
      setData({
        ...data,
        meal: 'breakfast',
        lunchDishes: [],
        breakfastDishes: [],
        dinnerDishes: [],
        bookingTime: '',
      })
    }

    if (
      setLunchMenu === false &&
      setDinnerMenu === true &&
      setBreakfastMenu === true
    ) {
      // console.log('Dinner_and_breakfast')
      setData({
        ...data,
        meal: 'breakfast_dinner',
        bookingTime: '',
        dishDetails: [],
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === false &&
      setBreakfastMenu === true
    ) {
      // console.log('lunch_b')
      setData({
        ...data,
        meal: 'breakfast_lunch',
        bookingTime: '',
        dishDetails: [],
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === true &&
      setBreakfastMenu === false
    ) {
      // console.log('lunch_d')
      setData({
        ...data,
        meal: 'lunch_dinner',
        bookingTime: '',
        dishDetails: [],
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === true &&
      setBreakfastMenu === true
    ) {
      // console.log('b_l_d')
      setData({
        ...data,
        meal: 'breakfast_lunch_dinner',
        bookingTime: '',
        dishDetails: [],
      })
    }
  }, [setDinnerMenu, setBreakfastMenu, setLunchMenu])

  // code before breakfast introduction

  // const onDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   if (name === 'occasionId' || name === 'numberOfPeople') {
  //     setData({ ...data, [name]: parseInt(value) })
  //   } else if (name === 'meal') {
  //     if (value === 'full_day')
  //       setData({
  //         ...data,
  //         meal: 'full_day',
  //         bookingTime: '11:00',
  //         dishDetails: [],
  //       })
  //     else
  //       setData({
  //         ...data,
  //         meal: value,
  //         lunchDishes: [],
  //         breakfastDishes: [],
  //         dinnerDishes: [],
  //       })
  //   } else if (
  //     name === 'bookingDate'

  //   ) {
  //     setData({ ...data, meal: '', [name]: value })
  //   }
  //   else if (
  //     name === 'bookingTime' ||
  //     name === 'meal'
  //   ) {
  //     setData({ ...data, [name]: value })
  //   }

  // }

  const date = new Date()
  const stop = 365
  let start = dayjs().format('H') >= 20 ? 1 : 0
  const fontStyles =
    screen.width > 968
      ? {
          singleValue: (styles: any) => ({ ...styles, color: '#777' }),
          dropdownIndicator: (styles: any) => ({ ...styles, color: 'black' }),
        }
      : {
          singleValue: (styles: any) => ({ ...styles, color: '#777' }),
          control: (styles: any) => ({ ...styles, fontSize: '13px' }),
          placeholder: (styles: any) => ({
            ...styles,
            fontSize: '13px',
          }),
          option: (styles: any) => ({
            ...styles,
            fontSize: '13px',
          }),
          dropdownIndicator: (styles: any) => ({ ...styles, color: 'black' }),
        }

  const onSelectedOccasionStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.occasionId ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.occasionId ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.occasionId ? 'white' : 'black',
    }),
  }

  const onSelectedDateStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.bookingDate ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.bookingDate ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.bookingDate ? 'white' : 'black',
    }),
  }

  const onSelectedPeopleStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.numberOfPeople ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.numberOfPeople ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.numberOfPeople ? 'white' : 'black',
    }),
  }

  return (
    <div>
      <h5 id="bh_select_occasion" className={styles.dark__grey__color}>
        Select Occasion
      </h5>
      <Row>
        <Col md={4}>
          {occasionsState.length === 0 ? (
            <ShimmerLoader />
          ) : (
            // occasionsState
            //   .filter((occasion) => occasion.published)
            //   .map(({ id, name }) => {
            //     return (
            //       <span key={id}>
            //         <input
            //           type="radio"
            //           name="occasionId"
            //           id={`occasion_${id}`}
            //           value={id}
            //           onChange={onDataChange}
            //           checked={data.occasionId === id}
            //         />
            //         <label
            //           htmlFor={`occasion_${id}`}
            //           className={` ${styles.light__grey__color}`}
            //         >
            //           <span>{name}</span>
            //         </label>
            //       </span>
            //     )
            //   })
            <Select
              name="occasionId"
              defaultValue={occasionsState
                .filter((occasion) => occasion.published)
                .map(({ id, name }) => {
                  if (id == data.occasionId) {
                    return { value: id, label: name }
                  }
                })}
              options={occasionsState
                .filter((occasion) => occasion.published)
                .map(({ id, name }) => {
                  return { value: id, label: name }
                })}
              className="text-muted"
              styles={{ ...fontStyles, ...onSelectedOccasionStyles }}
              placeholder="Select from dropdown list"
              onChange={(option, action) => onSelectDataChange(option, action)}
              isSearchable={false}
              maxMenuHeight={400}
            />
          )}
        </Col>
      </Row>
      <br />
      <h5 className={styles.dark__grey__color} id="goToDate">
        Select Date
      </h5>
      <Row>
        <Col md={4}>
          <div className="form-group">
            {/* <Form.Control
            as="select"
            onChange={onDataChange}
            value={data.bookingDate}
            name="bookingDate"
            required
            className="text-muted"
          >
            <option value="" disabled>
              Select
            </option>
            {Array.from({ length: 56 }, (_, k) => k + 1)?.map((number, idx) => {
              const dateString = dayjs(date)
                .add(number, 'days')
                .format('D MMM, ddd')
              const dateValueString = dayjs(date)
                .add(number, 'days')
                .format('YYYY-MM-DD')
              const holiDates = ['2021-03-29']
              if (!holiDates.includes(dateValueString)) {
                return (
                  <option value={dateValueString} key={idx}>
                    {dateString}
                  </option>
                )
              } else {
                return (
                  <option value={dateValueString} key={idx}>
                    {dateString} (Holi Rates Apply)
                  </option>
                )
              }
            })}
          </Form.Control> */}
            <Select
              onChange={(option, action) => onSelectDataChange(option, action)}
              options={Array.from(
                { length: stop - start + 1 },
                (_, k) => k
              ).map((number) => {
                const dateString = dayjs()
                  .add(number + start, 'days')
                  .format('D MMM, dddd')
                const dateValueString = dayjs(date)
                  .add(number + start, 'days')
                  .format('YYYY-MM-DD')
                let isSpecialDate = false,
                  specialDateDesc = ''
                specialDates.forEach((dateObj: any) => {
                  if (dateObj.date === dateValueString) {
                    isSpecialDate = true
                    specialDateDesc = dateObj.description
                      ? dateObj.description
                      : ''
                  }
                })
                if (!isSpecialDate) {
                  return { value: dateValueString, label: dateString }
                } else {
                  return {
                    value: dateValueString,
                    label:
                      // <p className="m-0">
                      //   {dateString}
                      //   <small className="text-danger">
                      //     {' '}
                      //     ({specialDateDesc})
                      //   </small>
                      // </p>
                      `${dateString} ${specialDateDesc}`,
                  }
                }
              })}
              name="bookingDate"
              className="text-muted"
              styles={{ ...fontStyles, ...onSelectedDateStyles }}
              isSearchable={false}
              placeholder="Select from dropdown list"
            />
          </div>
        </Col>
      </Row>
      <br />
      <h5 className={styles.dark__grey__color} id="goToPeople">
        Number of Guests
        <span className={`text-muted ${styles.font__size__14} ml-1`}>
          drinkers and non-drinkers
        </span>
      </h5>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <Select
              onChange={(option, action) => onSelectDataChange(option, action)}
              options={[
                ...Array.from({ length: 50 }, (_, k) => k + 1)?.map(
                  (number) => {
                    return { value: number, label: `${number} people` }
                  }
                ),
                ...[{ value: 51, label: '50+ People' }],
              ]}
              name="numberOfPeople"
              className="text-muted"
              styles={{ ...fontStyles, ...onSelectedPeopleStyles }}
              isSearchable={false}
              placeholder="Select from dropdown list"
              minMenuHeight={800}
              value={numberOfPeople}
              // menuIsOpen={true}
            />
          </div>
        </Col>
      </Row>
      <br />
      <div className="radio__button__container">
        <h5 className={styles.dark__grey__color} id="goToTime">
          Select Bartender Arrival Time <br />
        </h5>
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="8am"
              checked={data.bookingTime === `08:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('8:00am', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`08:00`}
              onChange={onDataChange}
            />
            <label htmlFor="8am" className={`${styles.light__grey__color}`}>
              <span className="p-1">8 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="9am"
              checked={data.bookingTime === `09:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('9:00am', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`09:00`}
              onChange={onDataChange}
            />
            <label htmlFor="9am" className={`${styles.light__grey__color}`}>
              <span className="p-1">9 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="10am"
              checked={data.bookingTime === `10:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('10:00am', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`10:00`}
              onChange={onDataChange}
            />
            <label htmlFor="10am" className={`${styles.light__grey__color}`}>
              <span className="p-1">10 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="11am"
              checked={data.bookingTime === `11:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('11:00am', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`11:00`}
              onChange={onDataChange}
            />
            <label htmlFor="11am" className={`${styles.light__grey__color}`}>
              <span className="p-1">11 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="12pm"
              checked={data.bookingTime === `12:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('12:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`12:00`}
              onChange={onDataChange}
            />
            <label htmlFor="12pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">12 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="1pm"
              checked={data.bookingTime === `13:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('1:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`13:00`}
              onChange={onDataChange}
            />
            <label htmlFor="1pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">1 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="2pm"
              checked={data.bookingTime === `14:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('2:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`14:00`}
              onChange={onDataChange}
            />
            <label htmlFor="2pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">2 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="3pm"
              checked={data.bookingTime === `15:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('3:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`15:00`}
              onChange={onDataChange}
            />
            <label htmlFor="3pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">3 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="4pm"
              checked={data.bookingTime === `16:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('4:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`16:00`}
              onChange={onDataChange}
            />
            <label htmlFor="4pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">4 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="5pm"
              checked={data.bookingTime === `17:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('5:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`17:00`}
              onChange={onDataChange}
            />
            <label htmlFor="5pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">5 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="6pm"
              checked={data.bookingTime === `18:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('6:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`18:00`}
              onChange={onDataChange}
            />
            <label htmlFor="6pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">6 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="7pm"
              checked={data.bookingTime === `19:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('7:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`19:00`}
              onChange={onDataChange}
            />
            <label htmlFor="7pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">7 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="8pm"
              checked={data.bookingTime === `20:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('8:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`20:00`}
              onChange={onDataChange}
            />
            <label htmlFor="8pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">8 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="9pm"
              checked={data.bookingTime === `21:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('9:00pm', 'h:mma').isBefore(dayjs().add(1, 'hours'))
              }
              value={`21:00`}
              onChange={onDataChange}
            />
            <label htmlFor="9pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">9 pm </span>
            </label>
          </>
        }
      </div>
      {/* <span className={`${styles.light__grey__color} ${styles.font__size__14}`}>
        {data.bookingTime ? (
          <label>
            {' '}
            <img
              style={{ height: '10px', width: 'auto' }}
              src={tickMark}
              alt="tick"
            />
            &nbsp;Bartender will arrive by{' '}
            {dayjs(data.bookingTime, 'hh: mm').format('h a')}
          </label>
        ) : null}
      </span>
      <br /> */}
      {/* <br />

      
      <br />
      <h5 className={styles.dark__grey__color}>
        Select Gas Stove{' '}
        <span className={`${styles.font__size__16} text-danger`}>
          (how many burners in your kitchen?)
        </span>
      </h5>
      <div className="container radio__button__container">
        {REDUX__burners.map((burner) => {
          return (
            <span key={burner.id}>
              <input
                type="radio"
                name="numberOfBurners"
                id={checkVal(burner.type)}
                value={checkVal(burner.type)}
                onChange={onDataChange}
                checked={data.numberOfBurners === checkVal(burner.type)}
              />
              <label
                htmlFor={checkVal(burner.type)}
                className={`ml-2 ${styles.light__grey__color}`}
              >
                <span>
                  {checkVal(burner.type) === '1'
                    ? '1 burner'
                    : `${checkVal(burner.type)} burners`}
                </span>
              </label>
            </span>
          )
        })}
      </div> */}
      <br />

      <PeopleConfirmationModal
        show={showPeopleConfirmation}
        setShow={setShowPeopleConfirmation}
      />
    </div>
  )
}

export default BookingDetails
