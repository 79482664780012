/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
// REACT
import React, { useState, useEffect } from 'react'

// STYLES
import styles from './index.module.css'
import bmStyles from '../../index.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'

// AXIOS
import axios from 'axios'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

import { Col, Container, Row } from 'react-bootstrap'

import Image from 'next/image'
import { data } from '@/staticData/register-as-chef.data'

// HELPERS
import { BurnerType, Iburner } from 'types/bookingModalTypes'

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const GreyTick = () => (
  <img src={tickMark} alt="tick" className={styles.grey__tick} />
)

const BookingLevel = (): JSX.Element => {
  const dispatch = useDispatch()

  const REDUX__bookingLevel = useSelector(
    (state: RootState) => state.bookingModal.bookingLevel
  )
  const REDUX__burners = useSelector(
    (state: RootState) => state.bookingModal.burners
  )

  const checkVal = (type: BurnerType) => {
    if (type === 'One Burners') {
      return '1'
    } else if (type === 'Two Burners') {
      return '2'
    } else if (type === 'Three Burners') {
      return '3'
    } else if (type === 'Four Burners') {
      return '4'
    } else if (type === 'Five Burners') {
      return '5'
    } else if (type === 'Six Burners') {
      return '6'
    }
  }
  const initialBookingDataState = {
    bookingLevel: '',
    comboId: 0,
    is_veg: '',
    numberOfBurners: '',
    is_fried: 'yes',
  }

  const [dat, setDat] = useState(initialBookingDataState)
  const [combos, setCombos] = useState([])

  useEffect(() => {
    axios({
      url: `/cuisines/combos`,
      method: 'GET',
    })
      .then((response) => {
        setCombos(
          response.data.combos.filter(
            ({ published }: { published: boolean }) => published
          )
        )
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    dispatch(bookingModalSlice.actions.setBookingLevel(dat))
  }, [dat])

  return (
    <Container className="p-0">
      <Row className="mx-0">
        <Col className={window.innerWidth < 968 ? 'pr-2' : 'pr-2 pl-0'}>
          <Row className="align-items-center">
            {/* <Image
              src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/book_a_chef.png"
              width="120px"
              height="160px"
            /> */}
            <img
              src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/book_a_chef.png"
              width="120px"
              height="160px"
            />
          </Row>
          <br />
          <Row className="align-items-center">
            <Col>
              <h2 className="DARK__GREY__COLOR">CHEF</h2>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <GreyTick /> Multi Cuisine Dishes
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <GreyTick /> For Special Occasions
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <GreyTick /> Choose from 500+ Dishes
            </Col>
          </Row>
          <br />
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <div className="radio__button__container">
                <div >
                  <span>
                    <input
                      type="radio"
                      name="bookingLevel"
                      value="Chef"
                      id="Chef"
                      onChange={() => {
                        setDat({
                          ...dat,
                          bookingLevel: 'Chef',
                          comboId: 0,
                        })
                        dispatch(
                          bookingModalSlice.actions.setBookingLevel({
                            bookingLevel: 'Chef',
                            comboId: '',
                          })
                        )
                      }}
                      checked={dat.bookingLevel === 'Chef'}
                    />
                    <label
                      htmlFor={'Chef'}
                      className={` ${styles.light__grey__color} ${styles.elongated__select}`}
                    >
                      <p className="text-center m-0">Select Chef</p>
                    </label>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          className="pl-4 pr-4"
          style={{ borderLeft: 'solid 0.5px lightgrey' }}
        >
          <Row className="align-items-center pl-3">
            {/* <Image
              src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/book_a_cook.png"
              width="120px"
              height="160px"
            /> */}
            <img
              src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/book_a_cook.png"
              width="120px"
              height="160px"
            />
          </Row>
          <br />
          <Row className="align-items-center">
            <Col>
              <h2 className="DARK__GREY__COLOR">COOK</h2>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <GreyTick /> Simple Home Food
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <GreyTick /> For Daily Basis
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <GreyTick /> Choose from 50+ Dishes
            </Col>
          </Row>
          <br />
          <Row className="justify-content-center align-items-center">
            <Col className={`${styles.text}`}>
              <div className="  radio__button__container">
                <div >
                  <span>
                    <input
                      type="radio"
                      name="bookingLevel"
                      value="Cook"
                      id="Cook"
                      onChange={() => {
                        setDat({
                          ...dat,
                          bookingLevel: 'Cook',
                          comboId: 0,
                        })
                        dispatch(
                          bookingModalSlice.actions.setBookingLevel({
                            bookingLevel: 'Cook',
                            comboId: '',
                          })
                        )
                      }}
                      checked={dat.bookingLevel === 'Cook'}
                    />
                    <label
                      htmlFor={'Cook'}
                      className={`ml-2 ${styles.light__grey__color} ${styles.elongated__select}`}
                    >
                      <p className="text-center m-0">Select Cook</p>
                    </label>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <div>
        <h5 className={bmStyles.dark__grey__color}>Select Cuisine</h5>
        <div className="radio__button__container">
          {combos.map(({ id, name }) => {
            return (
              <span key={id}>
                <input
                  type="radio"
                  name="combos"
                  id={`combo_${id}`}
                  value={id}
                  onChange={({ target: { value } }) => {
                    setDat({ ...dat, comboId: parseInt(value) })
                    dispatch(
                      bookingModalSlice.actions.setBookingLevel({
                        comboId: parseInt(value),
                      })
                    )
                  }}
                  checked={id === dat.comboId}
                  disabled={dat.bookingLevel === 'Cook' && id !== 8 && id !== 9}
                />
                <label
                  htmlFor={`combo_${id}`}
                  className={
                    dat.bookingLevel === 'Cook' && id !== 9 && id !== 8
                      ? ` ${styles.disabled_cuisine}`
                      : ` ${styles.light__grey__color}`
                  }
                  style={
                    dat.bookingLevel === 'Cook' && id !== 8 && id !== 9
                      ? { pointerEvents: 'none' }
                      : {}
                  }
                >
                  <span>{name}</span>
                </label>
              </span>
            )
          })}
        </div>
      </div>
      <br />
      <div>
        <h5 className={bmStyles.dark__grey__color}>Select Preference</h5>
        <div className="radio__button__container">
          <input
            type="radio"
            name="veg"
            id="veg"
            value="veg"
            onChange={({ target: { value } }) => {
              setDat({ ...dat, is_veg: 'veg' })
            }}
            // onChange={onDataChange}
            checked={dat.is_veg === 'veg'}
          />
          <label htmlFor="veg" className={` ${styles.light__grey__color}`}>
            <span>Veg only</span>
          </label>
          <input
            type="radio"
            name="nonveg"
            id="nonveg"
            value="nonveg"
            onChange={({ target: { value } }) => {
              setDat({ ...dat, is_veg: 'nonveg' })
            }}
            // onChange={onDataChange}
            checked={dat.is_veg === 'nonveg'}
          />
          <label htmlFor="nonveg" className={` ${styles.light__grey__color}`}>
            <span>Veg + Non Veg</span>
          </label>
        </div>
      </div>
      <div>
        <br />
        <h5 className={bmStyles.dark__grey__color}>
          Select Gas Burners <br />
          <span className={`${styles.font__size__14} text-danger`}>
            in your kitchen?
          </span>
        </h5>
        <div className="radio__button__container">
          {REDUX__burners.map((burner) => {
            return (
              <span key={burner.id}>
                <input
                  type="radio"
                  name="numberOfBurners"
                  id={checkVal(burner.type)}
                  value={checkVal(burner.type)}
                  onChange={({ target: { value } }) => {
                    setDat({ ...dat, numberOfBurners: value })
                  }}
                  checked={dat.numberOfBurners === checkVal(burner.type)}
                />
                <label
                  htmlFor={checkVal(burner.type)}
                  className={` ${styles.light__grey__color}`}
                >
                  <span>
                    {checkVal(burner.type) === '1'
                      ? '1 burner'
                      : `${checkVal(burner.type)} burners`}
                  </span>
                </label>
              </span>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default BookingLevel
