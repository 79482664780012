import Head from 'next/head'
import { useRouter } from 'next/router'
import type { AppProps /*, AppContext */ } from 'next/app'

// import { pageview } from '../lib/gtm'

import {
  useState,
  useEffect,
  createContext,
  SetStateAction,
  Dispatch,
} from 'react'

// REACT QUERY
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// Import React Script Library to load Google object
import Script from 'react-load-script'

// REDUX
import { Provider } from 'react-redux'
import store from '../store'
import authSlice from '@/lib/slices/authSlice'

// GLOBAL CSS
import '../style/global.css' // custom global CSS
import '../style/notification-badge.css' // global notification badge CSS
import '../style/side-drawer.css' //  side drawer global CSS
import '../style/homepage-landing-img.css' //  homepage landing img global CSS
import '../style/custom-radio-btn.css' //  custom radio btn global CSS

import '../style/global-color-classes.css' //  global color classes
import '../style/global-font-size-classes.css' //  global font size classes

import 'swiper/swiper.min.css' // react-id-swiper CSS
import 'react-circular-progressbar/dist/styles.css' // react-circular progressbar styles
import 'react-datepicker/dist/react-datepicker.css' // react-datepicker CSS

// AXIOS
import axios from 'axios'

// COMPONENTS
import NavBar from '@/components/Navbar'
import FloatingBookNowBtn from '@/components/FloatingBookNowBtn'
import FloatingtoTopBtn from '@/components/FloatingtoTopBtn'

import Footer from '@/components/Footer'
import BookChefModal from '@/components/BookingModals/BookChefModal'

// CONSTANTS
import { BASE_URL } from '../constants'

// TS INTERFACES
import { SWtype } from '@/components/BookingModals/BookChefModal/commonInterfaces'
import BookBartenderModal from '@/components/BookingModals/BookBartenderModal'
import BookWaiterModal from '@/components/BookingModals/BookWaiterModal'
import BookCleanerModal from '@/components/BookingModals/BookCleanerModal'
import BookDailyCookModal from '@/components/BookingModals/BookDailyCookModal'
import AskServicesModal from '@/components/AskServicesModal'
axios.defaults.baseURL = BASE_URL // Setting default base URL

// Creating SW Context
export const SWContext = createContext<{
  SW: SWtype | null
  updateSWinContext: Dispatch<SetStateAction<null>>
}>({
  SW: null,
  updateSWinContext: () => ({}),
})

// Init React Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const [SW, setSW] = useState(null)

  const SWContextInitialData = {
    SW,
    updateSWinContext: setSW,
  }

  const router = useRouter()
  const logout = () => {
    store.dispatch(authSlice.actions.logoutUser())
    localStorage.clear()
    router.replace('/')
  }

  useEffect(() => {
    // INTERCEPT REQUEST VIA AXIOS
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        config.headers['Authorization'] =
          'Bearer ' + localStorage.getItem('accessToken')

        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    // INTERCEPT RESPONSE VIA AXIOS
    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        switch (error.response.status) {
          case 401:
            if (error.config.url === `/auth/refresh-token`) {
              logout()

              return new Promise((resolve, reject) => {
                reject(error)
              })
            }

            if (localStorage.getItem('refreshToken')) {
              return axios({
                method: 'POST',
                url: `/auth/refresh-token`,
                data: {
                  mobile: localStorage.getItem('mobile'),
                  refresh_token: localStorage.getItem('refreshToken'),
                  source: 'user_website',
                },
              })
                .then((response) => {
                  if (response.status === 200) {
                    // set into localstorage
                    localStorage.setItem(
                      'authUser',
                      JSON.stringify(response.data.user)
                    )
                    localStorage.setItem(
                      'authToken',
                      JSON.stringify(response.data.token)
                    )

                    localStorage.setItem(
                      'accessToken',
                      response.data.token.accessToken
                    )
                    localStorage.setItem(
                      'refreshToken',
                      response.data.token.refreshToken
                    )
                    localStorage.setItem(
                      'expiresIn',
                      response.data.token.expiresIn
                    )
                    localStorage.setItem('mobile', response.data.user.mobile)
                  }

                  // New request with new token
                  const config = error.config

                  config.headers['Authorization'] =
                    'Bearer ' + response.data.token.accessToken

                  return new Promise((resolve, reject) => {
                    axios
                      .request(config)
                      .then((response) => {
                        resolve(response)
                      })
                      .catch((error) => {
                        reject(error)
                      })
                  })
                })
                .catch((error) => {
                  console.log(error)
                })
            } else {
              break
            }
          case 429:
            alert(
              'Please try after ' +
                error.response.headers['retry-after'] +
                ' seconds !'
            )
            break
          case 400:
            // setErrMsg('Validation Error')
            // setShow(true)

            break
          case 406:
            // setErrMsg('Invalid Data')
            // setShow(true)
            break
          default:
            break
        }
        // Do something with response error
        return Promise.reject(error)
      }
    )
  })

  // useEffect(() => {
  //   router.events.on('routeChangeComplete', pageview)
  //   return () => {
  //     router.events.off('routeChangeComplete', pageview)
  //   }
  // }, [router.events])

  useEffect(() => {
    /**
     * Scrolling to top is controlled by using the following if check.
     *
     * @description The browser will not scroll to top whenever url includes any of these -> describe, ingredients, appliances
     */
    if (!window.location.pathname.includes('my-bookings')) {
      window.scrollTo(0, 0)
    }
  }, [router.pathname])

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Head>
        <title>COOX</title>
        <meta
          name="description"
          content="COOX Online Pvt. Ltd."
          key="description"
        />
      </Head>

      <Script
        url={`https://maps.googleapis.com/maps/api/js?sensor=${false}&key=${
          process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY
        }&libraries=places`}
      />

      <QueryClientProvider client={queryClient}>
        <SWContext.Provider value={SWContextInitialData}>
          <Provider store={store}>
            {/*------------------ NAVBAR ------------------*/}
            <NavBar homePage={router.pathname === '/'} />

            {/*------------------ APP BODY ------------------*/}
            <div
              style={
                router.pathname === '/'
                  ? { marginTop: '60px' }
                  : { marginTop: '60px' }
              }
            >
              <Component {...pageProps} />
              <AskServicesModal/>
              <BookChefModal />
              <BookBartenderModal />
              <BookWaiterModal />
              <BookCleanerModal />
            <BookDailyCookModal/>
              <FloatingBookNowBtn />
              <FloatingtoTopBtn />
            </div>

            {/*------------------ FOOTER ------------------*/}
            <Footer />
          </Provider>
        </SWContext.Provider>

        {/*------------------ REACT QUERY DEVTOOLS (available only in development) ------------------*/}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default MyApp
