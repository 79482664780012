import styles from './index.module.css'

interface Props {
  click: () => void
}

const backdrop = (props: Props): JSX.Element => (
  <div className={styles.backdrop} onClick={props.click} />
)

export default backdrop
