import React from 'react'

import styles from './index.module.css'

interface Props {
  click: () => void
}

const DrawerToggleButton = (props: Props): JSX.Element => (
  <button
    className={styles['toggle-button']}
    onClick={props.click}
    title="Open sidebar"
  >
    <div className={styles['toggle-button__line']} />
    <div className={styles['toggle-button__line']} />
    <div className={styles['toggle-button__line']} />
  </button>
)

export default DrawerToggleButton
