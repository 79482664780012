import { SyntheticEvent } from 'react'

// REACT SWITCH
import Switch from 'react-switch'

// TS INTERFACES
interface Props {
  title: string
  checked: boolean
  handleChange: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>
  ) => void
}

const textSwitch = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  fontSize: '8px',
  color: '#fff',
  paddingRight: 2,
}

const IsVegSwitch = ({ checked, handleChange, title }: Props): JSX.Element => {
  return (
    <label className="border-0 float-right">
      <span className="text-muted mr-2">
        <h6 className="mb-0 d-inline FONT__SIZE__12">{title}</h6>
      </span>
      <Switch
        onColor="#4cd137"
        className="react-switch mr-2"
        onChange={handleChange}
        checked={Boolean(checked) ?? false}
        checkedIcon={<div style={textSwitch}>ON</div>}
        uncheckedIcon={<div style={textSwitch}>OFF</div>}
        height={20}
        width={40}
      />
    </label>
  )
}

export default IsVegSwitch
