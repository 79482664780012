const whatsappSrc =
  'https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20'

interface IWhatsAppText {
  activeStep: number
}

const ChatOnWhatsAppText = ({ activeStep }: IWhatsAppText): JSX.Element => {
  // console.log(activeStep , "activeStep")
  return (
    <h6 className="w-100 text-center text-muted ">
      Need help?&nbsp; Call on <a href="tel:+919004044234">9004044234</a>
    </h6>
  )
  if (activeStep < 7) {
    // if (activeStep >= 4) {
    //   return <h6 className="w-100 text-center">Full Payment Summary Ahead</h6>
    // }
    return (
      <h6 className="w-100 text-center text-muted ">
        Need help?&nbsp;
        <a href={whatsappSrc} target="_blank" rel="noopener noreferrer">
          Chat on WhatsApp
        </a>
      </h6>
    )
  } else {
    return (
      <h6 className="w-100 text-center text-muted ">
        Need help?&nbsp;
        <a href={whatsappSrc} target="_blank" rel="noopener noreferrer">
          Chat on WhatsApp
        </a>{' '}
        or Call <a href="tel:+919004044234">9004044234</a>
      </h6>
    )
  }
}

export default ChatOnWhatsAppText
