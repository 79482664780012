// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col, Container } from 'react-bootstrap'

// STATIC DATA
import { bookingHowItWorksData } from '@/staticData/booking-modal-how-it-works.data'

import Gallery from 'react-photo-gallery'

// DATA
import { photos } from '@/staticData/booking-modal-glimpses.data'

// TS INTERFACES
import { SWtype } from '@/components/BookingModals/BookChefModal/commonInterfaces'

const BookingHowItWorks = ({ SW }: { SW: SWtype | null }): JSX.Element => {
  return (
    <>
      <Row>
        <Col className="pl-0">
          <p
            style={{ paddingLeft: '1%', fontSize: '17.5px' }}
            className="text-muted"
          >
            How It Works:
          </p>
        </Col>
      </Row>
      <Row
        className="mb-3"
        onClick={() => {
          SW.nextStep()
        }}
      >
        <Col className="d-inline-flex pl-0">
          <img
            src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/customMade.svg"
            alt="Choose your Menu"
            width="30px"
            height="30px"
            style={{ marginRight: '3.3%', marginLeft: '1%' }}
          />
          <p className="text-muted">Select Menu</p>
        </Col>
      </Row>
      {bookingHowItWorksData.map(({ imgSrc, point }) => (
        <Row key={point} className="mb-3">
          {/* <Col md={1} xs={2}></Col> */}
          <Col className="d-inline-flex pl-0">
            <img
              src={imgSrc}
              alt={point}
              width="30px"
              height="30px"
              style={{ marginRight: '3.3%', marginLeft: '1%' }}
            />
            <p className="text-muted">{point}</p>
          </Col>
        </Row>
      ))}

      {/* <Row>
        <Col>
          <p className="text-danger mb-0">
            * Chef & Assistant Cook do not wash utensils, serve or lay table
          </p>
          <p className="text-danger mb-2">
            * You can book waiters, cleaners, bartenders, ingredients separately
          </p>
        </Col>
      </Row> */}

      <div className="mt-3">
        <Gallery photos={photos} />
      </div>
    </>
  )
}

export default BookingHowItWorks
