import Link from 'next/link'
import { useRouter } from 'next/router'

// BOOTSTRAP
import { Dropdown, Nav } from 'react-bootstrap'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import authSlice from '@/lib/slices/authSlice'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

const LoggedInUserNavOptions = (): JSX.Element => {
  const dispatch = useDispatch()
  const router = useRouter()

  const REDUX__authData = useSelector((state: RootState) => state.auth)
  const {
    isLoggedIn,
    userData: { mobile, name },
  } = REDUX__authData

  const logout = () => {
    dispatch(authSlice.actions.logoutUser())
    localStorage.clear()
    router.replace('/')
  }

  const showLoginModal = () => {
    dispatch(authSlice.actions.toggleLoginModal())
    dispatch(authSlice.actions.setSource('navbar'))
    // props.toggleSideDrawer()
  }

  return (
    <>
      {isLoggedIn ? (
        <Dropdown>
          <Dropdown.Toggle variant="basic" className="p-0 m-0">
            <span>
              <img
                src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/default/user.jpg"
                alt="user default"
                width="30"
                height="30"
                className="rounded-circle"
              />
            </span>{' '}
            <span
              className="text-muted d-inline"
              style={{ fontSize: '17.6px' }}
            >
              {isLoggedIn && name ? name : mobile}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Link href="/my-profile">
              <a className="dropdown-item">My Profile</a>
            </Link>
            <Link href="/my-bookings">
              <a className="dropdown-item">My Bookings</a>
            </Link>
            <Link href="/my-credits">
              <a className="dropdown-item">My Wallet</a>
            </Link>
            <p
              className="dropdown-item text-muted cursor__pointer"
              onClick={() => logout()}
            >
              Logout
            </p>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Nav.Link onClick={showLoginModal} style={{ padding: '0px' }}>
          Sign Up / Login
        </Nav.Link>
      )}
    </>
  )
}

export default LoggedInUserNavOptions
