// BOOTSTRAP
import { Dispatch, SetStateAction } from 'react'
import { Container, Modal, Row, Col, Button } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'
// STYLES
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/lib/rootState'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'
import styles from './index.module.css'
import Link from 'next/link'
const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const GreyTick = () => (
  <img src={tickMark} alt="tick" className={styles.grey__tick} />
)

const AskServicesModal = (): JSX.Element => {
  const dispatch = useDispatch()
  const askServiceModal = useSelector(
    (state: RootState) => state.apiData.askServiceModal
  )
  return (
    <Modal
      show={askServiceModal}
      onHide={() => {
        // setShow(false)
        dispatch(apiDataSlice.actions.setAskServiceModal(false))
      }}
      size="lg"
      scrollable
      backdrop="static"
      dialogClassName="modal-dialog-wm"
    >
      <Modal.Header closeButton>
        <button
          onClick={() => dispatch(apiDataSlice.actions.setAskServiceModal(false))}
          className="btn btn-white m-0 p-0"
        >
          <FaArrowLeft />
        </button>
        <Modal.Title className="w-100">
          <span className="m-0">
            <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
              <b>Choose Service</b>
            </p>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {/* <Col > */}
            <Col md={6} xs={6} className="mt-2">
              <Link href={'/cook?action=book-a-chef'}>
                <a target="_blank">
                  <div className="text-center">
                    <img
                      // style={{ borderRadius: '50%' }}
                      src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/cooks_and_chefs.png"
                      width="300px"
                      className="img img-fluid"
                      // height="100px"
                    />
                  </div>
                  <br />
                  <div className="text-center">
                    <h4 style={{ color: 'blue', textDecoration: 'underline' }}>
                      Cook Chef
                    </h4>
                  </div>
                </a>
              </Link>
            </Col>
            <Col md={6} xs={6} className="mt-2">
              <Link href={'/bartender?action=book-a-bartender'}>
                <a target="_blank">
                  <div className="text-center">
                    <img
                      src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/private_bartenders.png"
                      width="300px"
                      className="img img-fluid"
                    />
                  </div>
                  <br />
                  <div className="text-center">
                    <h4 style={{ color: 'blue', textDecoration: 'underline' }}>
                      Bartender
                    </h4>
                  </div>
                </a>
              </Link>
            </Col>
            {/* </Col> */}
          </Row>
          <br />
          <Row className="mt-5">
            {/* <Col md={6} xs={6}> */}
            <Col md={6} xs={6} className="mt-4">
              <Link href={'/waiter?action=book-a-waiter'}>
                <a target="_blank">
                  <div className="text-center">
                    <img
                      src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/waiters_and_servers.png"
                      width="300px"
                      className="img img-fluid"
                    />
                  </div>
                  <br />
                  <div className="text-center">
                    <h4 style={{ color: 'blue', textDecoration: 'underline' }}>
                      Waiter
                    </h4>
                  </div>
                </a>
              </Link>
            </Col>
            <Col md={6} xs={6} className="mt-4">
              <Link href={'/cleaner?action=book-a-cleaner'}>
                <a target="_blank">
                  <div className="text-center">
                    <img
                      src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/kitchen_cleaners.png"
                      width="300px"
                      className="img img-fluid"
                    />
                  </div>
                  <br />
                  <div className="text-center">
                    <h4 style={{ color: 'blue', textDecoration: 'underline' }}>
                      Cleaner
                    </h4>
                  </div>
                </a>
              </Link>
            </Col>
            {/* </Col> */}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default AskServicesModal
