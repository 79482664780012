import Link from 'next/link'
import { useRouter } from 'next/router'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col, Container } from 'react-bootstrap'

// REACT SOCIAL ICONS
import { SocialIcon } from 'react-social-icons'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import authSlice from '@/lib/slices/authSlice'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

const footerHeaderStyleClass = 'font-weight-bolder FONT__SIZE__18 mt-3 mb-4'
const footerItemStyleClass =
  'text-decoration-none text-light font-weight-bold FONT__SIZE__14'

const Footer = (): JSX.Element => {
  const dispatch = useDispatch()
  const router = useRouter()

  const REDUX__authData = useSelector((state: RootState) => state.auth)
  const { isLoggedIn } = REDUX__authData

  const logout = () => {
    dispatch(authSlice.actions.logoutUser())
    localStorage.clear()
    router.replace('/')
  }

  const showLoginModal = () => {
    dispatch(authSlice.actions.toggleLoginModal())
    dispatch(authSlice.actions.setSource('navbar'))
  }

  return (
    <div className={`text-light p-4 ${styles.bg__black}`}>
      <Container className="px-md-4">
        <Row className="my-5">
          {/* Company */}
          <Col md={3}>
            <div className={footerHeaderStyleClass}>Company</div>

            <div className="text-light ">
              <div className="mb-2">
                <Link href="/about">
                  <a target="_blank" className={footerItemStyleClass}>
                    About Us
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/cook/how-it-works">
                  <a target="_blank" className={footerItemStyleClass}>
                    How It Works
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/register-as-cook">
                  <a target="_blank" className={footerItemStyleClass}>
                    Register as Partner
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/hiring">
                  <a target="_blank" className={footerItemStyleClass}>
                    We are Hiring
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <a
                  href="https://blog.coox.in"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={footerItemStyleClass}
                >
                  Blog
                </a>
              </div>
              <div className="mb-2">
                <Link href="/near-me">
                  <a target="_blank" className={footerItemStyleClass}>
                    Near Me
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/privacy-policy">
                  <a target="_blank" className={footerItemStyleClass}>
                    Privacy Policy
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/anti-discrimination-policy">
                  <a target="_blank" className={footerItemStyleClass}>
                    Anti Discrimination Policy
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/terms">
                  <a target="_blank" className={footerItemStyleClass}>
                    Terms & Conditions
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/helpcenter">
                  <a target="_blank" className={footerItemStyleClass}>
                    Help Center
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/sitemap">
                  <a target="_blank" className={footerItemStyleClass}>
                    Sitemap
                  </a>
                </Link>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className={footerHeaderStyleClass}>Services</div>
            <div className="text-light ">
              <div className="mb-2">
                <Link href="/cook">
                  <a target="_blank" className={footerItemStyleClass}>
                    Cook and Chef Service
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/caterer">
                  <a target="_blank" className={footerItemStyleClass}>
                    Catering Service
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/bartender">
                  <a target="_blank" className={footerItemStyleClass}>
                    Bartender Service
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/waiter">
                  <a target="_blank" className={footerItemStyleClass}>
                    Waiter Service
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/cleaner">
                  <a target="_blank" className={footerItemStyleClass}>
                    Cleaner Service
                  </a>
                </Link>
              </div>
            </div>
          </Col>
          {/* Serving In */}
          <Col md={3}>
            <div className={footerHeaderStyleClass}>Serving In</div>

            <div className="text-light ">
              <div className="mb-2">
                <Link href="/city/delhi">
                  <a target="_blank" className={footerItemStyleClass}>
                    Delhi
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/noida">
                  <a target="_blank" className={footerItemStyleClass}>
                    Noida
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/gurugram">
                  <a target="_blank" className={footerItemStyleClass}>
                    Gurugram
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/faridabad">
                  <a target="_blank" className={footerItemStyleClass}>
                    Faridabad
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/ghaziabad">
                  <a target="_blank" className={footerItemStyleClass}>
                    Ghaziabad
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/greater-noida">
                  <a target="_blank" className={footerItemStyleClass}>
                    Greater Noida
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/mumbai">
                  <a target="_blank" className={footerItemStyleClass}>
                    Mumbai
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/bengaluru">
                  <a target="_blank" className={footerItemStyleClass}>
                    Bengaluru
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/hyderabad">
                  <a target="_blank" className={footerItemStyleClass}>
                    Hyderabad
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/ahmedabad">
                  <a target="_blank" className={footerItemStyleClass}>
                    Ahmedabad
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/jaipur">
                  <a target="_blank" className={footerItemStyleClass}>
                    Jaipur
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/kolkata">
                  <a target="_blank" className={footerItemStyleClass}>
                    Kolkata
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/chandigarh">
                  <a target="_blank" className={footerItemStyleClass}>
                    Chandigarh
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/ludhiana">
                  <a target="_blank" className={footerItemStyleClass}>
                    Ludhiana
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/pune">
                  <a target="_blank" className={footerItemStyleClass}>
                    Pune
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/goa">
                  <a target="_blank" className={footerItemStyleClass}>
                    Goa
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/surat">
                  <a target="_blank" className={footerItemStyleClass}>
                    Surat
                  </a>
                </Link>
              </div>
              <div className="mb-2">
                <Link href="/city/chennai">
                  <a target="_blank" className={footerItemStyleClass}>
                    Chennai
                  </a>
                </Link>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className={footerHeaderStyleClass}>Contact</div>

            <div className="text-light ">
              <div className="mb-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:hello@coox.in"
                  className={footerItemStyleClass}
                >
                  hello@coox.in
                </a>
              </div>
              <div className="mb-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="tel:9004044234"
                  className={footerItemStyleClass}
                >
                  9004-044-234
                </a>
              </div>
              {/* <div className="mb-2">
                <a
                  href="https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={footerItemStyleClass}
                >
                  Chat on WhatsApp
                </a>
              </div> */}
              <div className="mb-2">
                <Link href="/contact-us">
                  <a target="_blank" className={footerItemStyleClass}>
                    Contact Us
                  </a>
                </Link>
              </div>
              {!isLoggedIn ? (
                <div className="mb-2">
                  <p
                    onClick={() => showLoginModal()}
                    className={`${footerItemStyleClass} cursor__pointer`}
                  >
                    Sign up / Login
                  </p>
                </div>
              ) : (
                <div className="mb-2">
                  <p
                    onClick={() => logout()}
                    className={`${footerItemStyleClass} cursor__pointer`}
                  >
                    Logout
                  </p>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <hr style={{ border: '0.1px solid whitesmoke' }} />
        <Row>
          <Col md={6} className="text-md-left text-center">
            <SocialIcon
              url="https://www.facebook.com/cooxonline"
              target="_blank"
              style={{ height: 30, width: 30 }}
              className="m-2"
              bgColor="white"
              rel="noopener"
            />
            <SocialIcon
              url="https://www.instagram.com/coox.in/"
              target="_blank"
              style={{ height: 30, width: 30 }}
              className="m-2"
              bgColor="white"
              rel="noopener"
            />
            <SocialIcon
              url="https://www.linkedin.com/company/cooxonline"
              target="_blank"
              style={{ height: 30, width: 30 }}
              className="m-2"
              bgColor="white"
              rel="noopener"
            />
            <SocialIcon
              url="https://twitter.com/cooxonline"
              target="_blank"
              style={{ height: 30, width: 30 }}
              className="m-2"
              bgColor="white"
              rel="noopener"
            />
            <SocialIcon
              url="https://www.youtube.com/channel/UCKc9QdEbYsibls1E_tTA-sA"
              target="_blank"
              style={{ height: 30, width: 30 }}
              className="m-2"
              bgColor="white"
              rel="noopener"
            />
            {/* <SocialIcon
              network="whatsapp"
              url="https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20"
              target="_blank"
              style={{ height: 30, width: 30 }}
              className="m-2"
              bgColor="white"
              rel="noopener"
            /> */}
          </Col>
          <Col md={6}>
            <div className="m-md-2  text-md-right text-center mt-3">
              Copyright © 2022 COOX Online Private Limited - All Rights
              Reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
