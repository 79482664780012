import { useRef } from 'react'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

// React Script Library to load Google object
import Script from 'react-load-script'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

const G_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY

const EditAddress = (): JSX.Element => {
  const autocomplete = useRef()

  const dispatch = useDispatch()

  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )

  const {
    userEditAddressData: {
      house_number,
      street,
      city,
      state,
      country,
      city_error,
      house_number_error,
      street_error,
    },
  } = REDUX__bookingModalData

  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {
      types: ['(cities)'],
      componentRestrictions: { country: 'in' },
    }

    // Initialize Google Autocomplete
    /*global google*/

    autocomplete.current = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options
    )

    // Avoid paying for data that you don't need by restricting the
    // set of place fields that are returned to just the address
    // components and formatted address
    autocomplete?.current?.setFields([
      'address_components',
      'formatted_address',
    ])
    // Fire Event when a suggested name is selected
    autocomplete?.current?.addListener('place_changed', handlePlaceSelect)
  }

  const handlePlaceSelect = () => {
    // Extract City From Address Object
    const addressObject = autocomplete?.current?.getPlace()

    const address = addressObject.address_components

    if (address[1]?.long_name && address[2]?.long_name) {
      // Setting in REDUX global State
      dispatch(
        bookingModalSlice.actions.setEditAddressDataForThatInstant({
          city: address[0].long_name,
          state: address[3]?.long_name
            ? address[2].long_name
            : address[1].long_name,
          country: address[3]?.long_name ?? address[2].long_name,
        })
      )
    } else {
      // Setting in REDUX global State
      dispatch(
        bookingModalSlice.actions.setEditAddressDataForThatInstant({
          city: address[0].long_name,
          state: '',
          country: '',
        })
      )
    }
  }

  const handleChange = (key: string, value: string) => {
    dispatch(
      bookingModalSlice.actions.setEditAddressDataForThatInstant({
        [key]: value,
      })
    )
  }

  return (
    <>
      <input
        name="house_number"
        placeholder="House No. / Flat No. / Floor / Apartment / Building Name"
        value={house_number ?? ''}
        onChange={({ target: { value } }) =>
          handleChange('house_number', value)
        }
        className="form-control"
      />
      <div style={{ fontSize: 12, color: 'red' }}>{house_number_error}</div>
      <br />
      <input
        name="street"
        placeholder="Street / Area / Sector"
        value={street ?? ''}
        onChange={({ target: { value } }) => handleChange('street', value)}
        className="form-control"
      />
      <div style={{ fontSize: 12, color: 'red' }}>{street_error}</div>
      <br />

      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${G_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <div className="form-group">
        <input
          readOnly
          disabled
          // id="autocomplete"
          placeholder="Search City"
          value={city ?? ''}
          className="form-control"
          onChange={({ target: { value } }) => handleChange('city', value)}
        />
        <div style={{ fontSize: 12, color: 'red' }}>{city_error}</div>
      </div>
      <Row>
        <Col className="form-group">
          <input
            name="state"
            placeholder="State"
            value={state ?? ''}
            className="form-control"
            readOnly
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            name="country"
            placeholder="Country"
            value={country ?? ''}
            className="form-control"
            readOnly
            disabled
          />
        </Col>
      </Row>
    </>
  )
}

export default EditAddress
