import React, { useState, useEffect } from 'react'
import Select, { ActionMeta } from 'react-select'

// STYLES
import styles from '../../index.module.css'

// DAYJS
import dayjs from 'dayjs'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'

// SHIMMER LOADER
import ShimmerLoader from './ShimmerLoader/index'

// INTERFACE
import { RootState } from '@/lib/rootState'

// AXIOS
import axios from 'axios'

// HELPERS
import {
  // PreferenceType,
  BurnerType,
  // , Iburner
} from 'types/bookingModalTypes'
import { Col, Row } from 'react-bootstrap'
import PeopleConfirmationModal from '@/components/BookChefModal/PeopleConfirmationModal'

const BookingDetails = (): JSX.Element => {
  const dispatch = useDispatch()

  const REDUX__bookingDetails = useSelector(
    (state: RootState) => state.bookingModal.bookingDetails
  )
  // const REDUX__menuPreferences = useSelector(
  //   (state: RootState) => state.bookingModal.menuPreferences
  // )

  const REDUX__burners = useSelector(
    (state: RootState) => state.bookingModal.burners
  )

  const checkVal = (type: BurnerType) => {
    if (type === 'One Burners') {
      return '1'
    } else if (type === 'Two Burners') {
      return '2'
    } else if (type === 'Three Burners') {
      return '3'
    } else if (type === 'Four Burners') {
      return '4'
    } else if (type === 'Five Burners') {
      return '5'
    } else if (type === 'Six Burners') {
      return '6'
    }
  }

  const initialBookingDataState = {
    occasionId: REDUX__bookingDetails.occasionId || null,
    numberOfPeople: '',
    bookingDate: '',
    bookingTime: '',
    meal: '',
    is_veg: 'nonveg',
    numberOfBurners: '',
    bookingLevel: 'Chef',
    // comboId: 0,
    comboIds: [],
    is_fried: 'yes',
    specialComboId: '',
  }
  // const [comboId, setComboId] = useState<number | ''>('')
  // const [is_veg, setIs_veg] = useState<boolean | ''>('')
  // const [preferenceType, setPreferenceType] = useState<PreferenceType>(
  //   'appetizers-and-main-course'
  // )
  // const [numberOfBurners, setNumberOfBurners] = useState<Iburner | ''>('')
  const [data, setData] = useState(initialBookingDataState)
  const [disableGhar, setDisableGhar] = useState(false)
  const [showPeopleConfirmation, setShowPeopleConfirmation] = useState(false)
  const [numberOfPeople, setNumberOfPeople] = useState(null)
  const [disableSpecial, setDisableSpecial] = useState(false)
  const [disableOtherCombos, setDisableOtherCombos] = useState(false)
  const [specialComboId, setSpecialComboId] = useState('')
  const [combosNotPublished, setCombosNotPublished] = useState([])
  useEffect(() => {
    dispatch(bookingModalSlice.actions.setBookingDetails(data))
  }, [data])

  // useEffect(() => {
  //   /**
  //    * set initial state values based on the redux state
  //    */
  //   // setIs_veg(REDUX__menuPreferences.is_veg ?? '')
  //   setNumberOfBurners(REDUX__menuPreferences.numberOfBurners ?? '')
  // }, [REDUX__menuPreferences])

  // TODO: move the fetching of occasionsState to a better place immediately
  // const [occasionsState, setOccasionsState] = useState(
  //   useSelector((state: RootState) => state.apiData.occasions)
  // )
  const [specialDates, setSpecialDates] = useState([])
  // const [combos, setCombos] = useState([])
  const combos = useSelector((state: RootState) => state.apiData.combos)
  const occasionsState = useSelector(
    (state: RootState) => state.apiData.occasions
  )

  useEffect(() => {
    axios.get('/admin/booking/special-date').then((res) => {
      const specialDates = res.data.special_dates.map((dateObj: any) => ({
        ...dateObj,
        date: dayjs(dateObj.date).format('YYYY-MM-DD'),
      }))
      setSpecialDates(specialDates)
    })

    axios({
      url: `/occasions?without_meta=true`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(apiDataSlice.actions.setOccasions(response.data))
      })
      .catch((err) => {
        // console.log(err)
      })

    axios({
      url: `/cuisines/combos`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(
          apiDataSlice.actions.setCombos(
            response.data.combos.filter(
              ({ published }: { published: boolean }) => published
            )
          )
        )
        dispatch(
          apiDataSlice.actions.setUnPublishedCombos(
            response.data.combos.filter(
              ({ published }: { published: boolean }) => !published
            )
          )
        )
        setCombosNotPublished(
          response.data.combos.filter(
            ({ published }: { published: boolean }) => !published
          )
        )
      })
      .catch((err) => {
        console.log(err)
      })

    axios({
      method: 'GET',
      url: '/appliances/gas_hobs',
    })
      .then((res) => {
        dispatch(bookingModalSlice.actions.setBurners(res.data.gas_hobs))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    {
      occasionsState
        .filter((occasion) => occasion.published)
        .map(({ id, name, cuisine_combo_id }) => {
          if (id == data.occasionId) {
            if (cuisine_combo_id != null) {
              setSpecialComboId(cuisine_combo_id)
            } else {
              setSpecialComboId('')
            }
          }
        })
    }
    setDisableGhar(false)
    setDisableOtherCombos(false)
    setDisableSpecial(false)
  }, [occasionsState, data.occasionId])

  useEffect(() => {
    setData({ ...data, specialComboId: specialComboId })
    // dispatch(bookingModalSlice.actions.setSpecialComboId(specialComboId))
  }, [specialComboId])

  const [setLunchMenu, setShowLunch] = useState(false)
  const [setBreakfastMenu, setShowBreakfast] = useState(false)
  const [setDinnerMenu, setShowDinner] = useState(false)

  const onDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // {console.log('cuisine clicked', e)}

    console.log('name', e.target.name)

    console.log('value', e.target.value)

    console.log('type', e.target.type)
    if (name === 'comboIds') {
      let combos = [...data.comboIds]
      const comboId = parseInt(value)
      const index = combos.indexOf(comboId)
      if (index > -1) {
        combos.splice(index, 1)
      } else {
        if (combos.length > 2) {
          alert('You can choose maximum 3 cuisines')
          return
        }
        combos = [...combos, comboId]
      }
      if (combos.length === 0) {
        setDisableGhar(false)
        setDisableOtherCombos(false)
        setDisableSpecial(false)
      }
      if (combos.length > 0 && combos.includes(9)) {
        setDisableOtherCombos(true)
        setDisableGhar(false)
        setDisableSpecial(true)
      } else if (combos.length > 0 && combos.includes(specialComboId)) {
        setDisableGhar(true)
        setDisableOtherCombos(true)
        setDisableSpecial(false)
      } else if (combos.length > 0) {
        setDisableOtherCombos(false)
        setDisableGhar(true)
        setDisableSpecial(true)
      }
      setData({ ...data, [name]: combos })
    } else if (name === 'occasionId' || name === 'comboId') {
      if (name === 'occasionId' && value == '56') {
        setSpecialComboId('')
        setData({ ...data, [name]: parseInt(value), is_veg: 'veg' })
      } else if (name === 'occasionId') {
        setSpecialComboId('')
        setData({ ...data, [name]: parseInt(value), is_veg: 'nonveg' })
      } else {
        setData({ ...data, [name]: parseInt(value) })
      }
    } else if (name === 'bookingDate' || name === 'bookingTime') {
      setData({ ...data, [name]: value })
    } else if (name === 'numberOfBurners') {
      setData({ ...data, numberOfBurners: value })
    }
  }

  const onSelectDataChange = (
    option:
      | { value: string; label: string }
      | { value: number; label: string }
      | null,
    action:
      | ActionMeta<{ value: string; label: string }>
      | ActionMeta<{ value: number; label: string }>
  ) => {
    const name = action.name
    let value = option.value
    if (name === 'numberOfPeople') {
      if (value === 51) {
        setShowPeopleConfirmation(true)
        setNumberOfPeople(null)
        setData({ ...data, [name]: '' })
        return
      }

      setNumberOfPeople(option)
      setData({ ...data, [name]: parseInt(value) })
    } else if (name === 'bookingDate') {
      setData({ ...data, [name]: value, bookingTime: '' })
    } else if (name === 'occasionId') {
      if (value == '56') {
        setData({
          ...data,
          [name]: parseInt(value),
          is_veg: 'veg',
          comboIds: [],
        })
      } else {
        setData({
          ...data,
          [name]: parseInt(value),
          is_veg: 'nonveg',
          comboIds: [],
        })
      }
    }
  }

  useEffect(() => {
    if (
      setLunchMenu === false &&
      setDinnerMenu === false &&
      setBreakfastMenu === false
    ) {
      // console.log('nothing')
      setData({ ...data, meal: '' })
    }
    if (
      setLunchMenu === false &&
      setDinnerMenu === true &&
      setBreakfastMenu === false
    ) {
      // console.log('dinner')
      setData({
        ...data,
        meal: 'dinner',
        lunchDishes: [],
        breakfastDishes: [],
        dinnerDishes: [],
        bookingTime: '',
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === false &&
      setBreakfastMenu === false
    ) {
      // console.log('lunch')
      setData({
        ...data,
        meal: 'lunch',
        lunchDishes: [],
        breakfastDishes: [],
        dinnerDishes: [],
        bookingTime: '',
      })
    }

    if (
      setLunchMenu === false &&
      setDinnerMenu === false &&
      setBreakfastMenu === true
    ) {
      // console.log('Breakfast')
      setData({
        ...data,
        meal: 'breakfast',
        lunchDishes: [],
        breakfastDishes: [],
        dinnerDishes: [],
        bookingTime: '',
      })
    }

    if (
      setLunchMenu === false &&
      setDinnerMenu === true &&
      setBreakfastMenu === true
    ) {
      // console.log('Dinner_and_breakfast')
      setData({
        ...data,
        meal: 'breakfast_dinner',
        bookingTime: '',
        dishDetails: [],
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === false &&
      setBreakfastMenu === true
    ) {
      // console.log('lunch_b')
      setData({
        ...data,
        meal: 'breakfast_lunch',
        bookingTime: '',
        dishDetails: [],
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === true &&
      setBreakfastMenu === false
    ) {
      // console.log('lunch_d')
      setData({
        ...data,
        meal: 'lunch_dinner',
        bookingTime: '',
        dishDetails: [],
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === true &&
      setBreakfastMenu === true
    ) {
      // console.log('b_l_d')
      setData({
        ...data,
        meal: 'breakfast_lunch_dinner',
        bookingTime: '',
        dishDetails: [],
      })
    }
  }, [setDinnerMenu, setBreakfastMenu, setLunchMenu])

  const handleClickBreakfast = () => {
    setShowBreakfast(!setBreakfastMenu)
  }

  const handleClickDinner = () => {
    setShowDinner(!setDinnerMenu)
  }

  const handleClickLunch = () => {
    setShowLunch(!setLunchMenu)
  }

  // code before breakfast introduction

  // const onDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target
  //   if (name === 'occasionId' || name === 'numberOfPeople') {
  //     setData({ ...data, [name]: parseInt(value) })
  //   } else if (name === 'meal') {
  //     if (value === 'full_day')
  //       setData({
  //         ...data,
  //         meal: 'full_day',
  //         bookingTime: '11:00',
  //         dishDetails: [],
  //       })
  //     else
  //       setData({
  //         ...data,
  //         meal: value,
  //         lunchDishes: [],
  //         breakfastDishes: [],
  //         dinnerDishes: [],
  //       })
  //   } else if (
  //     name === 'bookingDate'

  //   ) {
  //     setData({ ...data, meal: '', [name]: value })
  //   }
  //   else if (
  //     name === 'bookingTime' ||
  //     name === 'meal'
  //   ) {
  //     setData({ ...data, [name]: value })
  //   }

  // }

  const date = new Date()
  const stop = 365
  let start = dayjs().format('H') >= 20 ? 1 : 0
  const fontStyles =
    screen.width > 968
      ? {
          singleValue: (styles: any) => ({ ...styles, color: '#777' }),
          //   singleValue: (styles: any) => ({ ...styles, color: 'white' }),
          //   valueContainer: (styles: any) => ({
          //     ...styles,
          //     backgroundColor: 'black',
          //   }),
          dropdownIndicator: (styles: any) => ({ ...styles, color: 'black' }),
        }
      : {
          singleValue: (styles: any) => ({ ...styles, color: '#777' }),
          control: (styles: any) => ({ ...styles, fontSize: '13px' }),
          placeholder: (styles: any) => ({
            ...styles,
            fontSize: '13px',
          }),
          option: (styles: any) => ({
            ...styles,
            fontSize: '13px',
          }),
          dropdownIndicator: (styles: any) => ({ ...styles, color: 'black' }),
        }

  const onSelectedOccasionStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.occasionId ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.occasionId ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.occasionId ? 'white' : 'black',
    }),
  }

  const onSelectedDateStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.bookingDate ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.bookingDate ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.bookingDate ? 'white' : 'black',
    }),
  }

  const onSelectedPeopleStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.numberOfPeople ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.numberOfPeople ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.numberOfPeople ? 'white' : 'black',
    }),
  }

  const handleBookingStatus = (value: any) => {
    const val = dayjs().hour()
    if (val >= 5 && val <= 7) {
      alert(
        `${value} time slot not available for today. You can choose from ${dayjs()
          .add(3, 'hours')
          .format('h a')} Breakfast onwards`
      )
    } else if (val >= 8 && val <= 13) {
      alert(
        `${value}  time slot not available for today. You can choose from ${dayjs()
          .add(3, 'hours')
          .format('h a')} Lunch onwards`
      )
    } else if (val >= 14 && val <= 19) {
      alert(
        `${value} time slot not available for today. You can choose from ${dayjs()
          .add(3, 'hours')
          .format('h a')} Dinner onwards`
      )
    } else {
      alert('No slots available today. Please choose tomorrow’s date.')
    }
  }

  return (
    <div>
      <br />
      <h5 id="bh_select_occasion" className={styles.dark__grey__color}>
        Select Occasion
      </h5>
      <Row>
        <Col md={4}>
          {occasionsState.length === 0 ? (
            <ShimmerLoader />
          ) : (
            // occasionsState
            //   .filter((occasion) => occasion.published)
            //   .map(({ id, name }) => {
            //     return (
            //       <span key={id}>
            //         <input
            //           type="radio"
            //           name="occasionId"
            //           id={`occasion_${id}`}
            //           value={id}
            //           onChange={onDataChange}
            //           checked={data.occasionId === id}
            //         />
            //         <label
            //           htmlFor={`occasion_${id}`}
            //           className={` ${styles.light__grey__color}`}
            //         >
            //           <span>{name}</span>
            //         </label>
            //       </span>
            //     )
            //   })
            <Select
              name="occasionId"
              defaultValue={occasionsState
                .filter((occasion) => occasion.published)
                .map(({ id, name }) => {
                  if (id == data.occasionId) {
                    return { value: id, label: name }
                  }
                })}
              options={occasionsState
                .filter((occasion) => occasion.published)
                .map(({ id, name }) => {
                  return { value: id, label: name }
                })}
              className="text-muted"
              styles={{ ...fontStyles, ...onSelectedOccasionStyles }}
              placeholder="Select from dropdown list"
              onChange={(option, action) => onSelectDataChange(option, action)}
              isSearchable={false}
              maxMenuHeight={400}
            />
          )}
        </Col>
      </Row>
      <br />
      <h5 className={styles.dark__grey__color} id="goToDate">
        Select Date
      </h5>
      <Row>
        <Col md={4}>
          <div className="form-group">
            {/* <Form.Control
            as="select"
            onChange={onDataChange}
            value={data.bookingDate}
            name="bookingDate"
            required
            className="text-muted"
          >
            <option value="" disabled>
              Select
            </option>
            {Array.from({ length: 56 }, (_, k) => k + 1)?.map((number, idx) => {
              const dateString = dayjs(date)
                .add(number, 'days')
                .format('D MMM, ddd')
              const dateValueString = dayjs(date)
                .add(number, 'days')
                .format('YYYY-MM-DD')
              const holiDates = ['2021-03-29']
              if (!holiDates.includes(dateValueString)) {
                return (
                  <option value={dateValueString} key={idx}>
                    {dateString}
                  </option>
                )
              } else {
                return (
                  <option value={dateValueString} key={idx}>
                    {dateString} (Holi Rates Apply)
                  </option>
                )
              }
            })}
          </Form.Control> */}
            <Select
              onChange={(option, action) => onSelectDataChange(option, action)}
              options={Array.from(
                { length: stop - start + 1 },
                (_, k) => k
              )?.map((number) => {
                const dateString = dayjs()
                  .add(number + start, 'days')
                  .format('D MMM, dddd')
                const dateValueString = dayjs(date)
                  .add(number + start, 'days')
                  .format('YYYY-MM-DD')
                let isSpecialDate = false,
                  specialDateDesc = ''
                specialDates.forEach((dateObj: any) => {
                  if (dateObj.date === dateValueString) {
                    isSpecialDate = true
                    specialDateDesc = dateObj.description
                      ? dateObj.description
                      : ''
                  }
                })
                if (!isSpecialDate) {
                  return { value: dateValueString, label: dateString }
                } else {
                  return {
                    value: dateValueString,
                    label:
                      // <p className="m-0">
                      //   {dateString}
                      //   <small className="text-danger">
                      //     {' '}
                      //     ({specialDateDesc})
                      //   </small>
                      // </p>
                      `${dateString} ${specialDateDesc}`,
                  }
                }
              })}
              name="bookingDate"
              className="text-muted"
              styles={{ ...fontStyles, ...onSelectedDateStyles }}
              isSearchable={false}
              placeholder="Select from dropdown list"
            />
          </div>
        </Col>
      </Row>
      <br />
      <h5 className={styles.dark__grey__color} id="goToPeople">
        No. of People
        <span className={`text-muted ${styles.font__size__14} ml-1`}>
          above 5 years
        </span>
      </h5>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <Select
              onChange={(option, action) => onSelectDataChange(option, action)}
              options={[
                ...Array.from({ length: 50 }, (_, k) => k + 1)?.map(
                  (number) => {
                    return { value: number, label: `${number} people` }
                  }
                ),
                ...[{ value: 51, label: '50+ People' }],
              ]}
              name="numberOfPeople"
              minMenuHeight={400}
              className="text-muted"
              styles={{ ...fontStyles, ...onSelectedPeopleStyles }}
              isSearchable={false}
              placeholder="Select from dropdown list"
              // menuIsOpen={true}
              value={numberOfPeople}
            />
            {/* <Form.Control
            as="select"
            onChange={onDataChange}
            value={data.numberOfPeople}
            name="numberOfPeople"
            required
            className="text-muted"
          >
            <option value="" disabled>
              Select
            </option>
            {Array.from({ length: 39 }, (_, k) => k + 2)?.map((number, idx) => {
              return (
                <option value={number} key={idx}>
                  {number} people
                </option>
              )
            })}
          </Form.Control> */}
          </div>
        </Col>
      </Row>
      {/* <br />

      
      <br />
      <h5 className={styles.dark__grey__color}>
        Select Gas Stove{' '}
        <span className={`${styles.font__size__16} text-danger`}>
          (how many burners in your kitchen?)
        </span>
      </h5>
      <div className="container radio__button__container">
        {REDUX__burners.map((burner) => {
          return (
            <span key={burner.id}>
              <input
                type="radio"
                name="numberOfBurners"
                id={checkVal(burner.type)}
                value={checkVal(burner.type)}
                onChange={onDataChange}
                checked={data.numberOfBurners === checkVal(burner.type)}
              />
              <label
                htmlFor={checkVal(burner.type)}
                className={`ml-2 ${styles.light__grey__color}`}
              >
                <span>
                  {checkVal(burner.type) === '1'
                    ? '1 burner'
                    : `${checkVal(burner.type)} burners`}
                </span>
              </label>
            </span>
          )
        })}
      </div> */}
      <br />
      <h5 className={styles.dark__grey__color} id="goToMeal">
        Select Meal(s)
        {/* <br />
        <span className={`text-danger ${styles.font__size__14}`}>
          you can select multiple
        </span> */}
      </h5>
      <div className="radio__button__container">
        <span>
          <input
            type="checkbox"
            name="meal_breakfast"
            value="meal_breakfast"
            id="meal_breakfast"
            onChange={handleClickBreakfast}
            checked={setBreakfastMenu === true ? true : false}
          />
          <label
            htmlFor="meal_breakfast"
            className={`${styles.light__grey__color}`}
          >
            <span>Breakfast</span>
          </label>
        </span>

        <span>
          <input
            type="checkbox"
            name="meal_lunch"
            value="lunch"
            id="lunch"
            onChange={handleClickLunch}
            checked={setLunchMenu === true ? true : false}
          />
          <label htmlFor="lunch" className={`${styles.light__grey__color}`}>
            <span>Lunch</span>
          </label>
        </span>

        <span>
          <input
            type="checkbox"
            name="meal_dinner"
            value="dinner"
            id="dinner"
            onChange={handleClickDinner}
            checked={setDinnerMenu === true ? true : false}
          />
          <label htmlFor="dinner" className={`${styles.light__grey__color}`}>
            <span>Dinner</span>
          </label>
        </span>
      </div>
      <br />
      {data.meal.length !== 0 &&
        (data.meal === 'dinner' ||
          data.meal === 'lunch' ||
          data.meal === 'breakfast') && (
          <>
            <h5 className={styles.dark__grey__color} id="goToTime">
              Start having {data.meal.charAt(0).toUpperCase()}
              {data.meal.slice(1)} from <br />
            </h5>

            <div className="radio__button__container">
              {data.meal === 'breakfast' && (
                <>
                  <input
                    type="radio"
                    name="bookingTime"
                    id="8am"
                    checked={data.bookingTime === `8:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('6:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`8:00`}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('6:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('8 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="8am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>8 am onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="9am"
                    checked={data.bookingTime === `9:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('7:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`9:00`}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('7:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('9 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="9am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>9 am onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="10am"
                    checked={data.bookingTime === `10:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('8:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`10:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('8:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('10 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="10am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>10 am onwards </span>
                  </label>

                  {/* {(data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    dayjs('11:00am', 'h:mma').isBefore(
                      dayjs()
                    )) || (
                    <>
                      <input
                        type="radio"
                        name="bookingTime"
                        id="11am"
                        checked={data.bookingTime === `11:00`}
                        disabled={
                          data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                          dayjs('11:00am', 'h:mma').isBefore(
                            dayjs()
                          )
                        }
                        value={`11:00`}
                        onChange={onDataChange}
                      />
                      <label
                        htmlFor="11am"
                        className={` ${styles.light__grey__color}`}
                      >
                        <span>11 am onwards </span>
                      </label>
                    </>
                  )} */}
                </>
              )}

              {data.meal === 'lunch' && (
                <>
                  <input
                    type="radio"
                    name="bookingTime"
                    id="10am"
                    checked={data.bookingTime === `10:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('8:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`10:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('8:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('10 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="10am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>10 am onwards </span>
                  </label>
                  <input
                    type="radio"
                    name="bookingTime"
                    id="11am"
                    checked={data.bookingTime === `11:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('9:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`11:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('9:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('11 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="11am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>11 am onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="12pm"
                    checked={data.bookingTime === `12:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('10:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`12:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('10:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('12 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="12pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>12 pm onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="1pm"
                    checked={data.bookingTime === `13:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('11:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`13:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('11:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('1 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="1pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>1 pm onwards </span>
                  </label>

                  {/* {(data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    dayjs('12:00pm', 'h:mma').isBefore(dayjs())) || (
                    <> */}
                  <input
                    type="radio"
                    name="bookingTime"
                    id="2pm"
                    checked={data.bookingTime === `14:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('12:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`14:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('12:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('2 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="2pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>2 pm onwards </span>
                  </label>
                  {/* </>
                  )} */}

                  <input
                    type="radio"
                    name="bookingTime"
                    id="3pm"
                    checked={data.bookingTime === `15:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('1:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`15:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('1:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('3 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="3pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>3 pm onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="4pm"
                    checked={data.bookingTime === `16:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('2:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`16:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('2:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('4 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="4pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>4 pm onwards </span>
                  </label>
                </>
              )}

              {data.meal === 'dinner' && (
                <>
                  <input
                    type="radio"
                    name="bookingTime"
                    id="5pm"
                    checked={data.bookingTime === `17:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('3:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`17:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('3:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('5 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="5pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>5 pm onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="6pm"
                    checked={data.bookingTime === `18:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('4:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`18:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('4:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('6 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="6pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>6 pm onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="7pm"
                    checked={data.bookingTime === `19:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('5:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`19:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('5:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('7 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="7pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>7 pm onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="8pm"
                    checked={data.bookingTime === `20:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('6:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`20:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('6:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('8 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="8pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>8 pm onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="9pm"
                    checked={data.bookingTime === `21:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('7:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`21:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('7:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('9 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="9pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>9 pm onwards </span>
                  </label>

                  <input
                    type="radio"
                    name="bookingTime"
                    id="10pm"
                    checked={data.bookingTime === `22:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('8:00pm', 'h:mma').isBefore(dayjs())
                    // }
                    value={`22:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('8:00pm', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('10 pm')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="10pm"
                    className={` ${styles.light__grey__color}`}
                  >
                    <span>10 pm onwards </span>
                  </label>

                  {/* {(data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  dayjs('9:00pm', 'h:mma').isBefore(
                    dayjs()
                  )) || (
                  <>
                    <input
                      type="radio"
                      name="bookingTime"
                      id="9pm"
                      checked={data.bookingTime === `21:00`}
                      disabled={
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('9:00pm', 'h:mma').isBefore(
                          dayjs()
                        )
                          ? true
                          : false
                      }
                      value={`21:00`}
                      onChange={onDataChange}
                    />
                    <label
                      htmlFor="9pm"
                      className={`ml-2 ${styles.light__grey__color}`}
                    >
                      <span>
                        9 pm{' '}
                        {isSelectedTimeSlot(data.bookingTime, `21:00`) &&
                          'onwards'}
                      </span>
                    </label>
                  </>
                )} */}
                </>
              )}
            </div>
            <br></br>
          </>
        )}
      {/* if we choose LUNCH and DINNER */}
      {/* {data.meal.length !== 0 && data.meal === 'lunch_dinner' && ( */}
      {data.meal.length !== 0 && data.meal === 'lunch_dinner' && (
        <>
          {/* <Row>
            <Col>
              <h5 className={styles.dark__grey__color}>Service Duration </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-danger mb-2">
                9:30 am to 11 pm (total 13.5 hours of service)
              </p>
              <p className="text-muted small mb-2">
                You can extend if needed. Charges apply.
              </p>
            </Col>
          </Row>
          <br /> */}
          <div className="radio__button__container">
            <h5 className={styles.dark__grey__color} id="goToTime">
              Start having Lunch from <br />
            </h5>
            {
              <>
                <input
                  type="radio"
                  name="bookingTime"
                  id="10am"
                  checked={data.bookingTime === `10:00`}
                  // disabled={
                  //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  //   dayjs('8:00am', 'h:mma').isBefore(dayjs())
                  // }
                  value={`10:00`}
                  // onChange={onDataChange}
                  onChange={(e) => {
                    if (
                      data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                      dayjs('8:00am', 'h:mma').isBefore(dayjs())
                    ) {
                      handleBookingStatus('10 am')
                      return
                    }
                    onDataChange(e)
                  }}
                />
                <label
                  htmlFor="10am"
                  className={`${styles.light__grey__color}`}
                >
                  <span>10 am onwards </span>
                </label>
              </>
            }
            {
              <>
                <input
                  type="radio"
                  name="bookingTime"
                  id="11am"
                  checked={data.bookingTime === `11:00`}
                  // disabled={
                  //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  //   dayjs('9:00am', 'h:mma').isBefore(dayjs())
                  // }
                  value={`11:00`}
                  // onChange={onDataChange}
                  onChange={(e) => {
                    if (
                      data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                      dayjs('9:00am', 'h:mma').isBefore(dayjs())
                    ) {
                      handleBookingStatus('11 am')
                      return
                    }
                    onDataChange(e)
                  }}
                />
                <label
                  htmlFor="11am"
                  className={`${styles.light__grey__color}`}
                >
                  <span>11 am onwards </span>
                </label>
              </>
            }
            {
              <>
                <input
                  type="radio"
                  name="bookingTime"
                  id="12pm"
                  checked={data.bookingTime === `12:00`}
                  // disabled={
                  //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  //   dayjs('10:00am', 'h:mma').isBefore(dayjs())
                  // }
                  value={`12:00`}
                  // onChange={onDataChange}
                  onChange={(e) => {
                    if (
                      data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                      dayjs('10:00am', 'h:mma').isBefore(dayjs())
                    ) {
                      handleBookingStatus('12 am')
                      return
                    }
                    onDataChange(e)
                  }}
                />
                <label
                  htmlFor="12pm"
                  className={` ${styles.light__grey__color}`}
                >
                  <span>12 pm onwards </span>
                </label>
              </>
            }

            {
              <>
                <input
                  type="radio"
                  name="bookingTime"
                  id="1pm"
                  checked={data.bookingTime === `13:00`}
                  // disabled={
                  //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  //   dayjs('11:00am', 'h:mma').isBefore(dayjs())
                  // }
                  value={`13:00`}
                  // onChange={onDataChange}
                  onChange={(e) => {
                    if (
                      data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                      dayjs('11:00am', 'h:mma').isBefore(dayjs())
                    ) {
                      handleBookingStatus('1 pm')
                      return
                    }
                    onDataChange(e)
                  }}
                />
                <label
                  htmlFor="1pm"
                  className={` ${styles.light__grey__color}`}
                >
                  <span>1 pm onwards </span>
                </label>
              </>
            }

            {(data.bookingDate === dayjs().format('YYYY-MM-DD') &&
              dayjs('2:00pm', 'h:mma').isBefore(dayjs())) || (
              <>
                <input
                  type="radio"
                  name="bookingTime"
                  id="2pm"
                  checked={data.bookingTime === `14:00`}
                  // disabled={
                  //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  //   dayjs('12:00pm', 'h:mma').isBefore(dayjs())
                  // }
                  value={`14:00`}
                  // onChange={onDataChange}
                  onChange={(e) => {
                    if (
                      data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                      dayjs('12:00pm', 'h:mma').isBefore(dayjs())
                    ) {
                      handleBookingStatus('2 pm')
                      return
                    }
                    onDataChange(e)
                  }}
                />
                <label
                  htmlFor="2pm"
                  className={` ${styles.light__grey__color}`}
                >
                  <span>2 pm onwards </span>
                </label>
              </>
            )}

            {
              <>
                <input
                  type="radio"
                  name="bookingTime"
                  id="3pm"
                  checked={data.bookingTime === `15:00`}
                  // disabled={
                  //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  //   dayjs('1:00pm', 'h:mma').isBefore(dayjs())
                  // }
                  value={`15:00`}
                  // onChange={onDataChange}
                  onChange={(e) => {
                    if (
                      data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                      dayjs('1:00pm', 'h:mma').isBefore(dayjs())
                    ) {
                      handleBookingStatus('3 pm')
                      return
                    }
                    onDataChange(e)
                  }}
                />
                <label
                  htmlFor="3pm"
                  className={` ${styles.light__grey__color}`}
                >
                  <span>3 pm onwards </span>
                </label>
              </>
            }

            {
              <>
                <input
                  type="radio"
                  name="bookingTime"
                  id="4pm"
                  checked={data.bookingTime === `16:00`}
                  // disabled={
                  //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  //   dayjs('2:00pm', 'h:mma').isBefore(dayjs())
                  // }
                  value={`16:00`}
                  // onChange={onDataChange}
                  onChange={(e) => {
                    if (
                      data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                      dayjs('2:00pm', 'h:mma').isBefore(dayjs())
                    ) {
                      handleBookingStatus('4 pm')
                      return
                    }
                    onDataChange(e)
                  }}
                />
                <label
                  htmlFor="4pm"
                  className={` ${styles.light__grey__color}`}
                >
                  <span>4 pm onwards </span>
                </label>
              </>
            }
          </div>
          <br></br>
        </>
      )}
      {/* if we choose BREAKFAST & DINNER */}
      {data.meal.length !== 0 &&
        data.meal !== 'dinner' &&
        data.meal !== 'lunch' &&
        data.meal !== 'breakfast' &&
        data.meal !== 'breakfast_lunch' &&
        data.meal !== 'lunch_dinner' && (
          <>
            {/* <Row>
              <Col>
                <h5 className={styles.dark__grey__color}>Service Duration </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-danger mb-2">
                  8:30 am to 11 pm (total 14.5 hours of service)
                </p>
                <p className="text-muted small mb-2">
                  You can extend if needed. Charges apply.
                </p>
              </Col>
            </Row>
            <br /> */}
            <div className="radio__button__container">
              <h5 className={styles.dark__grey__color} id="goToTime">
                Start having Breakfast from <br />
              </h5>
              {
                <>
                  <input
                    type="radio"
                    name="bookingTime"
                    id="8am"
                    checked={data.bookingTime === `8:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('6:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`8:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('6:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('8 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="8am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>8 am onwards </span>
                  </label>
                </>
              }
              {
                <>
                  <input
                    type="radio"
                    name="bookingTime"
                    id="9am"
                    checked={data.bookingTime === `9:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('7:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`9:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('7:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('9 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="9am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>9 am onwards </span>
                  </label>
                </>
              }

              {
                <>
                  <input
                    type="radio"
                    name="bookingTime"
                    id="10am"
                    checked={data.bookingTime === `10:00`}
                    // disabled={
                    //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                    //   dayjs('8:00am', 'h:mma').isBefore(dayjs())
                    // }
                    value={`10:00`}
                    // onChange={onDataChange}
                    onChange={(e) => {
                      if (
                        data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                        dayjs('8:00am', 'h:mma').isBefore(dayjs())
                      ) {
                        handleBookingStatus('10 am')
                        return
                      }
                      onDataChange(e)
                    }}
                  />
                  <label
                    htmlFor="10am"
                    className={`${styles.light__grey__color}`}
                  >
                    <span>10 am onwards </span>
                  </label>
                </>
              }
            </div>
            <br></br>
          </>
        )}
      {/* if we choose BREAKFAST & LUNCH */}
      {data.meal.length !== 0 && data.meal === 'breakfast_lunch' && (
        <>
          {/* <Row>
            <Col>
              <h5 className={styles.dark__grey__color}>Service Duration </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-danger mb-2">
                8:30 am to 5 pm (total 8.5 hours of service)
              </p>
              <p className="text-muted small mb-2">
                You can extend if needed. Charges apply.
              </p>
            </Col>
          </Row>
          <br /> */}
          <div className="radio__button__container">
            <h5 className={styles.dark__grey__color} id="goToTime">
              Start having Breakfast from <br />
            </h5>
            <input
              type="radio"
              name="bookingTime"
              id="8am"
              checked={data.bookingTime === `8:00`}
              // disabled={
              //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
              //   dayjs('6:00am', 'h:mma').isBefore(dayjs())
              // }
              value={`8:00`}
              // onChange={onDataChange}
              onChange={(e) => {
                if (
                  data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  dayjs('6:00am', 'h:mma').isBefore(dayjs())
                ) {
                  handleBookingStatus('8 am')
                  return
                }
                onDataChange(e)
              }}
            />
            <label htmlFor="8am" className={`${styles.light__grey__color}`}>
              <span>8 am onwards </span>
            </label>
            <input
              type="radio"
              name="bookingTime"
              id="9am"
              checked={data.bookingTime === `9:00`}
              // disabled={
              //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
              //   dayjs('7:00am', 'h:mma').isBefore(dayjs())
              // }
              value={`9:00`}
              // onChange={onDataChange}
              onChange={(e) => {
                if (
                  data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  dayjs('7:00am', 'h:mma').isBefore(dayjs())
                ) {
                  handleBookingStatus('9 am')
                  return
                }
                onDataChange(e)
              }}
            />
            <label htmlFor="9am" className={`${styles.light__grey__color}`}>
              <span>9 am onwards </span>
            </label>

            <input
              type="radio"
              name="bookingTime"
              id="10am"
              checked={data.bookingTime === `10:00`}
              // disabled={
              //   data.bookingDate === dayjs().format('YYYY-MM-DD') &&
              //   dayjs('8:00am', 'h:mma').isBefore(dayjs())
              // }
              value={`10:00`}
              // onChange={onDataChange}
              onChange={(e) => {
                if (
                  data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                  dayjs('8:00am', 'h:mma').isBefore(dayjs())
                ) {
                  handleBookingStatus('10 am')
                  return
                }
                onDataChange(e)
              }}
            />
            <label htmlFor="10am" className={`${styles.light__grey__color}`}>
              <span>10 am onwards </span>
            </label>
          </div>
          <br></br>
        </>
      )}
      {data.occasionId ? (
        <>
          <h5 className={styles.dark__grey__color} id="goToCuisine">
            Select Cuisine(s)
            {/* <br />
            <span className={`text-danger ${styles.font__size__14}`}>
              you can select max 3
            </span> */}
          </h5>
          <div className="radio__button__container">
            {combosNotPublished.map(({ id, name }) => {
              if (id === specialComboId) {
                return (
                  <span key={id}>
                    <input
                      type="checkbox"
                      name="comboIds"
                      id={`combo_${id}`}
                      value={id}
                      onChange={onDataChange}
                      checked={data.comboIds.includes(id)}
                      disabled={
                        disableSpecial ||
                        (data.bookingLevel === 'Cook' && id !== 8 && id !== 9)
                      }
                    />
                    <label
                      htmlFor={`combo_${id}`}
                      className={`${styles.light__grey__color}`}
                    >
                      <span
                        style={
                          disableSpecial ||
                          (data.bookingLevel === 'Cook' && id !== 8 && id !== 9)
                            ? {
                                cursor: 'not-allowed',
                                backgroundColor: 'white',
                                opacity: '0.5',
                              }
                            : {}
                        }
                      >
                        {name}
                      </span>
                    </label>
                  </span>
                )
              }
            })}
            {combos.map(({ id, name }) => {
              return (
                <span key={id}>
                  <input
                    type="checkbox"
                    name="comboIds"
                    id={`combo_${id}`}
                    value={id}
                    onChange={onDataChange}
                    checked={data.comboIds.includes(id)}
                    disabled={
                      (id === 9 && disableGhar) ||
                      (id !== 9 && disableOtherCombos) ||
                      (data.bookingLevel === 'Cook' && id !== 8 && id !== 9)
                    }
                  />
                  <label
                    htmlFor={`combo_${id}`}
                    className={`${styles.light__grey__color}`}
                  >
                    <span
                      style={
                        (data.bookingLevel === 'Cook' &&
                          id !== 9 &&
                          id !== 8) ||
                        (id === 9 && disableGhar) ||
                        (id !== 9 && disableOtherCombos)
                          ? {
                              cursor: 'not-allowed',
                              backgroundColor: 'white',
                              opacity: '0.5',
                            }
                          : {}
                      }
                    >
                      {name}
                    </span>
                  </label>
                </span>
              )
            })}
          </div>
        </>
      ) : null}
      <br />
      {data.occasionId ? (
        <>
          <h5 className={styles.dark__grey__color} id="goToGasBurner">
            No. of Gas Burners
            <span className={`${styles.font__size__14} text-muted ml-1`}>
              in your kitchen
            </span>
          </h5>
          <div className="radio__button__container">
            {REDUX__burners.length === 0 ? (
              <ShimmerLoader />
            ) : (
              REDUX__burners.map((burner) => {
                return (
                  <span key={burner.id}>
                    <input
                      type="radio"
                      name="numberOfBurners"
                      id={checkVal(burner.type)}
                      value={checkVal(burner.type)}
                      onChange={onDataChange}
                      checked={data.numberOfBurners === checkVal(burner.type)}
                    />
                    <label
                      htmlFor={checkVal(burner.type)}
                      className={` ${styles.light__grey__color}`}
                    >
                      <span>
                        {checkVal(burner.type) === '1'
                          ? '1 burner'
                          : `${checkVal(burner.type)} burners`}
                      </span>
                    </label>
                  </span>
                )
              })
            )}
          </div>
        </>
      ) : null}

      <PeopleConfirmationModal
        show={showPeopleConfirmation}
        setShow={setShowPeopleConfirmation}
      />
    </div>
  )
}

export default BookingDetails
