// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col, Button } from 'react-bootstrap'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'

// DAYJS
import dayjs from 'dayjs'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

// COMPONENTS
import BookingPaymentBody from '@/components/BookingPaymentBody'

// USING DAYJS PLUGIN
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

// REACT ICONS
import { FaCheck } from 'react-icons/fa'

import axios from 'axios'
import { useState } from 'react'

export interface Promo {
  code: string
  description: string
  discount_amount: number
  expires_at: string | null
  is_fixed: boolean
  max_discount: number
  max_uses_user: number
  name: string
}

const initialPromoState = {
  code: '',
  description: '',
  discount_amount: 0,
  expires_at: null,
  is_fixed: false,
  max_discount: 0,
  max_uses_user: 0,
  name: '',
}

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const GreyTick = () => (
  <img src={tickMark} alt="tick" className={styles.grey__tick} />
)

const BookingSummary = (props): JSX.Element => {
  const dispatch = useDispatch()
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookDailyCookModal
  )

  //local states
  const [promo, setPromo] = useState<Promo | { code: string }>(
    initialPromoState
  )
  const [loading, setLoading] = useState(false)
  const [couponError, setCouponError] = useState('')
  const [showPromoModal, setShowPromoModal] = useState(false)
  const [upgradeLoading, setUpgradeLoading] = useState(false)
  const [showUpgradeBookingModal, setShowUpgradeBookingModal] = useState(false)

  // const REDUX__bookingLevel = useSelector(
  //   (state: RootState) => state.bookingModal.bookingLevel
  // )

  // const { bookingLevel } = REDUX__bookingLevel

  // console.log('dff', bookingLevel)

  const {
    bookingDetails: {
      numberOfPeople = 0,
      bookingDate = '',
      bookingId,
      bookingLevel,
      booking_type
    },
    designMenu: { dishDetails: dishes },
    summary: {
      available_coupons,
      booking_section: {
        number_of_cooks = 0,
        number_of_helpers = 0,
        expected_arrival_time = '',
        number_of_people,
      },
      invoice,
      payment_gateways,
    },
    payment: { paymentGateway },
    userAddressData: { addresses, selectedAddressId },
    upgradeBooking,
    citiesList,
  } = REDUX__bookingModalData

  const bookingLocation = addresses?.filter(
    (address) => +address?.address_id === +selectedAddressId
  )[0]
  const goToCityModalStep = () => {
    props.previousStep()
  }

  // show date as 21 Mar 2021
  const formattedBookingDate = dayjs(bookingDate).format('D MMMM YYYY, dddd')
  const addToCart = async () => {
    // so that leave site dialog box do not open
    dispatch(bookDailyCookModalSlice.actions.setPaymentOpen(true))
    const res = await axios.post(`/booking/${bookingId}/add-to-cart`)
    // setLoading(false)
    window.location.href = `/my-bookings?bookingId=${bookingId}&event=cart`
  }
  const updateBooking = async (data = {}) => {
    try {
      // console.log('jhayahab', couponCode, upgradeBooking)

      const res = await axios.post('/payment/pre-payment', {
        booking_id: bookingId,
        ...data,
        coupon_code:
          data.coupon_code !== undefined
            ? data.coupon_code
            : promo.code || undefined,
        upgrade_booking:
          data.upgrade_booking !== undefined
            ? data.upgrade_booking
            : upgradeBooking,
      })
      if (res.data.data.invoice.invoice_footer.discount.valid === false) {
        setCouponError(res.data.data.invoice.invoice_footer.discount.message)
        throw new Error(res.data.data.invoice.invoice_footer.discount.message)
      }
      // dispatch to update the invoice
      dispatch(
        bookDailyCookModalSlice.actions.setBookingInvoice(res.data.data.invoice)
      )
      setLoading(false)
      setUpgradeLoading(false)
      setShowPromoModal(false)
      if (data.upgrade_booking !== undefined) {
        dispatch(
          bookDailyCookModalSlice.actions.setUpgradeBooking(
            data.upgrade_booking
          )
        )
        setShowUpgradeBookingModal(false)
      }
    } catch (err) {
      setLoading(false)
      setUpgradeLoading(false)
      console.log('Something went wrong', err)
    }
  }
  const removePromo = async () => {
    setLoading(true)
    setCouponError('')
    updateBooking({ coupon_code: null })
    setPromo(initialPromoState)
  }
  const addPromo = (promo: Promo) => {
    setCouponError('')
    setPromo(promo)
  }
  const couponApplyHandler = async (couponCode: string) => {
    // validate the Promocode
    setLoading(true)
    // availableCoupons.forEach(async (coupon, index) => {
    // if (coupon.code === (couponCode || couponInput)) {
    try {
      updateBooking({ coupon_code: couponCode })
      addPromo({ code: couponCode })
    } catch (err) {
      // if (err.message === 'Invalid Coupon') {
      //   setCouponError('Invalid coupon')
      // }
      // alert('Something went wrong')
      // console.log(err.response)
    }
    // } else if (
    //   coupon.code !== (couponCode || couponInput) &&
    //   availableCoupons.length === index + 1
    // ) {
    // if no code is found
    // setLoading(false)
    // setCouponError('Invalid coupon')
    // }
    // })
  }
  return (
    <div>
      <p className="mb-1">
        <FaCheck /> {number_of_cooks} Cook{' '}
        {number_of_helpers > 0 && `and ${number_of_helpers} Assistant Cook `}
        will come 
      </p>
      <p className="mb-1">
        <FaCheck /> From {formattedBookingDate} in{' '}
        <Button
          variant="link"
          className="px-0 pt-0 pb-1"
          onClick={goToCityModalStep}
        >
          {bookingLocation?.city} (Change City)
        </Button>
      </p>
      <p className="mb-1">
        <FaCheck />{' '}
        {dayjs(expected_arrival_time, 'HH:mm:ss').format('h:mm a') +
          ' to ' +
          dayjs(expected_arrival_time, 'HH:mm:ss')
            .add(invoice?.invoice_middle?.service_hours, 'hours')
            .format('h:mm a')}
      </p>
      <p>
        <FaCheck /> {bookingLevel === "DailyCook" ? 'Cook' : bookingLevel} does not carry anything with himself
      </p>
      <p>
        <FaCheck /> Service will be upto 1.5 hours per visit
      </p>
      <hr />
      <BookingPaymentBody
        REDUX__bookingModalData={REDUX__bookingModalData}
        invoice={invoice}
        payment_gateways={payment_gateways}
        availableCoupons={available_coupons}
        paymentGateway={paymentGateway}
        number_of_dishes={dishes?.length}
        number_of_people={number_of_people}
        bookingLevel={bookingLevel}
        bookingId={bookingId}
        setPaymentGateway={(value: GatewayTypes) =>
          dispatch(bookDailyCookModalSlice.actions.setPaymentGateway(value))
        }
        updateBooking={updateBooking}
        proceedingToNextStep={props.proceedingToNextStep}
        promoLoading={loading}
        setLoading={setLoading}
        couponError={couponError}
        setCouponError={setCouponError}
        removePromo={removePromo}
        addPromo={addPromo}
        promo={promo}
        showPromoModal={showPromoModal}
        setShowPromoModal={setShowPromoModal}
        couponApplyHandler={couponApplyHandler}
        upgradeLoading={upgradeLoading}
        setUpgradeLoading={setUpgradeLoading}
        showUpgradeBookingModal={showUpgradeBookingModal}
        setShowUpgradeBookingModal={setShowUpgradeBookingModal}
        SW={props.SW}
        citiesList={citiesList}
        addToCart={addToCart}
        paymentModalBtnType="retry"
        ispre={true}
      />
    </div>
  )
}

export default BookingSummary
