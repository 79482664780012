import { SetStateAction, Dispatch, useState } from 'react'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import {
  // Container,
  Row,
  Col,
  Card,
  Tooltip,
  Modal,
  Button,
  OverlayTrigger,
} from 'react-bootstrap'

// REACT ICONS
import {
  FaQuestionCircle,
  FaArrowLeft,
  FaCheck,
  FaPencilAlt,
} from 'react-icons/fa'
import CancellationPolicyModal from '../MyBookings/Modals/CancellationPolicyModal'
// DAYJS
// import dayjs from 'dayjs'

// HELPERS
// import { sumTwoNumbers } from '../BookChefModal/helpers'

// COMPONENTS
import FakeCheckbox from '@/components/FakeCheckbox'

// TS INTERFACES
import { RootState } from '@/lib/rootState'
import { GatewayTypes, PaymentModalCTAType } from '@/types/commonTypes'
import ApplyPromoCode from './ApplyPromoCode'
import { useSelector } from 'react-redux'
import BookingStatus from '@/types/myBookingsTypes'
import { checkingAvailabilityCondition } from '../BookingModals/BookChefModal/helpers/CTAHelper'
import UpgradeBookingModal from '../MyBookings/Modals/UpgradeBookingModal'
import { BsCart4 } from 'react-icons/bs'

// Optional parameters couponCode,paymentModalBtnType,expected_arrival_time,setCouponCode, status were added to reuse the same component in my bookings. Optimize this later when optimizing booking flow
interface Props {
  invoice: RootState['bookingModal']['summary']['invoice']
  payment_gateways: RootState['bookingModal']['summary']['payment_gateways']
  availableCoupons: RootState['bookingModal']['summary']['available_coupons']
  number_of_people: number
  number_of_dishes: number
  setPaymentGateway: ((value: GatewayTypes) => void) &
    Dispatch<SetStateAction<GatewayTypes>>
  couponCode?: string | null
  paymentModalBtnType?: PaymentModalCTAType
  expected_arrival_time?: string | undefined
  status?: string

  setCouponCode?: Dispatch<SetStateAction<string | null>>
  paymentGateway: GatewayTypes
  // bookingId: number
  proceedingToNextStep: boolean
  promoLoading: boolean
  upgradeLoading: boolean
  setPromoLoading: Dispatch<SetStateAction<boolean>>
  setUpgradeLoading: Dispatch<SetStateAction<boolean>>
  removePromo: () => Promise<void>
  updateSummary: (data?: {}) => Promise<void>
}

const wrapS3URLForPaymentIcons = (fileName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/payment-icons/${fileName}`
}

const BookingPaymentBody = (props: Props): JSX.Element => {
  // const REDUX__bookingModalData = useSelector(
  //   (state: RootState) => state.bookingModal
  // )
  const [loadingAddToCart, setLoadingAddToCart] = useState(false)
  const [isOpenedCancellationPolicy, setIsOpenedCancellationPolicy] =
    useState(false)
  const {
    REDUX__bookingModalData,
    invoice,
    payment_gateways,
    setPaymentGateway,
    paymentGateway,
    availableCoupons,
    number_of_dishes,
    number_of_people,
    proceedingToNextStep,
    couponCode,
    updateSummary,
    removePromo,
    promo,
    promoLoading,
    couponError,
    setCouponError,
    showPromoModal,
    setShowPromoModal,
    couponApplyHandler,
    addAssistantLoading,
    upgradeLoading,
    setPromoLoading,
    setUpgradeLoading,
    showUpgradeBookingModal,
    setShowUpgradeBookingModal,
    upgradeBooking,
    paymentModalBtnType,
    SW,
    citiesList,
    addToCart,
    bookingLevel,
  } = props
  const {
    summary: {
      booking_section: { expected_arrival_time = '', number_of_helpers },
    },
    userAddressData: { selectedAddressId, addresses },
    bookingDetails: { bookingDate, meal, numberOfPeople },
  } = REDUX__bookingModalData
  const city = addresses.filter(
    (address) => +address.address_id === +selectedAddressId
  )[0]?.city
  const stateName = addresses.filter(
    (address) => +address.address_id === +selectedAddressId
  )[0]?.state

  // const couponApplyHandler = async (couponCode: string) => {
  //   // validate the Promocode
  //   setPromoLoading(true)
  //   // availableCoupons.forEach(async (coupon, index) => {
  //   // if (coupon.code === (couponCode || couponInput)) {
  //   if (setCouponCode) {
  //     setCouponCode(couponCode)
  //     setPromoLoading(false)
  //     setShowPromoModal(false)
  //   } else {
  //     try {
  //       updateSummary({ coupon_code: couponCode })
  //       addPromo({ code: couponCode })
  //       setShowPromoModal(false)
  //     } catch (err) {
  //       // if (err.message === 'Invalid Coupon') {
  //       //   setCouponError('Invalid coupon')
  //       // }
  //       // alert('Something went wrong')
  //       // console.log(err.response)
  //     }
  //     // } else if (
  //     //   coupon.code !== (couponCode || couponInput) &&
  //     //   availableCoupons.length === index + 1
  //     // ) {
  //     // if no code is found
  //     // setPromoLoading(false)
  //     // setCouponError('Invalid coupon')
  //     // }
  //     // })
  //   }
  // }

  return (
    <div className="mt-2 paymentDataContainer">
      <Row>
        {/* <Col lg={1}></Col> */}
        <Col lg={5}>
          <div style={{ width: '20rem' }}>
            <h6 className="font-weight-bold mb-0">Payment Summary</h6>
            <div>
              {bookingLevel !== 'Waiter' && bookingLevel !== 'Cleaner' && (
                <small className="text-muted">
                  Charges depend on number of people and{' '}
                  {bookingLevel == 'Bartender' ? 'variety of drinks' : bookingLevel === 'DailyCook' ? 'visits' : 'dishes'}:
                </small>
              )}
            </div>
          </div>
          <Card
            style={{
              width: '20rem',
              margin: '5px 0px 20px 0px',
            }}
            className="cardWidth"
          >
            <div className="p-2">
              <div className={styles.font__size__16}>
                <Row>
                  <Col
                    md={8}
                    xs={8}
                    className={`${styles.light__grey__color} pr-0`}
                  >
                    {bookingLevel == 'Waiter' || bookingLevel == 'Cleaner'
                      ? bookingLevel
                      : `${number_of_people} People`}

                    {bookingLevel !== 'Waiter' &&
                      bookingLevel !== 'Cleaner' &&
                      SW && (
                        <Button
                          size="sm"
                          variant="link"
                          onClick={() => {
                            SW.goToNamedStep('details')
                          }}
                          className={`${styles.font__size__11}`}
                        >
                          <FaPencilAlt /> Edit
                        </Button>
                      )}
                  </Col>

                  <Col
                    md={4}
                    xs={4}
                    className={`${styles.light__grey__color} pl-0`}
                  >
                    &nbsp;&nbsp;₹{invoice?.invoice_header?.service_charges}
                  </Col>
                </Row>
                {invoice?.invoice_middle?.extra_dishes_charges !== undefined &&
                invoice?.invoice_middle?.extra_dishes_charges !== 0 &&
                invoice?.invoice_middle?.extra_dishes_charges !== null ? (
                  <Row>
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      {number_of_dishes}{' '}
                      {bookingLevel === 'Bartender' ? 'Drinks' : 'Dishes'}
                      {SW && (
                        <Button
                          size="sm"
                          variant="link"
                          className={`${styles.font__size__11}`}
                          onClick={() => {
                            if (bookingLevel === 'Bartender') {
                              SW.goToNamedStep('menu')
                            } else {
                              if (meal === 'dinner') {
                                SW.goToNamedStep('dinner')
                              } else if (
                                meal === 'lunch' ||
                                meal === 'lunch_dinner'
                              ) {
                                SW.goToNamedStep('lunch')
                              } else {
                                SW.goToNamedStep('breakfast')
                              }
                            }
                          }}
                        >
                          <FaPencilAlt /> Edit
                        </Button>
                      )}
                      {/* <OverlayTrigger
                          placement={'top'}
                          overlay={
                            <Tooltip>
                              Dish charges don’t apply for up to 4 dishes. They
                              apply 5th dish onwards.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <FaQuestionCircle />
                          </span>
                        </OverlayTrigger> */}
                    </Col>

                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      &nbsp;&nbsp;₹
                      {invoice?.invoice_middle?.extra_dishes_charges}
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                {invoice?.invoice_middle?.overtime_charge &&
                invoice?.invoice_middle?.overtime_charge !== 0 ? (
                  <Row>
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      Overtime Charges
                      {/* <OverlayTrigger
                          placement={'top'}
                          overlay={
                            <Tooltip>
                              Dish charges don’t apply for up to 4 dishes. They
                              apply 5th dish onwards.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <FaQuestionCircle />
                          </span>
                        </OverlayTrigger> */}
                    </Col>

                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      &nbsp;&nbsp;₹{invoice?.invoice_middle?.overtime_charge}
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                {invoice?.invoice_middle?.package_upgrade_charge &&
                invoice?.invoice_middle?.package_upgrade_charge !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      <span>
                        {' '}
                        Pro Upgrade{' '}
                        {paymentModalBtnType === 'payNow' ? null : (
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => {
                              updateSummary({ upgrade_booking: false })
                            }}
                            disabled={upgradeLoading}
                            className={`${styles.font__size__11} p-0 text-danger`}
                          >
                            Remov{upgradeLoading ? 'ing...' : 'e'}
                          </Button>
                        )}
                      </span>
                    </Col>

                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      <span>
                        &nbsp;&nbsp;₹
                        {invoice?.invoice_middle?.package_upgrade_charge}
                      </span>
                    </Col>
                  </Row>
                ) : null}
                {invoice?.invoice_middle?.change_professional_charges &&
                invoice?.invoice_middle?.change_professional_charges !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      <span> Change / Choose Professional </span>
                    </Col>
                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      <span>
                        &nbsp;&nbsp;₹
                        {invoice?.invoice_middle?.change_professional_charges}
                      </span>
                    </Col>
                  </Row>
                ) : null}
                {invoice?.invoice_middle?.helper_charge &&
                invoice?.invoice_middle?.helper_charge !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      <span>Assistant Cook </span>
                      {paymentModalBtnType === 'payNow' ? null : (
                        <Button
                          variant="link"
                          size="sm"
                          className={`${styles.font__size__11} p-0 text-danger`}
                          onClick={() => {
                            updateSummary({ add_assistant: false })
                          }}
                          disabled={addAssistantLoading}
                        >
                          Remov{addAssistantLoading ? 'ing...' : 'e'}
                        </Button>
                      )}
                    </Col>

                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      <span>
                        &nbsp;&nbsp;₹{invoice?.invoice_middle?.helper_charge}
                      </span>
                    </Col>
                  </Row>
                ) : null}

                {invoice?.invoice_middle?.on_hold_charge &&
                invoice?.invoice_middle?.on_hold_charge !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      <span> On Hold Charges</span>
                    </Col>

                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      <span>
                        &nbsp;&nbsp;₹{invoice?.invoice_middle?.on_hold_charge}
                      </span>
                    </Col>
                  </Row>
                ) : null}
                {invoice?.invoice_middle?.transportation_charge &&
                invoice?.invoice_middle?.transportation_charge !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      <span> Transportation Charges</span>
                    </Col>

                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      <span>
                        &nbsp;&nbsp;₹
                        {invoice?.invoice_middle?.transportation_charge}
                      </span>
                    </Col>
                  </Row>
                ) : null}

                {/* For Promo Code */}
                <Row>
                  {((promo.code !== '' || couponCode) &&
                    invoice?.invoice_footer?.discount?.amount !== undefined) ||
                  promoLoading ? (
                    <>
                      {/* Promo Applied */}
                      <Col md={8} xs={8} className={`text-primary pr-0`}>
                        <span> Discount</span>
                        {paymentModalBtnType === 'payNow' ? null : (
                          <Button
                            variant="link"
                            size="sm"
                            onClick={removePromo}
                            disabled={promoLoading}
                            className={`${styles.font__size__11} p-0 ml-2 text-danger`}
                          >
                            Remov{promoLoading ? 'ing...' : 'e'}
                          </Button>
                        )}
                      </Col>

                      <Col md={4} xs={4} className={`text-primary pl-0`}>
                        − ₹{invoice?.invoice_footer?.discount?.amount}
                      </Col>
                    </>
                  ) : null}
                </Row>

                {invoice?.invoice_middle?.discount_amount &&
                invoice?.invoice_middle?.discount_amount !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col md={8} xs={8} className={`text-primary pr-0`}>
                      <span> Coupon Discount</span>
                    </Col>

                    <Col md={4} xs={4} className={`text-primary pl-0`}>
                      <span>− ₹{invoice?.invoice_middle?.discount_amount}</span>
                    </Col>
                  </Row>
                ) : null}

                {invoice?.invoice_middle?.additional_discount_amount &&
                invoice?.invoice_middle?.additional_discount_amount !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      <span> Additional Discount </span>
                    </Col>
                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      <span>
                        − ₹
                        <b>
                          {invoice?.invoice_middle?.additional_discount_amount}
                        </b>
                      </span>
                    </Col>
                  </Row>
                ) : null}

                <Row>
                  <Col md={8} xs={8} className={`pr-0`}>
                    <small className="text-muted">GST 18%</small>
                  </Col>

                  <Col md={4} xs={4} className={`pl-0`}>
                    <small className="text-muted">
                      &nbsp;&nbsp;&nbsp;₹{invoice?.invoice_middle?.gst}
                    </small>
                  </Col>
                </Row>

                {/* <Row>
                    <Col
                      md={8}
                      xs={7}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      Platform Charges
                    </Col>

                    <Col
                      md={4}
                      xs={5}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      &nbsp;&nbsp;₹{invoice?.invoice_middle?.platform_charges}
                    </Col>
                  </Row> */}
                <Row
                  style={{
                    backgroundColor: '#fcc107',
                    margin: '2px -8px 2px -9px',
                  }}
                >
                  {/* Total amount */}
                  <Col md={8} xs={8} className="pl-2 font-weight-bold">
                    Total Amount
                  </Col>

                  <Col md={4} xs={4} className="pl-0 font-weight-bold">
                    &nbsp;&nbsp;&nbsp;₹
                    <b className="font-weight-bold">
                      {invoice?.invoice_footer?.amount_payable}
                    </b>
                  </Col>
                </Row>
                {/* <br /> */}
                {/* Check for city in pre booking and for status in post booking
                to show/hide paynow */}

                {invoice?.invoice_footer?.payable_now &&
                paymentModalBtnType !== 'payNow' &&
                bookingLevel !== 'DailyCook' &&
                bookingLevel !== 'Waiter' &&
                bookingLevel !== 'Cleaner' &&
                ((city
                  ? !checkingAvailabilityCondition(
                      city,
                      stateName,
                      bookingDate,
                      meal,
                      citiesList
                    )
                  : false) ||
                  (props.status
                    ? props.status !== BookingStatus.CHECKING_AVAILABILITY
                    : false)) ? (
                  <>
                    <hr className="m-2" />
                    <Row>
                      <Col
                        md={8}
                        xs={8}
                        className={`${styles.light__grey__color} pr-0`}
                      >
                        Pay Now (40% Advance)
                      </Col>

                      <Col
                        md={4}
                        xs={4}
                        className={`${styles.light__grey__color} pl-0`}
                      >
                        &nbsp;&nbsp;&nbsp;₹
                        <b className={styles.light__grey__color}>
                          {invoice?.invoice_footer?.payable_now}
                        </b>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                {invoice?.invoice_footer?.payable_later &&
                !checkingAvailabilityCondition(
                  city,
                  stateName,
                  bookingDate,
                  meal,
                  citiesList
                ) ? (
                  <Row>
                    <Col
                      md={8}
                      xs={8}
                      className={`${styles.light__grey__color} pr-0`}
                    >
                      Balance Payment
                      <OverlayTrigger
                        placement={'top'}
                        overlay={
                          <Tooltip>
                            Pay balance 60% at the end of service
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <FaQuestionCircle />
                        </span>
                      </OverlayTrigger>
                    </Col>

                    <Col
                      md={4}
                      xs={4}
                      className={`${styles.light__grey__color} pl-0`}
                    >
                      &nbsp;&nbsp;&nbsp;₹
                      <b className={styles.light__grey__color}>
                        {invoice?.invoice_footer?.payable_later}
                      </b>
                    </Col>
                    <hr />
                  </Row>
                ) : (
                  <> </>
                )}
                {/* ----------------- BALANCE USE CASE -- in MyBookings  -----------------*/}
                {!invoice?.invoice_footer?.balance_amount ||
                invoice?.invoice_footer?.balance_amount ===
                  invoice?.invoice_footer?.amount_payable ? (
                  ''
                ) : (
                  <>
                    <Row>
                      <Col
                        md={8}
                        xs={8}
                        className={`${styles.light__grey__color} pr-0`}
                      >
                        Already Paid
                      </Col>

                      <Col
                        md={4}
                        xs={4}
                        className={`${styles.light__grey__color} pl-0`}
                      >
                        &nbsp;&nbsp;₹
                        <b className={styles.light__grey__color}>
                          {(
                            invoice?.invoice_footer?.amount_payable -
                            invoice?.invoice_footer?.balance_amount
                          ).toFixed(0)}
                        </b>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        md={8}
                        xs={8}
                        className={`${styles.light__grey__color} pr-0`}
                      >
                        Balance Amount
                      </Col>

                      <Col
                        md={4}
                        xs={4}
                        className={`${styles.light__grey__color} pl-0`}
                      >
                        &nbsp;&nbsp;₹
                        <b className={styles.light__grey__color}>
                          {invoice?.invoice_footer?.balance_amount}
                        </b>
                      </Col>
                    </Row>
                  </>
                )}
                {invoice?.invoice_middle?.refunded_amount &&
                invoice?.invoice_middle?.refunded_amount !== 0 ? (
                  <Row>
                    {/* Promo Applied */}
                    <Col md={8} xs={8} className={`text-danger pr-0`}>
                      <span> Refunded </span>
                    </Col>
                    <Col md={4} xs={4} className={`text-danger pl-0`}>
                      <span>
                        − ₹<b>{invoice?.invoice_middle?.refunded_amount}</b>
                      </span>
                    </Col>
                  </Row>
                ) : null}
                {paymentModalBtnType === 'payNow' ||
                bookingLevel === 'Waiter' ||
                bookingLevel === 'Bartender' ||
                bookingLevel === 'DailyCook' ||
                bookingLevel === 'Cleaner' ? null : (
                  <hr className="m-2" />
                )}
                {paymentModalBtnType === 'payNow' ||
                bookingLevel === 'Waiter' ||
                bookingLevel === 'DailyCook' ||
                bookingLevel === 'Bartender' ||
                bookingLevel === 'Cleaner' ? null : upgradeBooking ||
                  invoice?.invoice_middle?.package_upgrade_charge !== 0 ? (
                  <div className={`${styles.font__size__12} text-muted pl-0`}>
                    <span>Booking upgraded </span>
                    {/* <Button
                      variant="link"
                      size="sm"
                      onClick={() => {
                        updateSummary({ upgrade_booking: false })
                      }}
                      disabled={upgradeLoading}
                      className="p-0"
                    >
                      Remov{upgradeLoading ? 'ing...' : 'e'}
                    </Button> */}
                  </div>
                ) : (
                  <Button
                    variant="link"
                    size="sm"
                    className="p-0"
                    onClick={() => setShowUpgradeBookingModal(true)}
                  >
                    Upgrade Booking
                  </Button>
                )}
                <UpgradeBookingModal
                  show={showUpgradeBookingModal}
                  setShow={setShowUpgradeBookingModal}
                  upgradeLoading={upgradeLoading}
                  upgradeBooking={(upgrade_booking) => {
                    updateSummary({ upgrade_booking })
                  }}
                />
                <hr className="m-2" />
                {(promo.code !== '' || couponCode) &&
                invoice?.invoice_footer?.discount?.amount !== undefined ? (
                  <div className={`${styles.font__size__12} text-muted pl-0`}>
                    <span>{promo.code || couponCode} Coupon applied </span>
                    {/* <Button
                      variant="link"
                      size="sm"
                      onClick={removePromo}
                      disabled={promoLoading}
                      className="p-0"
                    >
                      Remov{promoLoading ? 'ing...' : 'e'}
                    </Button> */}
                  </div>
                ) : null}
                {/* ----------------- BALANCE USE CASE -- in MyBookings  */}
                {/* ----------------- Promo Code Modal ----------------- */}
                {(promo.code !== '' || couponCode) &&
                invoice?.invoice_footer?.discount?.amount !==
                  undefined ? null : paymentModalBtnType === 'payNow' ||
                  promoLoading ? null : (
                  <Button
                    // className={styles.ApplyPromoCode}
                    variant="link"
                    size="sm"
                    className="p-0"
                    onClick={() => setShowPromoModal(true)}
                  >
                    View Discounts
                  </Button>
                )}
                <Modal
                  show={showPromoModal}
                  onHide={() => {
                    setShowPromoModal(false)
                    setCouponError('')
                  }}
                  size="lg"
                  scrollable
                  backdrop="static"
                  dialogClassName="modal-dialog-wm"
                >
                  <Modal.Header closeButton>
                    <button
                      onClick={() => {
                        setCouponError('')
                        setShowPromoModal(false)
                      }}
                      className="btn btn-white m-0 p-0"
                    >
                      <FaArrowLeft />
                    </button>
                    <Modal.Title className="w-100">
                      <span className="m-0">
                        <p
                          className={`w-100 text-center mb-0 ${styles.font__size__20}`}
                        >
                          <b>View Discounts</b>
                        </p>
                      </span>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ApplyPromoCode
                      availableCoupons={availableCoupons}
                      applyHandler={couponApplyHandler}
                      promoLoading={promoLoading}
                      couponError={couponError}
                      setCouponError={setCouponError}
                      bookingTotal={invoice?.invoice_footer?.amount_payable}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </Card>
          {checkingAvailabilityCondition(
            city,
            stateName,
            bookingDate,
            meal,
            citiesList
          ) ? (
            <div className="text-muted">
              {/* <div className={styles.font__size__12}>
                <FaCheck /> Ingredients list for {numberOfPeople} people will be
                shared once the booking is confirmed
              </div> */}

              {/* {number_of_helpers > 0 && (
                <div className={styles.font__size__12}>
                  Helper assists the {bookingLevel}. 
                  <FaCheck /> Helper does not wash utensils, serve or lay the
                  table
                </div>
              )} */}
              {/* <li className={styles.font__size__12}>
                Overtime charges are ₹8.5/min after{' '}
                {dayjs(
                  props.expected_arrival_time
                    ? props.expected_arrival_time
                    : expected_arrival_time,
                  'HH:mm:ss'
                )
                  .add(invoice?.invoice_middle?.service_hours, 'hours')
                  .format('h a')}
              </li> */}
              {/* <li className={styles.font__size__12}>
                Price does not include cost of ingredients
              </li> */}
              {/* <li className={styles.font__size__12}>
                Please arrange ingredients yourself
              </li> */}
              {/* <li className={styles.font__size__12}>
                Overtime charges are applicable @ INR 8.5/minute after that
              </li> */}
              <div className={styles.font__size__12}>
                <FaCheck /> Helper is optional and up to the decision of the{' '}
                {bookingLevel}
              </div>
              <div className={styles.font__size__12}>
                <FaCheck /> View{' '}
                <a
                  href="javascript:void(0)"
                  onClick={() => setIsOpenedCancellationPolicy(true)}
                >
                  cancellation policy
                </a>{' '}
                from here
              </div>
              <div className={styles.font__size__12}>
                <FaCheck /> I agree to the{' '}
                <a href="/terms" target="_blank">
                  T&C
                </a>
                ,{' '}
                <a href="/privacy-policy" target="_blank">
                  privacy policy
                </a>
                ,{' '}
                <a href="/covid19-guidelines" target="_blank">
                  covid-19 guidelines
                </a>
              </div>
            </div>
          ) : (
            <>
              {bookingLevel !== 'Waiter' &&
                bookingLevel !== 'DailyCook' &&
                bookingLevel !== 'Bartender' &&
                bookingLevel !== 'Cleaner' && (
                  <div className="text-muted my-3">
                    <div className={styles.font__size__12}>
                      <FaCheck /> Advance payment is for booking confirmation
                      and is non-refundable
                    </div>
                    {paymentModalBtnType !== 'payNow' && (
                      <div className={styles.font__size__12}>
                        <FaCheck /> Balance payment can be done in cash or
                        online
                      </div>
                    )}
                    {/* <li className={styles.font__size__12}>
                  Price does not include cost of ingredients
                </li> */}
                  </div>
                )}
              <div className="text-muted mb-5">
                {/* <div className={styles.font__size__12}>
                  <FaCheck /> Ingredients list for {numberOfPeople} people will
                  be shared once the booking is confirmed
                </div> */}
                {/* {number_of_helpers > 0 && (
                  <div className={styles.font__size__12}>
                    Helper assists the {bookingLevel}. 
                    <FaCheck /> Helper does not wash utensils, serve or lay the
                    table
                  </div>
                )} */}
                {paymentModalBtnType !== 'payNow' && (
                  <div className={styles.font__size__12}>
                    <FaCheck /> Full address needs to be filled after payment
                  </div>
                )}

                {/* <div className={styles.font__size__12}>
                  <FaCheck /> No separate charges for transportation
                </div> */}
                <div className={styles.font__size__12}>
                  <FaCheck /> Overtime charges are ₹8.5/min if you extend
                  service
                  {/* after{' '}{dayjs(
                    props.expected_arrival_time
                      ? props.expected_arrival_time
                      : expected_arrival_time,
                    'HH:mm:ss'
                  )
                    .add(invoice?.invoice_middle?.service_hours, 'hours')
                    .format('h a')} */}
                </div>

                {/* <li className={styles.font__size__12}>
                Please arrange ingredients yourself
              </li> */}
                {/* <li className={styles.font__size__12}>
                Overtime charges are applicable @ INR 8.5/minute after that
              </li> */}
                <div className={styles.font__size__12}>
                  <FaCheck /> Helper is optional and up to the decision of the{' '}
                  {bookingLevel}
                </div>
                <div className={styles.font__size__12}>
                  <FaCheck /> View{' '}
                  <a
                    href="javascript:void(0)"
                    onClick={() => setIsOpenedCancellationPolicy(true)}
                  >
                    cancellation policy
                  </a>{' '}
                  from here
                </div>
                <div className={styles.font__size__12}>
                  <FaCheck /> I agree to the{' '}
                  <a href="/terms" target="_blank">
                    T&C
                  </a>
                  ,{' '}
                  <a href="/privacy-policy" target="_blank">
                    privacy policy
                  </a>
                  ,{' '}
                  <a href="/covid19-guidelines" target="_blank">
                    covid-19 guidelines
                  </a>
                </div>
              </div>
              <CancellationPolicyModal
                show={isOpenedCancellationPolicy}
                setShow={setIsOpenedCancellationPolicy}
              />
            </>
          )}
        </Col>
        {checkingAvailabilityCondition(
          city,
          stateName,
          bookingDate,
          meal,
          citiesList
        ) || 
        bookingLevel === 'DailyCook' ? (
          <Col>
            <br />
            <h5
              className="font-weight-bold text-danger"
              style={{ lineHeight: '1.5' }}
            >
              Once you send request, we will check availability in your area and
              confirm shortly.
            </h5>
          </Col>
        ) : (
          <Col lg={6}>
            <h6 className="font-weight-bold mb-3" id="paymentMethod">
              Select Payment Method
            </h6>
            {payment_gateways?.map((pg) => {
              if (pg.name === 'PAYTM_INR') {
                return (
                  <>
                    <Row>
                      <Col xs={2}>
                        <label htmlFor="paytm" className="d-inline">
                          <FakeCheckbox
                            name="paytm"
                            id="paytm"
                            value="paytm"
                            onChange={({ target: { value } }) => {
                              if (!proceedingToNextStep) {
                                setPaymentGateway(value)
                              }
                            }}
                            checked={paymentGateway === 'paytm'}
                          />
                        </label>
                      </Col>
                      <Col xs={10}>
                        <img
                          src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/logos/paytm_logo.png"
                          alt="Paytm Payment gateway"
                          width="60px"
                          className="ml-1"
                        />
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col xs={2}></Col>
                      <Col xs={10}>
                        <label htmlFor="paytm">UPI, Wallet, Net Banking</label>
                      </Col>
                    </Row>
                  </>
                )
              } else if (pg.name === 'RAZORPAY_INR') {
                return (
                  <>
                    <Row className="mt-4">
                      <Col xs={2}>
                        <label htmlFor="rzp" className="d-inline">
                          <FakeCheckbox
                            name="rzp"
                            id="rzp"
                            value="razorpay"
                            onChange={({ target: { value } }) => {
                              if (!proceedingToNextStep) {
                                setPaymentGateway(value)
                              }
                            }}
                            checked={paymentGateway === 'razorpay'}
                          />
                        </label>
                      </Col>
                      <Col xs={10}>
                        <img
                          src={wrapS3URLForPaymentIcons('card.svg')}
                          alt="Credit Card, Debit Card"
                          width="30px"
                          className="ml-2"
                        />
                        <img
                          src={wrapS3URLForPaymentIcons('UPI.svg')}
                          alt="UPI"
                          width="30px"
                          className="ml-3"
                        />
                        <img
                          src={wrapS3URLForPaymentIcons('netbanking.svg')}
                          alt="Net Banking"
                          width="30px"
                          className="ml-3"
                        />
                        <img
                          src={wrapS3URLForPaymentIcons('wallet.svg')}
                          alt="Wallets"
                          width="30px"
                          className="ml-3"
                        />
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col xs={2}></Col>
                      <Col xs={10}>
                        <label htmlFor="rzp" className="d-inline">
                          UPI, Google Pay, PhonePe, Wallets, Credit Cards, Debit
                          Cards, Net Banking
                        </label>
                      </Col>
                    </Row>
                  </>
                )
              }
            })}
          </Col>
        )}
        {/* <Col lg={1}></Col> */}
      </Row>
      {/* <Row>
          <Col>
            <p
              className={`${styles.font__size__12} ${styles.light__grey__color}`}
            >
              I agree to the{' '}
              <a href="/terms" target="_blank">
                terms of use
              </a>
              ,{' '}
              <a href="/privacy-policy" target="_blank">
                privacy policy
              </a>{' '}
              and{' '}
              <a href="/covid19-guidelines" target="_blank">
                covid-19 guidelines
              </a>
            </p>
          </Col>
        </Row> */}
      {!checkingAvailabilityCondition(
        city,
        stateName,
        bookingDate,
        meal,
        citiesList
      ) && props.ispre ? (
        <Button
          className="mt-5"
          variant="link"
          style={
            loadingAddToCart
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid grey',
                }
              : {
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #007bff',
                }
          }
          onClick={() => {
            setLoadingAddToCart(true)
            addToCart()
          }}
          disabled={loadingAddToCart}
        >
          <BsCart4 size={16} /> <span className="ml-1"></span> Sav
          {loadingAddToCart ? 'ing' : 'e'} to Cart
          {loadingAddToCart ? '...' : null}
        </Button>
      ) : null}
    </div>
  )
}

export default BookingPaymentBody
