import { useState, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'

// libraries
import axios from 'axios'
import { Modal, Container } from 'react-bootstrap'

// STYLES
import styles from '../BookingModals/BookChefModal/index.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import authSlice from '@/lib/slices/authSlice'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'

// Components
import EnterMobileView from './steps/EnterMobileView'
import EnterOtpView from './steps/EnterOtpView'
import CTAhelper from './helpers/CTAhelper'

// CONTEXT
import { SWContext } from 'pages/_app'

// ICONS
import { FaArrowLeft } from 'react-icons/fa'
import { HashLoader } from 'react-spinners'

// TS INTERFACES
import { RootState } from '@/lib/rootState'
import ChatOnWhatsAppText from '../ChatOnWhatsAppText'

const LoginModal = (): JSX.Element => {
  const router = useRouter()
  const dispatch = useDispatch()

  const showLoginModal = useSelector(
    (state: RootState) => state.auth.showLoginModal
  )
  const loginModalSource = useSelector((state: RootState) => state.auth.source)
  const mobile = useSelector((state: RootState) => state.auth.mobile)
  const otp = useSelector((state: RootState) => state.auth.otp)
  const bookingId = useSelector(
    (state: RootState) => state.bookingModal.bookingDetails.bookingId
  )
  const spclAppliances = useSelector(
    (state: RootState) => state.bookingModal.spclAppliances.spclAppliances
  )
  const bookingBartenderId = useSelector(
    (state: RootState) => state.bookingBartenderModal.bookingDetails.bookingId
  )
  const spclBartenderAppliances =
    useSelector(
      (state: RootState) =>
        state.bookingBartenderModal.spclAppliances.spclAppliances
    ) || []

  const bookingWaiterId = useSelector(
    (state: RootState) => state.bookingWaiterModal.bookingDetails.bookingId
  )
  const bookingCleanerId = useSelector(
    (state: RootState) => state.bookingCleanerModal.bookingDetails.bookingId
  )
  const bookingDailyCookId = useSelector(
    (state: RootState) => state.bookDailyCookModal.bookingDetails.bookingId
  )
  // const spclWaiterAppliances =
  //   useSelector(
  //     (state: RootState) =>
  //       state.bookingWaiterModal.spclAppliances.spclAppliances
  //   ) || []

  const [showOtpView, setShowOtpView] = useState<boolean>(false)
  const [tempMobile, setTempMobile] = useState<string>('')
  const [mobileError, setMobileError] = useState<string>('')
  const [mobileLoading, setMobileLoading] = useState(false)
  const [otpError, setOtpError] = useState<string>('')
  const [tcLoginTimer, setTcLoginTimer] = useState<string>('')
  const [tcLoading, setTCLoading] = useState<boolean>(false)
  const [otpLoading, setOtpLoading] = useState(false)
  useEffect(() => {
    if (otp.length === 0) {
      setOtpError('')
    }
  }, [otp])
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )
  const REDUX__bookingBartenderModalData = useSelector(
    (state: RootState) => state.bookingBartenderModal
  )
  const REDUX__bookingWaiterModalData = useSelector(
    (state: RootState) => state.bookingWaiterModal
  )
  const REDUX__bookingCleanerModalData = useSelector(
    (state: RootState) => state.bookingCleanerModal
  )
  const REDUX__bookDailyCookModalData = useSelector(
    (state: RootState) => state.bookDailyCookModal
  )
  useEffect(() => {
    setOtpError('')
  }, [showOtpView])

  const { SW } = useContext(SWContext)

  const createBookingLead = (mobile, hideModal = false) => {
    // console.log('DD')
    // Destructuting
    const {
      bookingDetails: {
        occasionId,
        numberOfPeople,
        bookingDate,
        bookingTime,
        meal,
        comboIds,
        bookingLevel,
      },
      designMenu: {
        cuisineIds,
        dishDetails,
        lunchDishes,
        dinnerDishes,
        breakfastDishes,
      },
    } = REDUX__bookingModalData
    const {
      bookingDetails: {
        occasionId: occasionIdBar,
        numberOfPeople: numberOfPeopleBar,
        bookingDate: bookingDateBar,
      },
      designMenu: { dishDetails: dishDetailsBar },
    } = REDUX__bookingBartenderModalData

    const {
      bookingDetails: {
        occasionId: occasionIdWaiter,
        numberOfPeople: numberOfPeopleWaiter,
        bookingDate: bookingDateWaiter,
        bookingTime: bookingTimeWaiter,
      },
      designMenu: { dishDetails: dishDetailsWaiter },
    } = REDUX__bookingWaiterModalData

    const {
      bookingDetails: {
        occasionId: occasionIdCleaner,
        numberOfPeople: numberOfPeopleCleaner,
        bookingDate: bookingDateCleaner,
        bookingTime: bookingTimeCleaner,
      },
      designMenu: { dishDetails: dishDetailsCleaner },
    } = REDUX__bookingCleanerModalData

    const {
      bookingDetails: {
        numberOfPeople: numberOfPeopleDailyCook,
        bookingDate: bookingDateDailyCook,
        bookingTime: bookingTimeDailyCook,
        booking_type
      },
      designMenu: { dishDetails: dishDetailsDailyCook },
    } = REDUX__bookDailyCookModalData

    const dishObjFunc = (dish: any) => ({
      id: dish.id,
      meal_type: dish.meal_type,
    })
    const dishObjFuncBar = (dish: any) => dish.id
    const dishObjFuncWaiter = (dish: any) => dish.id
    const lunchDishes2 = lunchDishes?.map(dishObjFunc)
    const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
    const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
    const dishDetails2 = dishDetails?.map(dishObjFunc)
    const dishDetails2Bar = dishDetailsBar?.map(dishObjFuncBar)
    const dishDetails2Waiter = dishDetailsWaiter?.map(dishObjFuncWaiter)

    // Create booking
    if (loginModalSource === 'booking_bartender_modal') {
      axios({
        method: 'POST',
        url: `/booking/bartender`,
        data: {
          cuisines: [52],
          occasion_id: occasionIdBar,
          number_of_people: numberOfPeopleBar,
          booking_date: bookingDateBar,
          booking_time: '19:00',
          dishes: dishDetails2Bar,
          mobile: String(mobile) || localStorage.getItem('mobile'),
          request_otp: hideModal ? false : true,
        },
      })
        .then((res) => {
          setShowOtpView(true)
          dispatch(
            bookingBartenderModalSlice.actions.setBookingId(res.data.booking.id)
          )
          dispatch(
            bookingBartenderModalSlice.actions.setSpclAppliances(
              res.data.special_appliances
            )
          )
          if (hideModal) {
            dispatch(authSlice.actions.toggleLoginModal())
          }
        })
        .catch(() => {
          console.log('Some error occured!')
        })
        .finally(() => {
          setMobileLoading(false)
        })
    } else if (loginModalSource === 'booking_waiter_modal') {
      axios({
        method: 'POST',
        url: `/booking/waiter-cleaner`,
        data: {
          occasion_id: occasionIdWaiter,
          service_type: 'Waiter',
          // number_of_people: numberOfPeopleWaiter,
          booking_date: bookingDateWaiter,
          booking_time: bookingTimeWaiter,
          mobile: String(mobile) || localStorage.getItem('mobile'),
          request_otp: hideModal ? false : true,
        },
      })
        .then((res) => {
          setShowOtpView(true)
          dispatch(
            bookingWaiterModalSlice.actions.setBookingId(res.data.booking.id)
          )
          // dispatch(
          //   bookingWaiterModalSlice.actions.setSpclAppliances(
          //     res.data.booking.special_appliances
          //   )
          // )
          if (hideModal) {
            dispatch(authSlice.actions.toggleLoginModal())
          }
        })
        .catch(() => {
          console.log('Some error occured!')
        })
        .finally(() => {
          setMobileLoading(false)
        })
    } else if (loginModalSource === 'booking_cleaner_modal') {
      axios({
        method: 'POST',
        url: `/booking/waiter-cleaner`,
        data: {
          occasion_id: occasionIdCleaner,
          service_type: 'Cleaner',
          // number_of_people: numberOfPeopleCleaner,
          booking_date: bookingDateCleaner,
          booking_time: bookingTimeCleaner,
          mobile: String(mobile) || localStorage.getItem('mobile'),
          request_otp: hideModal ? false : true,
        },
      })
        .then((res) => {
          setShowOtpView(true)
          dispatch(
            bookingCleanerModalSlice.actions.setBookingId(res.data.booking.id)
          )
          // dispatch(
          //   bookingWaiterModalSlice.actions.setSpclAppliances(
          //     res.data.booking.special_appliances
          //   )
          // )
          if (hideModal) {
            dispatch(authSlice.actions.toggleLoginModal())
          }
        })
        .catch(() => {
          console.log('Some error occured!')
        })
        .finally(() => {
          setMobileLoading(false)
        })
    } else if (loginModalSource === 'book_daily_cook_modal') {
      axios({
        method: 'POST',
        url: `/booking/daily-cook`,
        data: {
          number_of_people: numberOfPeopleDailyCook,
          booking_date: bookingDateDailyCook,
          cook_arrival_time: bookingTimeDailyCook,
          booking_type,
          mobile: String(mobile) || localStorage.getItem('mobile'),
          request_otp: hideModal ? false : true,
        },
      })
        .then((res) => {
          setShowOtpView(true)
          dispatch(
            bookDailyCookModalSlice.actions.setBookingId(res.data.data.booking.id)
          )
          // dispatch(
          //   bookingWaiterModalSlice.actions.setSpclAppliances(
          //     res.data.booking.special_appliances
          //   )
          // )
          if (hideModal) {
            dispatch(authSlice.actions.toggleLoginModal())
          }
        })
        .catch(() => {
          console.log('Some error occured!')
        })
        .finally(() => {
          setMobileLoading(false)
        })
    } else {
      axios({
        method: 'POST',
        url: `/booking`,
        data: {
          cuisines: cuisineIds,
          occasion_id: occasionId,
          number_of_people: numberOfPeople,
          booking_date: bookingDate,
          booking_time: bookingTime,
          dish_details:
            meal === 'breakfast_lunch_dinner'
              ? [...lunchDishes2, ...dinnerDishes2, ...breakfastDishes2]
              : meal === 'breakfast_lunch'
              ? [...lunchDishes2, ...breakfastDishes2]
              : meal === 'breakfast_dinner'
              ? [...dinnerDishes2, ...breakfastDishes2]
              : meal === 'lunch_dinner'
              ? [...dinnerDishes2, ...lunchDishes2]
              : dishDetails2,
          cuisine_combos: comboIds,
          booking_type: meal,
          mobile: String(mobile) || localStorage.getItem('mobile'),
          cook_level: bookingLevel,
          request_otp: hideModal ? false : true,
        },
      })
        .then((res) => {
          setShowOtpView(true)
          dispatch(
            bookingModalSlice.actions.setBookingId(res.data.data.booking.id)
          )
          dispatch(
            bookingModalSlice.actions.setSpclAppliances(
              res.data.data.booking.special_appliances
            )
          )
          if (hideModal) {
            dispatch(authSlice.actions.toggleLoginModal())
          }
          // if (hideModal) {
          //   dispatch(authSlice.actions.toggleLoginModal())
          //   // props.setShowOtpView(false)
          // }
          // if (res.data.data.booking.special_appliances.length === 0) {
          // console.log('F')
          //   // dispatch(
          //   //   bookingModalSlice.actions.toggleAddAddressModal({
          //   //     stepNo: 5,
          //   //   })
          //   // )
          // } else {
          //   SW?. goToNamedStep('address')
          // }
        })
        .catch(() => {
          // alert('Some error occured!')
        })
        .finally(() => {
          setMobileLoading(false)
          // dispatch(authSlice.actions.toggleUserDetailsModal())
        })
    }
  }

  const createBookingLeadAndFetchUserAddresses = (hideModal = false) => {
    // console.log('D')
    // Destructuting
    const {
      bookingDetails: {
        occasionId,
        numberOfPeople,
        bookingDate,
        bookingTime,
        meal,
        comboIds,
        bookingLevel,
        numberOfBurners,
        appliances,
      },
      spclAppliances: { spclAppliances },
      userAddressData: { selectedAddressId },
      designMenu: {
        cuisineIds,
        dishDetails,
        lunchDishes,
        dinnerDishes,
        breakfastDishes,
      },
    } = REDUX__bookingModalData
    const {
      bookingDetails: {
        occasionId: occasionIdBar,
        numberOfPeople: numberOfPeopleBar,
        bookingDate: bookingDateBar,
      },
      designMenu: { dishDetails: dishDetailsBar },
    } = REDUX__bookingBartenderModalData

    const {
      bookingDetails: {
        occasionId: occasionIdWaiter,
        numberOfPeople: numberOfPeopleWaiter,
        bookingDate: bookingDateWaiter,
        bookingTime: bookingTimeWaiter,
      },
      designMenu: { dishDetails: dishDetailsWaiter },
    } = REDUX__bookingWaiterModalData

    const {
      bookingDetails: {
        occasionId: occasionIdCleaner,
        numberOfPeople: numberOfPeopleCleaner,
        bookingDate: bookingDateCleaner,
        bookingTime: bookingTimeCleaner,
      },
      designMenu: { dishDetails: dishDetailsCleaner },
    } = REDUX__bookingCleanerModalData

    const {
      bookingDetails: {
        numberOfPeople: numberOfPeopleDailyCook,
        bookingDate: bookingDateDailyCook,
        bookingTime: bookingTimeDailyCook,
        booking_type
      },
      designMenu: { dishDetails: dishDetailsDailyCook },
    } = REDUX__bookDailyCookModalData

    const dishObjFunc = (dish: any) => ({
      id: dish.id,
      meal_type: dish.meal_type,
    })
    const dishObjFuncBar = (dish: any) => dish.id
    const dishObjFuncWaiter = (dish: any) => dish.id
    const lunchDishes2 = lunchDishes?.map(dishObjFunc)
    const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
    const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
    const dishDetails2 = dishDetails?.map(dishObjFunc)
    const dishDetails2Bar = dishDetailsBar?.map(dishObjFuncBar)
    const dishDetails2Waiter = dishDetailsWaiter?.map(dishObjFuncWaiter)

    // Create booking lead
    const url =
      loginModalSource === 'booking_bartender_modal'
        ? `/booking/bartender`
        : loginModalSource === 'booking_waiter_modal' ||
          loginModalSource === 'booking_cleaner_modal'
        ? `/booking/waiter-cleaner` :
        loginModalSource === 'book_daily_cook_modal'
        ? `booking/daily-cook`
        : `/booking`
    const data =
      loginModalSource === 'booking_bartender_modal'
        ? {
            cuisines: [52],
            occasion_id: occasionIdBar,
            number_of_people: numberOfPeopleBar,
            booking_date: bookingDateBar,
            booking_time: '19:00',
            dishes: dishDetails2Bar,
            mobile: String(mobile) || localStorage.getItem('mobile'),
            request_otp: hideModal ? false : true,
          }
        : loginModalSource === 'booking_waiter_modal'
        ? {
            occasion_id: occasionIdWaiter,
            service_type: 'Waiter',
            // number_of_people: numberOfPeopleWaiter,
            booking_date: bookingDateWaiter,
            booking_time: bookingTimeWaiter,
            mobile: String(mobile) || localStorage.getItem('mobile'),
            request_otp: hideModal ? false : true,
          }
        : loginModalSource === 'booking_cleaner_modal'
        ? {
            occasion_id: occasionIdCleaner,
            service_type: 'Cleaner',
            // number_of_people: numberOfPeopleCleaner,
            booking_date: bookingDateCleaner,
            booking_time: bookingTimeCleaner,
            mobile: String(mobile) || localStorage.getItem('mobile'),
            request_otp: hideModal ? false : true,
          }
        : loginModalSource === 'book_daily_cook_modal'
        ? {
              number_of_people: numberOfPeopleDailyCook,
              booking_date: bookingDateDailyCook,
              cook_arrival_time: bookingTimeDailyCook,
              booking_type,
              mobile: String(mobile) || localStorage.getItem('mobile'),
              request_otp: hideModal ? false : true,
          }
        : {
            cuisines: cuisineIds,
            occasion_id: occasionId,
            number_of_people: numberOfPeople,
            booking_date: bookingDate,
            booking_time: bookingTime,
            mobile: String(mobile) || localStorage.getItem('mobile'),
            dish_details:
              meal === 'breakfast_lunch_dinner'
                ? [...lunchDishes2, ...dinnerDishes2, ...breakfastDishes2]
                : meal === 'breakfast_lunch'
                ? [...lunchDishes2, ...breakfastDishes2]
                : meal === 'breakfast_dinner'
                ? [...dinnerDishes2, ...breakfastDishes2]
                : meal === 'lunch_dinner'
                ? [...dinnerDishes2, ...lunchDishes2]
                : dishDetails2,
            cuisine_combos: comboIds,
            booking_type: meal,
            cook_level: bookingLevel,
            request_otp: hideModal ? false : true,
          }
    axios({
      method: 'POST',
      url,
      data,
    })
      .then((res) => {
        if (loginModalSource === 'booking_bartender_modal') {
          dispatch(
            bookingBartenderModalSlice.actions.setBookingId(res.data.booking.id)
          )
          dispatch(
            bookingBartenderModalSlice.actions.setSpclAppliances(
              res.data.booking.special_appliances
            )
          )
        } else if (loginModalSource === 'booking_waiter_modal') {
          dispatch(
            bookingWaiterModalSlice.actions.setBookingId(res.data.booking.id)
          )
          // dispatch(
          //   bookingWaiterModalSlice.actions.setSpclAppliances(
          //     res.data.booking.special_appliances
          //   )
          // )
        } else if (loginModalSource === 'booking_cleaner_modal') {
          dispatch(
            bookingCleanerModalSlice.actions.setBookingId(res.data.booking.id)
          )
          // dispatch(
          //   bookingWaiterModalSlice.actions.setSpclAppliances(
          //     res.data.booking.special_appliances
          //   )
          // )
        } else if (loginModalSource === 'book_daily_cook_modal') {
          dispatch(
            bookDailyCookModalSlice.actions.setBookingId(res.data.booking.id)
          )
          // dispatch(
          //   bookingWaiterModalSlice.actions.setSpclAppliances(
          //     res.data.booking.special_appliances
          //   )
          // )
        } else {
          dispatch(
            bookingModalSlice.actions.setBookingId(res.data.data.booking.id)
          )
          dispatch(
            bookingModalSlice.actions.setSpclAppliances(
              res.data.data.booking.special_appliances
            )
          )
        }
        axios.get(`/user/address`).then((response) => {
          const defaultAddressId: number = (
            response.data.addresses.find(
              (address: IaddressFromAPI) => address.is_default === 1
            ) || { address_id: null }
          ).address_id
          if (loginModalSource === 'booking_bartender_modal') {
            dispatch(
              bookingBartenderModalSlice.actions.setUserAddressData({
                addresses: response.data.addresses,
                selectedAddressId: defaultAddressId,
              })
            )
          } else if (loginModalSource === 'booking_waiter_modal') {
            dispatch(
              bookingWaiterModalSlice.actions.setUserAddressData({
                addresses: response.data.addresses,
                selectedAddressId: defaultAddressId,
              })
            )
          } else if (loginModalSource === 'booking_cleaner_modal') {
            dispatch(
              bookingCleanerModalSlice.actions.setUserAddressData({
                addresses: response.data.addresses,
                selectedAddressId: defaultAddressId,
              })
            )
          } else if (loginModalSource === 'book_daily_cook_modal') {
            dispatch(
              bookDailyCookModalSlice.actions.setUserAddressData({
                addresses: response.data.addresses,
                selectedAddressId: defaultAddressId,
              })
            )
          } else {
            dispatch(
              bookingModalSlice.actions.setUserAddressData({
                addresses: response.data.addresses,
                selectedAddressId: defaultAddressId,
              })
            )
          }
          if (hideModal) {
            dispatch(authSlice.actions.toggleLoginModal())
            setShowOtpView(false)
          }
          if (loginModalSource === 'booking_bartender_modal') {
            if (res.data.booking.special_appliances.length === 0) {
              if (response.data.addresses.length === 0) {
                dispatch(
                  bookingBartenderModalSlice.actions.toggleAddAddressModal({
                    stepNo: 4,
                  })
                )
              } else {
                if (defaultAddressId) {
                  axios({
                    method: 'POST',
                    url: `booking/bartender/summary`,
                    data: {
                      payment_option: 2,
                      address_id: defaultAddressId,
                      appliance_ids: [],
                      booking_id: bookingBartenderId,
                    },
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(
                          bookingBartenderModalSlice.actions.setBookingSummary(
                            response.data
                          )
                        )
                        return SW?.goToNamedStep('summary')
                      }
                    })
                    .catch((error) => {
                      if (error?.response?.status === 406) {
                        dispatch(
                          bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error?.response?.status === 500) {
                        dispatch(
                          bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {})
                } else SW?.goToNamedStep('address')
              }
            } else {
              SW?.goToNamedStep('appliances')
            }
          } else if (loginModalSource === 'booking_waiter_modal') {
            // if (res.data.booking.special_appliances.length === 0) {
            if (response.data.addresses.length === 0) {
              dispatch(
                bookingWaiterModalSlice.actions.toggleAddAddressModal({
                  stepNo: 4,
                })
              )
            } else {
              if (defaultAddressId) {
                axios({
                  method: 'POST',
                  url: `booking/waiter-cleaner/summary`,
                  data: {
                    address_id: selectedAddressId,
                    booking_id: bookingWaiterId,
                  },
                })
                  .then((response) => {
                    if (response?.status === 200) {
                      dispatch(
                        bookingWaiterModalSlice.actions.setBookingSummary(
                          response.data
                        )
                      )
                      SW?.goToNamedStep('summary')
                    }
                  })
                  .catch((error) => {
                    if (error?.response?.status === 406) {
                      dispatch(
                        bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    } else if (error?.response?.status === 500) {
                      dispatch(
                        bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    }
                  })
                  .finally(() => {})
              } else SW?.goToNamedStep('address')
            }
            // } else {
            //   SW?.goToNamedStep('appliances')
            // }
          } else if (loginModalSource === 'booking_cleaner_modal') {
            // if (res.data.booking.special_appliances.length === 0) {
            if (response.data.addresses.length === 0) {
              dispatch(
                bookingCleanerModalSlice.actions.toggleAddAddressModal({
                  stepNo: 4,
                })
              )
            } else {
              if (defaultAddressId) {
                axios({
                  method: 'POST',
                  url: `booking/waiter-cleaner/summary`,
                  data: {
                    address_id: selectedAddressId,
                    booking_id: bookingWaiterId,
                  },
                })
                  .then((response) => {
                    if (response?.status === 200) {
                      dispatch(
                        bookingCleanerModalSlice.actions.setBookingSummary(
                          response.data
                        )
                      )
                      SW?.goToNamedStep('summary')
                    }
                  })
                  .catch((error) => {
                    if (error?.response?.status === 406) {
                      dispatch(
                        bookingCleanerModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    } else if (error?.response?.status === 500) {
                      dispatch(
                        bookingCleanerModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    }
                  })
                  .finally(() => {})
              } else SW?.goToNamedStep('address')
            }
            // } else {
            //   SW?.goToNamedStep('appliances')
            // }
          } else if (loginModalSource === 'book_daily_cook_modal') {
            // if (res.data.booking.special_appliances.length === 0) {
            if (response.data.addresses.length === 0) {
              dispatch(
                bookingWaiterModalSlice.actions.toggleAddAddressModal({
                  stepNo: 2,
                })
              )
            } else {
              if (defaultAddressId) {
                axios({
                  method: 'POST',
                  url: `booking/daily-cook/summary`,
                  data: {
                    address_id: selectedAddressId,
                    booking_id: bookingWaiterId,
                  },
                })
                  .then((response) => {
                    if (response?.status === 200) {
                      dispatch(
                        bookingWaiterModalSlice.actions.setBookingSummary(
                          response.data
                        )
                      )
                      SW?.goToNamedStep('summary')
                    }
                  })
                  .catch((error) => {
                    if (error?.response?.status === 406) {
                      dispatch(
                        bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    } else if (error?.response?.status === 500) {
                      dispatch(
                        bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
                      )
                    }
                  })
                  .finally(() => {})
              } else SW?.goToNamedStep('address')
            }
            // } else {
            //   SW?.goToNamedStep('appliances')
            // }
          } else {
            if (res.data.data.booking.special_appliances.length === 0) {
              if (response.data.addresses.length === 0) {
                dispatch(
                  bookingModalSlice.actions.toggleAddAddressModal({
                    stepNo: 4,
                  })
                )
              } else {
                if (defaultAddressId) {
                  const dishObjFunc = (dish: any) => ({
                    id: dish.id,
                    meal_type: dish.meal_type,
                  })
                  // const lunchDishes2 = lunchDishes?.map(dishObjFunc)
                  // const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
                  // const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
                  // const dishDetails2 = dishDetails?.map(dishObjFunc)
                  const numberOfburner = parseInt(numberOfBurners, 10)
                  let appID = []
                  if (appliances) {
                    appID = [...appliances]
                  }
                  if (numberOfburner === 1) {
                    appID.push(41)
                  } else if (numberOfburner == 2) {
                    appID.push(35)
                  } else if (numberOfburner == 3) {
                    appID.push(36)
                  } else if (numberOfburner == 4) {
                    appID.push(37)
                  } else if (numberOfburner == 5) {
                    appID.push(38)
                  } else if (numberOfburner == 6) {
                    appID.push(42)
                  }

                  axios({
                    method: 'POST',
                    url: `/booking/summary`,
                    data: {
                      cuisines: cuisineIds,
                      occasion_id: occasionId,
                      cuisine_combos: comboIds,
                      number_of_people: numberOfPeople,
                      booking_date: bookingDate,
                      booking_time: bookingTime,
                      dish_details:
                        meal === 'lunch' ||
                        meal === 'dinner' ||
                        meal === 'breakfast'
                          ? dishDetails2
                          : breakfastDishes2
                              ?.concat(lunchDishes2)
                              ?.concat(dinnerDishes2),

                      payment_option: 2,
                      appliance_ids: appID, //add comma separated appliance ids in this array
                      address_id: defaultAddressId,
                      booking_id: bookingId,
                      booking_type: meal,
                    },
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(
                          bookingModalSlice.actions.setBookingSummary(
                            response.data.data
                          )
                        )
                        SW?.goToNamedStep('summary')
                      }
                    })
                    .catch((error) => {
                      if (error.response.status === 406) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error.response.status === 500) {
                        dispatch(
                          bookingModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {
                      // setLoading(false)
                    })
                } else {
                  dispatch(authSlice.actions.toggleLoginModal())
                  setShowOtpView(false)
                  SW?.goToNamedStep('address')
                }
              }
            } else {
              SW?.goToNamedStep('appliances')
            }
          }
        })
      })
      .catch((err) => {
        // alert('Some error occured!')
        console.log('createLeadandFetch', err)
      })

    // Fetch user addresses
  }

  const showTrueCaller = () => {
    var ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        // alert("1") // Chrome
      } else {
        return false
      }
    }
    const TC_REQUEST_ID = Date.now()
    window.location = `truecallersdk://truesdk/web_verify?requestNonce=${TC_REQUEST_ID}&partnerKey=${process.env.NEXT_PUBLIC_TRUECALLER_APP_KEY}&partnerName=COOX&skipOption=useanothernum&ctaColor=%23ffc107`
    setTimeout(() => {
      if (document.hasFocus()) {
        // Truecaller app not present on the device and you redirect the user
        // to your alternate verification page
        if (loginModalSource === 'booking_bartender_modal') {
          dispatch(bookingBartenderModalSlice.actions.setTruecallerOpen(false))
        } else if (loginModalSource === 'booking_waiter_modal') {
          dispatch(bookingWaiterModalSlice.actions.setTruecallerOpen(false))
        } else if (loginModalSource === 'booking_cleaner_modal') {
          dispatch(bookingCleanerModalSlice.actions.setTruecallerOpen(false))
        } else if (loginModalSource === 'book_daily_cook_modal') {
          dispatch(bookDailyCookModalSlice.actions.setTruecallerOpen(false))
        } else {
          dispatch(bookingModalSlice.actions.setTruecallerOpen(false))
        }
        console.log('truecaller not present')
      } else {
        console.log('truecaller present')
        // Truecaller app present on the device and the profile overlay opens
        // The user clicks on verify & you'll receive the user's access token to fetch the profile on your
        // callback URL - post which, you can refresh the session at your frontend and complete the user  verification
        setTCLoading(true)
        const timer = setInterval(() => {
          if (document.hasFocus()) {
            // Truecaller app not present on the device and you redirect the user
            // to your alternate verification page
            if (loginModalSource === 'booking_bartender_modal') {
              dispatch(
                bookingBartenderModalSlice.actions.setTruecallerOpen(false)
              )
            } else if (loginModalSource === 'booking_waiter_modal') {
              dispatch(bookingWaiterModalSlice.actions.setTruecallerOpen(false))
            } else if (loginModalSource === 'booking_cleaner_modal') {
              dispatch(
                bookingCleanerModalSlice.actions.setTruecallerOpen(false)
              )
            } else if (loginModalSource === 'book_daily_cook_modal') {
              dispatch(bookDailyCookModalSlice.actions.setTruecallerOpen(false))
            } else {
              dispatch(bookingModalSlice.actions.setTruecallerOpen(false))
            }
            setTCLoading(false)
            console.log('truecaller hidden')
          } else {
            console.log('truecaller open')
          }
          axios({
            method: 'POST',
            url: `/auth/login-and-signup`,
            data: {
              truecaller_request_id: TC_REQUEST_ID,
              source: 'user_website',
            },
          })
            .then((res) => {
              // set into redux store
              if (
                loginModalSource !== 'booking_modal' &&
                loginModalSource !== 'booking_bartender_modal' &&
                loginModalSource !== 'booking_waiter_modal' &&
                loginModalSource !== 'booking_cleaner_modal' &&
                loginModalSource !== 'book_daily_cook_modal'
              )
                dispatch(authSlice.actions.toggleLoginModal())
              else {
                {
                  clearInterval(timer)

                  setTCLoading(true)
                }
              }
              if (loginModalSource === 'book_daily_cook_modal') {
                dispatch(authSlice.actions.authUserSet(res.data.user))
              } else {
              dispatch(authSlice.actions.authUserSet(res.data.user))
              }
              dispatch(authSlice.actions.setIsLoggedIn(true))

              // set into localstorage
              localStorage.setItem('authUser', JSON.stringify(res.data.user))
              localStorage.setItem('authToken', JSON.stringify(res.data.token))

              localStorage.setItem('accessToken', res.data.token.accessToken)
              localStorage.setItem('refreshToken', res.data.token.refreshToken)
              localStorage.setItem('expiresIn', res.data.token.expiresIn)
              localStorage.setItem('mobile', res.data.user.mobile)

              // setTCLoading(false)

              if (
                loginModalSource === 'booking_modal' ||
                loginModalSource === 'booking_bartender_modal' ||
                loginModalSource === 'booking_waiter_modal' ||
                loginModalSource === 'booking_cleaner_modal'
              ) {
                if (!res.data.user.name || !res.data.user.email) {
                  // console.log('A')
                  createBookingLead(res.data.user.mobile, true)
                  dispatch(authSlice.actions.toggleUserDetailsModal())
                  return
                } else {
                  // console.log('B')
                  createBookingLeadAndFetchUserAddresses(true)
                }
              } else if (loginModalSource === 'book_daily_cook_modal') {
                if (!res.data.data.user.name || !res.data.data.user.email) {
                  // console.log('A')
                  createBookingLead(res.data.data.user.mobile, true)
                  dispatch(authSlice.actions.toggleUserDetailsModal())
                  return
                } else {
                  // console.log('B')
                  createBookingLeadAndFetchUserAddresses(true)
                }
              } else {
                // console.log('C')
                // dispatch(authSlice.actions.toggleLoginModal())
              }

              if (
                res.data.user.total_current_bookings > 0 &&
                loginModalSource !== 'booking_modal' &&
                loginModalSource !== 'booking_bartender_modal' &&
                loginModalSource !== 'booking_waiter_modal' &&
                loginModalSource !== 'booking_cleaner_modal' &&
                loginModalSource !== 'book_daily_cook_modal'
              ) {
                router.push('/my-bookings')
              }
            })
            .catch((err) => {
              const errorMsg = err.response && err.response.data.message
              console.log('showTrueCaller', err)
              // props.setOtpError(errorMsg)
            })
        }, 3000)
        setTcLoginTimer(timer)
      }
    }, 600)
  }

  const submitOTP = () => {
    const {
      bookingDetails: {
        occasionId,
        numberOfPeople,
        bookingDate,
        bookingTime,
        meal,
        comboIds,
        numberOfBurners,
        appliances,
      },
      userAddressData: { selectedAddressId },
      designMenu: {
        cuisineIds,
        dishDetails,
        lunchDishes,
        dinnerDishes,
        breakfastDishes,
      },
    } = REDUX__bookDailyCookModalData
    if (otp.length < 4) {
      setOtpError('OTP must be 4 digits')
      return false
    }
    setOtpLoading(true)

    axios({
      method: 'POST',
      url: `/auth/login-and-signup`,
      data: {
        mobile: mobile,
        otp: String(otp),
        source: 'user_website',
      },
    })
      .then((res) => {
        // set into redux store
        dispatch(authSlice.actions.authUserSet(res.data.user))
        dispatch(authSlice.actions.setIsLoggedIn(true))

        // set into localstorage
        localStorage.setItem('authUser', JSON.stringify(res.data.user))
        localStorage.setItem('authToken', JSON.stringify(res.data.token))

        localStorage.setItem('accessToken', res.data.token.accessToken)
        localStorage.setItem('refreshToken', res.data.token.refreshToken)
        localStorage.setItem('expiresIn', res.data.token.expiresIn)
        localStorage.setItem('mobile', res.data.user.mobile)

        if (!res.data.user.name || !res.data.user.email) {
          // console.log('A')
          setOtpLoading(false)
          setShowOtpView(false)
          dispatch(authSlice.actions.toggleLoginModal())
          if (
            loginModalSource === 'booking_modal' ||
            loginModalSource === 'booking_bartender_modal' ||
            loginModalSource === 'booking_waiter_modal' ||
            loginModalSource === 'booking_cleaner_modal' ||
            loginModalSource === 'book_daily_cook_modal'
          ) {
            dispatch(authSlice.actions.toggleUserDetailsModal())
          }
          return
        } else {
          // console.log('C')
          // ie if booking lead was created on entering mobile no
          if (loginModalSource === 'booking_bartender_modal') {
            if (bookingBartenderId) {
              if (spclBartenderAppliances?.length > 0) {
                dispatch(authSlice.actions.toggleLoginModal())
                setShowOtpView(false)
                SW?.goToNamedStep('appliances')
              } else {
                axios.get(`/user/address`).then((response) => {
                  const defaultAddressId: number = (
                    response.data.addresses.find(
                      (address: IaddressFromAPI) => address.is_default === 1
                    ) || { address_id: null }
                  ).address_id

                  dispatch(
                    bookingBartenderModalSlice.actions.setUserAddressData({
                      addresses: response.data.addresses,
                      selectedAddressId: defaultAddressId,
                    })
                  )
                  if (defaultAddressId) {
                    axios({
                      method: 'POST',
                      url: `booking/bartender/summary`,
                      data: {
                        payment_option: 2,
                        address_id: defaultAddressId,
                        appliance_ids: [],
                        booking_id: bookingBartenderId,
                      },
                    })
                      .then((response) => {
                        if (response.status === 200) {
                          dispatch(
                            bookingBartenderModalSlice.actions.setBookingSummary(
                              response.data
                            )
                          )
                          return SW?.goToNamedStep('summary')
                        }
                      })
                      .catch((error) => {
                        if (error?.response?.status === 406) {
                          dispatch(
                            bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                          )
                        } else if (error?.response?.status === 500) {
                          dispatch(
                            bookingBartenderModalSlice.actions.toggleAPIAddressErrorModal()
                          )
                        }
                      })
                      .finally(() => {
                        dispatch(authSlice.actions.toggleLoginModal())
                        setShowOtpView(false)
                      })
                  } else {
                    SW?.goToNamedStep('address')
                    dispatch(authSlice.actions.toggleLoginModal())
                    setShowOtpView(false)
                  }
                })
              }
            } else {
              dispatch(authSlice.actions.toggleLoginModal())
              setShowOtpView(false)
            }
          } else if (loginModalSource === 'booking_waiter_modal') {
            if (bookingWaiterId) {
              // if (spclWaiterAppliances?.length > 0) {
              //   dispatch(authSlice.actions.toggleLoginModal())
              //   setShowOtpView(false)
              //   SW?.goToNamedStep('appliances')
              // } else {
              axios.get(`/user/address`).then((response) => {
                const defaultAddressId: number = (
                  response.data.addresses.find(
                    (address: IaddressFromAPI) => address.is_default === 1
                  ) || { address_id: null }
                ).address_id

                dispatch(
                  bookingWaiterModalSlice.actions.setUserAddressData({
                    addresses: response.data.addresses,
                    selectedAddressId: defaultAddressId,
                  })
                )
                if (defaultAddressId) {
                  axios({
                    method: 'POST',
                    url: `booking/waiter-cleaner/summary`,
                    data: {
                      address_id: defaultAddressId,
                      booking_id: bookingWaiterId,
                    },
                  })
                    .then((response) => {
                      if (response?.status === 200) {
                        dispatch(
                          bookingWaiterModalSlice.actions.setBookingSummary(
                            response.data
                          )
                        )
                        SW?.goToNamedStep('summary')
                      }
                    })
                    .catch((error) => {
                      if (error?.response?.status === 406) {
                        dispatch(
                          bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error?.response?.status === 500) {
                        dispatch(
                          bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {
                      dispatch(authSlice.actions.toggleLoginModal())
                      setShowOtpView(false)
                    })
                } else {
                  SW?.goToNamedStep('address')
                  dispatch(authSlice.actions.toggleLoginModal())
                  setShowOtpView(false)
                }
              })
              // }
            } else {
              dispatch(authSlice.actions.toggleLoginModal())
              setShowOtpView(false)
            }
          } else if (loginModalSource === 'booking_cleaner_modal') {
            if (bookingCleanerId) {
              // if (spclWaiterAppliances?.length > 0) {
              //   dispatch(authSlice.actions.toggleLoginModal())
              //   setShowOtpView(false)
              //   SW?.goToNamedStep('appliances')
              // } else {
              axios.get(`/user/address`).then((response) => {
                const defaultAddressId: number = (
                  response.data.addresses.find(
                    (address: IaddressFromAPI) => address.is_default === 1
                  ) || { address_id: null }
                ).address_id

                dispatch(
                  bookingCleanerModalSlice.actions.setUserAddressData({
                    addresses: response.data.addresses,
                    selectedAddressId: defaultAddressId,
                  })
                )

                if (defaultAddressId) {
                  axios({
                    method: 'POST',
                    url: `booking/waiter-cleaner/summary`,
                    data: {
                      address_id: defaultAddressId,
                      booking_id: bookingCleanerId,
                    },
                  })
                    .then((response) => {
                      if (response?.status === 200) {
                        dispatch(
                          bookingCleanerModalSlice.actions.setBookingSummary(
                            response.data
                          )
                        )
                        SW?.goToNamedStep('summary')
                      }
                    })
                    .catch((error) => {
                      if (error?.response?.status === 406) {
                        dispatch(
                          bookingCleanerModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error?.response?.status === 500) {
                        dispatch(
                          bookingCleanerModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {
                      dispatch(authSlice.actions.toggleLoginModal())
                      setShowOtpView(false)
                    })
                } else {
                  SW?.goToNamedStep('address')
                  dispatch(authSlice.actions.toggleLoginModal())
                  setShowOtpView(false)
                }
              })
              // }
            } else {
              dispatch(authSlice.actions.toggleLoginModal())
              setShowOtpView(false)
            }
          } else  if (loginModalSource === 'book_daily_cook_modal') {
            if (bookingDailyCookId) {
              // if (spclWaiterAppliances?.length > 0) {
              //   dispatch(authSlice.actions.toggleLoginModal())
              //   setShowOtpView(false)
              //   SW?.goToNamedStep('appliances')
              // } else {
              axios.get(`/user/address`).then((response) => {
                const defaultAddressId: number = (
                  response.data.addresses.find(
                    (address: IaddressFromAPI) => address.is_default === 1
                  ) || { address_id: null }
                ).address_id

                dispatch(
                  bookDailyCookModalSlice.actions.setUserAddressData({
                    addresses: response.data.addresses,
                    selectedAddressId: defaultAddressId,
                  })
                )

                if (defaultAddressId) {
                  axios({
                    method: 'POST',
                    url: `booking/daily-cook/summary`,
                    data: {
                      address_id: defaultAddressId,
                      booking_id: bookingDailyCookId,
                    },
                  })
                    .then((response) => {
                      if (response?.status === 200) {
                        dispatch(
                          bookDailyCookModalSlice.actions.setBookingSummary(
                            response.data
                          )
                        )
                        SW?.goToNamedStep('summary')
                      }
                    })
                    .catch((error) => {
                      if (error?.response?.status === 406) {
                        dispatch(
                          bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      } else if (error?.response?.status === 500) {
                        dispatch(
                          bookDailyCookModalSlice.actions.toggleAPIAddressErrorModal()
                        )
                      }
                    })
                    .finally(() => {
                      dispatch(authSlice.actions.toggleLoginModal())
                      setShowOtpView(false)
                    })
                } else {
                  SW?.goToNamedStep('address')
                  dispatch(authSlice.actions.toggleLoginModal())
                  setShowOtpView(false)
                }
              })
              // }
            } else {
              dispatch(authSlice.actions.toggleLoginModal())
              setShowOtpView(false)
            }
          } else {
            if (bookingId) {
              if (spclAppliances.length > 0) {
                dispatch(authSlice.actions.toggleLoginModal())
                setShowOtpView(false)
                SW?.goToNamedStep('appliances')
              } else {
                axios.get(`/user/address`).then((response) => {
                  const defaultAddressId: number = (
                    response.data.addresses.find(
                      (address: IaddressFromAPI) => address.is_default === 1
                    ) || { address_id: null }
                  ).address_id

                  dispatch(
                    bookingModalSlice.actions.setUserAddressData({
                      addresses: response.data.addresses,
                      selectedAddressId: defaultAddressId,
                    })
                  )
                  // dispatch(authSlice.actions.toggleLoginModal())
                  // setShowOtpView(false)
                  if (defaultAddressId) {
                    const dishObjFunc = (dish: any) => ({
                      id: dish.id,
                      meal_type: dish.meal_type,
                    })
                    const lunchDishes2 = lunchDishes?.map(dishObjFunc)
                    const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
                    const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
                    const dishDetails2 = dishDetails?.map(dishObjFunc)
                    const numberOfburner = parseInt(numberOfBurners, 10)
                    let appID = []
                    if (appliances) {
                      appID = [...appliances]
                    }
                    if (numberOfburner === 1) {
                      appID.push(41)
                    } else if (numberOfburner == 2) {
                      appID.push(35)
                    } else if (numberOfburner == 3) {
                      appID.push(36)
                    } else if (numberOfburner == 4) {
                      appID.push(37)
                    } else if (numberOfburner == 5) {
                      appID.push(38)
                    } else if (numberOfburner == 6) {
                      appID.push(42)
                    }
                    // setLoading(true)
                    axios({
                      method: 'POST',
                      url: `/booking/summary`,
                      data: {
                        cuisines: cuisineIds,
                        occasion_id: occasionId,
                        cuisine_combos: comboIds,
                        number_of_people: numberOfPeople,
                        booking_date: bookingDate,
                        booking_time: bookingTime,
                        dish_details:
                          meal === 'lunch' ||
                          meal === 'dinner' ||
                          meal === 'breakfast'
                            ? dishDetails2
                            : breakfastDishes2
                                ?.concat(lunchDishes2)
                                ?.concat(dinnerDishes2),

                        payment_option: 2,
                        appliance_ids: appID, //add comma separated appliance ids in this array
                        address_id: defaultAddressId,
                        booking_id: bookingId,
                        booking_type: meal,
                        // upgrade_booking: upgradeBooking,
                      },
                    })
                      .then((response) => {
                        if (response.status === 200) {
                          dispatch(
                            bookingModalSlice.actions.setBookingSummary(
                              response.data.data
                            )
                          )
                          SW?.goToNamedStep('summary')
                        }
                      })
                      .catch((error) => {
                        if (error.response.status === 406) {
                          dispatch(
                            bookingModalSlice.actions.toggleAPIAddressErrorModal()
                          )
                        } else if (error.response.status === 500) {
                          dispatch(
                            bookingModalSlice.actions.toggleAPIAddressErrorModal()
                          )
                        }
                      })
                      .finally(() => {
                        dispatch(authSlice.actions.toggleLoginModal())
                        setShowOtpView(false)

                        // setLoading(false)
                      })
                    // callback && callback('summary')
                  } else {
                    SW?.goToNamedStep('address')
                    dispatch(authSlice.actions.toggleLoginModal())
                    setShowOtpView(false)
                  }
                })
              }
            } else {
              dispatch(authSlice.actions.toggleLoginModal())
              setShowOtpView(false)
            }
          }
        }

        if (
          res.data.user.total_current_bookings > 0 &&
          loginModalSource !== 'booking_modal' &&
          loginModalSource !== 'booking_bartender_modal' &&
          loginModalSource !== 'booking_waiter_modal' &&
          loginModalSource !== 'booking_cleaner_modal' &&
          loginModalSource !== 'book_daily_cook_modal'
        ) {
          router.push('/my-bookings')
        }
      })
      .catch((err) => {
        console.log('submitOTP', err)
        const errorMsg = err.response && err.response.data.message
        if (err.response?.status === 401) setOtpError('Incorrect OTP')
        else setOtpError(errorMsg)
        setOtpLoading(false)
      })
  }

  const hideTrueCaller = () => {
    // console.log('hideTrueCaller', tcLoginTimer)
    setTCLoading(false)
    clearInterval(tcLoginTimer)
  }

  //SUBMIT MOBILE NUMBER
  const submitMobile = (mobile) => {
    if (mobile.length < 10) {
      setMobileError('Mobile number must be at least 10 digits')
      return false
    }

    setMobileLoading(true)
    setTempMobile(String(mobile))
    // donot call generate otp when logging from booking modal flow
    if (
      loginModalSource !== 'booking_modal' &&
      loginModalSource !== 'booking_bartender_modal' &&
      loginModalSource !== 'booking_waiter_modal' &&
      loginModalSource !== 'booking_cleaner_modal' &&
      loginModalSource !== 'book_daily_cook_modal'
    ) {
      submitMobileToAPI(String(mobile))
        .then(() => {
          setShowOtpView(true)
          setMobileError('')
        })
        .catch((err) => {
          const errorMsg = err.response && err.response.data.message
          setMobileError(errorMsg)
        })
        .finally(() => setMobileLoading(false))
    } else {
      setMobileError('')
      createBookingLead(mobile)
    }
  }

  const submitMobileToAPI = (mobile: string) => {
    if (
      loginModalSource !== 'booking_modal' &&
      loginModalSource !== 'booking_bartender_modal' &&
      loginModalSource !== 'booking_waiter_modal' &&
      loginModalSource !== 'booking_cleaner_modal' &&
      loginModalSource !== 'book_daily_cook_modal'
    ) {
      return axios({
        method: 'POST',
        url: `/auth/generate-otp`,
        data: {
          source: 'user_website',
          mobile,
        },
      })
    }
  }

  const toggleLoginModal = () => {
    dispatch(authSlice.actions.toggleLoginModal())
  }

  if (
    loginModalSource === 'booking_modal' ||
    loginModalSource === 'booking_bartender_modal' ||
    loginModalSource === 'booking_waiter_modal' ||
    loginModalSource === 'booking_cleaner_modal'||
    loginModalSource === 'book_daily_cook_modal'
  ) {
    return (
      <Modal
        show={showLoginModal}
        onHide={toggleLoginModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        scrollable
        dialogClassName="modal-dialog-wm"
        onEnter={showTrueCaller}
        onExit={hideTrueCaller}
      >
        <Modal.Header
          closeButton
          style={{ padding: '0.6rem 1rem 0.2rem 1rem' }}
        >
          {showOtpView ? (
            <button
              className="p-0 m-0 btn btn-white"
              onClick={() => setShowOtpView(false)}
            >
              <FaArrowLeft />
            </button>
          ) : (
            <button
              className="p-0 m-0 btn btn-white"
              onClick={toggleLoginModal}
            >
              <FaArrowLeft />
            </button>
          )}
          <Modal.Title className="w-100">
            <span className="m-0">
              <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
                <b>Basic Details</b>
              </p>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showOtpView ? (
            <EnterOtpView
              tempMobile={tempMobile}
              submitMobile={submitMobile}
              submitOTP={submitOTP}
              otpError={otpError}
              setShowOtpView={setShowOtpView}
            />
          ) : (
            <EnterMobileView
              mobileError={mobileError}
              submitMobile={submitMobile}
              setMobileError={setMobileError}
            />
          )}
        </Modal.Body>

        <Modal.Footer className="pt-0">
          <ChatOnWhatsAppText activeStep={4} />
          <CTAhelper
            showOtpView={showOtpView}
            submitMobile={submitMobile}
            mobileLoading={mobileLoading}
            submitOTP={submitOTP}
            otpLoading={otpLoading}
          />
        </Modal.Footer>
        {tcLoading && (
          <div
            className="overlay dark"
            style={{
              backgroundColor: 'rgba(0,0,0,.5)',
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 50,
            }}
          >
            <HashLoader size={60} color={'#F0C600'} />
          </div>
        )}
      </Modal>
    )
  } else {
    return (
      <Modal
        show={showLoginModal}
        scrollable
        onHide={toggleLoginModal}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        style={{ overflowY: 'hidden' }}
        onEnter={showTrueCaller}
        onExit={hideTrueCaller}
      >
        <Modal.Header closeButton>
          {showOtpView ? (
            <button
              className="p-0 m-0 btn btn-white"
              onClick={() => setShowOtpView(false)}
            >
              <FaArrowLeft />
            </button>
          ) : null}
        </Modal.Header>
        <Modal.Body>
          <Container>
            {showOtpView ? (
              <EnterOtpView
                tempMobile={tempMobile}
                submitMobile={submitMobile}
                submitOTP={submitOTP}
                setShowOtpView={setShowOtpView}
                otpError={otpError}
              />
            ) : (
              <EnterMobileView
                mobileError={mobileError}
                submitMobile={submitMobile}
                setMobileError={setMobileError}
              />
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <CTAhelper
            showOtpView={showOtpView}
            submitMobile={submitMobile}
            mobileLoading={mobileLoading}
            submitOTP={submitOTP}
            otpLoading={otpLoading}
          />
        </Modal.Footer>
        {tcLoading && (
          <div
            className="overlay dark"
            style={{
              backgroundColor: 'rgba(0,0,0,.5)',
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              zIndex: 50,
            }}
          >
            <HashLoader size={60} color={'#F0C600'} />
          </div>
        )}
      </Modal>
    )
  }
}

export default LoginModal
