// STYLES
import styles from './index.module.css'
import Image from 'next/image'
// BOOTSTRAP
import { Row, Col, Container } from 'react-bootstrap'

// STATIC DATA
import { bookingHowItWorksData } from '@/staticData/how-it-works-bartender.data'

import Gallery from 'react-photo-gallery'

// DATA
import { photos } from '@/staticData/booking-bartender-modal-glimpses.data'

// TS INTERFACES
import { SWtype } from '@/components/BookingModals/BookChefModal/commonInterfaces'

const BookingHowItWorks = ({ SW }: { SW: SWtype | null }): JSX.Element => {
  return (
    <>
      <Row>
        <Col className="pl-0">
          <p
            style={{ paddingLeft: '1%', fontSize: '17.5px' }}
            className="text-muted"
          >
            How It Works:
          </p>
        </Col>
      </Row>
      <Row
        className="mb-3"
        onClick={() => {
          SW.nextStep()
        }}
      >
        <Col className="d-inline-flex pl-2">
          <Image
            src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/customMade.svg"
            alt="Choose your Menu"
            width="40px"
            height="40px"
            // unoptimized={true}
          />
          <p className="text-muted ml-3">Select Drinks Menu</p>
        </Col>
      </Row>
      {bookingHowItWorksData.map(({ imgSrc, point }) => (
        <Row key={point} className="mb-3">
          <Col className="d-inline-flex pl-2">
            <Image
              src={imgSrc}
              alt={point}
              width="40px"
              height="40px"
              // unoptimized={true}
            />
            <p className="text-muted ml-3">{point}</p>
          </Col>
        </Row>
      ))}
      <div className="mt-3">
        <Gallery photos={photos} />
      </div>
    </>
  )
}

export default BookingHowItWorks
