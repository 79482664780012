// BOOTSTRAP
import { Dispatch, SetStateAction } from 'react'
import { Container, Modal, Row, Col, Button } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'
// STYLES
import styles from './index.module.css'

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const GreyTick = () => (
  <img src={tickMark} alt="tick" className={styles.grey__tick} />
)

interface Props {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

const AddAssisantCookModal = (props: Props): JSX.Element => {
  // const { show, setShow, upgradeLoading, upgradeBooking } = props
  const { show, setShow, addAssistantLoading, setAddAssistantLoading, addAssistantBooking } = props
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      scrollable
      backdrop="static"
      dialogClassName="modal-dialog-wm"
    >
      <Modal.Header closeButton>
        <button
          onClick={() => setShow(false)}
          className="btn btn-white m-0 p-0"
        >
          <FaArrowLeft />
        </button>
        <Modal.Title className="w-100">
          <span className="m-0">
            <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
              <b>Add Assistant Cook</b>
            </p>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="mt-5">
            <Col >
              <div className="text-center">
                <img
                  style={{ borderRadius: '50%' }}
                  src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/default/user.jpg"
                  width="120px"
                />
              </div>
              <br />
              <div className="text-center">
                <h2 className="DARK__GREY__COLOR">Assistant Cook</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}></Col>
            <Col className={` mt-5`}>
              <GreyTick /> helps in basic cooking
            </Col>
          </Row>
          <Row>
            <Col lg={4}></Col>
            <Col className={` mt-3`}>
              <GreyTick /> does not wash utensils, lay the table or serve
            </Col>
          </Row>
          <Row>
            <Col lg={4}></Col>
            <Col className={` mt-3`}>
              <GreyTick /> You can book waiter, cleaner, bartender separately for that
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col>
          <Button
            variant="light"
            type="button"
            block
            style={{color:'black', border: '2px solid #fcba03'}}
            onClick={() => setShow(false)}
            className="d-inline-flex align-items-center justify-content-center"
          >
            Close
          </Button>
          </Col>
          <Col>
          <Button
            variant="warning"
            type="button"
            block
            onClick={() => {
              addAssistantBooking(true)
            }}
            disabled={addAssistantLoading}
            className="d-inline-flex align-items-center justify-content-center"
          >
            {addAssistantLoading
              ? 'Adding Assistant Cook...'
              : 'Add For ₹799'}
          </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default AddAssisantCookModal
