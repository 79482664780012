import { ChangeEvent, useState, useEffect } from 'react'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Modal, Button, Row, Col } from 'react-bootstrap'

// COMPONENTS
import EditAddress from '../EditAddress'
import FakeCheckbox from '../FakeCheckbox'

// AXIOS
import axios from 'axios'

// REACT ICONS
import { FaArrowLeft, FaTrash } from 'react-icons/fa'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

interface Props {
  address_id: number | null
  house_number: string | null | undefined
  street: string | null | undefined
  city: null | string
  state: null | string
  country: null | string
  index: number
  REDUX__bookingModalData: any
}

const AddressList = (props: Props): JSX.Element => {
  const { address_id, house_number, street, city, state, country, REDUX__bookingModalData } = props

  // Initializing dispatch
  const dispatch = useDispatch()

  // // Fetching data from global REDUX State
  // const REDUX__bookingModalData = useSelector(
  //   (state: RootState) => state.bookingBartenderModal
  // )

  const {
    userAddressData: { selectedAddressId },
    userEditAddressData: {
      house_number: REDUX__houseNumber,
      street: REDUX__street,
      city: REDUX__city,
      state: REDUX__state,
      country: REDUX__country,
    },
  } = REDUX__bookingModalData

  const handleBookingAddressInputChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    props.handleBookingAddressInputChange(event.target.value)
  }

  const [loading, setLoading] = useState(false)

  const onSubmit = () => {
    const isValid = props.validate(REDUX__houseNumber, REDUX__street, REDUX__city)
    if (isValid) {
      setLoading(true)
      // updating user address
      const addressId = props.address_id

      const updatedAddress = {
        address_id: addressId,
        house_number: REDUX__houseNumber,
        street: REDUX__street,
        city: REDUX__city,
        state: REDUX__state,
        country: REDUX__country,
      }
      props.onSubmitHandler()
      axios({
        method: 'PUT',
        url: `/user/address/${addressId}`,
        data: {
          house_number: REDUX__houseNumber,
          street: REDUX__street,
          city: REDUX__city,
          state: REDUX__state,
          country: REDUX__country,
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      })
        .then(() => {
          handleEditClose()
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const [showEditAddressModal, setShowEditAddressModal] = useState(false)

  const handleEditClose = () => {
    setShowEditAddressModal(false)
    props.handleClose()
  }

  const [deleteShow, setDeleteShow] = useState(false)
  const [yesBtnDisabled, setYesBtnDisabled] = useState(false)
  const handleDeleteClose = () => setDeleteShow(false)
  const handleDeleteShow = () => setDeleteShow(true)

  const handleDeleteAddress = (e, address_id) => {
    e.preventDefault()
    setYesBtnDisabled(true)
    const addressId = address_id
    // delete user address
    axios({
      method: 'DELETE',
      url: `/user/address/${addressId}`,

      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
      .then((response) => {
        alert('Address Deleted!')
        props.onDeleteAddress(addressId)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    // Set to Redux State if showEditAddressModal is true

    if (showEditAddressModal) {
      props.instantSaveAddress(house_number, street, city, state, country)
    }
  }, [showEditAddressModal])

  return (
    <div className={styles.options}>
      <div className="row align-items-center">
        <div className="col col-2">
          <label htmlFor={`address_id${address_id}`} className="d-inline">
            <Row>
              <Col>
                <FakeCheckbox
                  name="address"
                  id={`address_id${address_id}`}
                  value={address_id ?? ''}
                  onChange={handleBookingAddressInputChange}
                  checked={selectedAddressId == address_id}
                />
              </Col>
            </Row>
          </label>
        </div>
        <div className="col col-6">
          <span>
            {house_number}
            {!!house_number && <>,</>} {street}
            {!!street && <>,</>} {city}, {state}
          </span>
        </div>
        <div className="col col-4">
          <FaTrash className={styles.FaTrash} onClick={handleDeleteShow} />
        </div>
      </div>

      <br />
      <br />
      <Modal
        show={showEditAddressModal}
        onHide={handleEditClose}
        size="lg"
        scrollable
        backdrop="static"
      >
        <Modal.Header closeButton>
          <button onClick={handleEditClose} className="btn btn-white m-0 p-0">
            <FaArrowLeft />
          </button>
          <Modal.Title className="w-100">
            <span className="m-0">
              <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
                <b>Edit Address</b>
              </p>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditAddress />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={onSubmit} disabled={loading} block>
            Save
            {loading ? '...' : null}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Address Modal */}
      <Modal
        show={deleteShow}
        onHide={handleDeleteClose}
        centered
        style={{ backgroundColor: 'rgba(128,128,128,0.3)' }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <center>
            Are you sure you want to <b>delete</b> this address?
          </center>
        </Modal.Body>
        <div
          style={{
            textAlign: 'center',
            display: 'inline-block',
          }}
        >
          <Button
            variant="danger m-2"
            onClick={(e) => handleDeleteAddress(e, address_id)}
            disabled={yesBtnDisabled}
          >
            Yes{yesBtnDisabled ? '...' : null}
          </Button>
          <Button variant="outline-dark m-2" onClick={handleDeleteClose}>
            No
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AddressList
