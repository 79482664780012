import { useRouter } from 'next/router'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Button } from 'react-bootstrap'

// REDUX
import { useDispatch } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'
// GTM
import { gtmEvent } from '@/lib/gtm'

const FloatingBookNowBtn = (): JSX.Element => {
  const { pathname } = useRouter()
  const dispatch = useDispatch()

  if (
    pathname.includes('register-as-cook') ||
    pathname.includes('register-as-cook-hindi') ||
    pathname.includes('hiring') ||
    pathname.includes('my-bookings')
  ) {
    return <></>
  }
  return (
    <div className={`p-3 m-0 ${styles.floating__btn__container}`}>
      <Button
        size="lg"
        className={`m-2 px-2 font-weight-bolder ${styles.floating__btn__border} ${styles.floating__btn}  ${styles.font__size__20}`}
        variant="warning"
        onClick={() => {
          if (pathname.includes('bartender')) {
            gtmEvent('book-bartender-flow', {
              step: 'how-it-works',
              type: 'floating',
            })
            dispatch(
              bookingBartenderModalSlice.actions.toggleBookingBartenderModal()
            )
          } else if (pathname.includes('waiter')) {
            gtmEvent('book-waiter-flow', {
              step: 'how-it-works',
              type: 'floating',
            })
            dispatch(bookingWaiterModalSlice.actions.toggleBookingWaiterModal())
          } else if (pathname.includes('cleaner')) {
            gtmEvent('book-cleaner-flow', {
              step: 'how-it-works',
              type: 'floating',
            })
            dispatch(
              bookingCleanerModalSlice.actions.toggleBookingCleanerModal()
            )
          } else if (pathname === '/') {
            dispatch(apiDataSlice.actions.setAskServiceModal(true))
          } else if (pathname.includes('gallery')) {
            dispatch(apiDataSlice.actions.setAskServiceModal(true))
          } else {
            gtmEvent('book-chef-flow', {
              step: 'how-it-works',
              type: 'floating',
            })
            dispatch(bookingModalSlice.actions.toggleBookingModal())
          }
        }}
      >
        Book Now<span className={styles.overlay}></span>
      </Button>
    </div>
  )
}

export default FloatingBookNowBtn
