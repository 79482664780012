import Head from 'next/head'

import { useState, useContext, useEffect } from 'react'

// BOOTSTRAP
import { Modal } from 'react-bootstrap'

// STYLES
import styles from './index.module.css'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'

// ICONS
import { FaArrowLeft, FaTimes } from 'react-icons/fa'

// STEP WIZARD
import StepWizard from 'react-step-wizard'

// HELPERS
import { BookingModalHeading, CTAHelper } from './helpers'

// COMPONENTS
import ChatOnWhatsAppText from '../../ChatOnWhatsAppText'
import ExitAlertModal from './ExitAlertModal'

// STEPS
import {
  HowItWorks,
  Details,
  // DesignMenu,
  // RequiredAppliances,
  Address,
  Summary,
} from './steps'

// CONTEXT
import { SWContext } from 'pages/_app'

// INTERFACES
import { SWtype } from './commonInterfaces'
import { RootState } from '@/lib/rootState'

// GTM
import { gtmEvent } from '@/lib/gtm'

const transitions = {
  enterRight: 'none',
  enterLeft: 'none',
  exitRight: 'none',
  exitLeft: 'none',
}

const bookingSteps = {
  1: 'details',
  2: 'address',
  3: 'summary',
}

const BookDailyCookModal = (): JSX.Element => {
  const SWContextData = useContext(SWContext)
  const [loading, setLoading] = useState(false)

  const [SW, setSW] = useState<SWtype | null>(null)
  const [activeStep, setActiveStep] = useState<number>(1)
  const [showExitAlertModal, setShowExitAlertModal] = useState<boolean>(false)

  const dispatch = useDispatch()

  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookDailyCookModal
  )

  const {
    userAddressData: { addresses, selectedAddressId },
    showBookDailyCookModal,
    truecallerOpen,
    paymentOpen,
  } = REDUX__bookingModalData

  const onWizardStepChange = (updatedSW: {
    previousStep: number
    activeStep: number
  }) => {
    // Scroll to top on every step change in Modal
    const modalBodyDomNode = document.getElementById(
      'BOOKWAITER___MODAL___BODY'
    )

    if (modalBodyDomNode) {
      modalBodyDomNode.scrollTop = 0
    }

    setActiveStep(updatedSW.activeStep)

    gtmEvent('book-waiter-flow', { step: bookingSteps[updatedSW.activeStep] })
  }

  const setWizardInstance = (SWinstance) => {
    // Setting in local State
    setSW({
      nextStep: SWinstance.nextStep,
      previousStep: SWinstance.previousStep,
      goToStep: SWinstance.goToStep,
      goToNamedStep: SWinstance.goToNamedStep,
      activeStep: SWinstance.state.activeStep,
    })

    // Set in context
    SWContextData.updateSWinContext({
      nextStep: SWinstance.nextStep,
      previousStep: SWinstance.previousStep,
      goToStep: SWinstance.goToStep,
      goToNamedStep: SWinstance.goToNamedStep,
      activeStep: SWinstance.state.activeStep,
    })
  }

  const goBackOnBookingModal = () => {
    if (loading) return false

    if (activeStep === 1) {
      setShowExitAlertModal(true)
    }
    if (selectedAddressId && activeStep === 4) {
      SW?.goToNamedStep('details')
    } else SW?.previousStep()
  }

  useEffect(() => {
    const beforeUnload = (e) => {
      if (showBookDailyCookModal && !truecallerOpen && !paymentOpen) {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        return (e.returnValue = 'Are you sure?')
      }
    }

    window.addEventListener('beforeunload', beforeUnload)

    return () => window.removeEventListener('beforeunload', beforeUnload)
  }, [showBookDailyCookModal, truecallerOpen, paymentOpen])

  return (
    <>
      <Head>
        {/* ---------------- LOADING PAYTM SCRIPT ---------------- */}

        <script
          type="application/javascript"
          src="https://checkout.razorpay.com/v1/checkout.js"
          crossOrigin="anonymous"
        />
        {/* ---------------- LOADING PAYTM SCRIPT ---------------- */}
      </Head>
      <Modal
        show={showBookDailyCookModal}
        onHide={() =>
          dispatch(bookDailyCookModalSlice.actions.toggleBookDailyCookModal())
        }
        keyboard={false}
        size="lg"
        scrollable
        backdrop="static"
        dialogClassName="modal-dialog-wm"
      >
        <Modal.Header style={{ padding: '0.6rem 1rem 0.2rem 1rem' }}>
          <FaArrowLeft
            onClick={goBackOnBookingModal}
            className={styles.cursor__pointer}
          />

          <Modal.Title className="w-100">
            <span className="m-0">
              <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
                <b>
                  <BookingModalHeading step={activeStep} />
                </b>
              </p>
            </span>
          </Modal.Title>
          <FaTimes
            onClick={() => setShowExitAlertModal(true)}
            className={styles.cursor__pointer}
          />
        </Modal.Header>

        <Modal.Body id="BOOKWAITER___MODAL___BODY">
          <StepWizard
            initialStep={1}
            instance={setWizardInstance}
            transitions={transitions}
            onStepChange={onWizardStepChange}
          >
            <Details stepName={'details'} />
            <Address SW={SW} stepName={'address'} />
            <Summary
              proceedingToNextStep={loading}
              SW={SW}
              stepName={'summary'}
            />
          </StepWizard>
        </Modal.Body>
        <Modal.Footer style={{ padding: '0.08rem .75rem .3rem .75rem' }}>
          <ChatOnWhatsAppText activeStep={activeStep} />
          <CTAHelper
            activeStep={activeStep}
            SW={SW}
            loading={loading}
            setLoading={setLoading}
          />
        </Modal.Footer>
      </Modal>

      <ExitAlertModal
        show={showExitAlertModal}
        setShow={setShowExitAlertModal}
        setActiveStep={setActiveStep}
      />
    </>
  )
}

export default BookDailyCookModal
