const wrapS3URL = (glimpseName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/${glimpseName}.jpg`
}

interface Iphotos {
  src: string
  alt: string
  width: number
  height: number
}

export const photos: Array<Iphotos> = [
  {
    src: wrapS3URL('glimpse16'),
    alt: 'COOX Cleaner washing dishes and clearing slab. Book Cleaners online',
    width: 3,
    height: 4,
  },
  {
    src:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/cleaner10.jpeg', //barbeque
    alt: 'COOX Cleaners family pictures with our customers. Hire professional cleaner near me',
    width: 3,
    height: 4,
  },
  // {
  //   src: wrapS3URL('glimpse17'), //family
  //   alt: 'COOX Cleaners family pictures with our customers. Hire professional cleaner near me',
  //   width: 5,
  //   height: 4,
  // },
  {
    src: "https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/cleaner7.jpg", //family
    alt: 'COOX Cleaners family pictures with our customers. Hire professional cleaner near me',
    width: 3,
    height: 4,
  },
  {
    src: "https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/cleaner8.jpg",
    alt: 'COOX Cleaners family pictures with our customers. Hire professional cleaner near me',
    width: 3,
    height: 4,
  },
  {
    src: "https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/gallery/cleaner9.png",
    alt: 'COOX Cleaners family pictures with our customers. Hire professional cleaner near me',
    width: 3,
    height: 4,
  },
]

///
