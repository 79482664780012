import { addressModalType } from '@/components/MyBookings/Modals/AddressModal'
import { IeventType } from '@/types/myBookingsTypes'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../rootState'

const initialState = {
  menuDetails: {
    number_of_people: 0,
    number_of_adults: 0,
    number_of_children: 0,
    number_of_veg_people: 0,
    number_of_nonveg_people: 0,
    use_onion: true,
    use_garlic: true,
    use_potato: true,
    booking_dishes:
      [] as RootState['myBookings']['menuDetails']['booking_dishes'],
  },
  paymentOpen: false,
  bookingDetails: {
    upgradeBooking: false,
  },
  updatedMenuDetails: [],
  bookingModifications: [],
  citiesList: [],
  paymentOpen: false,
  appliancesDetails: {
    gas_hob_described: false,
    appliances:
      [] as RootState['myBookings']['appliancesDetails']['appliances'],
    addDishAppliances:
      [] as RootState['myBookings']['appliancesDetails']['addDishAppliances'],
    replaceDishAppliances:
      [] as RootState['myBookings']['appliancesDetails']['replaceDishAppliances'],
    address_id: 0,
    preFilledAppliances:
      [] as RootState['myBookings']['appliancesDetails']['appliances'],
  },
  showBookingsType: 'current',
  selectedBookingId: null,
  modal: {
    showAddressModal: false,
    addressModalType: '',

    eventKey: '',
    showEditTimeModal: false,
    showAddPeopleModal: false,
    showEditPeopleModal: false,
    showAddHoursModal: false,
    showAddOnServicesModal: false,
    showCancelRescheduleModal: false,
    showReactivateBookingModal: false,
    showConfirmReactivateBookingModal: false,
    showBookingRatingModal: false,
    showConfirmAlertModal: false,
    showBuyExtensionModal: false,
    showAddCombosModal: false,
  },
}

const myBookingsSlice = createSlice({
  name: 'myBookings',
  initialState,
  reducers: {
    setMenuApiState: (
      state,
      data: { payload: RootState['myBookings']['menuDetails'] }
    ) => {
      state = {
        ...state,
        menuDetails: {
          ...state.menuDetails,
          ...data.payload,
        },
      }
      return state
    },
    setAppliancesApiState: (
      state,
      data: {
        payload: {
          gas_hob_described: boolean
          appliances: RootState['myBookings']['appliancesDetails']['appliances']
          address_id: number
        }
      }
    ) => {
      state.appliancesDetails.gas_hob_described = data.payload.gas_hob_described
      state.appliancesDetails.appliances = data.payload.appliances
      state.appliancesDetails.address_id = data.payload.address_id
    },
    setUpgradeBooking: (state, data) => {
      state.bookingDetails.upgradeBooking = data.payload
    },
    setCitiesList: (state, data) => {
      state.citiesList = data.payload
    },
    setBookingModifications: (state, data) => {
      state.bookingModifications = data.payload
    },
    setPaymentOpen: (state, data) => {
      state.paymentOpen = data.payload
    },
    setUpdatedMenuDetails: (state, data) => {
      state.updatedMenuDetails = data.payload
    },
    setNoOfPeople: (state, data) => {
      state.menuDetails.number_of_people = data.payload
    },
    setNoOfAdults: (state, data) => {
      state.menuDetails.number_of_adults = data.payload
    },
    setNoOfChildren: (state, data) => {
      state.menuDetails.number_of_children = data.payload
    },
    setNoOfVegPeople: (state, data) => {
      state.menuDetails.number_of_veg_people = data.payload
    },
    setNoOfNonVegPeople: (state, data) => {
      state.menuDetails.number_of_nonveg_people = data.payload
    },
    setUseOnion: (state, data: { payload: boolean }) => {
      state.menuDetails.use_onion = data.payload
    },
    setUseGarlic: (state, data) => {
      state.menuDetails.use_garlic = data.payload
    },
    setUsePotato: (state, data: { payload: boolean }) => {
      state.menuDetails.use_potato = data.payload
    },
    setShowBookingsType: (state, data: { payload: 'current' | 'past' }) => {
      state.showBookingsType = data.payload
    },
    setSelectedBookingId: (state, data: { payload: number | null }) => {
      state.selectedBookingId = data.payload as any
    },
    setPaymentOpen: (state, data) => {
      state.paymentOpen = data.payload
    },
    setpreFilledAppliances: (state, data) => {
      state.appliancesDetails.preFilledAppliances = data.payload
    },
    setaddDishAppliances: (state, data) => {
      state.appliancesDetails.addDishAppliances = data.payload
    },
    setReplaceDishAppliances: (state, data) => {
      state.appliancesDetails.replaceDishAppliances = data.payload
    },
    updateBookingDish: (
      state,
      data: {
        payload: {
          dish_id: number
          meal_type: string | undefined
          key: 'served_at' | 'instructions'
          value: any
        }
      }
    ) => {
      const bookingDishes = state.menuDetails.booking_dishes.map((dish) => {
        if (
          dish.dish_id === data.payload.dish_id &&
          dish.meal_type === data.payload.meal_type
        ) {
          return {
            ...dish,
            [data.payload.key]: data.payload.value,
          }
        }
        return dish
      })
      state.menuDetails.booking_dishes = bookingDishes
    },
    updateApplianceAvailability: (
      state,
      data: {
        payload: { appliance_id: number; available: boolean }
      }
    ) => {
      const appliances = state.appliancesDetails.appliances
      appliances.map((appliance) => {
        if (appliance.appliance_id === data.payload.appliance_id) {
          appliance.available = data.payload.available
        }
        return appliance
      })
      state.appliancesDetails.appliances = appliances
    },
    setShowAddressModal: (
      state,
      data: { payload: { show: boolean; type: addressModalType | '' } }
    ) => {
      state.modal.showAddressModal = data.payload.show
      state.modal.addressModalType = data.payload.type
    },
    setEventKey: (state, data: { payload: IeventType | '' }) => {
      state.modal.eventKey = data.payload
    },
    setShowAddPeopleModal: (state, data: { payload: boolean }) => {
      state.modal.showAddPeopleModal = data.payload
    },
    setShowUpgradeBookingModal: (state, data: { payload: boolean }) => {
      state.modal.showUpgradeBookingModal = data.payload
    },
    setShowEditPeopleModal: (state, data: { payload: boolean }) => {
      state.modal.showEditPeopleModal = data.payload
    },
    setShowEditTimeModal: (state, data: { payload: boolean }) => {
      state.modal.showEditTimeModal = data.payload
    },
    setShowAddHoursModal: (state, data: { payload: boolean }) => {
      state.modal.showAddHoursModal = data.payload
    },
    setShowAddOnServicesModal: (state, data: { payload: boolean }) => {
      state.modal.showAddOnServicesModal = data.payload
    },
    setShowCancelRescheduleModal: (state, data: { payload: boolean }) => {
      state.modal.showCancelRescheduleModal = data.payload
    },
    setShowBuyExtensionModal: (state, data: { payload: boolean }) => {
      state.modal.showBuyExtensionModal = data.payload
    },
    setShowConfirmAlertModal: (state, data: { payload: boolean }) => {
      state.modal.showConfirmAlertModal = data.payload
    },
    setShowReactivateBookingModal: (state, data: { payload: boolean }) => {
      state.modal.showReactivateBookingModal = data.payload
    },
    setShowConfirmReactivateBookingModal: (
      state,
      data: { payload: boolean }
    ) => {
      state.modal.showConfirmReactivateBookingModal = data.payload
    },
    setShowBookingRatingModal: (state, data: { payload: boolean }) => {
      state.modal.showBookingRatingModal = data.payload
    },
    setShowAddCombosModal: (state, data: { payload: boolean }) => {
      state.modal.showAddCombosModal = data.payload
    },
  },
})

export default myBookingsSlice
