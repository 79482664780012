import React, { useState, useEffect } from 'react'
import Select, { ActionMeta } from 'react-select'

// STYLES
import styles from '../../index.module.css'

// DAYJS
import dayjs from 'dayjs'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'

// SHIMMER LOADER
import ShimmerLoader from './ShimmerLoader/index'

// INTERFACE
import { RootState } from '@/lib/rootState'

// AXIOS
import axios from 'axios'

// HELPERS
import {
  // PreferenceType,
  BurnerType,
  // , Iburner
} from 'types/bookingModalTypes'
import { Col, Row } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'

const BookingDetails = (): JSX.Element => {
  const [setLunchMenu, setShowLunch] = useState(false)
  const [setBreakfastMenu, setShowBreakfast] = useState(false)
  const [setDinnerMenu, setShowDinner] = useState(false)

  const dispatch = useDispatch()

  const REDUX__bookingDetails = useSelector(
    (state: RootState) => state.bookDailyCookModal.bookingDetails
  )

  const initialBookingDataState = {
    occasionId: REDUX__bookingDetails.occasionId || null,
    numberOfPeople: '',
    bookingDate: '',
    bookingTime: '',
    booking_type: '',
    meal: '',
    is_veg: 'nonveg',
    numberOfBurners: '',
    bookingLevel: 'DailyCook',
    // comboId: 0,
    comboIds: [],
    is_fried: 'yes',
  }
  const [data, setData] = useState(initialBookingDataState)

  useEffect(() => {
    dispatch(bookDailyCookModalSlice.actions.setBookingDetails(data))
  }, [data])

  const tickMark =
    'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

  const [specialDates, setSpecialDates] = useState([])
  const combos = useSelector((state: RootState) => state.apiData.combos)
  const occasionsState = useSelector(
    (state: RootState) => state.apiData.occasions
  )

  useEffect(() => {
    axios.get('/admin/booking/special-date').then((res) => {
      const specialDates = res.data.special_dates.map((dateObj: any) => ({
        ...dateObj,
        date: dayjs(dateObj.date).format('YYYY-MM-DD'),
      }))
      setSpecialDates(specialDates)
    })

    axios({
      url: `/occasions?without_meta=true`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(apiDataSlice.actions.setOccasions(response.data))
      })
      .catch((err) => {
        // console.log(err)
      })

    axios({
      url: `/cuisines/combos`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(
          apiDataSlice.actions.setCombos(
            response.data.combos.filter(
              ({ published }: { published: boolean }) => published
            )
          )
        )
      })
      .catch((err) => {
        console.log(err)
      })

    axios({
      method: 'GET',
      url: '/appliances/gas_hobs',
    })
      .then((res) => {
        dispatch(bookDailyCookModalSlice.actions.setBurners(res.data.gas_hobs))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (
      setLunchMenu === false &&
      setDinnerMenu === false &&
      setBreakfastMenu === false
    ) {
      // console.log('nothing')
      setData({ ...data, booking_type: '' })
    }
    if (
      setLunchMenu === false &&
      setDinnerMenu === true &&
      setBreakfastMenu === false
    ) {
      // console.log('dinner')
      setData({
        ...data,
        booking_type: 'dinner',
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === false &&
      setBreakfastMenu === false
    ) {
      // console.log('lunch')
      setData({
        ...data,
        booking_type: 'lunch',
      })
    }

    if (
      setLunchMenu === false &&
      setDinnerMenu === false &&
      setBreakfastMenu === true
    ) {
      // console.log('Breakfast')
      setData({
        ...data,
        booking_type: 'breakfast',
      })
    }

    if (
      setLunchMenu === false &&
      setDinnerMenu === true &&
      setBreakfastMenu === true
    ) {
      // console.log('Dinner_and_breakfast')
      setData({
        ...data,
        booking_type: 'breakfast_dinner',
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === false &&
      setBreakfastMenu === true
    ) {
      // console.log('lunch_b')
      setData({
        ...data,
        booking_type: 'breakfast_lunch',
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === true &&
      setBreakfastMenu === false
    ) {
      // console.log('lunch_d')
      setData({
        ...data,
        booking_type: 'lunch_dinner',
      })
    }

    if (
      setLunchMenu === true &&
      setDinnerMenu === true &&
      setBreakfastMenu === true
    ) {
      // console.log('b_l_d')
      setData({
        ...data,
        booking_type: 'breakfast_lunch_dinner',
      })
    }
  }, [setDinnerMenu, setBreakfastMenu, setLunchMenu])

  const onDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // {console.log('cuisine clicked', e)}
    console.log('name', e.target.name)
    console.log('value', e.target.value)
    console.log('type', e.target.type)
    if (name === 'comboIds') {
      let combos = [...data.comboIds]
      const comboId = parseInt(value)
      const index = combos.indexOf(comboId)
      if (index > -1) {
        combos.splice(index, 1)
      } else {
        if (combos.length > 2) return
        combos = [...combos, comboId]
      }

      setData({ ...data, [name]: combos })
    } else if (name === 'occasionId' || name === 'comboId') {
      setData({ ...data, [name]: parseInt(value) })
    } else if (name === 'bookingDate' || name === 'bookingTime') {
      setData({ ...data, [name]: value })
    } else if (name === 'numberOfBurners') {
      setData({ ...data, numberOfBurners: value })
    }
  }

  const handleClickBreakfast = () => {
    setShowBreakfast(!setBreakfastMenu)
  }

  const handleClickDinner = () => {
    setShowDinner(!setDinnerMenu)
  }

  const handleClickLunch = () => {
    setShowLunch(!setLunchMenu)
  }

  const onSelectDataChange = (
    option:
      | { value: string; label: string }
      | { value: number; label: string }
      | null,
    action:
      | ActionMeta<{ value: string; label: string }>
      | ActionMeta<{ value: number; label: string }>
  ) => {
    const name = action.name
    const value = option.value

    if (name === 'numberOfPeople') {
      setData({ ...data, [name]: parseInt(value) })
    } else if (name === 'bookingDate') {
      setData({ ...data, [name]: value, bookingTime: '' })
    } else if (name === 'occasionId') {
      setData({ ...data, [name]: parseInt(value) })
    }
  }

  const date = new Date()
  const fontStyles =
    screen.width > 968
      ? {
          singleValue: (styles: any) => ({ ...styles, color: '#777' }),
          dropdownIndicator: (styles: any) => ({ ...styles, color: 'black' }),
        }
      : {
          singleValue: (styles: any) => ({ ...styles, color: '#777' }),
          control: (styles: any) => ({ ...styles, fontSize: '13px' }),
          placeholder: (styles: any) => ({
            ...styles,
            fontSize: '13px',
          }),
          option: (styles: any) => ({
            ...styles,
            fontSize: '13px',
          }),
          dropdownIndicator: (styles: any) => ({ ...styles, color: 'black' }),
        }

  const onSelectedDateStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.bookingDate ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.bookingDate ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.bookingDate ? 'white' : 'black',
    }),
  }

  const onSelectedPeopleStyles = {
    singleValue: (styles: any) => ({
      ...styles,
      color: data.numberOfPeople ? 'white' : '#777',
    }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: data.numberOfPeople ? 'black' : 'white',
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: data.numberOfPeople ? 'white' : 'black',
    }),
  }
  
  return (
    <div>
      <h5 className={styles.dark__grey__color} id="goToPeople">
        Number of People
        <br />
      </h5>
      <div>
        <div className="form-group">
          <Select
            onChange={(option, action) => onSelectDataChange(option, action)}
            options={Array.from({ length: 6 }, (_, k) => k + 1)?.map(
              (number) => {
                return { value: number, label: `${number} people` }
              }
            )}
            name="numberOfPeople"
            className="text-muted"
            styles={{...fontStyles, ...onSelectedPeopleStyles}}
            isSearchable={false}
            placeholder="Select from dropdown list"
          />
        </div>
      </div>
      <br />
      <h5 className={styles.dark__grey__color} id="goToDate">
        Service Start Date
      </h5>
      <div>
        <div className="form-group">
          <Select
            onChange={(option, action) => onSelectDataChange(option, action)}
            options={Array.from({ length: 365 }, (_, k) => k)?.map((number) => {
              const dateString = dayjs(date)
                .add(number, 'days')
                .format('D MMM, dddd')
              const dateValueString = dayjs(date)
                .add(number, 'days')
                .format('YYYY-MM-DD')

              return { value: dateValueString, label: dateString }
            })}
            name="bookingDate"
            className="text-muted"
            styles={{...fontStyles, ...onSelectedDateStyles}}
            isSearchable={false}
            placeholder="Select from dropdown list"
          />
        </div>
      </div>
      <br />
      <h5 className={styles.dark__grey__color} id="goToMeal">
        Select Meal(s)
        {/* <br />
        <span className={`text-danger ${styles.font__size__14}`}>
          you can select multiple
        </span> */}
      </h5>
      <div className="radio__button__container">
        <span>
          <input
            type="checkbox"
            name="meal_breakfast"
            value="breakfast"
            id="breakfast"
            onChange={handleClickBreakfast}
            checked={setBreakfastMenu === true ? true : false}
          />
          <label htmlFor="breakfast" className={`${styles.light__grey__color}`}>
            <span>Breakfast</span>
          </label>
        </span>

        <span>
          <input
            type="checkbox"
            name="meal_lunch"
            value="lunch"
            id="lunch"
            onChange={handleClickLunch}
            checked={setLunchMenu === true ? true : false}
          />
          <label htmlFor="lunch" className={`${styles.light__grey__color}`}>
            <span>Lunch</span>
          </label>
        </span>

        <span>
          <input
            type="checkbox"
            name="meal_dinner"
            value="dinner"
            id="dinner"
            onChange={handleClickDinner}
            checked={setDinnerMenu === true ? true : false}
          />
          <label htmlFor="dinner" className={`${styles.light__grey__color}`}>
            <span>Dinner</span>
          </label>
        </span>
      </div>
      {(setBreakfastMenu || setLunchMenu) && setDinnerMenu ? (
        <small style={{ color: 'gray' }}>
          <FaCheck /> 1 Cook will visit 2 times per day
        </small>
      ) : !setBreakfastMenu && !setLunchMenu && !setDinnerMenu ? (
        <></>
      ) : (
        <small style={{ color: 'gray' }}>
          <FaCheck /> 1 Cook will visit once per day
        </small>
      )}
      <br />
      {/* <div className="radio__button__container">
        <h5 className={styles.dark__grey__color} id="goToTime">
          Cook Arrival Time <br />
        </h5>
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="8am"
              checked={data.bookingTime === `08:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('8:00am', 'h:mma').isBefore(dayjs())
              }
              value={`08:00`}
              onChange={onDataChange}
            />
            <label htmlFor="8am" className={`${styles.light__grey__color}`}>
              <span className="p-1">8 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="9am"
              checked={data.bookingTime === `09:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('9:00am', 'h:mma').isBefore(dayjs())
              }
              value={`09:00`}
              onChange={onDataChange}
            />
            <label htmlFor="9am" className={`${styles.light__grey__color}`}>
              <span className="p-1">9 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="10am"
              checked={data.bookingTime === `10:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('10:00am', 'h:mma').isBefore(dayjs())
              }
              value={`10:00`}
              onChange={onDataChange}
            />
            <label htmlFor="10am" className={`${styles.light__grey__color}`}>
              <span className="p-1">10 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="11am"
              checked={data.bookingTime === `11:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('11:00am', 'h:mma').isBefore(dayjs())
              }
              value={`11:00`}
              onChange={onDataChange}
            />
            <label htmlFor="11am" className={`${styles.light__grey__color}`}>
              <span className="p-1">11 am </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="12pm"
              checked={data.bookingTime === `12:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('12:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`12:00`}
              onChange={onDataChange}
            />
            <label htmlFor="12pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">12 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="1pm"
              checked={data.bookingTime === `13:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('1:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`13:00`}
              onChange={onDataChange}
            />
            <label htmlFor="1pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">1 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="2pm"
              checked={data.bookingTime === `14:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('2:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`14:00`}
              onChange={onDataChange}
            />
            <label htmlFor="2pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">2 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="3pm"
              checked={data.bookingTime === `15:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('3:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`15:00`}
              onChange={onDataChange}
            />
            <label htmlFor="3pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">3 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="4pm"
              checked={data.bookingTime === `16:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('4:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`16:00`}
              onChange={onDataChange}
            />
            <label htmlFor="4pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">4 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="5pm"
              checked={data.bookingTime === `17:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('5:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`17:00`}
              onChange={onDataChange}
            />
            <label htmlFor="5pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">5 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="6pm"
              checked={data.bookingTime === `18:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('6:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`18:00`}
              onChange={onDataChange}
            />
            <label htmlFor="6pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">6 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="7pm"
              checked={data.bookingTime === `19:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('7:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`19:00`}
              onChange={onDataChange}
            />
            <label htmlFor="7pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">7 pm </span>
            </label>
          </>
        }
        {
          <>
            <input
              type="radio"
              name="bookingTime"
              id="8pm"
              checked={data.bookingTime === `20:00`}
              disabled={
                data.bookingDate === dayjs().format('YYYY-MM-DD') &&
                dayjs('8:00pm', 'h:mma').isBefore(dayjs())
              }
              value={`20:00`}
              onChange={onDataChange}
            />
            <label htmlFor="8pm" className={`${styles.light__grey__color}`}>
              <span className="p-1">8 pm </span>
            </label>
          </>
        }
      </div>
      <span className={`${styles.light__grey__color} ${styles.font__size__14}`}>
        {data.bookingTime ? (
          <label>
            {' '}
            <img
              style={{ height: '10px', width: 'auto' }}
              src={tickMark}
              alt="tick"
            />
            &nbsp;Cook will arrive by{' '}
            {dayjs(data.bookingTime, 'hh: mm').format('ha')}
          </label>
        ) : null}
      </span> */}
      <br />
    </div>
  )
}

export default BookingDetails
