// Booking Status Type
enum BookingStatus {
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  ASSIGNED = 'Assigned',
  CANCELLED_BY_COOK = 'Cancelled by Cook',
  JOB_STARTED = 'Job started',
  JOB_ENDED = 'Job ended',
  CANCELLED_BY_CUSTOMER = 'Cancelled by Customer',
  UNASSIGNED = 'Unassigned',
  FAILED = 'Failed',
  ON_HOLD = 'On Hold',
  CANCELLED_BY_SYSTEM = 'Cancelled by System',
  CHECKING_AVAILABILITY = 'Checking Availability',
  NOT_REQUIRED = 'Not Required',
}
export default BookingStatus

export interface Ibooking {
  admin_ingredients_approved: boolean
  balance_amount: number
  booking_date: string
  booking_id: number
  booking_otp: number
  booking_time: string
  cancelled_at: string | null
  cooks: Array<Icook>
  cook_level: string
  created_at: string
  cuisine_combo_id: number
  cuisine_combo_name: string
  cuisines: Array<any>
  extra_dishes_charge: number
  expected_arrival_time: string
  helper_charge: number
  helpers?: Array<Ihelper>
  ingredients_approval_deadline: string
  is_rated: boolean
  number_of_adults: null | number
  number_of_children: null | number
  number_of_cooks: number
  number_of_helpers: number
  number_of_people: number
  occasion: string
  on_hold_till: string | null
  payable_amount: number
  payment_status: null | string
  platform_charge: number
  rescheduled_at: string | null
  service_charge: number
  service_hours: number
  service_type: string
  status: BookingStatus
}

export interface IbookingDetails extends Ibooking {
  address_id: number
  add_on_services_described: boolean
  address_person_name: string | null
  additional_discount_amount: number
  balance_amount: number
  city: string
  discount_amount: number
  dishes: IbookingDish[]
  extra_dishes_charge: number
  house_number: string | null
  is_rated: boolean
  job_end_time: string | null
  job_start_time: string | null
  lat: string | null
  long: string | null
  overtime_charge: string
  overtime_charge_per_minute: number
  overtime_minutes: number
  payable_amount: number
  payment_transactions: IpaymentTransaction[]
  rating: number | null
  state: string
  street: string | null
  address_type: string
}

export interface IpaymentTransaction {
  created_at: string
  mode: string
  paid_amount: number
  payment_gateway_name: string
  type: string
}

export interface IbookingDish {
  id: number
  image: string
  meal_type: string
  name: string
  preparation_required: number
  qty_per_plate: number
  quantity: number
  serves: number
  type: string
  unit: string
}

export interface Ihelper {
  id: number
  name: string
  profile_img: string
  profile_name: null | string
  published: boolean
}

export interface Icook {
  id: 8
  level: string
  name: string
  profile_img: string
  profile_name: string
  published: boolean
  helpers?: Ihelper[]
}

export type IeventType =
  | 'menu'
  | 'appliances'
  | 'ingredients'
  | 'failed'
  | 'success'
  | 'checkAddress'
  | 'giveRating'
  | 'payBalance'
  | 'booking_rating'
