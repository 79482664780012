import React, { useRef, useState, useEffect } from 'react'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'

// React Script Library to load Google object
import Script from 'react-load-script'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

import { cities } from '@/staticData/cities.data'
import Select from 'react-select'

const G_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY

const cityOptions = [
  { label: 'Delhi', value: 'Delhi', state: 'Delhi' },
  { label: 'Noida', value: 'Noida', state: 'Uttar Pradesh' },
  { label: 'Gurugram', value: 'Gurugram', state: 'Haryana' },
  { label: 'Ghaziabad', value: 'Ghaziabad', state: 'Uttar Pradesh' },
  { label: 'Faridabad', value: 'Faridabad', state: 'Haryana' },
  { label: 'Greater Noida', value: 'Greater Noida', state: 'Uttar Pradesh' },
  { label: 'Bengaluru', value: 'Bengaluru', state: 'Karnataka' },
  { label: 'Hyderabad', value: 'Hyderabad', state: 'Telangana' },
  { label: 'Mumbai', value: 'Mumbai', state: 'Maharashtra' },
  { label: 'Pune', value: 'Pune', state: 'Maharashtra' },
  { label: 'Other city', value: 'other' },
]

const ManualAddressCleanerAddition = (): JSX.Element => {
  const autocomplete = useRef()

  const dispatch = useDispatch()

  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingCleanerModal
  )

  const {
    userAddAddressData: { city: REDUX__city, state, country, city_error },
  } = REDUX__bookingModalData

  const [city, setCity] = useState('')
  const [tCity, setTCity] = useState('')
  const [showGCity, setShowGCity] = useState(false)

  // useEffect(() => {
  //   // Set city from REDUX to local state avriable
  //   setCity(REDUX__city)
  // }, [REDUX__city])

  useEffect(() => {
    // update the REDUX state variables for city, state, country when local state's city is empty
    if (!city) {
      dispatch(
        bookingCleanerModalSlice.actions.setAddAddressDataForThatInstant({
          city: '',
          state: '',
          country: 'India',
        })
      )
    } else {
      const trimmedCity = city.toLowerCase().trim()
      if (cities[trimmedCity]) {
        dispatch(
          bookingCleanerModalSlice.actions.setAddAddressDataForThatInstant(
            cities[trimmedCity]
          )
        )
      }
    }
  }, [city])

  // G API
  const handleScriptLoad = (): void => {
    // Declare Options For Autocomplete
    const options = {
      // types: ["(cities)"],
      // country: ["in"],
      types: ['(cities)'],
      componentRestrictions: { country: 'in' },
    }

    // Initialize Google Autocomplete
    /*global google*/

    autocomplete.current = new google.maps.places.Autocomplete(
      document.getElementById('xx-autocomplete'),
      options
    )
    // Avoid paying for data that you don't need by restricting the
    // set of place fields that are returned to just the address
    // components and formatted address
    autocomplete?.current?.setFields([
      'address_components',
      'formatted_address',
    ])
    // Fire Event when a suggested name is selected
    autocomplete?.current?.addListener('place_changed', handlePlaceSelect)
  }

  const handlePlaceSelect = (): void => {
    // Extract City From Address Object
    const addressObject = autocomplete?.current?.getPlace()
    const address = addressObject.address_components

    if (address[1]?.long_name && address[2]?.long_name) {
      // Setting in REDUX global State
      dispatch(
        bookingCleanerModalSlice.actions.setAddAddressDataForThatInstant({
          city: address[0].long_name,
          state: address[3]?.long_name
            ? address[2].long_name
            : address[1].long_name,
          country: address[3]?.long_name ?? address[2].long_name,
          city_error: '',
        })
      )
      setCity(address[0].long_name)
    } else {
      // Setting in REDUX global State
      dispatch(
        bookingCleanerModalSlice.actions.setAddAddressDataForThatInstant({
          city: address[0].long_name,
          state: '',
          country: 'India',
          city_error: '',
        })
      )
      setCity(address[0].long_name)
    }
  }

  return (
    <>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?sensor=${false}&key=${G_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <div className="form-group">
        <Select
          placeholder="Select from dropdown list"
          styles={{
            option: (styles, { data }) => ({
              ...styles,

              color: data.value === 'other' ? 'blue' : styles.color,
            }),
          }}
          // components={{ Option }}
          value={tCity}
          onChange={(selectedCity) => {
            console.log('ddd', selectedCity)
            setTCity(selectedCity)
            const city = selectedCity.value
            if (city === 'other') {
              setShowGCity(true)
              setCity('')
            } else {
              setShowGCity(false)
              setCity(city)
            }
          }}
          options={cityOptions}
          minMenuHeight={400}
        />
        <input
          id="xx-autocomplete"
          placeholder="type here..."
          type="text"
          value={city}
          onChange={({ target: { value } }) => setCity(value)}
          className={`form-control mt-3 ${showGCity === false && 'd-none'}`}
        />
        <div className={`${styles.font__size__12} text-danger`}>
          {city_error}
        </div>
      </div>

      <Row>
        <Col className="form-group">
          <input
            name="state"
            placeholder="State"
            value={state}
            className="form-control"
            readOnly
            disabled
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            name="country"
            placeholder="Country"
            value={country}
            className="form-control"
            readOnly
            disabled
          />
        </Col>
      </Row>
    </>
  )
}

export default ManualAddressCleanerAddition
