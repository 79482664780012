import { createSlice } from '@reduxjs/toolkit'

// TS INTERFACES
import { IaddressFromAPI } from 'types/bookingModalTypes'
import { RootState } from '@/lib/rootState'

const initialState: RootState['bookingModal'] = {
  showBookingModal: false,
  upgradeBooking: false,
  addAssistant: false,
  truecallerOpen: false,
  paymentOpen: false,
  bookingDetails: {
    bookingId: null,
    occasionId: '',
    numberOfPeople: '',
    numberOfChildren: '',
    numberOfAdults: '',
    bookingDate: '',
    bookingTime: '',
    meal: '',
    comboIds: [],
    appliances: [],
    specialComboId:'',
    autoFillDishes: false,
  },
  menuData:[],
  citiesList:[],
  spclAppliances: {
    spclAppliances: [],
  },
  menuPreferences: {
    comboId: '',
    is_veg: '',
    preferenceType: '',
    numberOfBurners: '',
  },
  bookingLevel: {
    bookingLevel: '',
  },
  designMenu: {
    cuisineIds: [],
    dishDetails: [],
    lunchDishes: [],
    breakfastDishes: [],
    dinnerDishes: [],
  },
  dishesAllowed: null,
  userAddressData: {
    addresses: [],
    defaultSelectedAddressId: null,
    selectedAddressId: null,
    showNewServingCitiesModal: false,
  },
  userAddAddressData: {
    showAddAddressModal: false,
    stepNo: 6,
    showAPIAddressErrorModal: false,
    city: '',
    state: '',
    country: '',
    city_error: '',
  },
  userEditAddressData: {
    house_number: '',
    street: '',
    city: '',
    state: '',
    country: '',
    city_error: '',
    house_number_error: '',
    street_error: '',
  },
  summary: {
    booking_section: {},
    invoice: {
      invoice_header: { service_charges: 0 },
      invoice_middle: {
        service_hours: 0,
        helper_charge: 0,
        extra_dishes_charges: 0,
        platform_charges: 0,
      },
      invoice_footer: {
        amount_payable: 0,
        payable_now: 0,
        payable_later: 0,
      },
      payment_options: [],
    },
    available_coupons: [],
  },
  payment: {
    paymentGateway: '',
  },
  burners: [],
}

const bookingModalSlice = createSlice({
  name: 'bookingModal',
  initialState,
  reducers: {
    setUpgradeBooking: (state, data) => {
      state.upgradeBooking = data.payload
    },
    setAddAssistant: (state, data) => {
      state.addAssistant = data.payload
    },
    setCitiesList: (state, data) => {
      state.citiesList = data.payload
    },
    setMenuData: (state, data) => {
      state.menuData = data.payload
    },
    toggleBookingModal: (state) => {
      state.showBookingModal = !state.showBookingModal
    },
    setTruecallerOpen: (state, data) => {
      state.truecallerOpen = data.payload
    },
    setPaymentOpen: (state, data) => {
      state.paymentOpen = data.payload
    },
    closeBookingModalAndClearState: (state) => {
      state = { ...initialState }

      return state
    },
    setBookingDetails: (state, details) => {
      state.bookingDetails = details.payload
    },
    setOccasionId: (state, occasionId) => {
      state.bookingDetails.occasionId = occasionId.payload
    },
    setMenuPreferences: (state, preferences) => {
      state.menuPreferences = {
        ...state.menuPreferences,
        ...preferences.payload,
      }
    },
    setBookingLevel: (state, level) => {
      state.bookingLevel = level.payload
    },
    setDesignMenu: (state, data) => {
      state.designMenu = data.payload
    },
    setLunchDishes: (state, data) => {
      state.designMenu.lunchDishes = data.payload
    },
    setBreakFastDishes: (state, data) => {
      state.designMenu.breakfastDishes = data.payload
    },
    setDinnerDishes: (state, data) => {
      state.designMenu.dinnerDishes = data.payload
    },
    setCuisineIds: (state, data) => {
      state.designMenu.cuisineIds = data.payload
    },
    resetDesignMenu: (state) => {
      state.designMenu = initialState.designMenu
    },
    setDishesAllowed: (state, dishesAllowed) => {
      state.dishesAllowed = dishesAllowed.payload
    },
    setBurners: (state, burners) => {
      state.burners = burners.payload
    },
    setAutoFillDishes: (state, data) => {
      state.bookingDetails.autoFillDishes = data.payload
    },
    setSpecialComboId: (state, data) => {
      state.bookingDetails.specialComboId = data.payload
    },
    setAppliances: (state, data) => {
      state.bookingDetails.appliances = data.payload
    },
    setBookingId: (state, bookingId) => {
      state.bookingDetails.bookingId = bookingId.payload
    },
    setSpclAppliances: (state, spclAppliances) => {
      state.spclAppliances.spclAppliances = spclAppliances.payload
    },
    setUserAddressData: (state, data) => {
      state.userAddressData = data.payload
    },
    toggleAddAddressModal: (state, data) => {
      // console.log(data, 'data')
      state.userAddAddressData = {
        ...state.userAddAddressData,
        showAddAddressModal: !state.userAddAddressData.showAddAddressModal,
        stepNo: data.payload.stepNo,
      }
    },
    toggleAPIAddressErrorModal: (state) => {
      state.userAddAddressData.showAPIAddressErrorModal =
        !state.userAddAddressData.showAPIAddressErrorModal
    },
    toggleNewServingCitiesModal: (state, data) => {
      state.userAddressData.showNewServingCitiesModal = data.payload
    },
    setAddAddressDataForThatInstant: (state, data) => {
      state.userAddAddressData = {
        ...state.userAddAddressData,
        ...data.payload,
      }
    },
    clearAddAddressDataForThatInstantAndCloseModal: (state) => {
      state.userAddAddressData = initialState.userAddAddressData
    },
    setSelectedAddressId: (state, addressId) => {
      state.userAddressData.selectedAddressId = addressId.payload
    },
    addNewAddressToUserAddressesList: (
      state,
      newAddress: { payload: IaddressFromAPI }
    ) => {
      // update the selected address ID
      state.userAddressData.selectedAddressId = newAddress.payload.address_id

      // Add the newly added address to the end of addresses array
      state.userAddressData.addresses = [
        ...state.userAddressData.addresses,
        newAddress.payload,
      ]
    },
    setEditAddressDataForThatInstant: (state, data) => {
      state.userEditAddressData = {
        ...state.userEditAddressData,
        ...data.payload,
      }
    },
    clearEditAddressDataForThatInstant: (state) => {
      state.userEditAddressData = initialState.userEditAddressData
    },
    updateAddressInAddressList: (state, data) => {
      const { updatedAddress, index } = data.payload
      state.userAddressData.addresses[index] = updatedAddress
    },
    setBookingSummary: (state, data) => {
      state.summary = data.payload
    },
    setBookingInvoice: (state, data) => {
      state.summary.invoice = data.payload
    },
    setPaymentGateway: (state, gateway) => {
      state.payment.paymentGateway = gateway.payload
    },
  },
})

export default bookingModalSlice
