const wrapS3URLForIcons = (imgName: string) =>
  `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/${imgName}`

export const bookingHowItWorksData = [
  {
    imgSrc: wrapS3URLForIcons('ingredients.png'),
    point: 'Ingredients List is shared',
  },
  {
    imgSrc: wrapS3URLForIcons('bartender5.png'),
    point: 'Bartender arrives on time',
  },
  {
    imgSrc: wrapS3URLForIcons('bartender4.png?v=1'),
    point: 'Serves drinks, cocktails and mocktails',
  },
  {
    imgSrc: wrapS3URLForIcons('clean_kitchen.png'),
    point: 'Wraps up and leaves',
  },
]
