const wrapS3URL = (glimpseName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/${glimpseName}.jpg`
}

interface Iphotos {
  src: string
  alt: string
  width: number
  height: number
}

export const photos: Array<Iphotos> = [
  {
    src: wrapS3URL('glimpse22'),
    alt: 'COOX Waiters serving dishes to our clients. Book Waiters online',
    width: 2,
    height: 3,
  },
  {
    src:wrapS3URL('glimpse27'),
    alt: 'COOX waiter serving drinks. Hire top rated waiters in your city',
    width: 3,
    height: 4,
  },
  
  // {
  //   src: wrapS3URL('glimpse19'), //table
  //   alt: 'COOX Waiters pictures with our customers. Hire professional waiter near me',
  //   width: 2,
  //   height: 3,
  // },
  {
    src: wrapS3URL('glimpse28'), //family
    alt: 'COOX Waiters family pictures with our customers. Hire professional waiter near me',
    width: 5,
    height: 3,
  },
  {
    src: wrapS3URL('glimpse23'),
    alt: 'COOX waiter services near you. Hire personal waiter for parties events',
    width: 3,
    height: 4,
  },
  {
    src: wrapS3URL('glimpse21'),
    alt: 'COOX Waiters family pictures with our customers. Hire professional waiter near me',
    width: 2,
    height: 3,
  },
  {
    src:wrapS3URL('glimpse25'),
    alt: 'COOX waiter serving drinks. Hire top rated waiters in your city',
    width: 3,
    height: 4,
  },
]

///