const wrapS3URLForIcons = (imgName: string) =>
  `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/${imgName}`

export const bookingHowItWorksData = [
  {
    imgSrc: wrapS3URLForIcons('waiter4.png'),
    point: 'Waiter arrives on time',
  },
  {
    imgSrc: wrapS3URLForIcons('hotAndfresh.png'),
    point: 'Serves food hot and fresh',
  },
  {
    imgSrc: wrapS3URLForIcons('clean_kitchen.png'),
    point: 'Wraps up and leaves',
  },
]
