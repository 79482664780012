import React, { useState, useEffect, useContext, useRef } from 'react'

// STYLES
import styles from './index.module.css'
// STYLES
import bStyles from '../BookingModals/BookChefModal/index.module.css'

// BOOTSTRAP
import { Modal, Button, Row, Col } from 'react-bootstrap'

// REACT SWITCH
import Switch from 'react-switch'

// AXIOS
import axios from 'axios'

// ICONS
import { FaArrowLeft } from 'react-icons/fa'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { FaArrowRight } from 'react-icons/fa'
import authSlice from '@/lib/slices/authSlice'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'

// CONTEXT
import { SWContext } from 'pages/_app'

// TS INTERFACES
import { RootState } from '@/lib/rootState'
import { IaddressFromAPI } from 'types/bookingModalTypes'
import ChatOnWhatsAppText from '../ChatOnWhatsAppText'

const UserDetailsModal = React.memo((): JSX.Element => {
  const dispatch = useDispatch()
  const SW = useContext(SWContext).SW
  // const SWContextData = useContext(SWContext)
  const REDUX__authData = useSelector((state: RootState) => state.auth)

  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )

  const { userData, showUserDetailsModal } = REDUX__authData
  const mobile = userData.mobile
  const {
    spclAppliances: { spclAppliances },
  } = REDUX__bookingModalData

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('male')
  const nameInputRef = useRef<HTMLInputElement>(null)

  // useEffect(() => {
  //   nameInputRef.current?.focus()
  // }, [])

  useEffect(() => {
    setName(userData.name ?? '')
    setEmail(userData.email ?? '')
    setGender(userData.gender ?? 'male')
  }, [userData.name, userData.email, userData.gender])

  const [loading, setLoading] = useState(false)
  const [nameError, setNameError] = useState('')
  const [error, setError] = useState('')
  const [proceedClicked, setProceedClicked] = useState(false)

  const validate = () => {
    // if (!name && !email) {
    //   setNameError('Name cannot be blank')
    //   setError('Email cannot be blank')
    //   return false
    // } else
    let valid = true
    if (!name) {
      setNameError('Name cannot be blank')
      valid = false
    } else {
      setNameError('')
    }
    // else
    if (!email) {
      setError('Email cannot be blank')
      valid = false
    } else {
      setError('')
    }
    if (valid === false) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (proceedClicked === true) {
      validate()
    }
  }, [email, name])

  const submitDetails = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setProceedClicked(true)
    event.preventDefault()
    setName(name.trim())
    const isValid = validate()
    if (isValid) {
      setLoading(true)

      axios({
        method: 'PUT',
        url: `/user`,
        data: {
          name,
          email,
          gender,
        },
      })
        .then((res) => {
          // set into redux store
          // TODO: check this or remove this
          dispatch(
            authSlice.actions.updateAuthUser({
              name,
              email,
              gender,
            })
          )
          dispatch(authSlice.actions.authUserSet(res.data))

          // set into localstorage
          localStorage.setItem('authUser', JSON.stringify(res.data))
          setNameError('')
          setError('')

          dispatch(authSlice.actions.toggleUserDetailsModal())
          // console.log('spcl applen', spclAppliances)
          if (spclAppliances.length === 0) {
            axios.get(`/user/address`).then((response) => {
              const defaultAddressId: number = (
                response.data.addresses.find(
                  (address: IaddressFromAPI) => address.is_default === 1
                ) || { address_id: null }
              ).address_id

              dispatch(
                bookingModalSlice.actions.setUserAddressData({
                  addresses: response.data.addresses,
                  selectedAddressId: defaultAddressId,
                })
              )
              if (response.data.addresses.length > 0) {
                SW?.goToNamedStep('address')
              } else {
                dispatch(
                  bookingModalSlice.actions.toggleAddAddressModal({
                    stepNo: 4,
                  })
                )
              }
            })
          } else {
            SW?.goToNamedStep('appliances')
          }
        })
        .catch((err) => {
          let errorMsg
          if (err.response?.data?.code === 400) {
            errorMsg = err.response?.data?.errors?.body[0]?.message
          } else {
            errorMsg = err.response?.data?.message
          }
          setError(errorMsg)
          setLoading(false)
        })
    }
  }

  return (
    <Modal
      animation={false}
      show={showUserDetailsModal}
      onHide={() => dispatch(authSlice.actions.toggleUserDetailsModal())}
      size="lg"
      backdrop="static"
      scrollable
      dialogClassName="modal-dialog-wm"
      onShow={() => {
        // console.log('labalaba', nameInputRef)

        setTimeout(() => {
          nameInputRef.current?.focus()
        }, 1000)
      }}
    >
      <Modal.Header closeButton style={{ padding: '0.6rem 1rem 0.2rem 1rem' }}>
        <button
          onClick={() => dispatch(authSlice.actions.toggleUserDetailsModal())}
          className="btn btn-white m-0 p-0"
        >
          <FaArrowLeft />
        </button>
        <span className="w-100 text-center">
          <p className={`m-0 ${styles.font__size__20}`}>
            <b>Basic Details</b>
          </p>
        </span>
      </Modal.Header>
      <Modal.Body>
        <p className="text-left text-muted">
          Please add your details to view total bill
        </p>
        <div className="form-group">
          <h5 className={bStyles.dark__grey__color}>Mobile</h5>
          <input type="text" readOnly className="form-control" value={mobile} />
        </div>
        <div className="form-group">
          <h5 className={bStyles.dark__grey__color}>OTP</h5>
          <input
            type="text"
            readOnly
            placeholder="OTP verified"
            className="form-control"
            value={''}
          />
        </div>
        <Row>
          {/* uncommented to remove asking gender during booking flow */}
          {/* <Col xs={3} className="mt-1"> */}
          {/* <Switch
                className="react-switch"
                onChange={(checked) => {
                  setGender(checked ? 'male' : 'female')
                }}
                checked={gender === 'male'}
                onColor="#5e5e5e"
                checkedIcon={<div className={styles.text__switch}>Mr</div>}
                uncheckedIcon={<div className={styles.text__switch}>Ms</div>}
              />
            </Col> */}
          <Col>
            <div className="form-group">
              <h5 className={bStyles.dark__grey__color}>Name</h5>

              <input
                ref={nameInputRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                name="name"
                // autoFocus
                // placeholder="NAME"
                className="form-control"
              />
              <div className={styles.error__message}>{nameError}</div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="form-group">
              <h5 className={`${styles.dark__grey__color} pull-left d-inline`}>
                Email
              </h5>
              <small>&nbsp;(we promise we won’t disturb you)</small>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                // placeholder="EMAIL"
                className="form-control"
              />
              <div className={styles.error__message}>{error && error}</div>
              {/* <small className="text-muted">
                  we promise we won’t disturb you
                </small> */}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ChatOnWhatsAppText activeStep={8} />
        <Button
          variant="warning"
          disabled={loading}
          block
          onClick={submitDetails}
        >
          View Total Bill&nbsp;
          {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
          {loading && '...'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default UserDetailsModal
