import { useState, useEffect } from 'react'

// COMPONENTS
import Toolbar from './Toolbar'
import SideDrawer from './SideDrawer'
import Backdrop from './Backdrop'

// REDUX
import { useDispatch } from 'react-redux'
import authSlice from '@/lib/slices/authSlice'

interface Props {
  homePage: boolean
}

const NavBar = (props: Props): JSX.Element => {
  const { homePage } = props
  const isLoggedIn =
    typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(authSlice.actions.setIsLoggedIn(true))
      dispatch(
        authSlice.actions.updateAuthUser(
          JSON.parse(localStorage.getItem('authUser') as string)
        )
      )
    }
  }, [isLoggedIn])

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

  const toggleSideDrawer = () => setSideDrawerOpen(!sideDrawerOpen)

  const backdropClickHandler = () => setSideDrawerOpen(!sideDrawerOpen)

  let backdrop

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />
  }
  return (
    <div style={{ height: '100%' }} id="toppp">
      <Toolbar toggleSideDrawer={toggleSideDrawer} homePage={homePage} />
      <SideDrawer
        sideDrawerOpen={sideDrawerOpen}
        toggleSideDrawer={toggleSideDrawer}
      />
      {backdrop}
    </div>
  )
}
export default NavBar
