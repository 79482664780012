import Head from 'next/head'

import { useState, useContext, useEffect } from 'react'

// BOOTSTRAP
import { Modal } from 'react-bootstrap'

// STYLES
import styles from './index.module.css'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'

// ICONS
import { FaArrowLeft, FaTimes } from 'react-icons/fa'

// STEP WIZARD
import StepWizard from 'react-step-wizard'

// HELPERS
import { BookingModalHeading, CTAHelper } from './helpers'

// COMPONENTS
import ChatOnWhatsAppText from '../../ChatOnWhatsAppText'
import ExitAlertModal from './ExitAlertModal'

// STEPS
import {
  STEP_1,
  STEP_2,
  STEP_3,
  STEP_4_1,
  STEP_4_2,
  STEP_4_3,
  STEP_5,
  STEP_6,
  STEP_7,
  STEP_8,
} from './steps'

// CONTEXT
import { SWContext } from 'pages/_app'

// INTERFACES
import { SWtype } from './commonInterfaces'
import { RootState } from '@/lib/rootState'

// GTM
import { gtmEvent } from '@/lib/gtm'

const transitions = {
  enterRight: 'none',
  enterLeft: 'none',
  exitRight: 'none',
  exitLeft: 'none',
}

const bookingSteps = {
  1: 'how-it-works',
  2: 'details',
  3: 'breakfast',
  4: 'lunch',
  5: 'dinner',
  6: 'appliances',
  7: 'address',
  8: 'summary',
}

const BookingModal = (): JSX.Element => {
  const SWContextData = useContext(SWContext)
  const [loading, setLoading] = useState(false)

  const [SW, setSW] = useState<SWtype | null>(null)
  const [activeStep, setActiveStep] = useState<number>(1)
  const [showExitAlertModal, setShowExitAlertModal] = useState<boolean>(false)

  const dispatch = useDispatch()

  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )
  // const {
  //   spclAppliances: {
  //     spclAppliances,
  //   },
  // } = REDUX__bookingModalData

  const {
    spclAppliances: { spclAppliances },
    userAddressData: { addresses, selectedAddressId },
    showBookingModal,
    truecallerOpen,
    paymentOpen,
    bookingDetails: { meal },
  } = REDUX__bookingModalData

  // console.log(spclAppliances , "ssds")
  const onWizardStepChange = (updatedSW: {
    previousStep: number
    activeStep: number
  }) => {
    // Scroll to top on every step change in Modal
    const modalBodyDomNode = document.getElementById('BOOKCHEF___MODAL___BODY')

    if (modalBodyDomNode) {
      modalBodyDomNode.scrollTop = 0
    }

    setActiveStep(updatedSW.activeStep)

    gtmEvent('book-chef-flow', { step: bookingSteps[updatedSW.activeStep] })
  }

  const setWizardInstance = (SWinstance) => {
    // console.log('chococ', SWinstance)

    // Setting in local State
    setSW({
      nextStep: SWinstance.nextStep,
      previousStep: SWinstance.previousStep,
      goToStep: SWinstance.goToStep,
      goToNamedStep: SWinstance.goToNamedStep,
      activeStep: SWinstance.state.activeStep,
    })

    // Set in context
    SWContextData.updateSWinContext({
      nextStep: SWinstance.nextStep,
      previousStep: SWinstance.previousStep,
      goToStep: SWinstance.goToStep,
      goToNamedStep: SWinstance.goToNamedStep,
      activeStep: SWinstance.state.activeStep,
    })
  }

  const goBackOnBookingModal = () => {
    // console.log('activeStep, meal', activeStep, meal)

    if (loading) return false
    // console.log(meal , activeStep, spclAppliances.required_appliances.length)

    if (activeStep === 1) {
      setShowExitAlertModal(true)
    }

    if (selectedAddressId && activeStep === 8) {
      if (spclAppliances.length === 0) {
        const mealTypes = meal.split('_')
        if (mealTypes.includes('dinner')) return SW?.goToNamedStep('dinner')
        else if (mealTypes.includes('lunch')) return SW?.goToNamedStep('lunch')
        else return SW?.goToNamedStep('breakfast')
      } else {
        return SW?.goToNamedStep('appliances')
      }
    }

    if (meal === 'breakfast' && activeStep === 6) SW?.goToNamedStep('breakfast')
    else if (meal === 'lunch' && activeStep === 6) SW?.goToNamedStep('lunch')
    else if (
      (meal === 'lunch' && activeStep === 4) ||
      (meal === 'dinner' && activeStep === 5)
    )
      SW?.goToNamedStep('details')
    else if (meal === 'lunch_dinner' && activeStep === 5)
      SW?.goToNamedStep('lunch')
    else if (meal === 'lunch_dinner' && activeStep === 4)
      SW?.goToNamedStep('details')
    else if (meal === 'breakfast_dinner' && activeStep === 5)
      SW?.goToNamedStep('breakfast')
    else if (meal === 'breakfast_lunch' && activeStep === 6)
      SW?.goToNamedStep('lunch')
    else if (
      (activeStep === 8 || activeStep === 7) &&
      spclAppliances.length === 0
    ) {
      if (meal === 'breakfast') SW?.goToNamedStep('breakfast')
      else if (meal === 'lunch') SW?.goToNamedStep('lunch')
      else if (meal === 'dinner') SW?.goToNamedStep('dinner')
      else if (meal === 'breakfast_lunch') SW?.goToNamedStep('lunch')
      else if (meal === 'lunch_dinner') SW?.goToNamedStep('dinner')
      else if (meal === 'breakfast_dinner') SW?.goToNamedStep('dinner')
      else if (meal === 'breakfast_lunch_dinner') SW?.goToNamedStep('dinner')
      else SW?.previousStep()
    } else {
      SW?.previousStep()
    }
  }

  useEffect(() => {
    const beforeUnload = (e) => {
      // console.log('fdsdfsd', showBookingModal)
      if (showBookingModal && !truecallerOpen && !paymentOpen) {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        return (e.returnValue = 'Are you sure?')
      }
    }

    window.addEventListener('beforeunload', beforeUnload)

    return () => window.removeEventListener('beforeunload', beforeUnload)
  }, [showBookingModal, truecallerOpen, paymentOpen])

  return (
    <>
      <Head>
        {/* ---------------- LOADING PAYTM SCRIPT ---------------- */}

        <script
          type="application/javascript"
          src="https://checkout.razorpay.com/v1/checkout.js"
          crossOrigin="anonymous"
        />
        {/* ---------------- LOADING PAYTM SCRIPT ---------------- */}
      </Head>
      <Modal
        show={showBookingModal}
        onHide={() => dispatch(bookingModalSlice.actions.toggleBookingModal())}
        keyboard={false}
        size="lg"
        scrollable
        backdrop="static"
        dialogClassName="modal-dialog-wm"
      >
        <Modal.Header style={{ padding: '0.6rem 1rem 0.2rem 1rem' }}>
          <FaArrowLeft
            onClick={goBackOnBookingModal}
            className={styles.cursor__pointer}
          />

          <Modal.Title className="w-100">
            <span className="m-0">
              <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
                <b>
                  <BookingModalHeading step={activeStep} />
                </b>
              </p>
            </span>
          </Modal.Title>
          <FaTimes
            onClick={() => setShowExitAlertModal(true)}
            className={styles.cursor__pointer}
          />
        </Modal.Header>

        <Modal.Body id="BOOKCHEF___MODAL___BODY">
          <StepWizard
            initialStep={1}
            instance={setWizardInstance}
            transitions={transitions}
            onStepChange={onWizardStepChange}
          >
            <STEP_1 SW={SW} />
            <STEP_2 stepName={'details'} />
            {/* <STEP_3 /> */}
            <STEP_4_1 activeStep={activeStep} stepName={'breakfast'} />
            <STEP_4_2 activeStep={activeStep} stepName={'lunch'} />
            <STEP_4_3 activeStep={activeStep} stepName={'dinner'} />
            <STEP_5 SW={SW} stepName={'appliances'} />
            <STEP_6 SW={SW} stepName={'address'} />
            <STEP_7
              SW={SW}
              proceedingToNextStep={loading}
              stepName={'summary'}
            />
            {/* <STEP_8 proceedingToNextStep={loading} /> */}
          </StepWizard>
        </Modal.Body>
        <Modal.Footer style={{ padding: '0.08rem .75rem .3rem .75rem' }}>
          <ChatOnWhatsAppText activeStep={activeStep} />
          <CTAHelper
            activeStep={activeStep}
            SW={SW}
            loading={loading}
            setLoading={setLoading}
          />
        </Modal.Footer>
      </Modal>

      <ExitAlertModal
        show={showExitAlertModal}
        setShow={setShowExitAlertModal}
        setActiveStep={setActiveStep}
      />
    </>
  )
}

export default BookingModal
