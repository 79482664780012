// BOOTSTRAP
import { Dispatch, SetStateAction } from 'react'
import { Container, Modal, Row, Col, Button } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'
import { GiElectric } from 'react-icons/gi'
// STYLES
import styles from './index.module.css'

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const GreyTick = () => (
  <img src={tickMark} alt="tick" className={styles.grey__tick} />
)

interface Props {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

const UpgradeBookingModal = (props: Props): JSX.Element => {
  const { show, setShow, upgradeLoading, upgradeBooking } = props
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      scrollable
      backdrop="static"
      dialogClassName="modal-dialog-wm"
    >
      <Modal.Header closeButton>
        <button
          onClick={() => setShow(false)}
          className="btn btn-white m-0 p-0"
        >
          <FaArrowLeft />
        </button>
        <Modal.Title className="w-100">
          <span className="m-0">
            <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
              <b>Upgrade Booking</b>
            </p>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row className="mx-0 mt-5">
            <Col>
              <div className="text-center">
                <img
                  style={{ borderRadius: '50%' }}
                  src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/classic_chef.jpeg"
                  width="120px"
                />
              </div>
              <br />
              <div className="text-center">
                <h5>CLASSIC CHEF</h5>
              </div>
              <br />
              <br />
              <Row>
                <Col md={{ offset: 3 }} className={`${styles.text} mt-2`}>
                  <GreyTick /> Professional Experience
                </Col>
              </Row>
              <Row>
                <Col md={{ offset: 3 }} className={`${styles.text} mt-2`}>
                  <GreyTick /> Avg. 7 years work exp
                </Col>
              </Row>
            </Col>
            <Col className={`${styles.partition}`}>
              <div className="text-center">
                <img
                  style={{ borderRadius: '50%' }}
                  src="https://coox-new.s3.ap-south-1.amazonaws.com/images/cook/17059/profile-imgaafcf107-43d0-49e3-b799-2c84d4e9fb3f.jpeg"
                  width="120px"
                />
              </div>
              <br />
              <div className="text-center">
                <h5>PRO CHEF </h5>
                <span className={`${styles.Pro}`}>
                  <GiElectric />
                  Pro
                </span>
              </div>
              <Row>
                <Col md={{ offset: 3 }} className={`${styles.text} mt-2`}>
                  <GreyTick /> Premium Experience
                </Col>
              </Row>
              <Row>
                <Col md={{ offset: 3 }} className={`${styles.text} mt-2`}>
                  <GreyTick /> Avg. 10 years work exp
                </Col>
              </Row>
              <Row>
                <Col md={{ offset: 3 }} className={`${styles.text} mt-2`}>
                  <GreyTick /> Top Rated Chefs
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col>
            <Button
              variant="light"
              type="button"
              size="sm"
              block
              style={{ color: 'black', border: '2px solid #fcba03' }}
              onClick={() => setShow(false)}
            >
              Close
            </Button>
          </Col>
          <Col>
            <Button
              variant="warning"
              type="button"
              size="sm"
              block
              onClick={() => {
                upgradeBooking(true)
              }}
              disabled={upgradeLoading}
            >
              {upgradeLoading ? 'Upgrading to Pro...' : 'Upgrade For ₹999'}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default UpgradeBookingModal
