// HELPER
import { loadScript } from './BookingHelper'

// AXIOS
import axios from 'axios'

import { NextRouter } from 'next/router'

export const displayPaytm = async (
  amount_payable: number,
  balance_amount: number,
  bookingId: number | null,
  paymentOption: string,
  setLoading: (loading: boolean) => void,
  toggleBookingModalFromPaytm: () => void,
  couponCode: string | null,
  router: NextRouter,
  setPaymentClose?: () => void // required to able redirect after pre booking flow without leave site popup
): Promise<any> => {
  if (!couponCode) {
    couponCode = ''
  }
  let PAYTM_URL = `/payment/paytm-js-checkout/${bookingId}`
  PAYTM_URL =
    paymentOption !== ''
      ? `${PAYTM_URL + paymentOption}&coupon_code=${couponCode}`
      : PAYTM_URL

  const bookingData = await axios.get(PAYTM_URL).then((res) => res.data)

  const { id, amount, txn_token } = bookingData.order
  const res = await loadScript(
    `${process.env.NEXT_PUBLIC_PAYTM_BASE_URL}/merchantpgpui/checkoutjs/merchants/${process.env.NEXT_PUBLIC_PAYTM_MID}.js`
  )

  if (!res) {
    alert('Paytm SDK failed to load. Are you online?')
    setLoading(false)

    return
  }

  const config = {
    root: '',
    flow: 'DEFAULT',
    data: {
      orderId: id,
      token: txn_token,
      tokenType: 'TXN_TOKEN',
      amount,
    },
    merchant: {
      redirect: false,
    },
    handler: {
      transactionStatus: async function transactionStatus(paymentStatus) {
        console.log('paymentStatus => ', paymentStatus)
        let redirectUrl = ''
        if (paymentStatus.STATUS === 'TXN_SUCCESS') {
          await axios.patch(`/booking/${bookingId}/payment-tracking-status`)
          redirectUrl = `/my-bookings?bookingId=${bookingId}&event=success`
        } else {
          redirectUrl = `/my-bookings?bookingId=${bookingId}&event=failed`
        }
        if (amount_payable === balance_amount) {
          redirectUrl += '&payment=paid_advance'
        } else {
          redirectUrl += '&payment=paid_balance'
        }
        // router.push(redirectUrl)
        window.location.href = redirectUrl
      },
      notifyMerchant: function (eventName, data) {
        // toggleBookingModalFromPaytm()
        // window.location.reload()
        if (eventName === 'APP_CLOSED') {
          // router.push(`/my-bookings?bookingId=${bookingId}&event=failed`)
          window.location.href = `/my-bookings?bookingId=${bookingId}&event=failed`
        }
        // console.log('notifyMerchant handler function called')
        console.log('eventName => ', eventName)
        // console.log('data => ', data)
      },
    },
  }
  if (window.Paytm && window.Paytm.CheckoutJS) {
    console.log('1 -----', window.Paytm)

    window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
      console.log('2 -----', window.Paytm)

      // initialze configuration using init method
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          console.log('3 -----', window.Paytm)
          toggleBookingModalFromPaytm()
          // after successfully updating configuration, invoke JS Checkout
          window.Paytm.CheckoutJS.invoke()
          console.log('4 -----', window.Paytm)
        })
        .catch(function onError(error) {
          console.log('error => ', error)
        })
        .finally(() => {
          setLoading(false)
          setPaymentClose && setPaymentClose()
        })
    })
  }
}
