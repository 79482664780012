import { SetStateAction } from 'react'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Button, Modal } from 'react-bootstrap'

// TS INTERFACES
interface Props {
  show: boolean
  setShow: (value: SetStateAction<boolean>) => void
}

const PeopleConfirmationModal = (props: Props): JSX.Element => {
  const { show, setShow } = props

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      style={{ backgroundColor: 'rgba(128,128,128,0.3)' }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className={styles.font__size__16}>
        <p>For more than 50 people, call us on 9004044234 to discuss.</p>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={() => setShowOpenModal(false)}> */}
        <Button variant="primary">
          <a href="tel:+919004044234" style={{ color: 'white' }}>
            Call on 9004044234
          </a>
        </Button>
        <Button
          style={{ backgroundColor: 'white', borderColor: 'blue' }}
          onClick={() => {
            setShow(false)
          }}
          className={styles.exit__alert__cta}
        >
          <span style={{ color: 'blue' }}>Cancel</span>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PeopleConfirmationModal
