import { useEffect, useState } from 'react'

// STYLES
import styles from './index.module.css'
import bmStyles from '../../index.module.css'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

// REACT SELECT
import Select, { components } from 'react-select'

// IS VEG SWITCH
import IsVegSwitch from '@/components/IsVegSwitch'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'

// AXIOS
import axios from 'axios'

// HELPERs
import {
  getCuisineIdsByComboId,
  // findDishesBasedOnCuisineIds,
  shapeSelectedDishesForSummaryAPI,
  // convertFilteredDishesToMultiSelectInputType,
} from '@/components/BookingModals/BookChefModal/helpers'

// COMPONENTS
// import UserDetailsModal from '@/components/UserDetailsModal'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

interface Props {
  lunchOrDinner: 'dinner' | 'lunch' | 'breakfast'
}

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const DesignMenuLogic = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  const REDUX__bookingDetails = useSelector(
    (state: RootState) => state.bookingBartenderModal.bookingDetails
  )

  const { meal, comboIds } = REDUX__bookingDetails

  const [cuisineIds, setCuisineIds] = useState([])

  // TODO: move the fetching to a better place immediately
  const combos = useSelector((state: RootState) => state.apiData.combos)

  // TODO: move the fetching to a better place immediately
  const [dishesList, setDishesList] = useState([])

  const [cocktailsOptions, setCocktailsOptions] = useState([])
  const [mocktailsOptions, setMocktailsOptions] = useState([])

  useEffect(() => {
    axios({
      url: '/dishes/list?type=Cocktails & Mocktails',
      method: 'GET',
    })
      .then((response) => {
        setDishesList(response.data.menu)
        console.log(response.data.menu)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  // console.log('dishesList', dishesList)

  useEffect(() => {
    /**
     * Find and set cuisineIds based on the selected combo
     */
    setCuisineIds(getCuisineIdsByComboId(comboIds, combos))
  }, [comboIds, combos])

  useEffect(() => {
    /**
     * filter dishes based on the selected combo and set appetizers & main course dishes
     *
     */
    let cocktailsOptionss = []
    for (let i = 0; i < dishesList.length; i++) {
      for (let k = 0; k < dishesList[i]?.dishes.length; k++) {
        const objofdish = {
          cuisine_name: dishesList[i]?.dishes[k]?.cuisine_name,
          value: dishesList[i]?.dishes[k]?.id,
          label:
            dishesList[i]?.dishes[k]?.name +
            ' (₹' +
            dishesList[i]?.dishes[k]?.price +
            ')',
          name: dishesList[i]?.dishes[k]?.name,
          special_appliances: dishesList[i]?.dishes[k]?.special_appliances,
          cost: dishesList[i]?.dishes[k]?.cost,
          price: dishesList[i]?.dishes[k]?.price,
          type: dishesList[i]?.dishes[k]?.type,
          is_veg: dishesList[i]?.dishes[k]?.is_veg,
          is_recommended: dishesList[i]?.dishes[k]?.is_recommended,
          image: dishesList[i]?.dishes[k]?.image,
          dish_type_id: dishesList[i]?.dishes[k]?.dish_type_id,
          served_with: dishesList[i].dishes[k]?.served_with,
          fried: dishesList[i].dishes[k]?.fried,
          requires_gas: dishesList[i].dishes[k]?.requires_gas,
        }
        if (objofdish.type === 'Cocktails & Mocktails') {
          cocktailsOptionss.push(objofdish)
        }
      }
    }
    if (cocktailsOptionss.length > 0) {
      const drinkGroups = cocktailsOptionss.reduce((drinkGroups, item) => {
        const group = drinkGroups[item.cuisine_name] || []
        group.push(item)
        drinkGroups[item.cuisine_name] = group
        return drinkGroups
      }, {})

      let drinksList = []
      Object.keys(drinkGroups).forEach(function (cuisine) {
        drinksList.push({
          label: cuisine,
          options: drinkGroups[cuisine],
        })
      })

      let cocktails = drinksList.find((drink) => drink.label === 'Cocktails')
      let mocktails = drinksList.find((drink) => drink.label === 'Mocktails')

      setCocktailsOptions([cocktails])
      setMocktailsOptions([mocktails])
    }
  }, [dishesList])

  const [selectedCocktails, setSelectedCocktails] = useState([])
  const [selectedMocktails, setSelectedMocktails] = useState([])

  useEffect(() => {
    /**
     * set number of dishes selected
     */
    const selected = [...selectedCocktails, ...selectedMocktails]
    const dishesArray = shapeSelectedDishesForSummaryAPI(
      selected,
      props.lunchOrDinner
    )

    if (
      meal !== 'breakfast' &&
      meal !== 'dinner' &&
      meal !== 'lunch' &&
      meal !== ''
    ) {
      let key = 'lunchDishes'
      if (props.lunchOrDinner === 'dinner') {
        key = 'dinnerDishes'
      }
      if (props.lunchOrDinner === 'breakfast') {
        key = 'breakfastDishes'
      }

      if (key === 'lunchDishes') {
        dispatch(bookingBartenderModalSlice.actions.setLunchDishes(dishesArray))
        dispatch(bookingBartenderModalSlice.actions.setCuisineIds(cuisineIds))
      } else if (key === 'dinnerDishes') {
        dispatch(
          bookingBartenderModalSlice.actions.setDinnerDishes(dishesArray)
        )
        dispatch(bookingBartenderModalSlice.actions.setCuisineIds(cuisineIds))
      } else {
        dispatch(
          bookingBartenderModalSlice.actions.setBreakFastDishes(dishesArray)
        )
        dispatch(bookingBartenderModalSlice.actions.setCuisineIds(cuisineIds))
      }
    } else {
      dispatch(
        bookingBartenderModalSlice.actions.setDesignMenu({
          dishDetails: dishesArray,
          cuisineIds,
        })
      )
    }
  }, [selectedCocktails, selectedMocktails])

  useEffect(() => {
    dispatch(bookingBartenderModalSlice.actions.resetDesignMenu())
  }, [meal])

  const groupBadgeStyles = {
    borderRadius: '2em',
    color: '#999',
    display: 'inline-block',
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
    textTransform: 'lowercase',
  }

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
      &nbsp;
      <span style={groupBadgeStyles}>{data.options.length} items</span>
    </div>
  )

  const Option = (props: {
    // for futrue ref props inherited from helper/bookingmodal.helper
    data: {
      image: string
      label: string
      is_veg: boolean
      special_appliances: any[]
      cost: number
      is_recommended: boolean
      name: string
      price: number
      served_with: Array<{
        id: number
        served_with_dish_id: string
        name: string
        quantity: number
      }>
    }
  }) => {
    const {
      image,
      is_veg,
      name,
      is_recommended,
      special_appliances,
      served_with,
    } = props?.data
    const served = served_with?.map((ingredient, index) => (
      <span key={ingredient.id}>
        {ingredient.name}
        {served_with?.length === index + 1 ? ' ' : ', '}
      </span>
    ))
    // console.log('recommended', is_recommended)
    const appliancesRequired = special_appliances.map((appliance) => {
      if (appliance.alternative_appliance) {
        return `${appliance.name} or ${appliance.alternative_appliance.name} `
      }
      return appliance.name
    })

    return (
      <components.Option {...props}>
        <Row>
          <Col className="col-auto">
            <img src={image} alt={name} className={styles.dish__img} />
          </Col>
          {/* <Col xs={1}> */}
          <img
            src={
              is_veg
                ? 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/veg_icon.png'
                : 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/non_veg_icon.png'
            }
            alt={`${is_veg ? 'veg' : 'nonVeg'} dish`}
            className={styles.veg__nonveg__icon}
          />
          &nbsp;
          {/* </Col> */}
          {/* &nbsp; &nbsp; */}
          <Col className="pl-0">
            <Row>
              <Col className={styles.dish__name}>
                {name}{' '}
                {is_recommended === true ? (
                  <small
                    style={{
                      padding: '2px',
                      backgroundColor: '#EDF7EF',
                      color: '#59AE6A',
                      borderRadius: '50px',
                    }}
                  >
                    Recommended
                  </small>
                ) : (
                  <span></span>
                )}
              </Col>
              {/* <Col className={`${styles.dish__name}`} xs={4} md={6} lg={8}>
                {price ? <>₹{price}</> : null}
              </Col> */}
            </Row>
            <Row>
              {/* &nbsp; &nbsp; &nbsp; */}
              <Col className="FONT__SIZE__12 LIGHT__GREY__COLOR">
                <Row>
                  <Col>
                    <p className={`${styles.served_with} mb-0`}>
                      {served?.length > 0 ? (
                        <>
                          served with&nbsp;
                          {served}
                        </>
                      ) : (
                        <>
                          <br />
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className={styles.ingredients__oven_required}>
                      <p style={{ marginBottom: '0px' }}>
                        {special_appliances.length > 0 ? (
                          <>
                            <span>
                              requires {appliancesRequired.join(', ')}
                            </span>
                          </>
                        ) : (
                          <br />
                        )}
                      </p>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </components.Option>
    )
  }

  const noOptionsMessageString = (
    <p className="text-left mb-0 my-2">No options to choose from</p>
  )

  // to reduce font-size when a item is selected
  const fontStyles =
    screen.width > 968
      ? {
          control: (styles) => ({ ...styles, fontSize: '0.7rem' }),
          placeholder: (styles) => ({
            ...styles,
            fontSize: '1rem',
          }),
          option: (styles) => ({
            ...styles,
            overflowX: 'hidden',
            paddingBottom: '0px',
          }),
          dropdownIndicator: (styles) => ({ ...styles, color: 'black' }),
        }
      : {
          control: (styles) => ({ ...styles, fontSize: '13px' }),
          placeholder: (styles) => ({
            ...styles,
            fontSize: '13px',
          }),
          option: (styles) => ({
            ...styles,
            fontSize: '13px',
            overflowX: 'hidden',
            paddingBottom: '0px',
          }),
          dropdownIndicator: (styles) => ({ ...styles, color: 'black' }),
        }

  const renderDropDowns = (
    <>
      <br />
      <h5 className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}>
        Cocktails
        <span className={`${styles.font__size__14} text-muted ml-1`}>
          (optional)
        </span>
      </h5>
      <div className="form-group">
        <Select
          value={selectedCocktails}
          isMulti
          components={{ Option }}
          name="Cocktails"
          options={cocktailsOptions}
          styles={fontStyles}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(data) => setSelectedCocktails(data ?? [])}
          closeMenuOnSelect={false}
          placeholder="Select from dropdown list"
          noOptionsMessage={() => noOptionsMessageString}
          blurInputOnSelect={false}
          isSearchable={false}
          formatGroupLabel={formatGroupLabel}
          isClearable={false}
        />
      </div>
      <h5 className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}>
        Mocktails
        <span className={`${styles.font__size__14} text-muted ml-1`}>
          (optional)
        </span>
      </h5>
      <div className="form-group">
        <Select
          value={selectedMocktails}
          isMulti
          components={{ Option }}
          name="Mocktails"
          options={mocktailsOptions}
          styles={fontStyles}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(data) => setSelectedMocktails(data ?? [])}
          closeMenuOnSelect={false}
          placeholder="Select from dropdown list"
          noOptionsMessage={() => noOptionsMessageString}
          blurInputOnSelect={false}
          isSearchable={false}
          formatGroupLabel={formatGroupLabel}
          isClearable={false}
        />
      </div>
    </>
  )

  return (
    <div>
      {renderDropDowns}
      <br />
      <Row>
        <Col className="text-left pr-0" md={3} xs={8}>
          <label className={`text-muted mb-0 ${styles.font__size__14}`}>
            <img
              style={{ height: '10px', width: 'auto' }}
              src={tickMark}
              alt="tick"
            />{' '}
            Includes serving juice, soft drinks, etc.
          </label>
        </Col>
      </Row>
    </div>
  )
}

export default DesignMenuLogic
