import { useEffect, useState } from 'react'

// STYLES
import styles from './index.module.css'
import bmStyles from '../../../index.module.css'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

// REACT SELECT
import Select, { components } from 'react-select'

// IS VEG SWITCH
import IsVegSwitch from '@/components/IsVegSwitch'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'

// AXIOS
import axios from 'axios'

// HELPERs
import {
  getCuisineIdsByComboId,
  shapeSelectedDishesForSummaryAPI,
} from '@/components/BookingModals/BookChefModal/helpers'

import { RootState } from '@/lib/rootState'

interface Props {
  lunchOrDinner: 'dinner' | 'lunch' | 'breakfast'
}

// const whatsappSrc =
//   'https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20'

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const DesignMenuLogic = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  const REDUX__bookingDetails = useSelector(
    (state: RootState) => state.bookingModal.bookingDetails
  )
  const REDUX__bookingLevel = useSelector(
    (state: RootState) => state.bookingModal.bookingLevel
  )
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )
  const {
    occasionId,
    meal,
    bookingLevel,
    numberOfBurners,
    is_veg,
    comboIds,
    is_fried,
    specialComboId,
  } = REDUX__bookingDetails
  const { menuData } = REDUX__bookingModalData
  const [cuisineIds, setCuisineIds] = useState([])
  const [autoFillDishes, setAutoFillDishes] = useState(true)
  const [miniPack, setMiniPack] = useState(false)
  const [partyPack, setPartyPack] = useState(false)
  const [jumboPack, setJumboPack] = useState(false)
  const combos = useSelector((state: RootState) => state.apiData.combos)
  const unPublishedCombos = useSelector(
    (state: RootState) => state.apiData.unPublishedCombos
  )

  const [loading, setLoading] = useState(true)
  const [dishesList, setDishesList] = useState([])
  const [maincourseOption, setMainCourseOptions] = useState([])
  const [appetizerOption, setAppetizerOptions] = useState([])
  const [breadOption, setBreadOptions] = useState([])
  const [beverageOption, setBeverageOptions] = useState([])
  const [desserOption, setDessertOptions] = useState([])
  const [breakfastOption, setBreakfastOptions] = useState([])

  const handleChangeCheckbox = () => {
    setAutoFillDishes(!autoFillDishes)
  }
  useEffect(() => {
    if (comboIds.includes(9)) {
      setMiniPack(false)
      setPartyPack(false)
      setJumboPack(false)
    } else {
      setAutoFillDishes(false)
      setMiniPack(false)
      setPartyPack(false)
      setJumboPack(false)
    }
  }, [comboIds, meal, props.lunchOrDinner])

  useEffect(() => {
    if (
      meal == 'breakfast' ||
      meal == 'breakfast_lunch' ||
      meal == 'breakfast_dinner' ||
      meal == 'breakfast_lunch_dinner'
    ) {
      let transformBreakfast =
        menuData?.meal_types?.breakfast?.types?.breakfast.filter(
          (dish: any) => {
            if (
              is_fried == 'yes' ||
              (is_fried == 'no' && dish.fried !== 'Deep')
            ) {
              if (
                (is_veg === 'veg' && dish.is_veg === 1) ||
                is_veg === 'nonveg'
              ) {
                return {
                  cuisine_name: dish.cuisine_name,
                  value: dish?.id,
                  label: dish?.name + ' (₹' + dish?.price + ')',
                  name: dish?.name,
                  special_appliances: dish?.special_appliances,
                  cost: dish?.cost,
                  price: dish?.price,
                  type: dish?.type,
                  is_veg: dish?.is_veg,
                  is_recommended: dish?.is_recommended,
                  image: dish?.image,
                  dish_type_id: dish?.dish_type_id,
                  served_with: dish?.served_with,
                  fried: dish?.fried,
                  requires_gas: dish?.requires_gas,
                }
              }
            }
          }
        ) ?? []
      let transformFilteredBreakfast = transformBreakfast.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const breakfastgroups = transformFilteredBreakfast.reduce(
        (breakfastgroups, item) => {
          const group = breakfastgroups[item.cuisine_name] || []
          group.push(item)
          breakfastgroups[item.cuisine_name] = group
          return breakfastgroups
        },
        {}
      )
      const breakfastDishes = []
      Object.keys(breakfastgroups).forEach(function (prop) {
        breakfastDishes.push({
          label: prop,
          options: breakfastgroups[prop],
        })
      })

      setBreakfastOptions(breakfastDishes)
      let transformBeverages =
        menuData?.meal_types?.breakfast?.types?.soups_and_beverages.filter(
          (dish: any) => {
            if (
              is_fried == 'yes' ||
              (is_fried == 'no' && dish.fried !== 'Deep')
            ) {
              if (
                (is_veg === 'veg' && dish.is_veg === 1) ||
                is_veg === 'nonveg'
              ) {
                return {
                  cuisine_name: dish.cuisine_name,
                  value: dish?.id,
                  label: dish?.name + ' (₹' + dish?.price + ')',
                  name: dish?.name,
                  special_appliances: dish?.special_appliances,
                  cost: dish?.cost,
                  price: dish?.price,
                  type: dish?.type,
                  is_veg: dish?.is_veg,
                  is_recommended: dish?.is_recommended,
                  image: dish?.image,
                  dish_type_id: dish?.dish_type_id,
                  served_with: dish?.served_with,
                  fried: dish?.fried,
                  requires_gas: dish?.requires_gas,
                }
              }
            }
          }
        ) ?? []
      let transformFilteredBeverages = transformBeverages.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const beveragegroups = transformFilteredBeverages.reduce(
        (beveragegroups, item) => {
          const group = beveragegroups[item.cuisine_name] || []
          group.push(item)
          beveragegroups[item.cuisine_name] = group
          return beveragegroups
        },
        {}
      )
      const beveragessDishes = []
      Object.keys(beveragegroups).forEach(function (prop) {
        beveragessDishes.push({
          label: prop,
          options: beveragegroups[prop],
        })
      })
      setBeverageOptions(beveragessDishes)
    }
    if (
      meal == 'lunch' ||
      meal == 'breakfast_lunch' ||
      meal == 'lunch_dinner' ||
      meal == 'breakfast_lunch_dinner'
    ) {
      let transformAppetizers =
        menuData?.meal_types?.lunch?.types?.appetizer.filter((dish: any) => {
          if (
            is_fried == 'yes' ||
            (is_fried == 'no' && dish.fried !== 'Deep')
          ) {
            if (
              (is_veg === 'veg' && dish.is_veg === 1) ||
              is_veg === 'nonveg'
            ) {
              return {
                cuisine_name: dish.cuisine_name,
                value: dish?.id,
                label: dish?.name + ' (₹' + dish?.price + ')',
                name: dish?.name,
                special_appliances: dish?.special_appliances,
                cost: dish?.cost,
                price: dish?.price,
                type: dish?.type,
                is_veg: dish?.is_veg,
                is_recommended: dish?.is_recommended,
                image: dish?.image,
                dish_type_id: dish?.dish_type_id,
                served_with: dish?.served_with,
                fried: dish?.fried,
                requires_gas: dish?.requires_gas,
              }
            }
          }
        }) ?? []
      let transformFilteredAppetizers = transformAppetizers.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const appetizergroups = transformFilteredAppetizers.reduce(
        (appetizergroups, item) => {
          const group = appetizergroups[item.cuisine_name] || []
          group.push(item)
          appetizergroups[item.cuisine_name] = group
          return appetizergroups
        },
        {}
      )
      const appetizerDishes = []
      Object.keys(appetizergroups).forEach(function (prop) {
        appetizerDishes.push({
          label: prop,
          options: appetizergroups[prop],
        })
      })
      setAppetizerOptions(appetizerDishes)
      let transformMainCourse =
        menuData?.meal_types?.lunch?.types?.maincourse.filter((dish: any) => {
          if (
            is_fried == 'yes' ||
            (is_fried == 'no' && dish.fried !== 'Deep')
          ) {
            if (
              (is_veg === 'veg' && dish.is_veg === 1) ||
              is_veg === 'nonveg'
            ) {
              return {
                cuisine_name: dish.cuisine_name,
                value: dish?.id,
                label: dish?.name + ' (₹' + dish?.price + ')',
                name: dish?.name,
                special_appliances: dish?.special_appliances,
                cost: dish?.cost,
                price: dish?.price,
                type: dish?.type,
                is_veg: dish?.is_veg,
                is_recommended: dish?.is_recommended,
                image: dish?.image,
                dish_type_id: dish?.dish_type_id,
                served_with: dish?.served_with,
                fried: dish?.fried,
                requires_gas: dish?.requires_gas,
              }
            }
          }
        }) ?? []
      let transformFilteredMainCourse = transformMainCourse.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const maincoursegroups = transformFilteredMainCourse.reduce(
        (maincoursegroups, item) => {
          const group = maincoursegroups[item.cuisine_name] || []
          group.push(item)
          maincoursegroups[item.cuisine_name] = group
          return maincoursegroups
        },
        {}
      )
      const maincourseDishes = []
      Object.keys(maincoursegroups).forEach(function (prop) {
        maincourseDishes.push({
          label: prop,
          options: maincoursegroups[prop],
        })
      })
      setMainCourseOptions(maincourseDishes)
      let transformSoups =
        menuData?.meal_types?.lunch?.types?.soups_and_beverages.filter(
          (dish: any) => {
            if (
              is_fried == 'yes' ||
              (is_fried == 'no' && dish.fried !== 'Deep')
            ) {
              if (
                (is_veg === 'veg' && dish.is_veg === 1) ||
                is_veg === 'nonveg'
              ) {
                return {
                  cuisine_name: dish.cuisine_name,
                  value: dish?.id,
                  label: dish?.name + ' (₹' + dish?.price + ')',
                  name: dish?.name,
                  special_appliances: dish?.special_appliances,
                  cost: dish?.cost,
                  price: dish?.price,
                  type: dish?.type,
                  is_veg: dish?.is_veg,
                  is_recommended: dish?.is_recommended,
                  image: dish?.image,
                  dish_type_id: dish?.dish_type_id,
                  served_with: dish?.served_with,
                  fried: dish?.fried,
                  requires_gas: dish?.requires_gas,
                }
              }
            }
          }
        ) ?? []
      let transformFilteredSoups = transformSoups.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const soupsgroups = transformFilteredSoups.reduce((soupsgroups, item) => {
        const group = soupsgroups[item.cuisine_name] || []
        group.push(item)
        soupsgroups[item.cuisine_name] = group
        return soupsgroups
      }, {})
      const soupsDishes = []
      Object.keys(soupsgroups).forEach(function (prop) {
        soupsDishes.push({
          label: prop,
          options: soupsgroups[prop],
        })
      })
      setBeverageOptions(soupsDishes)
      let transformBreads =
        menuData?.meal_types?.lunch?.types?.breads_rice_and_raita.filter(
          (dish: any) => {
            if (
              is_fried == 'yes' ||
              (is_fried == 'no' && dish.fried !== 'Deep')
            ) {
              if (
                (is_veg === 'veg' && dish.is_veg === 1) ||
                is_veg === 'nonveg'
              ) {
                return {
                  cuisine_name: dish.cuisine_name,
                  value: dish?.id,
                  label: dish?.name + ' (₹' + dish?.price + ')',
                  name: dish?.name,
                  special_appliances: dish?.special_appliances,
                  cost: dish?.cost,
                  price: dish?.price,
                  type: dish?.type,
                  is_veg: dish?.is_veg,
                  is_recommended: dish?.is_recommended,
                  image: dish?.image,
                  dish_type_id: dish?.dish_type_id,
                  served_with: dish?.served_with,
                  fried: dish?.fried,
                  requires_gas: dish?.requires_gas,
                }
              }
            }
          }
        ) ?? []
      let transformFilteredBreads = transformBreads.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const breadgroups = transformFilteredBreads.reduce(
        (breadgroups, item) => {
          const group = breadgroups[item.cuisine_name] || []
          group.push(item)
          breadgroups[item.cuisine_name] = group
          return breadgroups
        },
        {}
      )
      const breadDishes = []
      Object.keys(breadgroups).forEach(function (prop) {
        breadDishes.push({
          label: prop,
          options: breadgroups[prop],
        })
      })
      setBreadOptions(breadDishes)
      let transformDessert =
        menuData?.meal_types?.lunch?.types?.dessert.filter((dish: any) => {
          if (
            is_fried == 'yes' ||
            (is_fried == 'no' && dish.fried !== 'Deep')
          ) {
            if (
              (is_veg === 'veg' && dish.is_veg === 1) ||
              is_veg === 'nonveg'
            ) {
              return {
                cuisine_name: dish.cuisine_name,
                value: dish?.id,
                label: dish?.name + ' (₹' + dish?.price + ')',
                name: dish?.name,
                special_appliances: dish?.special_appliances,
                cost: dish?.cost,
                price: dish?.price,
                type: dish?.type,
                is_veg: dish?.is_veg,
                is_recommended: dish?.is_recommended,
                image: dish?.image,
                dish_type_id: dish?.dish_type_id,
                served_with: dish?.served_with,
                fried: dish?.fried,
                requires_gas: dish?.requires_gas,
              }
            }
          }
        }) ?? []
      let transformFilteredDessert = transformDessert.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const dessertgroups = transformFilteredDessert.reduce(
        (dessertgroups, item) => {
          const group = dessertgroups[item.cuisine_name] || []
          group.push(item)
          dessertgroups[item.cuisine_name] = group
          return dessertgroups
        },
        {}
      )
      const dessertDishes = []
      Object.keys(dessertgroups).forEach(function (prop) {
        dessertDishes.push({
          label: prop,
          options: dessertgroups[prop],
        })
      })
      setDessertOptions(dessertDishes)
    }
    if (
      meal == 'dinner' ||
      meal == 'breakfast_dinner' ||
      meal == 'lunch_dinner' ||
      meal == 'breakfast_lunch_dinner'
    ) {
      let transformAppetizers =
        menuData?.meal_types?.dinner?.types?.appetizer.filter((dish: any) => {
          if (
            is_fried == 'yes' ||
            (is_fried == 'no' && dish.fried !== 'Deep')
          ) {
            if (
              (is_veg === 'veg' && dish.is_veg === 1) ||
              is_veg === 'nonveg'
            ) {
              return {
                cuisine_name: dish.cuisine_name,
                value: dish?.id,
                label: dish?.name + ' (₹' + dish?.price + ')',
                name: dish?.name,
                special_appliances: dish?.special_appliances,
                cost: dish?.cost,
                price: dish?.price,
                type: dish?.type,
                is_veg: dish?.is_veg,
                is_recommended: dish?.is_recommended,
                image: dish?.image,
                dish_type_id: dish?.dish_type_id,
                served_with: dish?.served_with,
                fried: dish?.fried,
                requires_gas: dish?.requires_gas,
              }
            }
          }
        }) ?? []
      let transformFilteredAppetizers = transformAppetizers.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const appetizergroups = transformFilteredAppetizers.reduce(
        (appetizergroups, item) => {
          const group = appetizergroups[item.cuisine_name] || []
          group.push(item)
          appetizergroups[item.cuisine_name] = group
          return appetizergroups
        },
        {}
      )
      const appetizerDishes = []
      Object.keys(appetizergroups).forEach(function (prop) {
        appetizerDishes.push({
          label: prop,
          options: appetizergroups[prop],
        })
      })
      setAppetizerOptions(appetizerDishes)
      // setAppetizerOptions(transformFilteredAppetizers)
      let transformMainCourse =
        menuData?.meal_types?.dinner?.types?.maincourse.filter((dish: any) => {
          if (
            is_fried == 'yes' ||
            (is_fried == 'no' && dish.fried !== 'Deep')
          ) {
            if (
              (is_veg === 'veg' && dish.is_veg === 1) ||
              is_veg === 'nonveg'
            ) {
              return {
                cuisine_name: dish.cuisine_name,
                value: dish?.id,
                label: dish?.name + ' (₹' + dish?.price + ')',
                name: dish?.name,
                special_appliances: dish?.special_appliances,
                cost: dish?.cost,
                price: dish?.price,
                type: dish?.type,
                is_veg: dish?.is_veg,
                is_recommended: dish?.is_recommended,
                image: dish?.image,
                dish_type_id: dish?.dish_type_id,
                served_with: dish?.served_with,
                fried: dish?.fried,
                requires_gas: dish?.requires_gas,
              }
            }
          }
        }) ?? []
      let transformFilteredMainCourse = transformMainCourse.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const maincoursegroups = transformFilteredMainCourse.reduce(
        (maincoursegroups, item) => {
          const group = maincoursegroups[item.cuisine_name] || []
          group.push(item)
          maincoursegroups[item.cuisine_name] = group
          return maincoursegroups
        },
        {}
      )
      const maincourseDishes = []
      Object.keys(maincoursegroups).forEach(function (prop) {
        maincourseDishes.push({
          label: prop,
          options: maincoursegroups[prop],
        })
      })
      setMainCourseOptions(maincourseDishes)
      // setMainCourseOptions(transformFilteredMainCourse)
      let transformSoups =
        menuData?.meal_types?.dinner?.types?.soups_and_beverages.filter(
          (dish: any) => {
            if (
              is_fried == 'yes' ||
              (is_fried == 'no' && dish.fried !== 'Deep')
            ) {
              if (
                (is_veg === 'veg' && dish.is_veg === 1) ||
                is_veg === 'nonveg'
              ) {
                return {
                  cuisine_name: dish.cuisine_name,
                  value: dish?.id,
                  label: dish?.name + ' (₹' + dish?.price + ')',
                  name: dish?.name,
                  special_appliances: dish?.special_appliances,
                  cost: dish?.cost,
                  price: dish?.price,
                  type: dish?.type,
                  is_veg: dish?.is_veg,
                  is_recommended: dish?.is_recommended,
                  image: dish?.image,
                  dish_type_id: dish?.dish_type_id,
                  served_with: dish?.served_with,
                  fried: dish?.fried,
                  requires_gas: dish?.requires_gas,
                }
              }
            }
          }
        ) ?? []
      let transformFilteredSoups = transformSoups.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const soupsgroups = transformFilteredSoups.reduce((soupsgroups, item) => {
        const group = soupsgroups[item.cuisine_name] || []
        group.push(item)
        soupsgroups[item.cuisine_name] = group
        return soupsgroups
      }, {})
      const soupsDishes = []
      Object.keys(soupsgroups).forEach(function (prop) {
        soupsDishes.push({
          label: prop,
          options: soupsgroups[prop],
        })
      })
      setBeverageOptions(soupsDishes)
      // setBeverageOptions(transformFilteredSoups)
      let transformBreads =
        menuData?.meal_types?.dinner?.types?.breads_rice_and_raita.filter(
          (dish: any) => {
            if (
              is_fried == 'yes' ||
              (is_fried == 'no' && dish.fried !== 'Deep')
            ) {
              if (
                (is_veg === 'veg' && dish.is_veg === 1) ||
                is_veg === 'nonveg'
              ) {
                return {
                  cuisine_name: dish.cuisine_name,
                  value: dish?.id,
                  label: dish?.name + ' (₹' + dish?.price + ')',
                  name: dish?.name,
                  special_appliances: dish?.special_appliances,
                  cost: dish?.cost,
                  price: dish?.price,
                  type: dish?.type,
                  is_veg: dish?.is_veg,
                  is_recommended: dish?.is_recommended,
                  image: dish?.image,
                  dish_type_id: dish?.dish_type_id,
                  served_with: dish?.served_with,
                  fried: dish?.fried,
                  requires_gas: dish?.requires_gas,
                }
              }
            }
          }
        ) ?? []
      let transformFilteredBreads = transformBreads.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const breadgroups = transformFilteredBreads.reduce(
        (breadgroups, item) => {
          const group = breadgroups[item.cuisine_name] || []
          group.push(item)
          breadgroups[item.cuisine_name] = group
          return breadgroups
        },
        {}
      )
      const breadDishes = []
      Object.keys(breadgroups).forEach(function (prop) {
        breadDishes.push({
          label: prop,
          options: breadgroups[prop],
        })
      })
      setBreadOptions(breadDishes)
      // setBreadOptions(transformFilteredBreads)
      let transformDessert =
        menuData?.meal_types?.dinner?.types?.dessert.filter((dish: any) => {
          if (
            is_fried == 'yes' ||
            (is_fried == 'no' && dish.fried !== 'Deep')
          ) {
            if (
              (is_veg === 'veg' && dish.is_veg === 1) ||
              is_veg === 'nonveg'
            ) {
              return {
                cuisine_name: dish.cuisine_name,
                value: dish?.id,
                label: dish?.name + ' (₹' + dish?.price + ')',
                name: dish?.name,
                special_appliances: dish?.special_appliances,
                cost: dish?.cost,
                price: dish?.price,
                type: dish?.type,
                is_veg: dish?.is_veg,
                is_recommended: dish?.is_recommended,
                image: dish?.image,
                dish_type_id: dish?.dish_type_id,
                served_with: dish?.served_with,
                fried: dish?.fried,
                requires_gas: dish?.requires_gas,
              }
            }
          }
        }) ?? []
      let transformFilteredDessert = transformDessert.map((dish: any) => {
        return {
          cuisine_name: dish.cuisine_name,
          value: dish?.id,
          label: dish?.name + ' (₹' + dish?.price + ')',
          name: dish?.name,
          special_appliances: dish?.special_appliances,
          cost: dish?.cost,
          price: dish?.price,
          type: dish?.type,
          is_veg: dish?.is_veg,
          is_recommended: dish?.is_recommended,
          image: dish?.image,
          dish_type_id: dish?.dish_type_id,
          served_with: dish?.served_with,
          fried: dish?.fried,
          requires_gas: dish?.requires_gas,
        }
      })
      const dessertgroups = transformFilteredDessert.reduce(
        (dessertgroups, item) => {
          const group = dessertgroups[item.cuisine_name] || []
          group.push(item)
          dessertgroups[item.cuisine_name] = group
          return dessertgroups
        },
        {}
      )
      const dessertDishes = []
      Object.keys(dessertgroups).forEach(function (prop) {
        dessertDishes.push({
          label: prop,
          options: dessertgroups[prop],
        })
      })
      setDessertOptions(dessertDishes)
      // setDessertOptions(transformFilteredDessert)
    }
    setLoading(false)
  }, [is_fried, is_veg, menuData])

  // useEffect(()=>{
  //   if(meal == 'breakfast' || meal == 'breakfast_lunch' || meal == 'breakfast_dinner' || meal == 'breakfast_lunch_dinner'){
  //     let transformBreakfast = menuData.meal_types.breakfast.types.breakfast.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setBreakfastOptions(transformBreakfast)
  //     let transformBeverages = menuData.meal_types.breakfast.types.soups_and_beverages.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setBeverageOptions(transformBeverages)
  //   }
  //   if(meal == 'lunch' || meal == 'breakfast_lunch' || meal == 'lunch_dinner' || meal == 'breakfast_lunch_dinner'){
  //     let transformAppetizers = menuData.meal_types.lunch.types.appetizer.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setAppetizerOptions(transformAppetizers)
  //     let transformMainCourse = menuData.meal_types.lunch.types.maincourse.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setMainCourseOptions(transformMainCourse)
  //     let transformSoups = menuData.meal_types.lunch.types.soups_and_beverages.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setBeverageOptions(transformSoups)
  //     let transformBreads = menuData.meal_types.lunch.types.breads_rice_and_raita.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setBreadOptions(transformBreads)
  //     let transformDessert = menuData.meal_types.lunch.types.dessert.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setDessertOptions(transformDessert)
  //   }
  //   if(meal == 'dinner' || meal == 'breakfast_dinner' || meal == 'lunch_dinner' || meal == 'breakfast_lunch_dinner'){
  //     let transformAppetizers = menuData.meal_types.dinner.types.appetizer.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setAppetizerOptions(transformAppetizers)
  //     let transformMainCourse = menuData.meal_types.dinner.types.maincourse.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setMainCourseOptions(transformMainCourse)
  //     let transformSoups = menuData.meal_types.dinner.types.soups_and_beverages.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setBeverageOptions(transformSoups)
  //     let transformBreads = menuData.meal_types.dinner.types.breads_rice_and_raita.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setBreadOptions(transformBreads)
  //     let transformDessert = menuData.meal_types.dinner.types.dessert.map((dish : any)=>{
  //       if((is_fried == 'yes') || (is_fried == 'no' && dish.fried !== 'Deep')){
  //         if((is_veg === 'veg' && dish.is_veg === 1) || is_veg === 'nonveg' ){
  //           return ({
  //             cuisine_name: dish.cuisine_name,
  //             value: dish?.id,
  //             label:
  //               dish?.name +
  //               ' (₹' +
  //               dish?.price +
  //               ')',
  //             name: dish?.name,
  //             special_appliances: dish?.special_appliances,
  //             cost: dish?.cost,
  //             price: dish?.price,
  //             type: dish?.type,
  //             is_veg: dish?.is_veg,
  //             is_recommended: dish?.is_recommended,
  //             image: dish?.image,
  //             dish_type_id: dish?.dish_type_id,
  //             served_with: dish?.served_with,
  //             fried: dish?.fried,
  //             requires_gas: dish?.requires_gas,
  //           })
  //         }
  //       }
  //     })
  //     setDessertOptions(transformDessert)
  //   }
  //   setLoading(false)
  // },[menuData])

  const [selectedAppetizers, setSelectedAppetizers] = useState([])
  const [selectedMainCourse, setSelectedMainCourse] = useState([])
  const [selectedDesserts, setSelectedDesserts] = useState([])
  const [selectedBeverages, setSelectedBeverages] = useState([])
  const [selectedBreads, setSelectedBreads] = useState([])
  const [selectedBreakfast, setSelectedBreakfast] = useState([])

  const [showBeverages, setShowBeverages] = useState(false)
  const [showDesserts, setShowDesserts] = useState(false)

  // useEffect(() => {
  //   if(autoFillDishes === false){
  //     if(props.lunchOrDinner === 'dinner'){
  //       setSelectedBeverages([])
  //       setSelectedBreads([])
  //       setSelectedAppetizers([])
  //       setSelectedMainCourse([])
  //       setSelectedDesserts([])
  //     }else if(props.lunchOrDinner === 'lunch'){
  //       setSelectedBeverages([])
  //       setSelectedBreads([])
  //       setSelectedAppetizers([])
  //       setSelectedMainCourse([])
  //       setSelectedDesserts([])
  //     }else if(props.lunchOrDinner === 'breakfast'){
  //       setSelectedBeverages([])
  //       setSelectedBreakfast([])
  //     }
  //   }else{
  //     setAutoFillDishes(true)
  //     if(props.lunchOrDinner === 'dinner'){
  //       if(menuData?.meal_types?.dinner?.types?.selected_soups_and_beverages){
  //         setSelectedBeverages([...menuData?.meal_types?.dinner?.types?.selected_soups_and_beverages])
  //       }
  //       if(menuData?.meal_types?.dinner?.types?.selected_breads_rice_and_raita){
  //         setSelectedBreads([...menuData?.meal_types?.dinner?.types?.selected_breads_rice_and_raita])
  //       }
  //       if(menuData?.meal_types?.dinner?.types?.selected_appetizer){
  //         setSelectedAppetizers([...menuData?.meal_types?.dinner?.types?.selected_appetizer])
  //       }
  //       if(menuData?.meal_types?.dinner?.types?.selected_maincourse){
  //         setSelectedMainCourse([...menuData?.meal_types?.dinner?.types?.selected_maincourse])
  //       }
  //       if(menuData?.meal_types?.lunch?.types?.selected_dessert){
  //         setSelectedDesserts([...menuData?.meal_types?.lunch?.types?.selected_dessert])
  //       }
  //     }else if(props.lunchOrDinner === 'lunch'){
  //       if(menuData?.meal_types?.lunch?.types?.selected_soups_and_beverages){
  //         setSelectedBeverages([...menuData?.meal_types?.lunch?.types?.selected_soups_and_beverages])
  //       }
  //       if(menuData?.meal_types?.lunch?.types?.selected_breads_rice_and_raita){
  //         setSelectedBreads([...menuData?.meal_types?.lunch?.types?.selected_breads_rice_and_raita])
  //       }
  //       if(menuData?.meal_types?.lunch?.types?.selected_appetizer){
  //         setSelectedAppetizers([...menuData?.meal_types?.lunch?.types?.selected_appetizer])
  //       }
  //       if(menuData?.meal_types?.lunch?.types?.selected_maincourse){
  //         setSelectedMainCourse([...menuData?.meal_types?.lunch?.types?.selected_maincourse])
  //       }
  //       if(menuData?.meal_types?.lunch?.types?.selected_dessert){
  //         setSelectedDesserts([...menuData?.meal_types?.lunch?.types?.selected_dessert])
  //       }
  //     }else if(props.lunchOrDinner === 'breakfast'){
  //       if(menuData?.meal_types?.breakfast?.types?.selected_soups_and_beverages){
  //         setSelectedBeverages([...menuData?.meal_types?.breakfast?.types?.selected_soups_and_beverages])
  //       }
  //       if(menuData?.meal_types?.breakfast?.types?.selected_breakfast){
  //         setSelectedBreakfast([...menuData?.meal_types?.breakfast?.types?.selected_breakfast])
  //       }
  //     }
  //   }
  // }, [autoFillDishes])

  useEffect(() => {
    if (miniPack === false && partyPack === false && jumboPack === false) {
      if (props.lunchOrDinner === 'dinner') {
        setSelectedBeverages([])
        setSelectedBreads([])
        setSelectedAppetizers([])
        setSelectedMainCourse([])
        setSelectedDesserts([])
      } else if (props.lunchOrDinner === 'lunch') {
        setSelectedBeverages([])
        setSelectedBreads([])
        setSelectedAppetizers([])
        setSelectedMainCourse([])
        setSelectedDesserts([])
      } else if (props.lunchOrDinner === 'breakfast') {
        setSelectedBeverages([])
        setSelectedBreakfast([])
      }
    } else {
      // setAutoFillDishes(true)
      if (props.lunchOrDinner === 'dinner') {
        if (miniPack === true) {
          // const miniSelectedBreakfast = menuData?.meal_types?.dinner?.packages.filter((dish) => dish.type === 'Breakfast')
          const miniSelectedSoups =
            menuData?.meal_types?.dinner?.packages?.mini.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          const miniSelectedAppetizer =
            menuData?.meal_types?.dinner?.packages?.mini.filter(
              (dish: any) => dish.type === 'Appetizer'
            ) ?? []
          const miniSelectedBreads =
            menuData?.meal_types?.dinner?.packages?.mini.filter(
              (dish: any) => dish.type === 'Breads, Rice and Raita'
            ) ?? []
          const miniSelectedMainCourse =
            menuData?.meal_types?.dinner?.packages?.mini.filter(
              (dish: any) => dish.type === 'Main course'
            ) ?? []
          const miniSelectedDessert =
            menuData?.meal_types?.dinner?.packages?.mini.filter(
              (dish: any) => dish.type === 'Dessert'
            ) ?? []
          setSelectedAppetizers(miniSelectedAppetizer)
          setSelectedBeverages(miniSelectedSoups)
          setSelectedBreads(miniSelectedBreads)
          setSelectedDesserts(miniSelectedDessert)
          setSelectedMainCourse(miniSelectedMainCourse)
        } else if (partyPack === true) {
          const partySelectedSoups =
            menuData?.meal_types?.dinner?.packages?.party.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          const partySelectedAppetizer =
            menuData?.meal_types?.dinner?.packages?.party.filter(
              (dish: any) => dish.type === 'Appetizer'
            ) ?? []
          const partySelectedBreads =
            menuData?.meal_types?.dinner?.packages?.party.filter(
              (dish: any) => dish.type === 'Breads, Rice and Raita'
            ) ?? []
          const partySelectedMainCourse =
            menuData?.meal_types?.dinner?.packages?.party.filter(
              (dish: any) => dish.type === 'Main course'
            ) ?? []
          const partySelectedDessert =
            menuData?.meal_types?.dinner?.packages?.party.filter(
              (dish: any) => dish.type === 'Dessert'
            ) ?? []
          setSelectedAppetizers(partySelectedAppetizer)
          setSelectedBeverages(partySelectedSoups)
          setSelectedBreads(partySelectedBreads)
          setSelectedDesserts(partySelectedDessert)
          setSelectedMainCourse(partySelectedMainCourse)
        } else if (jumboPack === true) {
          const jumboSelectedSoups =
            menuData?.meal_types?.dinner?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          const jumboSelectedAppetizer =
            menuData?.meal_types?.dinner?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Appetizer'
            ) ?? []
          const jumboSelectedBreads =
            menuData?.meal_types?.dinner?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Breads, Rice and Raita'
            ) ?? []
          const jumboSelectedMainCourse =
            menuData?.meal_types?.dinner?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Main course'
            ) ?? []
          const jumboSelectedDessert =
            menuData?.meal_types?.dinner?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Dessert'
            ) ?? []
          setSelectedAppetizers(jumboSelectedAppetizer)
          setSelectedBeverages(jumboSelectedSoups)
          setSelectedBreads(jumboSelectedBreads)
          setSelectedDesserts(jumboSelectedDessert)
          setSelectedMainCourse(jumboSelectedMainCourse)
        }
      } else if (props.lunchOrDinner === 'lunch') {
        if (miniPack === true) {
          // const miniSelectedBreakfast = menuData?.meal_types?.dinner?.packages.filter((dish) => dish.type === 'Breakfast')
          const miniSelectedSoups =
            menuData?.meal_types?.lunch?.packages?.mini.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          const miniSelectedAppetizer =
            menuData?.meal_types?.lunch?.packages?.mini.filter(
              (dish: any) => dish.type === 'Appetizer'
            ) ?? []
          const miniSelectedBreads =
            menuData?.meal_types?.lunch?.packages?.mini.filter(
              (dish: any) => dish.type === 'Breads, Rice and Raita'
            ) ?? []
          const miniSelectedMainCourse =
            menuData?.meal_types?.lunch?.packages?.mini.filter(
              (dish: any) => dish.type === 'Main course'
            ) ?? []
          const miniSelectedDessert =
            menuData?.meal_types?.lunch?.packages?.mini.filter(
              (dish: any) => dish.type === 'Dessert'
            ) ?? []
          setSelectedAppetizers(miniSelectedAppetizer)
          setSelectedBeverages(miniSelectedSoups)
          setSelectedBreads(miniSelectedBreads)
          setSelectedDesserts(miniSelectedDessert)
          setSelectedMainCourse(miniSelectedMainCourse)
        } else if (partyPack === true) {
          const partySelectedSoups =
            menuData?.meal_types?.lunch?.packages?.party.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          const partySelectedAppetizer =
            menuData?.meal_types?.lunch?.packages?.party.filter(
              (dish: any) => dish.type === 'Appetizer'
            ) ?? []
          const partySelectedBreads =
            menuData?.meal_types?.lunch?.packages?.party.filter(
              (dish: any) => dish.type === 'Breads, Rice and Raita'
            ) ?? []
          const partySelectedMainCourse =
            menuData?.meal_types?.lunch?.packages?.party.filter(
              (dish: any) => dish.type === 'Main course'
            ) ?? []
          const partySelectedDessert =
            menuData?.meal_types?.lunch?.packages?.party.filter(
              (dish: any) => dish.type === 'Dessert'
            ) ?? []
          setSelectedAppetizers(partySelectedAppetizer)
          setSelectedBeverages(partySelectedSoups)
          setSelectedBreads(partySelectedBreads)
          setSelectedDesserts(partySelectedDessert)
          setSelectedMainCourse(partySelectedMainCourse)
        } else if (jumboPack === true) {
          const jumboSelectedSoups =
            menuData?.meal_types?.lunch?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          const jumboSelectedAppetizer =
            menuData?.meal_types?.lunch?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Appetizer'
            ) ?? []
          const jumboSelectedBreads =
            menuData?.meal_types?.lunch?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Breads, Rice and Raita'
            ) ?? []
          const jumboSelectedMainCourse =
            menuData?.meal_types?.lunch?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Main course'
            ) ?? []
          const jumboSelectedDessert =
            menuData?.meal_types?.lunch?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Dessert'
            ) ?? []
          setSelectedAppetizers(jumboSelectedAppetizer)
          setSelectedBeverages(jumboSelectedSoups)
          setSelectedBreads(jumboSelectedBreads)
          setSelectedDesserts(jumboSelectedDessert)
          setSelectedMainCourse(jumboSelectedMainCourse)
        }
      } else if (props.lunchOrDinner === 'breakfast') {
        if (miniPack === true) {
          const miniSelectedBreakfast =
            menuData?.meal_types?.breakfast?.packages?.mini.filter(
              (dish) => dish.type === 'Breakfast'
            ) ?? []
          const miniSelectedSoups =
            menuData?.meal_types?.breakfast?.packages?.mini.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          setSelectedBreakfast(miniSelectedBreakfast)
          setSelectedBeverages(miniSelectedSoups)
        } else if (partyPack === true) {
          const partySelectedBreakfast =
            menuData?.meal_types?.breakfast?.packages?.party.filter(
              (dish) => dish.type === 'Breakfast'
            ) ?? []
          const partySelectedSoups =
            menuData?.meal_types?.breakfast?.packages?.party.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          setSelectedBreakfast(partySelectedBreakfast)
          setSelectedBeverages(partySelectedSoups)
        } else if (jumboPack === true) {
          const jumboSelectedBreakfast =
            menuData?.meal_types?.breakfast?.packages?.jumbo.filter(
              (dish) => dish.type === 'Breakfast'
            ) ?? []
          const jumboSelectedSoups =
            menuData?.meal_types?.breakfast?.packages?.jumbo.filter(
              (dish: any) => dish.type === 'Soups & Beverages'
            ) ?? []
          setSelectedBreakfast(jumboSelectedBreakfast)
          setSelectedBeverages(jumboSelectedSoups)
        }
      }
    }
  }, [miniPack, partyPack, jumboPack, comboIds, meal, props.lunchOrDinner])

  useEffect(() => {
    /**
     * Reset selected dishes whenever comboId or meal changes
     */
    if (comboIds.includes(specialComboId)) {
      setAutoFillDishes(true)
      if (props.lunchOrDinner === 'dinner') {
        if (menuData?.meal_types?.dinner?.types?.selected_soups_and_beverages) {
          setSelectedBeverages([
            ...menuData?.meal_types?.dinner?.types
              ?.selected_soups_and_beverages,
          ])
        }
        if (
          menuData?.meal_types?.dinner?.types?.selected_breads_rice_and_raita
        ) {
          setSelectedBreads([
            ...menuData?.meal_types?.dinner?.types
              ?.selected_breads_rice_and_raita,
          ])
        }
        if (menuData?.meal_types?.dinner?.types?.selected_appetizer) {
          setSelectedAppetizers([
            ...menuData?.meal_types?.dinner?.types?.selected_appetizer,
          ])
        }
        if (menuData?.meal_types?.dinner?.types?.selected_maincourse) {
          setSelectedMainCourse([
            ...menuData?.meal_types?.dinner?.types?.selected_maincourse,
          ])
        }
      } else if (props.lunchOrDinner === 'lunch') {
        if (menuData?.meal_types?.lunch?.types?.selected_soups_and_beverages) {
          setSelectedBeverages([
            ...menuData?.meal_types?.lunch?.types?.selected_soups_and_beverages,
          ])
        }
        if (
          menuData?.meal_types?.lunch?.types?.selected_breads_rice_and_raita
        ) {
          setSelectedBreads([
            ...menuData?.meal_types?.lunch?.types
              ?.selected_breads_rice_and_raita,
          ])
        }
        if (menuData?.meal_types?.lunch?.types?.selected_appetizer) {
          setSelectedAppetizers([
            ...menuData?.meal_types?.lunch?.types?.selected_appetizer,
          ])
        }
        if (menuData?.meal_types?.lunch?.types?.selected_maincourse) {
          setSelectedMainCourse([
            ...menuData?.meal_types?.lunch?.types?.selected_maincourse,
          ])
        }
      } else if (props.lunchOrDinner === 'breakfast') {
        if (
          menuData?.meal_types?.breakfast?.types?.selected_soups_and_beverages
        ) {
          setSelectedBeverages([
            ...menuData?.meal_types?.breakfast?.types
              ?.selected_soups_and_beverages,
          ])
        }
        if (menuData?.meal_types?.breakfast?.types?.selected_breakfast) {
          setSelectedBreakfast([
            ...menuData?.meal_types?.breakfast?.types?.selected_breakfast,
          ])
        }
      }
      setShowBeverages(false)
      setShowDesserts(false)

      if (props.lunchOrDinner === 'breakfast') {
        setShowBeverages(true)
      }
    }
  }, [comboIds, meal, menuData])

  useEffect(() => {
    /**
     * set number of dishes selected
     */
    const selected = [
      ...selectedAppetizers,
      ...selectedMainCourse,
      ...selectedDesserts,
      ...selectedBeverages,
      ...selectedBreads,
      ...selectedBreakfast,
    ]
    const dishesArray = shapeSelectedDishesForSummaryAPI(
      selected,
      props.lunchOrDinner
    )
    if (
      meal !== 'breakfast' &&
      meal !== 'dinner' &&
      meal !== 'lunch' &&
      meal !== ''
    ) {
      let key = 'lunchDishes'
      if (props.lunchOrDinner === 'dinner') {
        key = 'dinnerDishes'
      }
      if (props.lunchOrDinner === 'breakfast') {
        key = 'breakfastDishes'
      }

      if (key === 'lunchDishes') {
        dispatch(bookingModalSlice.actions.setLunchDishes(dishesArray))
        dispatch(bookingModalSlice.actions.setCuisineIds(cuisineIds))
      } else if (key === 'dinnerDishes') {
        dispatch(bookingModalSlice.actions.setDinnerDishes(dishesArray))
        dispatch(bookingModalSlice.actions.setCuisineIds(cuisineIds))
      } else {
        dispatch(bookingModalSlice.actions.setBreakFastDishes(dishesArray))
        dispatch(bookingModalSlice.actions.setCuisineIds(cuisineIds))
      }
    } else {
      if (meal === props.lunchOrDinner) {
        dispatch(
          bookingModalSlice.actions.setDesignMenu({
            dishDetails: dishesArray,
            cuisineIds,
          })
        )
      }
    }
  }, [
    selectedBeverages,
    selectedBreads,
    selectedAppetizers,
    selectedMainCourse,
    selectedDesserts,
    selectedBreakfast,
    cuisineIds,
    meal,
  ])

  useEffect(() => {
    dispatch(bookingModalSlice.actions.resetDesignMenu())
    // setAutoFillDishes(false)
  }, [meal])

  const groupBadgeStyles = {
    borderRadius: '2em',
    color: '#999',
    display: 'inline-block',
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
    textTransform: 'lowercase',
  }

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
      &nbsp;
      <span style={groupBadgeStyles}>{data.options.length} items</span>
    </div>
  )

  const Option = (props: {
    // for futrue ref props inherited from helper/bookingmodal.helper
    data: {
      image: string
      label: string
      is_veg: boolean
      special_appliances: any[]
      cost: number
      is_recommended: boolean
      name: string
      price: number
      served_with: Array<{
        id: number
        served_with_dish_id: string
        name: string
        quantity: number
      }>
    }
  }) => {
    const {
      image,
      label,
      is_veg,
      name,
      price,
      is_recommended,
      special_appliances,
      cost,
      served_with,
    } = props?.data
    const served = served_with?.map((ingredient, index) => (
      <span key={ingredient.id}>
        {ingredient.name}
        {served_with?.length === index + 1 ? ' ' : ', '}
      </span>
    ))
    const appliancesRequired = special_appliances.map((appliance) => {
      if (appliance.alternative_appliance) {
        return `${appliance.name} or ${appliance.alternative_appliance.name} `
      }
      return appliance.name
    })

    return (
      <components.Option {...props}>
        <Row>
          <Col className="col-auto">
            <img src={image} alt={name} className={styles.dish__img} />
          </Col>
          {/* <Col xs={1}> */}
          <img
            src={
              is_veg
                ? 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/veg_icon.png'
                : 'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/non_veg_icon.png'
            }
            alt={`${is_veg ? 'veg' : 'nonVeg'} dish`}
            className={styles.veg__nonveg__icon}
          />
          &nbsp;
          {/* </Col> */}
          {/* &nbsp; &nbsp; */}
          <Col className="pl-0">
            <Row>
              <Col className={styles.dish__name}>
                {name}{' '}
                {is_recommended === true ? (
                  <small
                    style={{
                      padding: '2px',
                      backgroundColor: '#EDF7EF',
                      color: '#59AE6A',
                      borderRadius: '50px',
                    }}
                  >
                    Recommended
                  </small>
                ) : (
                  <span></span>
                )}
              </Col>
              {/* <Col className={`${styles.dish__name}`} xs={4} md={6} lg={8}>
                {price ? <>₹{price}</> : null}
              </Col> */}
            </Row>
            <Row>
              {/* &nbsp; &nbsp; &nbsp; */}
              <Col className="FONT__SIZE__12 LIGHT__GREY__COLOR">
                <Row>
                  <Col>
                    <p className={`${styles.served_with} mb-0`}>
                      {served?.length > 0 ? (
                        <>
                          served with&nbsp;
                          {served}
                        </>
                      ) : (
                        <>
                          <br />
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className={styles.ingredients__oven_required}>
                      <p style={{ marginBottom: '0px' }}>
                        {special_appliances.length > 0 ? (
                          <>
                            <span>
                              requires {appliancesRequired.join(', ')}
                            </span>
                          </>
                        ) : (
                          <br />
                        )}
                      </p>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </components.Option>
    )
  }

  const noOptionsMessageString = (
    <p className="text-left mb-0 my-2">No options to choose from</p>
  )

  // to reduce font-size when a item is selected
  const fontStyles =
    screen.width > 968
      ? {
          control: (styles) => ({ ...styles, fontSize: '0.9rem' }),
          placeholder: (styles) => ({
            ...styles,
            fontSize: '1rem',
          }),
          option: (styles) => ({
            ...styles,
            overflowX: 'hidden',
            paddingBottom: '0px',
          }),
          dropdownIndicator: (styles) => ({ ...styles, color: 'black' }),
        }
      : {
          control: (styles) => ({ ...styles, fontSize: '13px' }),
          placeholder: (styles) => ({
            ...styles,
            fontSize: '13px',
          }),
          option: (styles) => ({
            ...styles,
            fontSize: '13px',
            overflowX: 'hidden',
            paddingBottom: '0px',
          }),
          dropdownIndicator: (styles) => ({ ...styles, color: 'black' }),
        }

  const renderDropDowns = (
    <>
      <br />
      {(props.lunchOrDinner === 'lunch' ||
        props.lunchOrDinner === 'dinner') && (
        <>
          {' '}
          <h5
            className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}
          >
            Appetizers
            <span className={`${styles.font__size__14} text-muted ml-1`}>
              (optional)
            </span>
          </h5>
          <div className="form-group">
            <Select
              value={selectedAppetizers}
              isMulti
              components={{ Option }}
              name="appetizers"
              options={appetizerOption}
              styles={fontStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => {
                setSelectedAppetizers(data ?? [])
              }}
              closeMenuOnSelect={false}
              placeholder="Select from dropdown list"
              noOptionsMessage={() => noOptionsMessageString}
              blurInputOnSelect={false}
              isSearchable={false}
              formatGroupLabel={formatGroupLabel}
              // menuIsOpen={true}
              isClearable={false}
              isLoading={loading}
              isDisabled={
                (miniPack || partyPack || jumboPack) && comboIds.includes(9)
              }
              minMenuHeight={400}
            />
          </div>
          <br />
        </>
      )}

      {props.lunchOrDinner === 'breakfast' && (
        <>
          <h5
            className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}
          >
            Breakfast
            <span className={`${styles.font__size__14} text-muted ml-1`}>
              (optional)
            </span>
          </h5>
          <div className="form-group">
            <Select
              value={selectedBreakfast}
              isMulti
              components={{ Option }}
              name="appetizers"
              options={breakfastOption}
              styles={fontStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => {
                setSelectedBreakfast(data ?? [])
              }}
              closeMenuOnSelect={false}
              placeholder="Select from dropdown list"
              noOptionsMessage={() => noOptionsMessageString}
              blurInputOnSelect={false}
              isSearchable={false}
              formatGroupLabel={formatGroupLabel}
              // menuIsOpen={true}
              isClearable={false}
              isLoading={loading}
              isDisabled={
                (miniPack || partyPack || jumboPack) && comboIds.includes(9)
              }
              minMenuHeight={400}
            />
          </div>
        </>
      )}

      {(props.lunchOrDinner === 'lunch' ||
        props.lunchOrDinner === 'dinner') && (
        <>
          {' '}
          <h5
            className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}
          >
            Main Course
            <span className={`${styles.font__size__14} text-muted ml-1`}>
              (optional)
            </span>
          </h5>
          <div className="form-group">
            <Select
              value={selectedMainCourse}
              isMulti
              components={{ Option }}
              name="mainCourse"
              options={maincourseOption}
              styles={fontStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => {
                setSelectedMainCourse(data ?? [])
              }}
              closeMenuOnSelect={false}
              placeholder="Select from dropdown list"
              noOptionsMessage={() => noOptionsMessageString}
              blurInputOnSelect={false}
              isSearchable={false}
              formatGroupLabel={formatGroupLabel}
              isClearable={false}
              isLoading={loading}
              isDisabled={
                (miniPack || partyPack || jumboPack) && comboIds.includes(9)
              }
              minMenuHeight={400}
            />
          </div>
        </>
      )}

      {(props.lunchOrDinner === 'lunch' ||
        props.lunchOrDinner === 'dinner') && (
        <>
          {' '}
          <br />
          <h5
            className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}
          >
            Breads, Rice and Raita
            <span className={`${styles.font__size__14} text-muted ml-1`}>
              (optional)
            </span>
          </h5>
          <div className="form-group">
            <Select
              value={selectedBreads}
              isMulti
              components={{ Option }}
              name="Breads"
              options={breadOption}
              styles={fontStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedBreads(data ?? [])}
              closeMenuOnSelect={false}
              placeholder="Select from dropdown list"
              noOptionsMessage={() => noOptionsMessageString}
              blurInputOnSelect={false}
              isSearchable={false}
              formatGroupLabel={formatGroupLabel}
              isClearable={false}
              isLoading={loading}
              isDisabled={
                (miniPack || partyPack || jumboPack) && comboIds.includes(9)
              }
              minMenuHeight={400}
            />
          </div>
        </>
      )}

      {(props.lunchOrDinner === 'lunch' ||
        props.lunchOrDinner === 'dinner') && (
        <>
          <br />
          <h5
            className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}
          >
            Desserts
            <span className={`${styles.font__size__14} text-muted ml-1`}>
              (optional)
            </span>
          </h5>
          <div className="form-group">
            <Select
              value={selectedDesserts}
              isMulti
              components={{ Option }}
              name="desserts"
              options={desserOption}
              styles={fontStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => {
                setSelectedDesserts(data ?? [])
              }}
              closeMenuOnSelect={false}
              placeholder="Select from dropdown list"
              noOptionsMessage={() => noOptionsMessageString}
              blurInputOnSelect={false}
              isSearchable={false}
              formatGroupLabel={formatGroupLabel}
              isClearable={false}
              isLoading={loading}
              isDisabled={
                (miniPack || partyPack || jumboPack) && comboIds.includes(9)
              }
              minMenuHeight={400}
            />
          </div>
        </>
      )}

      {true && (
        <>
          <br />
          <h5
            className={`${bmStyles.dark__grey__color} ${styles.font__size__16}`}
          >
            Soups & Beverages
            <span className={`${styles.font__size__14} text-muted ml-1`}>
              (optional)
            </span>
          </h5>
          <div className="form-group">
            <Select
              value={selectedBeverages}
              isMulti
              components={{ Option }}
              name="desserts"
              options={beverageOption}
              styles={fontStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedBeverages(data ?? [])}
              closeMenuOnSelect={false}
              placeholder="Select from dropdown list"
              noOptionsMessage={() => noOptionsMessageString}
              blurInputOnSelect={false}
              isSearchable={false}
              formatGroupLabel={formatGroupLabel}
              isClearable={false}
              isLoading={loading}
              isDisabled={
                (miniPack || partyPack || jumboPack) && comboIds.includes(9)
              }
              minMenuHeight={400}
            />
          </div>
        </>
      )}
    </>
  )

  let mealType = ''
  if (props.activeStep === 3) {
    mealType = 'Breakfast'
  } else if (props.activeStep === 4) {
    mealType = 'Lunch'
  } else {
    mealType = 'Dinner'
  }

  return (
    <div>
      <Row>
        <Col className="text-left pr-0" md={3} xs={8}>
          <label className={`text-muted mb-0 ${styles.font__size__14}`}>
            <img
              style={{ height: '10px', width: 'auto' }}
              src={tickMark}
              alt="tick"
            />
            {` ${unPublishedCombos
              .filter((combo) => comboIds.includes(combo.id))
              .map((combo) => ` ${combo.name}`)} `}{' '}
            {` ${combos
              .filter((combo) => comboIds.includes(combo.id))
              .map((combo) => ` ${combo.name}`)} `}{' '}
          </label>
          <br />
          <label className={`text-muted mb-0 ${styles.font__size__14}`}>
            <img
              style={{ height: '10px', width: 'auto' }}
              src={tickMark}
              alt="tick"
            />{' '}
            Choose from a wide variety
          </label>
        </Col>
        <Col xs={4} style={{ padding: '0px' }}>
          <div className='float-left'>
          <IsVegSwitch
            title="Fried"
            checked={is_fried === 'yes' ? true : false}
            handleChange={() =>
              dispatch(
                bookingModalSlice.actions.setBookingDetails({
                  ...REDUX__bookingDetails,
                  is_fried: is_fried === 'yes' ? 'no' : 'yes',
                })
              )
            }
          />
          <IsVegSwitch
            title="Veg only"
            checked={is_veg === 'veg' ? true : false}
            handleChange={() =>
              dispatch(
                bookingModalSlice.actions.setBookingDetails({
                  ...REDUX__bookingDetails,
                  is_veg: is_veg === 'veg' ? 'nonveg' : 'veg',
                })
              )
            }
          /></div>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <label className={`text-muted mb-0 ${styles.font__size__14}`}>
            <img
              style={{ height: '10px', width: 'auto' }}
              src={tickMark}
              alt="tick"
            />{' '}
            Choose from a wide variety
          </label>
        </Col>
      </Row> */}
      <Row>
        {comboIds.includes(9) && (
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              checked={miniPack}
              onChange={() => {
                setMiniPack(!miniPack)
                setPartyPack(false)
                setJumboPack(false)
              }}
              className={`ml-3 mb-3 ${styles.visibility__hidden} ${styles.fake__checkbox__img}`}
            />
            <div className={`ml-4 text-muted mb-0 ${styles.font__size__12}`}>
              <b>Mini Pack</b>
            </div>
            <input
              type="checkbox"
              checked={partyPack}
              onChange={() => {
                setPartyPack(!partyPack)
                setJumboPack(false)
                setMiniPack(false)
              }}
              className={`ml-3 mb-3 ${styles.visibility__hidden} ${styles.fake__checkbox__img}`}
            />
            <div className={`ml-4 text-muted mb-0 ${styles.font__size__12}`}>
              <b>Party Pack</b>
            </div>
            <input
              type="checkbox"
              checked={jumboPack}
              onChange={() => {
                setJumboPack(!jumboPack)
                setPartyPack(false)
                setMiniPack(false)
              }}
              className={`ml-3 mb-3 ${styles.visibility__hidden} ${styles.fake__checkbox__img}`}
            />
            <div className={`ml-4 text-muted mb-0 ${styles.font__size__12}`}>
              <b>Jumbo Pack</b>
            </div>
          </div>
        )}
      </Row>
      {renderDropDowns}
      <br />
      <br />
    </div>
  )
}

export default DesignMenuLogic
