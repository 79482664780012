// REACT ID SWIPER
// import Swiper from 'react-id-swiper'

// STYLES
import styles from './index.module.css'

const ShimmerLoader = (): JSX.Element => {
  const params = {
    initialSlide: 0,
    slidesPerView: 4.5,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2.6,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1080: {
        slidesPerView: 4.5,
        spaceBetween: 20,
      },
    },
    spaceBetween: 30,
    loop: false,
    noSwiping: true,
  }

  return (
    <div className="swiper-outer-wrap">
      {/* <Swiper {...params}> */}
      <div>
        <div className={styles.shimmer__loader}></div>
      </div>
      {/* </Swiper> */}
    </div>
  )
}

export default ShimmerLoader
