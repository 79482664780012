import { useRouter } from 'next/router'

import { Dispatch, SetStateAction, useState, useContext } from 'react'

// BOOTSTRAP
import { Button } from 'react-bootstrap'

// AXIOS
import axios, { AxiosPromise } from 'axios'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import authSlice from '@/lib/slices/authSlice'
import { FaArrowRight } from 'react-icons/fa'

// CONTEXT
import { SWContext } from 'pages/_app'

// INTERFACES
import { RootState } from '@/lib/rootState'
interface Props {
  submitMobile: (mobile: string) => AxiosPromise<any>
  submitOTP: (otp: string) => AxiosPromise<any>
  mobileLoading: boolean
  otpLoading: boolean
  showOtpView: boolean
}

const CTAhelper = (props: Props): JSX.Element => {
  // const router = useRouter()

  // const dispatch = useDispatch()
  // const { SW } = useContext(SWContext)

  const mobile = useSelector((state: RootState) => state.auth.mobile)
  // const otp = useSelector((state: RootState) => state.auth.otp)
  const loginModalSource = useSelector((state: RootState) => state.auth.source)
  // const bookingId = useSelector(
  //   (state: RootState) => state.bookingModal.bookingDetails.bookingId
  // )
  // const spclAppliances = useSelector(
  //   (state: RootState) => state.bookingModal.spclAppliances.spclAppliances
  // )
  // const bookingModalState = useSelector((state) => state.bookingModalState)

  if (props.showOtpView) {
    let btnText = 'Login'
    if (loginModalSource === 'booking_modal') {
      btnText = 'Proceed'
    }
    return (
      <Button
        variant="warning"
        disabled={props.otpLoading}
        block
        onClick={props.submitOTP}
      >
        {btnText}{' '}
        {props.otpLoading ? (
          '...'
        ) : (
          <>
            {' '}
            <FaArrowRight style={{ verticalAlign: 'text-top' }} />
          </>
        )}
      </Button>
    )
  } else {
    return (
      <>
        {/* {loginModalSource === 'booking_modal' ? (
          <h6 className="w-100 text-center">Full Payment Summary Ahead</h6>
        ) : null} */}
        <Button
          variant="warning"
          disabled={props.mobileLoading}
          block
          onClick={() => props.submitMobile(mobile)}
        >
          Proceed{' '}
          {props.mobileLoading ? (
            '...'
          ) : (
            <>
              {' '}
              <FaArrowRight style={{ verticalAlign: 'text-top' }} />
            </>
          )}
        </Button>
      </>
    )
  }
}

export default CTAhelper
///
