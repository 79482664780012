// STYLES
import styles from './index.module.css'

const PanLoader = (): JSX.Element => {
  return (
    <div className={styles.box}>
      <div className={styles['circle-anim']}>
        <svg
          id="circle"
          className={styles.circle}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
        >
          <g id="circle" fill="#000000">
            <circle cx="5" cy="5" r="5"></circle>
          </g>
        </svg>
      </div>
      <div className={styles['square-pan-anim']}>
        <svg
          id="square"
          className={styles['square-pan']}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g id="Square" fill="#000000">
            <rect id="square" x="0" y="0" width="16" height="16"></rect>
          </g>
        </svg>
      </div>
      <div className={styles['square-anim']}>
        <svg
          id="square"
          className={styles.square}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g id="Square" fill="#000000">
            <rect id="square" x="0" y="0" width="16" height="16"></rect>
          </g>
        </svg>
      </div>
      <div className={styles['triangle-pan-anim']}>
        <svg
          id="triangle"
          className={styles['triangle-pan']}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <g id="Triangle" fill="#F5F5F5" stroke="#000000" strokeWidth="2">
            <polygon points="10 2 18 16 2 16"></polygon>
          </g>
        </svg>
      </div>
      <div className={styles['triangle-anim']}>
        <svg
          id="triangle"
          className={styles.triangle}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <g id="Triangle" fill="#F5F5F5" stroke="#000000" strokeWidth="2">
            <polygon points="10 2 18 16 2 16"></polygon>
          </g>
        </svg>
      </div>
      <div className={styles['saucepan-anim']}>
        <svg
          id="saucepan"
          className={styles.saucepan}
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="25"
          viewBox="0 0 100 25"
        >
          <path
            id="saucepan"
            fill="#000000"
            d="M99.4121763,0 C100.251924,0 100.083975,5.18181818 99.580126,5.18181818 C99.0762771,5.18181818 68.9573128,11.0909091 66.9979006,11.4545455 C65.7914565,11.6784427 64.7335781,12.109126 63.8896044,12.7465955 C61.9907888,19.0105689 60.9627999,22.4120996 60.806011,22.9533898 C60.4672589,24.1228814 58.6605807,25 34.5527194,25 C10.444858,25 8.58172115,25 7.45254731,22.2711864 C6.32337348,19.5423729 0.510480671,3.78672316 0.115269829,2.81214689 C-0.148204066,2.16242938 0.0399915733,1.90254237 0.679856747,2.03248588 L66.6236087,2.03248588 L66.6236087,2.03248588 C67,2.03248588 67.0940978,2.29237288 66.9059022,2.81214689 C66.5592917,3.95355462 66.2274015,5.04663387 65.9102317,6.09138463 C69.7930073,5.30938216 98.622234,0 99.4121763,0 Z"
          ></path>
        </svg>
      </div>
    </div>
  )
}

export default PanLoader
