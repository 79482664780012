import { Dispatch, SetStateAction } from 'react'
import Link from 'next/link'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Button, Modal } from 'react-bootstrap'

// REDUX
import { useDispatch } from 'react-redux'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'

// TS INTERFACES
interface Props {
  show: boolean
  setShow: (value: SetStateAction<boolean>) => void
  setActiveStep: Dispatch<SetStateAction<number>>
}

const WHATSAPP_SRC =
  'https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20'

const ExitAlertModal = (props: Props): JSX.Element => {
  const dispatch = useDispatch()

  const { show, setShow, setActiveStep } = props
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      style={{ backgroundColor: 'rgba(128,128,128,0.3)' }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className={styles.font__size__16}>
        <p>Are you sure you want to Exit ?</p>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" onClick={() => setShowOpenModal(false)}> */}
        <Button variant="primary">
          <a href="tel:+919004044234" style={{ color: 'white' }}>
            Call on 9004044234
          </a>
          {/* <Link href={WHATSAPP_SRC}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'white' }}
            >
              Chat on WhatsApp
            </a>
          </Link> */}
        </Button>
        <Button
          style={{ backgroundColor: 'white', borderColor: 'blue' }}
          onClick={() => {
            setActiveStep(1)
            dispatch(
              bookDailyCookModalSlice.actions.closeBookingModalAndClearState()
            )
            setShow(false)
          }}
          className={styles.exit__alert__cta}
        >
          <span style={{ color: 'blue' }}>Exit</span>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ExitAlertModal
