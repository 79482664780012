import { useSelector, useDispatch } from 'react-redux'

import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'

import { FaQuestionCircle } from 'react-icons/fa'

import Image from 'next/image'
import radioCheckboxStyles from '../../../../MyBookings/commonHelpers/radioCheckbox.module.css'
// TS INTERFACES
import { RootState } from '@/lib/rootState'
import { SWtype } from '@/components/BookingModals/BookChefModal/commonInterfaces'

import bookingModalSlice from '@/lib/slices/bookingModalSlice'
// interface Iappliance {
//   appliance_id: number
//   appliance_image: string
//   appliance_name: string
//   available: boolean
//   dishes: Array<string>
//   is_special: boolean
//   suggested_quantities: null
//   suggested_size: null
// }

const greytickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const redtickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/red_tick.jpeg'

// const whatsappSrc =
//   'https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20'

const GreyTick = ({ color }: { color?: string }) => (
  <img
    src={color === 'red' ? redtickMark : greytickMark}
    alt="tick"
    style={
      color === 'red'
        ? { height: '2.5rem', width: '1.5rem' }
        : {
            height: '1.25rem',
            width: '1.25rem',
          }
    }
  />
)

const RequiredAppliances = ({ SW }: { SW: SWtype | null }): JSX.Element => {
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )

  const dispatch = useDispatch()
  const {
    spclAppliances: { spclAppliances },
  } = REDUX__bookingModalData

  const REDUX__bookingDetails = useSelector(
    (state: RootState) => state.bookingModal.bookingDetails
  )

  const { bookingLevel, meal } = REDUX__bookingDetails

  const updatedAppliances = []

  for (let item = 0; item < spclAppliances?.length; item++) {
    let uniqueArray = []
    if (spclAppliances[item].appliance_id === undefined) {
      uniqueArray = Array.from(new Set(spclAppliances[item]?.dishes))
    }
    const obj = {
      dishes: uniqueArray,
      appliance_id: spclAppliances[item].id,
      appliance_name: spclAppliances[item].name,
      appliance_image: spclAppliances[item].image,
      alternative_appliance: spclAppliances[item].alternative_appliance,
      alternative_appliance_2: spclAppliances[item].alternative_appliance_2,
    }
    updatedAppliances.push(obj)
  }
  // console.log('up', updatedAppliances)

  let selectedAppliances = []
  if (REDUX__bookingDetails.appliances) {
    selectedAppliances = [...REDUX__bookingDetails.appliances]
  }
  const handleChange = (appliance) => {
    let value = null
    if (appliance.appliance_id === null) {
      value = appliance.id
    } else {
      value = appliance.appliance_id
    }
    var index = selectedAppliances.indexOf(value)
    if (index > -1) {
      selectedAppliances.splice(index, 1)
    } else {
      selectedAppliances.push(value)
    }
    dispatch(bookingModalSlice.actions.setAppliances(selectedAppliances))
  }

  return (
    <div className="mt-3">
      {updatedAppliances?.map((appliance, index) => {
        return (
          <>
            <Row>
              <Col
                style={{ fontSize: '20px', marginLeft: '10%' }}
                className="mb-3"
              >
                <small id={`appliance_${appliance.appliance_id}`}>
                  For{' '}
                  {appliance?.dishes?.map((dish, index) => (
                    <span className="mb-0 text-left" key={index}>
                      {index ? ', ' : ''}
                      {dish}
                    </span>
                  ))}{' '}
                  <Button
                    variant="link"
                    onClick={() => {
                      if (meal === 'dinner') {
                        SW.goToNamedStep('dinner')
                      } else if (meal === 'lunch' || meal === 'lunch_dinner') {
                        SW.goToNamedStep('lunch')
                      } else {
                        SW.goToNamedStep('breakfast')
                      }
                    }}
                    className="pl-0"
                  >
                    (replace dish)
                  </Button>
                </small>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={1} md={1}>
                <input
                  type="checkbox"
                  checked={selectedAppliances.includes(appliance.appliance_id)}
                  style={{ width: '20px', height: '20px' }}
                  onChange={() => handleChange(appliance)}
                  className={`${radioCheckboxStyles.visibility__hidden} ${radioCheckboxStyles.fake__checkbox__img}`}
                />
              </Col>
              <Col md={4} xs={4}>
                {/* <Image
                  src={appliance.appliance_image}
                  className="resizeForMobile"
                  height="100px"
                  width="150px"
                /> */}
                <img
                  src={appliance.appliance_image}
                  className="resizeForMobile"
                  height="100px"
                  width="150px"
                />
              </Col>
              <Col>{appliance.appliance_name}</Col>
              {/* <Col md={2} xs={3} className="ml-3 text-center">
                <small>
                  <OverlayTrigger
                    placement={'left'}
                    overlay={
                      <Tooltip id={`tooltip-dish-${index}`}>
                        <span>
                          <Row>
                            <Col className="text-left">Why ?</Col>
                          </Row>

                          {appliance?.dishes?.map((dish, index) => (
                            <p className="mb-0 text-left" key={index}>
                              - For {dish}
                            </p>
                          ))}
                        </span>
                      </Tooltip>
                    }
                  >
                    <span className="text-primary">Why Required?</span>
                  </OverlayTrigger>
                </small>
              </Col> */}
            </Row>
            {appliance.alternative_appliance ? (
              <>
                <Row>
                  <p
                    className="text-danger font-weight-bolder"
                    style={
                      // window.innerWidth < 580
                      //   ? { fontSize: '25px', paddingLeft: '35px' }
                      //   : { fontSize: '25px', paddingLeft: '65px' }

                      {
                        fontSize: '30px',
                        marginLeft: '6%',
                        paddingLeft: '6%',
                        paddingTop: '2%',
                      }
                    }
                  >
                    OR
                  </p>
                </Row>
                <Row className="align-items-center">
                  <Col xs={1} md={1}>
                    <input
                      type="checkbox"
                      style={{ width: '20px', height: '20px' }}
                      checked={selectedAppliances.includes(
                        appliance.alternative_appliance.appliance_id
                      )}
                      onChange={() =>
                        handleChange(appliance.alternative_appliance)
                      }
                      className={`${radioCheckboxStyles.visibility__hidden} ${radioCheckboxStyles.fake__checkbox__img}`}
                    />
                  </Col>
                  <Col xs={4}>
                    {/* <Image
                      src={appliance.alternative_appliance.appliance_image}
                      className="resizeForMobile"
                      height="100px"
                      width="150px"
                    /> */}
                    <img
                      src={appliance.alternative_appliance.appliance_image}
                      className="resizeForMobile"
                      height="100px"
                      width="150px"
                    />
                  </Col>
                  <Col xs={6}>
                    {appliance.alternative_appliance.appliance_name}
                  </Col>
                </Row>
              </>
            ) : null}
            {appliance.alternative_appliance_2 ? (
              <>
                <Row>
                  <p
                    className="text-danger font-weight-bolder"
                    style={
                      // window.innerWidth < 580
                      //   ? { fontSize: '25px', paddingLeft: '35px' }
                      //   : { fontSize: '25px', paddingLeft: '65px' }
                      {
                        fontSize: '30px',
                        marginLeft: '6%',
                        paddingLeft: '6%',
                        paddingTop: '2%',
                      }
                    }
                  >
                    OR
                  </p>
                </Row>
                <Row className="align-items-center">
                  <Col xs={1} md={1}>
                    <input
                      type="checkbox"
                      style={{ width: '20px', height: '20px' }}
                      checked={selectedAppliances.includes(
                        appliance.alternative_appliance_2.appliance_id
                      )}
                      onChange={() =>
                        handleChange(appliance.alternative_appliance_2)
                      }
                      className={`${radioCheckboxStyles.visibility__hidden} ${radioCheckboxStyles.fake__checkbox__img}`}
                    />
                  </Col>
                  <Col xs={4}>
                    {/* <Image
                      src={appliance.alternative_appliance_2.appliance_image}
                      className="resizeForMobile"
                      height="100px"
                      width="150px"
                    /> */}
                    <img
                      src={appliance.alternative_appliance_2.appliance_image}
                      className="resizeForMobile"
                      height="100px"
                      width="150px"
                    />
                  </Col>
                  <Col xs={6}>
                    {appliance.alternative_appliance_2.appliance_name}
                  </Col>
                </Row>
              </>
            ) : null}
            {updatedAppliances.length !== index + 1 ? (
              <>
                <hr /> <br />
              </>
            ) : null}
          </>
        )
      })}
      <Row>
        <Col className="text-muted mt-4">
          <GreyTick />
          &nbsp; Other basic kitchen appliances
        </Col>
      </Row>
      <Row>
        <Col className="text-muted mt-2 ">
          <GreyTick />
          &nbsp; Can be made using your small home appliances
        </Col>
      </Row>
      <Row>
        <Col className="text-muted mt-2 mb-2">
          <GreyTick />
          &nbsp; {bookingLevel === 'Chef' ? 'Chef' : 'Cook'} does not carry
          anything
        </Col>
      </Row>
      {/* <Row>
        <Col className="text-muted mb-2">
          <GreyTick />
          &nbsp; Not sure about your appliances? &nbsp;
          <a href={whatsappSrc} target="_blank" rel="noopener noreferrer">
            Chat on WhatsApp
          </a>
        </Col>
      </Row> */}
      <Row>
        <Col className="text-muted mb-3">
          <GreyTick />
          &nbsp; If you can’t arrange,{' '}
          <Button
            variant="link"
            onClick={() => {
              if (meal === 'dinner') {
                SW.goToNamedStep('dinner')
              } else if (meal === 'lunch' || meal === 'lunch_dinner') {
                SW.goToNamedStep('lunch')
              } else {
                SW.goToNamedStep('breakfast')
              }
            }}
            className="pl-0"
          >
            replace dish
          </Button>
        </Col>
      </Row>
    </div>
  )
}
export default RequiredAppliances
