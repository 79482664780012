import { useState, useEffect, useRef } from 'react'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'

// STYLES
import styles from '../../BookingModals/BookChefModal/index.module.css'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import auth from '@/lib/slices/authSlice'

// INTERFACES
import { RootState } from '@/lib/rootState'

// libraries
import axios from 'axios'

interface Props {
  submitMobile: (tempMobile: string) => void
  tempMobile: string
  otpError: string
}

const EnterOtpView = ({
  submitMobile,
  tempMobile,
  otpError,
  submitOTP,
  setShowOtpView,
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  //refetching OTP to maintain the last digit after dispatch
  const currentOTP = useSelector((state: RootState) => state.auth.otp)
  const [timer, setTimer] = useState(60)

  const otpInputRef = useRef<HTMLInputElement>(null)
  const ac = new AbortController()
  useEffect(() => {
    otpInputRef.current?.focus()
    if ('OTPCredential' in window) {
      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          setOtp(otp.code)
          ac.abort()
        })
        .catch((err) => {
          ac.abort()
          console.log(err)
        })
    }
    // setInterval returns the id of the interval so we can clear it later
    const id = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1
        }
        return 0
      })
    }, 1000)

    // Clear the interval on umount
    return () => {
      clearInterval(id)
    }
  }, [])

  const [otp, setOtp] = useState('')

  useEffect(() => {
    if (currentOTP.length === 4) {
      submitOTP()
      // ac.abort()
    }
  }, [currentOTP])

  useEffect(() => {
    // Set OTP in redux store
    dispatch(auth.actions.setUserOtpWhileLogin(otp))
  }, [otp])

  const sendOTPOnCall = () => {
    setTimer(60)
    axios({
      method: 'POST',
      url: `auth/generate-call-otp`,
      data: {
        mobile: tempMobile,
      },
    }).then((response) => {
      if (response?.status === 200) {
        return
      }
    })
  }

  const loginModalSource = useSelector((state: RootState) => state.auth.source)

  if (
    loginModalSource === 'booking_modal' ||
    loginModalSource === 'booking_bartender_modal' ||
    loginModalSource === 'booking_waiter_modal' ||
    loginModalSource === 'booking_cleaner_modal'
  ) {
    return (
      <>
        <p className="text-left text-muted">
          Please add your details to view total bill
        </p>
        <div>
          <div className="form-group">
            <Row>
              <Col>
                <h5
                  className={`${styles.dark__grey__color} pull-left d-inline`}
                >
                  Mobile
                </h5>
                &nbsp;(
                <span>
                  <button
                    type="button"
                    className="btn btn-link px-0"
                    onClick={() => {
                      setShowOtpView(false)
                    }}
                  >
                    change
                  </button>
                </span>
                )
              </Col>
            </Row>

            <input
              type="text"
              readOnly
              className="form-control"
              value={tempMobile}
            />
          </div>
          <div className="form-group">
            <Row>
              <Col>
                <h5
                  className={`${styles.dark__grey__color} pull-left d-inline pb-1`}
                >
                  OTP
                </h5>
                
                  {' ('}
                  {timer > 0 ? (
                    <span>Re-send in {timer} seconds</span>
                  ) : (
                    <>
                      Re-send OTP{' '}
                      <button
                        type="button"
                        className="btn btn-link px-0"
                        onClick={() => {
                          submitMobile(tempMobile)
                          setTimer(60)
                        }}
                      >
                        via text
                      </button>
                      {' / '}
                      <button
                        type="button"
                        className="btn btn-link px-0"
                        onClick={sendOTPOnCall}
                      >
                        via call
                      </button>
                    </>
                  )}
                  )
              </Col>
            </Row>

            <input
              ref={otpInputRef}
              inputMode="numeric"
              pattern="[0-9]*"
              type="text"
              name="otp"
              autoComplete="one-time-code"
              placeholder="Enter 4 digit OTP"
              className="form-control"
              value={otp}
              onChange={({ target: { value } }) => {
                const filteredNum = value.replace(/[^0-9]/g, '')
                if (filteredNum.length > 4) {
                  return
                } else {
                  setOtp(filteredNum)
                  // console.log(filteredNum, ' ll ', otp)
                }
              }}
            />
            <div className={`${styles.error__message} mt-2 text-left`}>
              {otpError}
            </div>
          </div>

          {/* <br />
          <p className="font-weight-light">OTP sent to: {tempMobile}</p> */}
        </div>
      </>
    )
  } else {
    return (
      <>
        <Row>
          <Col>
            <h4 className="DARK__GOLDEN__COLOR text-center">COOX</h4>
          </Col>
        </Row>
        <br />
        <br />
        <div className="form-group">
          <Row>
            <Col>
              <h5 className={`${styles.dark__grey__color} pull-left d-inline`}>
                Mobile
              </h5>
              &nbsp;(
              <span>
                <button
                  type="button"
                  className="btn btn-link px-0"
                  onClick={() => {
                    setShowOtpView(false)
                  }}
                >
                  change
                </button>
              </span>
              )
            </Col>
          </Row>
          <input
            type="text"
            readOnly
            className="form-control"
            value={tempMobile}
          />
        </div>
        <div className="form-group">
          <Row>
            <Col>
              <h5
                className={`${styles.dark__grey__color} pull-left d-inline pb-1`}
              >
                OTP
              </h5>
              {' ('}
              {timer > 0 ? (
                <span>Re-send in {timer} seconds</span>
              ) : (
                <>
                  Re-send OTP{' '}
                  <button
                    type="button"
                    className="btn btn-link px-0"
                    onClick={() => {
                      submitMobile(tempMobile)
                      setTimer(60)
                    }}
                  >
                    via text
                  </button>
                  {' / '}
                  <button
                    type="button"
                    className="btn btn-link px-0"
                    onClick={sendOTPOnCall}
                  >
                    via call
                  </button>
                </>
              )}
              )
            </Col>
          </Row>
          <input
            ref={otpInputRef}
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="off"
            type="text"
            name="otp"
            placeholder="Enter 4 digit OTP"
            className="form-control"
            value={otp}
            onChange={({ target: { value } }) => {
              const filteredNum = value.replace(/[^0-9]/g, '')
              if (filteredNum.length > 4) {
                return
              }
              if (filteredNum.length === 4) {
                setOtp(filteredNum)
                // console.log(filteredNum," ll ",mobile)
              } else {
                setOtp(filteredNum)
              }
            }}
          />
          <div className={`${styles.error__message} mt-2 text-left`}>
            {otpError}
          </div>
        </div>

        {/* <br />
        <p className="font-weight-light">OTP sent to: {tempMobile}</p> */}
      </>
    )
  }
}

export default EnterOtpView
