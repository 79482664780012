import Link from 'next/link'

import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

// STYLES
import styles from './index.module.css'

// AXIOS
import axios from 'axios'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'

// COMPONENTS
import DrawerToggleButton from '../DrawerToggleButton'
import LoggedInUserNavOptions from '@/components/LoggedInUserNavOptions'
import LoginModal from '@/components/Auth/LoginModal'
import UserDetailsModal from '@/components/UserDetailsModal'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

// GTM
import { gtmEvent } from '@/lib/gtm'
interface Props {
  toggleSideDrawer: () => void
  homePage: boolean
}

const Toolbar = (props: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { pathname } = useRouter()

  const REDUX__authData = useSelector((state: RootState) => state.auth)
  const { isLoggedIn } = REDUX__authData

  const [notification, setNotification] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      axios.get(`/notifications/booking?type=current`).then((response) => {
        setNotification(response?.data.total_booking)
      })
    }
  }, [isLoggedIn])
  return (
    <header
      className={
        props.homePage && scrollPosition <= 200
          ? `${styles.toolbar}`
          : `${styles.toolbar}`
      }
    >
      <nav className={styles.toolbar__navigation}>
        <div className={styles.toolbar__logo}>
          <Link href="/">
            <a>
              <img src="/longLogo.png" alt="logo" height="45" width="145" />
            </a>
          </Link>
        </div>
        {/* {isLoggedIn && <div className={styles.vertical}></div>} */}

        <div className={styles.my_boookings_text}>
          {isLoggedIn && (
            <Link href="/my-bookings">
              <a
                className={
                  notification
                    ? `notification--badge ${styles.no__badge__color}`
                    : `${styles.no__badge__color}`
                }
                data-badge={notification}
              >
                My Bookings
              </a>
            </Link>
          )}
        </div>
        <div className={styles['toolbar_navigation-items']}>
          <ul>
            <li>
              <a
                // onClick={() => {
                //   gtmEvent('book-chef-flow', {
                //     step: 'how-it-works',
                //     type: 'nav-bar',
                //   })
                //   dispatch(bookingModalSlice.actions.toggleBookingModal())
                // }}
                href="javascript:void(0)"
                onClick={() => {
                  if (pathname.includes('bartender')) {
                    gtmEvent('book-bartender-flow', {
                      step: 'how-it-works',
                      type: 'nav-bar',
                    })
                    dispatch(
                      bookingBartenderModalSlice.actions.toggleBookingBartenderModal()
                    )
                  } else if (pathname.includes('waiter')) {
                    gtmEvent('book-waiter-flow', {
                      step: 'how-it-works',
                      type: 'nav-bar',
                    })
                    dispatch(
                      bookingWaiterModalSlice.actions.toggleBookingWaiterModal()
                    )
                  } else if (pathname.includes('cleaner')) {
                    gtmEvent('book-cleaner-flow', {
                      step: 'how-it-works',
                      type: 'nav-bar',
                    })
                    dispatch(
                      bookingCleanerModalSlice.actions.toggleBookingCleanerModal()
                    )
                  } else if (
                    pathname.includes('cook') &&
                    pathname !== '/register-as-cook' &&
                    pathname !== 'register-as-cook-hindi'
                  ) {
                    gtmEvent('book-chef-flow', {
                      step: 'how-it-works',
                      type: 'nav-bar',
                    })
                    dispatch(bookingModalSlice.actions.toggleBookingModal())
                  } else if (pathname.includes('caterer')) {
                    gtmEvent('book-chef-flow', {
                      step: 'how-it-works',
                      type: 'nav-bar',
                    })
                    dispatch(bookingModalSlice.actions.toggleBookingModal())
                  } else {
                    dispatch(apiDataSlice.actions.setAskServiceModal(true))
                  }
                }}
              >
                Book Now
              </a>
            </li>
            {/* <li>
              <Link href="/about">
                <a>About Us</a>
              </Link>
            </li> */}
            <li>
              <Link href="/register-as-cook">
                <a>Register as Partner</a>
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.spacer} />
        <div className={styles['toolbar_navigation-items']}>
          <ul>
            <li>
              {isLoggedIn && (
                <Link href="/my-bookings">
                  <a
                    className={notification ? 'notification--badge' : ''}
                    data-badge={notification}
                  >
                    My Bookings
                  </a>
                </Link>
              )}
            </li>
            <li>
              <LoggedInUserNavOptions />
              <LoginModal />
              <UserDetailsModal />
            </li>
          </ul>
        </div>
        <div className={styles['toolbar__toggle-button']}>
          <DrawerToggleButton click={props.toggleSideDrawer} />
        </div>
      </nav>
    </header>
  )
}

export default Toolbar
