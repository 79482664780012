import { configureStore } from '@reduxjs/toolkit'

// SLICES
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'
import authSlice from '@/lib/slices/authSlice'
import apiDataSlice from '@/lib/slices/apiDataSlice'
import myBookingsSlice from './lib/slices/myBookingSlice'

export default configureStore({
  reducer: {
    // counter: counterReducer,
    // clock: clockReducer,
    // notes: notesReducer,
    bookingModal: bookingModalSlice.reducer,
    bookingBartenderModal: bookingBartenderModalSlice.reducer,
    bookingWaiterModal: bookingWaiterModalSlice.reducer,
    bookingCleanerModal: bookingCleanerModalSlice.reducer,
    bookDailyCookModal: bookDailyCookModalSlice.reducer,
    auth: authSlice.reducer,
    apiData: apiDataSlice.reducer,
    myBookings: myBookingsSlice.reducer,
  },
  devTools: true,
})
