const wrapS3URL = (glimpseName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/${glimpseName}.jpg`
}

interface Iphotos {
  src: string
  alt: string
  width: number
  height: number
}

export const photos: Array<Iphotos> = [
  {
    src: wrapS3URL('glimpse9'), //chef
    alt: 'Continental Chef cooking at home kitchen in Gurgaon via COOX',
    width: 2,
    height: 3,
  },
  {
    src:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/2B.JPG', //barbeque
    alt: 'Tandoor chefs at barbeque party in Noida via COOX',
    width: 2,
    height: 3,
  },
  {
    src:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/3B.jpg', //table
    alt: 'North Indian and Chinese food cooked at home by COOX',
    width: 2,
    height: 3,
  },
  {
    src: wrapS3URL('glimpse10'), //family
    alt: 'Book a chef online for birthday house party via COOX',
    width: 5,
    height: 3,
  },
  {
    src:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/5B.jpg',
    alt: 'Delicious soup prepared by COOX',
    width: 3,
    height: 4,
  },
  {
    src:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/7b.jpeg',
    alt: 'Mouth watering italian dishes by COOX',
    width: 3,
    height: 4,
  },
  {
    src:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/homePage/10.jpeg',
    alt: 'COOX available for Kitty party',
    width: 2,
    height: 2,
  },
  {
    src:
      'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/glimpses/8B.jpeg',
    alt: 'COOX at your home kitchen',
    width: 2,
    height: 3,
  },
]

///
