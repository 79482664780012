import { createSlice } from '@reduxjs/toolkit'

// TS INTERFACES
import { RootState } from '@/lib/rootState'

const initialState: RootState['auth'] = {
  isLoggedIn: false,
  showLoginModal: false,
  showUserDetailsModal: false,
  source: '',
  mobile: '',
  otp: '',
  userData: {
    id: null,
    name: null,
    gender: null,
    email: null,
    mobile_verified: false,
    mobile: null,
    is_blacklisted: false,
    created_at: '',
    permissions: [],
    roles: [],
    credits: 0,
    date_of_birth: '',
    total_current_bookings: 0,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleLoginModal: (state) => {
      state.showLoginModal = !state.showLoginModal
    },
    toggleUserDetailsModal: (state) => {
      state.showUserDetailsModal = !state.showUserDetailsModal
    },
    setSource: (state, source) => ({
      ...state,
      source: source.payload,
    }),
    setUserMobileWhileLogin: (state, mobile) => ({
      ...state,
      mobile: mobile.payload,
    }),
    setUserOtpWhileLogin: (state, otp) => ({
      ...state,
      otp: otp.payload,
    }),
    setIsLoggedIn: (state, isLoggedIn) => {
      state.isLoggedIn = isLoggedIn.payload
    },
    authUserSet: (state, userData) => {
      state.userData = userData.payload
    },
    updateAuthUser: (state, data) => {
      state.userData = { ...state.userData, ...data.payload }
    },
    logoutUser: (state) => {
      state = { ...initialState }

      return state
    },
  },
})

export default authSlice
