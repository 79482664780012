import React, { useState, useEffect } from 'react'
import Link from 'next/link'

// STYLES
import styles from './index.module.css'
import bmStyles from '../../index.module.css'

// BOOTSTRAP
import { Button, Modal } from 'react-bootstrap'

// ICONS
import { FaTimes, FaArrowLeft } from 'react-icons/fa'

// AXIOS
import axios from 'axios'

// COMPONENTS
import PanLoader from '@/components/Panloader'
import ManualAddressWaiterAddition from '@/components/ManualAddressWaiterAddition'
import AddressList from '@/components/AddressList'

// REDUX
import { useSelector, useDispatch } from 'react-redux'
import { FaArrowRight } from 'react-icons/fa'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'

// TS INTERFACES
import { RootState } from '@/lib/rootState'
import { SWtype } from '@/components/BookingModals/BookChefModal/commonInterfaces'

const whatsappSrc =
  'https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20'

const BookingAddress = ({ SW }: { SW: SWtype | null }): JSX.Element => {
  // Initializing dispatch
  const dispatch = useDispatch()

  // Fetching data from REDUX State
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingWaiterModal
  )

  const {
    bookingDetails: {
      bookingId,
      occasionId,
      numberOfPeople,
      bookingDate,
      bookingTime,
      meal,
      comboId,
      numberOfBurners,
    },
    designMenu: {
      cuisineIds,
      dishDetails,
      lunchDishes,
      dinnerDishes,
      breakfastDishes,
    },
    userAddressData: {
      addresses,
      selectedAddressId,
      showNewServingCitiesModal,
    },
    userAddAddressData: {
      stepNo,
      showAddAddressModal,
      showAPIAddressErrorModal,
      city,
      state,
      country,
    },
  } = REDUX__bookingModalData

  const dishObjFunc = (dish: any) => ({
    id: dish.id,
    meal_type: dish.meal_type,
  })
  const lunchDishes2 = lunchDishes?.map(dishObjFunc)
  const breakfastDishes2 = breakfastDishes?.map(dishObjFunc)
  const dinnerDishes2 = dinnerDishes?.map(dishObjFunc)
  const dishDetails2 = dishDetails?.map(dishObjFunc)

  const validate = () => {
    let city_error = ''

    if (!city) {
      city_error = 'Please select city from the drop-down list'
    } else if (!state || !country) {
      city_error = 'Please select a valid city'
    }
    if (city_error) {
      dispatch(
        bookingWaiterModalSlice.actions.setAddAddressDataForThatInstant({
          city_error,
        })
      )
      return false
    }

    return true
  }

  const [loading, setLoading] = useState(false)
  const numberOfburner = parseInt(numberOfBurners, 10)
  const appID = []
  if (numberOfburner === 1) {
    appID[0] = 41
  } else if (numberOfburner == 2) {
    appID[0] = 35
  } else if (numberOfburner == 3) {
    appID[0] = 36
  } else if (numberOfburner == 4) {
    appID[0] = 37
  } else if (numberOfburner == 5) {
    appID[0] = 38
  } else if (numberOfburner == 6) {
    appID[0] = 42
  }

  const getSummaryData = (addressId: number) => {
    axios({
      method: 'POST',
      url: `/booking/waiter-cleaner/summary`,
      data: {
        // occasion_id: occasionId,
        // number_of_people: numberOfPeople,
        // booking_date: bookingDate,
        // booking_time: bookingTime,
        payment_option: 1,
        address_id: addressId,
        booking_id: bookingId,
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
    })
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            bookingWaiterModalSlice.actions.setBookingSummary(response.data)
          )
          dispatch(
            bookingWaiterModalSlice.actions.clearAddAddressDataForThatInstantAndCloseModal()
          )
          // props.toggleAddAddressModal()
          // SW.goToStep(4)
          SW.nextStep && SW.nextStep()
        }
      })
      .catch((err) => {
        if (err?.response?.status === 406 || err?.response?.status === 500) {
          dispatch(
            bookingWaiterModalSlice.actions.clearAddAddressDataForThatInstantAndCloseModal()
          )

          dispatch(bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal())
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmit = () => {
    const isValid = validate()

    if (isValid) {
      setLoading(true)

      // Adding user address
      axios({
        method: 'POST',
        url: `/user/address`,
        data: {
          city,
          state,
          country,
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      }).then((response) => {
        const { id, is_default, city, state, country } = response.data

        const newAddress = {
          address_id: id,
          is_default,
          city,
          landmark: null,
          pincode: null,
          state,
          country,
        }
        dispatch(
          bookingWaiterModalSlice.actions.addNewAddressToUserAddressesList(
            newAddress
          )
        )
        SW?.goToNamedStep('address')
        getSummaryData(id)
      })
    }
  }

  const onDeleteAddressHandler = (address_id) => {
    const new_addresses = addresses.filter(
      (address) => address_id != address.address_id
    )
    dispatch(
      bookingWaiterModalSlice.actions.setUserAddressData({
        addresses: new_addresses,
      })
    )
  }

  const handleBookingAddressInputChange = (addressId: number) => {
    dispatch(
      bookingWaiterModalSlice.actions.setSelectedAddressId(event.target.value)
    )
  }

  const validated = (REDUX__houseNumber, REDUX__street, REDUX__city) => {
    let house_number_error = ''
    let street_error = ''
    let city_error = ''
    if (!REDUX__houseNumber) {
      house_number_error = 'House number cannot be blank'
    }
    if (!REDUX__street) {
      street_error = 'Street cannot be blank'
    }
    if (!REDUX__city) {
      city_error = 'Please select city from drop-down list'
    }
    if (house_number_error || street_error || city_error) {
      dispatch(
        bookingWaiterModalSlice.actions.setEditAddressDataForThatInstant({
          house_number_error,
          street_error,
          city_error,
        })
      )
      return false
    }
    return true
  }

  const handleClose = () => {
    bookingWaiterModalSlice.actions.clearEditAddressDataForThatInstant()
  }

  const instantSaveAddress = (house_number, street, city, state, country) => {
    dispatch(
      bookingWaiterModalSlice.actions.setEditAddressDataForThatInstant({
        house_number,
        street,
        city,
        state,
        country,
      })
    )
  }

  const onSubmitHandler = (updatedAddress, index, addressId) => {
    dispatch(
      bookingWaiterModalSlice.actions.updateAddressInAddressList({
        updatedAddress,
        index: props.index,
      })
    )

    // Select the currently edited address
    dispatch(bookingWaiterModalSlice.actions.setSelectedAddressId(addressId))
  }

  // Auto Save DropDown Cities

  useEffect(() => {
    if (city) {
      onSubmit()
    }
  }, [city])

  return (
    <div>
      <p
        className={`${bmStyles.dark__grey__color} ${styles.font__size__20} mb-4`}
      >
        Which City do you need this in?
      </p>
      {addresses?.map(
        ({ address_id, house_number, street, city, state, country }, index) => {
          return (
            <AddressList
              key={address_id}
              address_id={address_id}
              house_number={house_number}
              street={street}
              city={city}
              state={state}
              country={country}
              index={index}
              onDeleteAddress={onDeleteAddressHandler}
              REDUX__bookingModalData={REDUX__bookingModalData}
              handleBookingAddressInputChange={handleBookingAddressInputChange}
              validate={validated}
              handleClose={handleClose}
              instantSaveAddress={instantSaveAddress}
              onSubmitHandler={onSubmitHandler}
            />
          )
        }
      )}

      <button
        type="button"
        className="btn border-dark m-4"
        onClick={() =>
          dispatch(
            bookingWaiterModalSlice.actions.toggleAddAddressModal({
              stepNo: 4,
            })
          )
        }
      >
        +Add City
      </button>
      <br />
      <small style={{ color: '#6C757D', paddingLeft: '1.5%' }}>
        * Full address needs to be filled after advance payment
      </small>
      <br />
      <Modal
        show={showNewServingCitiesModal}
        onHide={() =>
          dispatch(
            bookingWaiterModalSlice.actions.toggleNewServingCitiesModal(false)
          )
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        backdrop="static"
        style={{ backgroundColor: 'rgba(128,128,128,0.3)' }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className={styles.font__size__16}>
          <h6 className="text-muted">
            <br />
            For Bengaluru, Hyderabad, Mumbai, Thane:&nbsp;
            <a
              href="https://wa.me/919004044234?text=Hey,%20I%20want%20to%20book%20a%20cook%20%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%8D%B3%20"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chat on WhatsApp
            </a>
          </h6>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary" onClick={() => setShowOpenModal(false)}> */}
          <Button variant="primary">
            <a href="tel:+919004044234" style={{ color: 'white' }}>
              Call on 9004044234
            </a>
            {/* <Link href={whatsappSrc}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              >
                Chat on WhatsApp
              </a>
            </Link> */}
          </Button>
          <Button
            style={{ backgroundColor: 'white', borderColor: 'blue' }}
            onClick={() => {
              dispatch(
                bookingWaiterModalSlice.actions.toggleNewServingCitiesModal(
                  false
                )
              )
            }}
            className={styles.exit__alert__cta}
          >
            <span style={{ color: 'blue' }}>Exit</span>
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAPIAddressErrorModal}
        onHide={() =>
          dispatch(bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal())
        }
        size="lg"
        scrollable
        backdrop="static"
        dialogClassName="modal-dialog-wm"
      >
        <Modal.Header>
          <FaArrowLeft
            onClick={() =>
              dispatch(
                bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
              )
            }
            className={styles.cursor__pointer}
          />
          <Modal.Title className="w-100">
            <span className="m-0">
              <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
                <b>Sorry!</b>
              </p>
            </span>
          </Modal.Title>

          <FaTimes
            onClick={() =>
              dispatch(
                bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
              )
            }
            className={styles.cursor__pointer}
          />
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <br />
            <br />
            <div className="w-100 d-flex justify-content-center">
              <PanLoader />
            </div>
            <br />
            <br />

            <>
              <h6 className="text-muted">
                We are currently serving in Delhi NCR, Bengaluru, Mumbai,
                Hyderabad, Jaipur, Ahmedabad, Kolkata, Pune, Chandigarh, Goa.
              </h6>
              <h6 className="text-muted">
                Coming soon to&nbsp;
                <Button
                  onClick={() => {
                    // dispatch(
                    //   bookingWaiterModalSlice.actions.setUserAddressData({
                    //     addresses: addresses,
                    //     selectedAddressId: '',
                    //   })
                    // )
                    dispatch(
                      bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
                    )
                    SW?.goToNamedStep('address')
                  }}
                  variant="link"
                  // className="p-0"
                  style={{ padding: '0px 0px 3px 0px' }}
                >
                  {
                    addresses?.filter(
                      (city) => +city.address_id === +selectedAddressId
                    )[0]?.city
                  }
                  &nbsp; (Change City)
                </Button>
              </h6>
              {/* <br />
              <h6 className="text-muted">Coming soon to other cities also!</h6> */}
            </>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              dispatch(
                bookingWaiterModalSlice.actions.toggleAPIAddressErrorModal()
              )
            }
            variant="warning"
            type="button"
            block
          >
            Got it
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAddAddressModal}
        onHide={() =>
          dispatch(
            bookingWaiterModalSlice.actions.clearAddAddressDataForThatInstantAndCloseModal()
          )
        }
        size="lg"
        scrollable
        backdrop="static"
        dialogClassName="modal-dialog-wm"
      >
        <Modal.Header>
          <button
            className="p-0 m-0 btn btn-white"
            onClick={() =>
              dispatch(
                bookingWaiterModalSlice.actions.clearAddAddressDataForThatInstantAndCloseModal()
              )
            }
          >
            <FaArrowLeft />
          </button>

          <Modal.Title className="w-100">
            <span className="m-0">
              <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
                <b>Add City</b>
              </p>
            </span>
          </Modal.Title>

          <FaTimes
            onClick={() =>
              dispatch(
                bookingWaiterModalSlice.actions.clearAddAddressDataForThatInstantAndCloseModal()
              )
            }
            className={styles.cursor__pointer}
          />
        </Modal.Header>
        <Modal.Body>
          <ManualAddressWaiterAddition />
          <br />
          <br />
          <small style={{ color: '#6C757D' }}>
            * Full address needs to be filled after advance payment
          </small>
        </Modal.Body>
        <Modal.Footer>
          <h6 className="w-100 text-center text-muted ">
            Need help?&nbsp;
            {/* <a href={whatsappSrc} target="_blank" rel="noopener noreferrer">
              Chat on WhatsApp
            </a>{' '}
            or */}{' '}
            Call on <a href="tel:+919004044234">9004044234</a>
          </h6>
          <Button variant="warning" onClick={onSubmit} disabled={loading} block>
            Proceed
            {!loading && <FaArrowRight style={{ verticalAlign: 'text-top' }} />}
            {loading && '...'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default BookingAddress
