// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col, Button } from 'react-bootstrap'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'

// HELPERS
import {
  giveCountBasedOnTypeAndMealType,
  giveCommaSeparatedDishNamesBasedOnTypeAndMealType,
} from './helpers'

// DAYJS
import dayjs from 'dayjs'

// TS INTERFACES
import { RootState } from '@/lib/rootState'
// import BookingLevel from '../3_BookingLevel'

// COMPONENTS
import SpinningHashLoader from '../../../../SpinningHashLoader'
import BookingPaymentBody from '@/components/BookingPaymentBody'
import AddAssisantCookModal from '@/components/MyBookings/Modals/AddAssistantCookModal'
// USING DAYJS PLUGIN
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

// REACT ICONS
import { FaCheck } from 'react-icons/fa'

import axios from 'axios'
import { useEffect, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
export interface Promo {
  code: string
  description: string
  discount_amount: number
  expires_at: string | null
  is_fixed: boolean
  max_discount: number
  max_uses_user: number
  name: string
}

const initialPromoState = {
  code: '',
  description: '',
  discount_amount: 0,
  expires_at: null,
  is_fixed: false,
  max_discount: 0,
  max_uses_user: 0,
  name: '',
}

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const GreyTick = () => (
  <img src={tickMark} alt="tick" className={styles.grey__tick} />
)

const BookingSummary = (props): JSX.Element => {
  const dispatch = useDispatch()
  const REDUX__bookingModalData = useSelector(
    (state: RootState) => state.bookingModal
  )

  //local states
  const [promo, setPromo] = useState<Promo | { code: string }>(
    initialPromoState
  )
  const [promoLoading, setPromoLoading] = useState(false)
  const [couponError, setCouponError] = useState('')
  const [showPromoModal, setShowPromoModal] = useState(false)
  const [upgradeLoading, setUpgradeLoading] = useState(false)
  const [addAssistantLoading, setAddAssistantLoading] = useState(false)
  const [showUpgradeBookingModal, setShowUpgradeBookingModal] = useState(false)
  const [showAddAssistantModal, setShowAddAssistantModal] = useState(false)
  // const REDUX__bookingLevel = useSelector(
  //   (state: RootState) => state.bookingModal.bookingLevel
  // )

  // const { bookingLevel } = REDUX__bookingLevel

  // console.log('dff', bookingLevel)
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const {
    bookingDetails: {
      numberOfPeople = 0,
      bookingDate = '',
      bookingId,
      bookingLevel,
    },
    summary: {
      available_coupons,
      booking_section: {
        dishes = [],
        dish_served_with = [],
        number_of_cooks = 0,
        number_of_helpers = 0,
        expected_arrival_time = '',
        special_appliances = [],
        number_of_people,
      },
      invoice,
      payment_gateways,
    },
    payment: { paymentGateway },
    userAddressData: { addresses, selectedAddressId },
    upgradeBooking,
    addAssistant,
    citiesList,
  } = REDUX__bookingModalData

  const bookingLocation = addresses?.filter(
    (address) => +address?.address_id === +selectedAddressId
  )[0]
  const goToCityModalStep = () => {
    props.previousStep()
  }

  // show date as 21 Mar 2021
  const formattedBookingDate = dayjs(bookingDate).format('D MMMM YYYY, dddd')
  const dayName = new Date(bookingDate).getDay()

  // const breakfastBreakfastCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Breakfast',
  //   'breakfast'
  // )
  // const breakfastBeverageCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Beverage',
  //   'breakfast'
  // )

  //  lunch dishes
  // const LunchAppetizerCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Appetizer',
  //   'lunch'
  // )
  // const LunchMainCourseCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Main course',
  //   'lunch'
  // )
  // const LunchDessertCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Dessert',
  //   'lunch'
  // )
  // const LunchBreadCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Breads, Rice and Raita',
  //   'lunch'
  // )
  // const LunchBeverageCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Beverage',
  //   'lunch'
  // )

  // //  dinner dishes
  // const DinnerAppetizerCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Appetizer',
  //   'dinner'
  // )

  // const DinnerMainCourseCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Main course',
  //   'dinner'
  // )

  // const DinnerDessertCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Dessert',
  //   'dinner'
  // )
  // const DinnerBreadCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Breads, Rice and Raita',
  //   'dinner'
  // )
  // const DinnerBeverageCount = giveCountBasedOnTypeAndMealType(
  //   dishes,
  //   'Beverage',
  //   'dinner'
  // )

  // const breakfastCount = breakfastBreakfastCount + breakfastBeverageCount
  // const lunchCount =
  //   LunchAppetizerCount +
  //   LunchMainCourseCount +
  //   LunchDessertCount +
  //   LunchBreadCount +
  //   LunchBeverageCount
  // const dinnerCount =
  //   DinnerAppetizerCount +
  //   DinnerMainCourseCount +
  //   DinnerDessertCount +
  //   DinnerBeverageCount +
  //   DinnerBreadCount
  // const totalDishesCount = breakfastCount + lunchCount + dinnerCount

  // breakfast selected dishes
  // const breakfastSelectedBreakfast =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Breakfast',
  //     'breakfast'
  //   )

  // const breakfastSelectedBeverage =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Beverage',
  //     'breakfast'
  //   )

  // lunch selected dishes
  // const lunchSelectedAppetizers =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Appetizer',
  //     'lunch'
  //   )

  // const lunchSelectedMainCourse =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Main course',
  //     'lunch'
  //   )

  // const lunchSelectedDesserts =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Dessert',
  //     'lunch'
  //   )
  // const lunchSelectedBread = giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //   dishes,
  //   'Breads, Rice and Raita',
  //   'lunch'
  // )
  // const lunchSelectedBeverage =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Beverage',
  //     'lunch'
  //   )

  // dinner selected dishes
  // const dinnerSelectedAppetizers =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Appetizer',
  //     'dinner'
  //   )

  // const dinnerSelectedMainCourse =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Main course',
  //     'dinner'
  //   )

  // const dinnerSelectedDesserts =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Dessert',
  //     'dinner'
  //   )

  // const dinnerSelectedBread = giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //   dishes,
  //   'Breads, Rice and Raita',
  //   'dinner'
  // )

  // const dinnerSelectedBeverage =
  //   giveCommaSeparatedDishNamesBasedOnTypeAndMealType(
  //     dishes,
  //     'Beverage',
  //     'dinner'
  //   )

  // const selectedServedWithBreakfast = dish_served_with
  //   .filter((dish) => dish.meal_type === 'breakfast')[0]
  //   ?.dishes?.map((dish) => dish.name)
  //   .join(', ')

  // const selectedServedWithLunch = dish_served_with
  //   .filter((dish) => dish.meal_type === 'lunch')[0]
  //   ?.dishes?.map((dish) => dish.name)
  //   .join(', ')

  // const selectedServedWithDinner = dish_served_with
  //   .filter((dish) => dish.meal_type === 'dinner')[0]
  //   ?.dishes?.map((dish) => dish.name)
  //   .join(', ')
  const addToCart = async () => {
    // so that leave site dialog box do not open
    dispatch(bookingModalSlice.actions.setPaymentOpen(true))
    axios({
      method: 'POST',
      url: `/booking/${bookingId}/add-to-cart`,
      data: {
        add_assistant: addAssistant,
      },
    })
      .then((res) => {
        window.location.href = `/my-bookings?bookingId=${bookingId}&event=cart`
        console.log(res)
      })
      .catch((error) => {
        console.log(error)
      })
    // const res = await axios.post(
    //   `/booking/${bookingId}/add-to-cart?add_assistant=${addAssistant}`
    // )
    // setLoading(false)
  }
  const updateSummary = async (data = {}) => {
    try {
      if (data.upgrade_booking !== undefined) {
        setUpgradeLoading(true)
      }
      if (data.coupon_code !== undefined) {
        setPromoLoading(true)
      }
      if (data.add_assistant !== undefined) {
        setAddAssistantLoading(true)
      }

      const res = await axios.post('/payment/pre-payment', {
        booking_id: bookingId,
        payment_option: 2,
        ...data,
        coupon_code:
          data.coupon_code !== undefined
            ? data.coupon_code
            : promo.code || undefined,
        upgrade_booking:
          data.upgrade_booking !== undefined
            ? data.upgrade_booking
            : upgradeBooking,
        add_assistant:
          data.add_assistant !== undefined ? data.add_assistant : addAssistant,
      })
      if (res.data.data.invoice.invoice_footer.discount.valid === false) {
        setPromo(initialPromoState)
        if (data.coupon_code !== undefined) {
          setCouponError(res.data.data.invoice.invoice_footer.discount.message)
          throw new Error(res.data.data.invoice.invoice_footer.discount.message)
        }
      }
      let newSummary = JSON.parse(
        JSON.stringify(REDUX__bookingModalData.summary)
      )

      newSummary.booking_section.number_of_helpers =
        res.data.data.number_of_helpers
      newSummary.invoice = res.data.data.invoice
      dispatch(bookingModalSlice.actions.setBookingSummary(newSummary))
      // dispatch to update the invoice
      // dispatch(
      //   bookingModalSlice.actions.setBookingInvoice(res.data.data.invoice)
      // )

      setPromoLoading(false)
      setUpgradeLoading(false)
      setShowPromoModal(false)
      setAddAssistantLoading(false)
      if (data.upgrade_booking !== undefined) {
        dispatch(
          bookingModalSlice.actions.setUpgradeBooking(data.upgrade_booking)
        )
        setShowUpgradeBookingModal(false)
      }
      if (data.add_assistant !== undefined) {
        dispatch(bookingModalSlice.actions.setAddAssistant(data.add_assistant))
        setShowAddAssistantModal(false)
      }
    } catch (err) {
      setPromoLoading(false)
      setUpgradeLoading(false)
      setAddAssistantLoading(false)
      console.log('Something went wrong', err)
    }
  }

  const removePromo = async () => {
    setPromoLoading(true)
    updateSummary({ coupon_code: null })
    setCouponError('')
    setPromo(initialPromoState)
  }
  const addPromo = (promo: Promo) => {
    setCouponError('')
    setPromo(promo)
  }

  useEffect(() => {
    if (invoice?.invoice_footer?.discount?.valid === true) {
      try {
        // updateSummary({ coupon_code: invoice?.invoice_footer?.discount?.code })
        addPromo({ code: invoice?.invoice_footer?.discount?.code })
      } catch (err) {}
    }
  }, [invoice?.invoice_footer?.discount?.code])

  const couponApplyHandler = async (couponCode: string) => {
    // validate the Promocode
    setPromoLoading(true)
    // availableCoupons.forEach(async (coupon, index) => {
    // if (coupon.code === (couponCode || couponInput)) {
    try {
      updateSummary({ coupon_code: couponCode })
      addPromo({ code: couponCode })
    } catch (err) {
      // if (err.message === 'Invalid Coupon') {
      //   setCouponError('Invalid coupon')
      // }
      // alert('Something went wrong')
      // console.log(err.response)
    }
    // } else if (
    //   coupon.code !== (couponCode || couponInput) &&
    //   availableCoupons.length === index + 1
    // ) {
    // if no code is found
    // setPromoLoading(false)
    // setCouponError('Invalid coupon')
    // }
    // })
  }
  return (
    <div>
      <p className="mb-1">
        <FaCheck /> {number_of_cooks}{' '}
        {bookingLevel === 'Chef' ? 'Chef' : 'Cook'}{' '}
        {number_of_helpers > 0 && `and ${number_of_helpers} Assistant Cook `}
        will come{' '}
        <small className="text-muted">
          (Profile is visible once {bookingLevel} accepts booking){' '}
          {/* ({bookingLevel} will be assigned on {formattedBookingDate} ) */}
        </small>
        {/* {invoice?.invoice_middle?.helper_charge > 0 ||
        number_of_helpers > 0 ? null : (
          <Button
            variant="link"
            className="FONT__SIZE__14"
            onClick={() => setShowAddAssistantModal(true)}
          >
            +Add Assistant Cook
          </Button>
        )} */}
      </p>
      <p className="mb-1">
        <FaCheck /> Ingredients list is shared after booking{' '}
        <small className="text-muted">
          {' '}
          (list is simple with easily available items)
        </small>
      </p>
      <p className="mb-1">
        <FaCheck /> {bookingLevel === 'Chef' ? 'Chef' : 'Cook'} does not carry
        anything
      </p>
      <p className="mb-1">
        <FaCheck />{' '}
        {/* {lunchCount !== 0 && dinnerCount !== 0
              ? '8 am to 10 pm (total 14 hours of service)'
              : */}
        {
          dayjs(expected_arrival_time, 'HH:mm:ss').format('h:mm a') +
            ' to ' +
            dayjs(expected_arrival_time, 'HH:mm:ss')
              .add(invoice?.invoice_middle?.service_hours, 'hours')
              .format('h:mm a')
          // + ` (total ${invoice?.invoice_middle?.service_hours} hours of service)`
        }
        <small className="text-muted">
          {' '}
          (Chef will arrive by{' '}
          {dayjs(expected_arrival_time, 'HH:mm:ss').format('h:mm a')}){' '}
        </small>
        {props.SW && (
          <Button
            variant="link"
            size="sm"
            className="px-0 pt-0 pb-1"
            onClick={() => {
              props.SW.goToNamedStep('details')
            }}
          >
            <FaPencilAlt /> Edit
          </Button>
        )}
        {/* <small className="text-muted"> */}{' '}
        {/* (Overtime charges are ₹8.5/min if you extend) */}
        {/* after{' '} */}
        {/* {lunchCount !== 0 && dinnerCount !== 0
                ? '10 pm'
                :  */}
        {/* {dayjs(expected_arrival_time, 'HH:mm:ss')
                      .add(invoice?.invoice_middle?.service_hours, 'hours')
                      .format('ha')}
                    ) */}
        {/* </small> */}
      </p>
      <p className="mb-1">
        <FaCheck /> {formattedBookingDate}{' '}
        <small className={'text-muted'}>
          {invoice?.invoice_middle?.special_day_charge_description}
        </small>
        {props.SW && (
          <Button
            variant="link"
            size="sm"
            className="px-0 pt-0 pb-1"
            onClick={() => {
              props.SW.goToNamedStep('details')
            }}
          >
            <FaPencilAlt /> Edit
          </Button>
        )}
      </p>
      <p className="mb-1">
        <FaCheck />{' '}
        {bookingLocation?.house_number
          ? bookingLocation?.house_number + ', '
          : bookingLocation?.house_number}
        {bookingLocation?.street
          ? bookingLocation?.street + ', '
          : bookingLocation?.street}
        {bookingLocation?.city
          ? bookingLocation?.city + ', '
          : bookingLocation?.city}
        {bookingLocation?.state
          ? bookingLocation?.state
          : bookingLocation?.state}{' '}
        <Button
          variant="link"
          size="sm"
          className="px-0 pt-0 pb-1"
          onClick={goToCityModalStep}
        >
          <FaPencilAlt /> Edit
        </Button>
      </p>
      {showAddAssistantModal && (
        <AddAssisantCookModal
          show={showAddAssistantModal}
          setShow={setShowAddAssistantModal}
          addAssistantLoading={addAssistantLoading}
          setAddAssistantLoading={setAddAssistantLoading}
          addAssistantBooking={(add_assistant) => {
            updateSummary({ add_assistant })
          }}
        />
      )}

      {/* <hr />

      <h6 className="font-weight-bold"> What Time? </h6>
      <Row>
        <Col md={12} xs={12}>
          </Col>
      </Row> */}

      <hr />
      {/* <h6 className="font-weight-bold">
        FULL MENU ({totalDishesCount} dishes)
      </h6>
      <br />
      {breakfastCount !== 0 && (
        <>
          <h6 className="font-weight-bold">
            Breakfast ({breakfastCount} dishes)
          </h6>
          {breakfastSelectedBreakfast === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Breakfast ({breakfastBreakfastCount})
                </p>
                <p>{breakfastSelectedBreakfast}</p>
              </Col>
            </Row>
          )}
          {breakfastSelectedBeverage === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Beverages ({breakfastBeverageCount})
                </p>
                <p>{breakfastSelectedBeverage}</p>
              </Col>
            </Row>
          )}
          {selectedServedWithBreakfast === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Served with (Complimentary)
                </p>
                <p>{selectedServedWithBreakfast}</p>
              </Col>
            </Row>
          )}

          <hr />
        </>
      )}

      {lunchCount !== 0 && (
        <>
          <h6 className="font-weight-bold">Lunch ({lunchCount} dishes)</h6>
          {lunchSelectedAppetizers === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Appetizers ({LunchAppetizerCount})
                </p>
                <p>{lunchSelectedAppetizers}</p>
              </Col>
            </Row>
          )}
          {lunchSelectedMainCourse === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Main Course ({LunchMainCourseCount})
                </p>
                <p>{lunchSelectedMainCourse}</p>
              </Col>
            </Row>
          )}
          {lunchSelectedBread === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Breads, Rice and Raita({LunchBreadCount})
                </p>
                <p>{lunchSelectedBread}</p>
              </Col>
            </Row>
          )}
          {lunchSelectedBeverage === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Beverages ({LunchBeverageCount})
                </p>
                <p>{lunchSelectedBeverage}</p>
              </Col>
            </Row>
          )}
          {lunchSelectedDesserts === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Dessert ({LunchDessertCount})
                </p>
                <p>{lunchSelectedDesserts}</p>
              </Col>
            </Row>
          )}
          {selectedServedWithLunch === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Served with (Complimentary)
                </p>
                <p>{selectedServedWithLunch}</p>
              </Col>
            </Row>
          )}

          <hr />
        </>
      )}

      {dinnerCount !== 0 && (
        <>
          <h6 className="font-weight-bold">Dinner ({dinnerCount} dishes)</h6>
          {dinnerSelectedAppetizers === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Appetizers ({DinnerAppetizerCount})
                </p>
                <p>{dinnerSelectedAppetizers}</p>
              </Col>
            </Row>
          )}
          {dinnerSelectedMainCourse === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Main Course ({DinnerMainCourseCount})
                </p>
                <p>{dinnerSelectedMainCourse}</p>
              </Col>
            </Row>
          )}
          {dinnerSelectedBread === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Breads, Rice and Raita ({DinnerBreadCount})
                </p>
                <p>{dinnerSelectedBread}</p>
              </Col>
            </Row>
          )}
          {dinnerSelectedBeverage === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Beverages ({DinnerBeverageCount})
                </p>
                <p>{dinnerSelectedBeverage}</p>
              </Col>
            </Row>
          )}
          {dinnerSelectedDesserts === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Dessert ({DinnerDessertCount})
                </p>
                <p>{dinnerSelectedDesserts}</p>
              </Col>
            </Row>
          )}
          {selectedServedWithDinner === '' ? (
            ''
          ) : (
            <Row>
              <Col md={12} xs={12}>
                <p className=" d-inline px-0 font-weight-bold text-muted mb-0">
                  Served with (Complimentary)
                </p>
                <p>{selectedServedWithDinner}</p>
              </Col>
            </Row>
          )}

          <hr />
        </>
      )} */}

      {/* <h6 className="font-weight-bold">Ingredients</h6>

      <Row>
        <Col md={12} xs={12}>
          <p className="mb-2">
            <GreyTick />
            Ingredients list is shared once you confirm the booking
          </p>
          <p className="mb-2">
            <GreyTick />
            List is simple with easily available items
          </p>
          <p className="mb-2">
            <GreyTick />
            You have to arrange ingredients yourself
          </p>
          <p>
            <GreyTick />
            Quantity will be for {numberOfPeople} People
          </p>
        </Col>
      </Row> */}

      {/* <hr />

      <>
        <Row>
          <Col>
            <h6 className="font-weight-bold">Appliances Required</h6>
            {special_appliances?.map((appliance) => appliance).join(', ')}
            {special_appliances?.length > 0
              ? ', Other basic kitchen appliances'
              : 'Basic kitchen appliances'}

            <p className="mt-2">
              <GreyTick />
              We don't carry any appliance. We use those available in your
              kitchen
            </p>
          </Col>
        </Row>
      </> */}
      <BookingPaymentBody
        REDUX__bookingModalData={REDUX__bookingModalData}
        invoice={invoice}
        payment_gateways={payment_gateways}
        availableCoupons={available_coupons}
        paymentGateway={paymentGateway}
        number_of_dishes={dishes?.length}
        number_of_people={number_of_people}
        setPaymentGateway={(value: GatewayTypes) =>
          dispatch(bookingModalSlice.actions.setPaymentGateway(value))
        }
        updateSummary={updateSummary}
        proceedingToNextStep={props.proceedingToNextStep}
        promoLoading={promoLoading}
        setPromoLoading={setPromoLoading}
        couponError={couponError}
        setCouponError={setCouponError}
        removePromo={removePromo}
        promo={promo}
        showPromoModal={showPromoModal}
        setShowPromoModal={setShowPromoModal}
        addAssistantLoading={addAssistantLoading}
        couponApplyHandler={couponApplyHandler}
        upgradeLoading={upgradeLoading}
        upgradeBooking={upgradeBooking}
        setUpgradeLoading={setUpgradeLoading}
        showUpgradeBookingModal={showUpgradeBookingModal}
        setShowUpgradeBookingModal={setShowUpgradeBookingModal}
        SW={props.SW}
        citiesList={citiesList}
        addToCart={addToCart}
        ispre={true}
        bookingLevel={bookingLevel}
      />
    </div>
  )
}

export default BookingSummary
