// BOOTSTRAP
import { Dispatch, SetStateAction } from 'react'
import { Container, Modal, Row, Col, Button } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'
// STYLES
import styles from './index.module.css'

const tickMark =
  'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/grey_tick.png'

const GreyTick = () => (
  <img src={tickMark} alt="tick" className={styles.grey__tick} />
)

interface Props {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

const CancellationPolicyModal = (props: Props): JSX.Element => {
  const { show, setShow } = props
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      scrollable
      backdrop="static"
      dialogClassName="modal-dialog-wm"
    >
      <Modal.Header closeButton>
        <button
          onClick={() => setShow(false)}
          className="btn btn-white m-0 p-0"
        >
          <FaArrowLeft />
        </button>
        <Modal.Title className="w-100">
          <span className="m-0">
            <p className={`w-100 text-center mb-0 ${styles.font__size__20}`}>
              <b>Cancellation Policy</b>
            </p>
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Container> */}
        <br /> <br />
        <h5 className="ml-4">
          <>
            {' '}
            - You can put booking On hold / Postpone for{' '}
            <b>FREE till 24 hours</b> before partner arrival time
          </>
        </h5>
        <br />
        <h5 className="ml-4">
          <>
            {' '}
            - You can put booking On hold / Postpone for{' '}
            <b>₹299 within 24 hours</b> of partner arrival time
          </>
        </h5>
        <br />
        <h5 className="ml-4">
          <>
            {' '}
            - You can permanently cancel the booking anytime. Advance amount is
            non-refundable
          </>
        </h5>
        {/* </Container> */}
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Button
            variant="warning"
            type="button"
            block
            // style={{color:'black', border: '2px solid #fcba03'}}
            onClick={() => setShow(false)}
            className="d-inline-flex align-items-center justify-content-center"
          >
            Okay
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default CancellationPolicyModal
